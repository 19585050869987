<template>
    <div class="page">
        <div class="header u-flex u-row-between u-col-center">
            <span class="title">异常日志</span>
        </div>
        <el-form :model="searchForm" class="search-form" inline>
            <el-form-item label="标题：" prop="date_between">
                <el-input v-model="searchForm.name" placeholder="请输入标题" size="small"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                    @click="searchTable('search')">搜索</el-button>
                <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                    @click="searchTable('reset')">重置
                </el-button>
            </el-form-item>
        </el-form>
        <!-- <div class="operate-box u-flex u-col-center">
            <div>
                <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary" @click="create">新增
                </el-button>
            </div>
        </div> -->
        <el-table v-loading="loading" :data="tableData" row-key="id" style="width: 100%;"
            header-row-class-name="header-table">
            <el-table-column label="请求标题" prop="item" align="center">
            </el-table-column>
            <el-table-column label="请求内容" prop="content" align="center">
                <template slot-scope="scope">
                    <div class="content" v-html="scope.row.content.content">
                        
                    </div>
                    <!-- <pre  style="display: block;padding: 9.5px;margin: 4px 0 0 0;
                    font-size: 13px;line-height: 1.42857;color: #333;
                    word-break: break-all;word-wrap: break-word;background-color: #F5F5F5;
                    border: 1px solid #CCC;border-radius: 4px;text-align:left;overflow-x:auto;" v-html="scope.row.content"></pre> -->
                        <!-- <pre  style="display: block;padding: 9.5px;margin: 4px 0 0 0;
                    font-size: 13px;line-height: 1.42857;color: #333;
                    word-break: break-all;word-wrap: break-word;background-color: #F5F5F5;
                    border: 1px solid #CCC;border-radius: 4px;">{{scope.row.content}}</pre> -->
                </template>
            </el-table-column>
            <!-- <el-table-column label="图书定价" prop="fix_price" align="center">
                <template slot-scope="scope">
                    ￥{{scope.row.fix_price}}
                </template>
            </el-table-column>
            <el-table-column label="进货次数" prop="purchase_times" align="center">
                <template slot-scope="scope">
                    {{scope.row.purchase_times}}次
                </template>
            </el-table-column>
            <el-table-column label="配发册数" prop="allotted_number" align="center">
            </el-table-column>
            <el-table-column label="配发数量定价" prop="allotted_number_fix_price" align="center">
                <template slot-scope="scope">
                    ￥{{scope.row.allotted_number_fix_price}}
                </template>
            </el-table-column>
            <el-table-column label="发货次数" prop="delivered_times" align="center">
                <template slot-scope="scope">
                    {{scope.row.delivered_times}}次
                </template>
            </el-table-column> -->
            <el-table-column label="创建日期" prop="add_time" align="center">
            </el-table-column>
            <el-table-column align="center" label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <span class="o-btn" @click="showEditLayer(scope.row)">查看详情</span>
                    <span class="o-btn o-btn-red" @click="remove(scope.row.id)">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="u-flex u-row-end u-p-t-32 u-p-b-24">
            <el-pagination :current-page="page.currentPage" :page-size="page.pageSize" :total="page.total"
                layout="total, prev, pager, next, jumper" @current-change="changePage">
            </el-pagination>
        </div>
        <!-- 详情弹窗 -->
        <el-dialog :close-on-click-modal="false" title="内容详情" :visible.sync="dialogVisible" destroy-on-close top="5vh">
            <div v-html="content">

            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "AbnormalLog",
    data() {
        return {
            loading: false,
            dialogVisible: false,
            page: {
                currentPage: 1,
                pageSize: 20,
                total: 1
            },
            searchForm: {
                name: '',
            },
            tableData: [],
            content:'"#0 \/web\/wwwroot\/dev.tszd.com\<br/>/app\/backend\/controller\/ApiController.php(95): think\\Validate->check()\n#1 \/web\/wwwroot\/dev.tszd.com\/app\/backend\/controller\/other\/BookRecommendCriticismController.php(76'
        }
    },
    created() {
        this.getData()
    },
    methods: {
        changePage(val) {
            this.page.currentPage = val;
            this.getData()
            // console.log(`当前页: ${val}`);
        },
        searchTable(t) {
            if (t === 'search') {
                this.getData()
            } else {
                this.searchForm = {
                    date_between: [],
                }
                this.getData()
            }
        },
        showEditLayer(e){
            this.content = e.content
            this.dialogVisible = true
        },
        remove(id) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('/backend/debug/delete', id).then(res => {
                    if (res.code === 1) {
                        this.$message.success('删除成功')
                        this.getData()
                    }
                })
            })
        },
        getData() {
            this.loading = true
            this.searchForm.page = this.page.currentPage
            this.$http.get('/backend/debug/list', {
                params: this.searchForm
            }).then(res => {
                if (res.code === 1) {
                    this.tableData = res.data.list.data.map(item => {
                        // item.content = item.content.replace(/(\n|\r|\r\n|↵)/g, '<br/>')
                        return item
                    })
                    this.page.total = res.data.list.total
                }
                console.log(this.tableData);
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 24px 32px;
}

.header {
    padding-bottom: 24px;
    border-bottom: solid 1px #DCDFE6;
    display: flex;
    justify-content: space-between;

    .title {
        font-size: 20px;
    }
}

.el-table {
    margin-top: 24px;

    .o-btn {
        color: #409EFF;
        cursor: pointer;

        ~.o-btn {
            margin-left: 12px;
        }
    }

    .content {
        overflow-x: auto;
        background-color: #f5f5f5;
        text-align-last: left;
    }
}

.cover {
    height: 80px;
}

/deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader {



    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}

.create-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
</style>
