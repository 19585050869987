<template>
    <div class="page boatPlan_Dateils" id="pageTop" v-if="infoData?.id">
        <el-page-header @back="goBack" content="查看详情"></el-page-header>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="下达日期：">
                <el-input v-model="infoData.createTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="承运商名称：">
                <el-input v-model="infoData.carrier_name.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="船名：">
                <el-input v-model="infoData.ship_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="出发港：">
                <el-input v-model="infoData.ship_port_begin.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="到达港：">
                <el-input v-model="infoData.ship_port_end.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="调整后港口：" v-if="infoData.changfeDestStr">
                <el-input v-model="infoData.changfeDestStr" disabled></el-input>
            </el-form-item>
            <el-form-item label="油品：">
                <el-input v-model="infoData.oil_type_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="数量(吨)：">
                <el-input v-model="infoData.oilTon" disabled></el-input>
            </el-form-item>
            <el-form-item label="流向：">
                <el-input v-model="infoData.company_name.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="属性：">
                <el-input v-model="infoData.KUNTP.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="计划状态：">
                <el-input v-model="infoData.statesName" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">发运信息</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="炼厂办事处：">
                <el-input v-model="infoData.office_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="发运时间：">
                <el-input v-model="infoData.startTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="船板量(吨)：">
                <el-input v-model="infoData.plankingTon"></el-input>
            </el-form-item>
            <el-form-item label="完成数(吨)：">
                <el-input v-model="infoData.shipErpAmount" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">卸油信息</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="船板量：">
                <el-input v-model="infoData.oildischargeplankingTon" ></el-input>
            </el-form-item>
            <el-form-item label="卸油量：">
                <el-input v-model="infoData.realTon" ></el-input>
            </el-form-item>
        </el-form>
        <el-button style="margin: 20px;width: 110px; display: block;" icon="el-icon-check"  type="primary" @click="submit()">提交</el-button>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
</template>
    
<script>
export default {
    name: "boatPlan_Dateils",
    data() {
        return {
            cxIndex: "",
            showReady: true,
            infoData: {
                title: '',
                birthday: '',
                job: '',

            },
            shipJob: [],
            infoData: {},
            infoFiel: [],
            dict: {
                staicData: []
            }
        }
    },
    created() {
        let id = this.$route.params.id
        this.getstaicData()
        if (id) {
            let data = {
                id: id,
            }
            this.$Api.shipPlan_shipPlanDetail(data).then(res => {
                if (res.code == 200) {
                    this.infoData = res.data
                    if (this.dict.staicData.length > 0) {
                        this.infoData.statesName = this.dict.staicData[this.infoData.states]
                    }
                    if (this.infoData.tonConfirmStatus == 0) {
                        let msg = "<div style='color: red;'>未确认</div>"
                        if (this.infoData.oildischargeStatus == 2) {
                            msg = `<div style='color: green;'>已驳回${this.infoData.tonConfirmDesc}</div>`
                        }
                        if (this.infoData.tonConfirmStatus == 0) {
                            this.infoData.tonConfirmStatusTxt = msg
                        } else {
                            this.infoData.tonConfirmStatusTxt = "<div style='color: red;'>已确认</div>"
                        }
                    }
                }
            })
            this.$Api.shipPlan_GetFileInfo(data).then(res => {
                if (res.code == 200) {
                    this.infoFiel[0] = res.data.ChangeList
                }
            })
        }
    },
    activated() {

    },
    mounted() {

    },
    methods: {
        submit(){
            let data = {
                id:this.$route.params.id,
                oildischargeplankingTon:this.infoData.oildischargeplankingTon,
                plankingTon:this.infoData.plankingTon,
                realTon:this.infoData.realTon
            }
            this.$Api.ShipPlan_UpdateAmountData(data).then(res=>{
                if(res.code == 200) {
                    this.$message.success('操作成功')
                }
            })
        },
        getstaicData() {
            this.$Api.publics_staicData().then(res => {
                if (res.code == 200) {
                    this.dict.staicData = res.data.shipPlanStates
                }
            })
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/numQualityManage/boatDataEdit')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        // --------------------------公共方法--------------------------------------
    }
}
</script>
    
<style lang="scss" scoped>
.boatPlan_Dateils {
    height: initial;

    .el-page-header {
        line-height: 50px;
    }

    /deep/.el-input {
        width: 182px !important;
    }

    /deep/.el-divider__text.is-left {
        font-size: 20px;
        font-weight: 500;
    }
}
</style>