<template>
  <div class="page Economize">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="姓名：">
          <el-input v-model="searchForm.title" placeholder="请输入姓名" size="small"></el-input>
        </el-form-item>
        <el-form-item label="登录名：">
          <el-input v-model="searchForm.account" placeholder="请输入登录名" size="small"></el-input>
        </el-form-item>
        <el-form-item label="单位：">
          <el-cascader v-model="searchForm.groupId" size="small"
            :props="{ value: 'id', label: 'title', children: '_child', checkStrictly: true }" placeholder="选择或搜索选择单位"
            :options="SSList" filterable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <!--列表-->
      <el-table v-if="ready" ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="姓名" prop="title">
        </el-table-column>
        <el-table-column align="center" label="角色" prop="role_info.roleName">
        </el-table-column>
        <el-table-column align="center" label="登录名" prop="account">
        </el-table-column>
        <el-table-column align="center" label="部门" prop="section_info.title">
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑角色</el-button>
            <el-button type="primary" size="mini" @click="setoil(scope.row)">绑定油库</el-button>
            <el-button type="danger" size="mini" @click="setstates(scope.row, 0)"
              v-if="scope.row.states == 1">禁用</el-button>
            <el-button size="mini" @click="setstates(scope.row, 1)" v-else>启用</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="30vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="选择角色：" prop="roleId">
            <el-select v-model="createdData.roleId" filterable placeholder="请选择角色">
              <el-option v-for="(item, index) in roleList" :key="item.index" :label="item.roleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="移动端账号：" prop="mobileAccount">
            <div class="moblie">
              <el-input v-model="createdData.mobileAccount" placeholder="请输入移动端账号"></el-input>
            </div>
            <!-- <span style="color: #999;margin-left: 10px;">请准确填写手机端账号，格式为：***.xshz</span> -->
          </el-form-item>
          <el-form-item label="是否启用：" prop="enableMobile">
            <el-select v-model="createdData.enableMobile" filterable placeholder="请选择是否启用">
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 绑定油库 -->
      <el-dialog :close-on-click-modal="false" title="绑定油库" :visible.sync="dialogVisible2" destroy-on-close top="5vh"
        width="60%">
        <div class="oilbox">
          <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
              <el-form-item label="姓名：">
                <el-input v-model="oilcreatedData.title" disabled size="small"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="u-custom-btn-primary" icon="el-icon-check" size="small" type="primary"
                  @click="bindoil">保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="oilboxList">
            <!--油库列表-->
            <el-table ref="multipleTabless" border :data="oildList" tooltip-effect="dark" style="margin:0 auto;"
              @selection-change="handleSelectionChange2" header-row-class-name="header-table">
              <el-table-column align="center" type="selection" width="55">
              </el-table-column>
              <el-table-column align="center" label="油库名称" prop="title">
              </el-table-column>
              <el-table-column align="center" label="省市公司" prop="group.subtitle">
              </el-table-column>
              <el-table-column align="center" label="港口名称" prop="ship_port.title">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-dialog>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Economize",
  data() {
    return {
      ready: true,
      isAdd: false,
      multipleSelection: [],
      searchForm: {
        title: '',
        groupId: '',
        account: '',
        groupId: "",
        title: "",
        phoneNum: ""
      },
      createdData: {
        roleId: "",
        enableMobile: '',
        mobileAccount: '',
        id: ''
      },
      rules: {
        roleId: {
          required: true, message: '请选择角色', trigger: 'blur'
        },
        mobileAccount: {
          required: true, message: '请输入移动端账号', trigger: 'blur'
        },
      },
      oilcreatedData: {
        id: '',
        userId: '',
        title: ''
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      SSList: [],
      roleList: [],
      oildList: [],
      oilSelect: []
    }
  },
  activated() {
    this.ready = false
    this.$nextTick(() => {
      this.ready = true
      this.$refs.multipleTable.doLayout()
    })
  },
  
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getSS()
    that.getRole()
  },
  methods: {
    bindoil() {
      let data = {
        id: this.oilcreatedData.userId,
        oilwarehouseIds: ''
      }
      if (this.oilSelect.length != 0) {
        let ss = []
        this.oilSelect.map(item => {
          ss.push(item.id)
        })
        data.oilwarehouseIds = ss.join(',')
      }
      this.$Api.member_saveBindoilwarehouse(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.dialogVisible2 = false
          this.getData()
        }
      })
    },
    setoil(row) {
      this.oilcreatedData.id = row.groupId
      this.oilcreatedData.title = row.title
      this.oilcreatedData.userId = row.id
      this.$Api.bases_getOilWarehouse({ id: row.id, page: 1, limit: 999 }).then(res => {
        this.oildList = res.data.data
        this.oildList.map(item => {
          if (item.LAY_CHECKED == true) {
            this.$nextTick(() => {
              this.$refs.multipleTabless.toggleRowSelection(item, true)
            })
          }
        })
      })

      this.dialogVisible2 = true
    },
    setstates(row, id) {
      let data = {
        id: row.id
      }
      if (id == 1) {
        this.$Api.member_enable(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      } else {
        this.$Api.member_disable(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            roleId: this.createdData.roleId,
            enableMobile: this.createdData.enableMobile,
          }
          if (this.createdData.mobileAccount != '' && this.createdData.mobileAccount != null) {
            data.mobileAccount = this.createdData.mobileAccount + '.xshz'
          } else {
            data.mobileAccount = ''
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.member_role_setUserRole(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        roleId: '',
        enableMobile: '',
        mobileAccount: ''
      }
      this.$refs['form'].resetFields();
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.oilSelect = val;
    },
    editerow(row) {
      this.isAdd = false;
      this.createdData = {
        roleId: row.role_info.id,
        enableMobile: row.enableMobile,
        mobileAccount: row.mobileAccount,
        id: row.id
      }
      if (row.mobileAccount != '' && row.mobileAccount != null) {
        let ss = row.mobileAccount.split('.');
        this.createdData.mobileAccount = ss[0]
      }
      this.dialogVisible = true;
    },
    deleterow(row) {
      this.$confirm('确定删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.company_detele(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // --------------------------公共方法--------------------------------------
    handleSizeChange(val) { //切换每页条数
     this.page.pageSize = val;
this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getRole() {
      let data = {
        limit: 999
      }
      this.$Api.member_role_getList(data).then(res => {
        this.roleList = res.data.data;
      })
    },
    getSS() {
      let data = {
        states: 1,
        show_type: 1,
        page: 1,
        limit: 999
      }
      this.$Api.getDict(data).then(res => {
        this.SSList = res.data;
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.searchForm.title,
        groupId: this.searchForm.groupId[0],
        section: this.searchForm.groupId[this.searchForm.groupId.length - 1],
        account: this.searchForm.account,
        memberType: 3
      }
      this.tableLoading = true
      this.$Api.member_getMemberList(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        if (res.data.data.length != 0) {
          res.data.data.map(item => {
            if (item.mobileAccount != '' && item.mobileAccount != null) {
              let ss = item.mobileAccount.split('.');
              item.mobileAccount = ss[0]
            }
          })
        }
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.Economize {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .moblie {
      /deep/.el-input__inner {
        width: 220px;
      }
    }

    .oilbox {
      width: 100%;
      height: 70vh;
      display: flex;
      flex-direction: column;

      .oilboxList {
        height: 1;
        flex: 1;
        overflow-y: scroll;
      }
    }
  }
}
</style>