<template>
    <div class="page assessmentTasks_Details">
        <el-page-header @back="goBack" content="考核项">
        </el-page-header>
        <div class="container">
            <div class="title">考核名称: {{ rowData.title }}</div>
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
                        @click="addRole">添加</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" size="small" type="primary"
                        @click="getData">刷新</el-button>
                </div>
                <div class="right"></div>
            </div>
            <!--列表-->
            <el-table v-if="isread" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
                tooltip-effect="dark" height="(100% - 104px)" style="width: 98%;margin:0 auto;"
                header-row-class-name="header-table">
                <el-table-column align="center" label="考核项目" prop="title" width="200">
                </el-table-column>
                <el-table-column align="center" label="分类" prop="itemtype_info.title" width="150">
                </el-table-column>
                <el-table-column align="center" label="权重" prop="itemtype_info.percent" width="55">
                    <template slot-scope="scope">
                        <span>{{ scope.row.itemtype_info.percent }}%</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="规则" prop="roles">
                    <template slot-scope="scope">
                        <el-popover placement="top-start" width="700" trigger="hover" :content="scope.row.roles">
                            <div class="rolesBox" slot="reference">{{ scope.row.roles }}</div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="分值" prop="points" width="55">
                </el-table-column>
                <el-table-column align="center" label="评分人" prop="rater_info.uSubName" width="150">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-10" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 添加/编辑 -->
            <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible"
                destroy-on-close top="10vh" width="700px">
                <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
                    <el-form-item label="考核项名称：" prop="title">
                        <el-input v-model="createdData.title" clearable placeholder="请输入考核项名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目分类：" prop="itemtype">
                        <el-select v-model="createdData.itemtype" placeholder="请选择项目分类" filterable style="width: 100%;">
                            <el-option v-for="item in typeList" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分值：" prop="points">
                        <el-input v-model="createdData.points" type="number" clearable placeholder="请输入分值"></el-input>
                    </el-form-item>
                    <el-form-item label="评分人：" prop="rater">
                        <el-select v-model="createdData.rater" placeholder="请选择评分人" filterable style="width: 100%;">
                            <el-option v-for="item in member" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="考核细则：" prop="roles">
                        <el-input v-model="createdData.roles" type="textarea" :rows="8" placeholder="请输入考核细则"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuse">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "assessmentTasks_Details",
    data() {
        return {
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            createdData: {
                id: '',
                title: '',
                assessId: '',
                roles: '',
                points: '',
                itemtype: '',
                rater: '',
            },
            rules: {
                title: {
                    required: true, message: '请输入考核项名称', trigger: 'blur'
                },
                roles: {
                    required: true, message: '请输入考核细则', trigger: 'blur'
                },
                points: {
                    required: true, message: '请输入分值', trigger: 'blur'
                },
                itemtype: {
                    required: true, message: '请选择项目分类', trigger: 'blur'
                },
                rater: {
                    required: true, message: '请选择评分人', trigger: 'blur'
                },
            },
            oilTypes: [],
            member: [],
            typeList: []
        }
    },
    created() {
        this.rowData = this.$route.params
        this.getData()
        this.getMember()
        this.getType()
    },
    activated() {
        this.rowData = this.$route.params
        this.getData()
        this.getMember()
        this.getType()
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        deleterow(row) {
            this.$confirm('确定删除该项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.carriers_deleteAssessItem2(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerow(row) {
            this.createdData = {
                id: row.id,
                title: row.title,
                assessId: row.assessId,
                roles: row.roles,
                points: row.points,
                itemtype: row.itemtype,
                rater: row.rater,
            }
            this.isAdd = false
            this.dialogVisible = true
        },
        subRefuse() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = this.createdData
                    data.sort = 0
                    data.states = 1
                    if (this.isAdd) {
                        data.assessId = this.rowData.id
                        delete data.id
                    }
                    this.$Api.carriers_saveAssessItem(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.dialogVisible = false
                            this.clearCreateData()
                            this.getData()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        addRole() {
            this.dialogVisible = true
            this.isAdd = true
            this.clearCreateData()
        },
        clearCreateData() {
            this.createdData = {
                id: '',
                title: '',
                assessId: '',
                roles: '',
                points: '',
                itemtype: '',
                rater: '',
            }
            this.$refs['form'].resetFields();
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/carrier/assessmentTasks_Details')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getType() {
            this.$Api.carriers_getAssessItemTypes().then(res => {
                if (res.code == 200) {
                    let arr = []
                    if (res.data.length > 0) {
                        if (this.rowData.assessType == 3) {
                            res.data.map(item => {
                                if (item.type == 2) {
                                    arr.push(item)
                                }
                            })
                        } else {
                            res.data.map(item => {
                                if (item.type == 1) {
                                    arr.push(item)
                                }
                            })
                        }
                        this.typeList = arr
                    }

                }

            })
        },
        getMember() {
            this.$Api.publicCharacter_Dict().then(res => {
                if (res.code == 200) {
                    if (res.data.member.length > 0) {
                        let arr = []
                        res.data.member.map(item => {
                            if (item.states == 1 && item.memberType == 1) {
                                arr.push(item)
                            }
                        })
                        this.member = arr
                    }
                }
            })
        },
        getData() { // 获取数据
            let data = {
                id: this.rowData.id,
            }
            this.tableLoading = true
            this.isread = false
            this.$Api.carriers_getAssessItemList(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total
                    this.tableData = res.data.data
                }
            })
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isread = true
                })
            })

        },
    }
}
</script>
  
<style lang="scss" scoped>
.assessmentTasks_Details {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    .container {
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .title {
            margin: 0px 0 12px;
            padding-left: 15px;
            font-size: 16px;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
</style>