<template>
  <div class="page BoatData">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="5">
            <el-form-item label="流向：">
              <el-select clearable v-model="searchForm.belongId_new" placeholder="请选择流向" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="属性：">
              <el-select clearable v-model="searchForm.KUNTPLIST_new" placeholder="请选择属性" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.groupCode"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="发港：">
              <el-select clearable v-model="searchForm.startPort_new" placeholder="请选择发港" filterable size="small">
                <el-option v-for="(item, index) in dict.shipPortBegin" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="到港：">
              <el-select clearable v-model="searchForm.destPort_new" placeholder="请选择到港" filterable size="small">
                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="品号：">
              <el-select clearable v-model="searchForm.oilList_new" placeholder="请选择品号" filterable size="small">
                <el-option v-for="(item, index) in dict.oilList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="5">
            <el-form-item label="录入开始：">
              <el-date-picker size="small" v-model="searchForm.arrivalTimeEnteringStart" value-format="yyyy-MM-dd"
                type="date" placeholder="请选择发运开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="录入结束：">
              <el-date-picker size="small" v-model="searchForm.arrivalTimeEnteringEnd" value-format="yyyy-MM-dd"
                type="date" placeholder="请选择发运结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="承运商：">
              <el-select clearable v-model="searchForm.carrierList_new" placeholder="请选择承运商" filterable size="small">
                <el-option v-for="(item, index) in dict.carrierList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="船舶：">
              <el-select clearable v-model="searchForm.shipList_new" placeholder="请选择船舶" filterable size="small">
                <el-option v-for="(item, index) in dict.shipList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="原发量：">
              <el-input v-model="searchForm.shipErpAmount" type="number" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg">
          <el-col :span="5">
            <el-form-item label="定损率：">
              <el-select clearable v-model="searchForm.feerate_filter" placeholder="请选择定损率" size="small">
                <el-option value="0" label="全部"></el-option>
                <el-option value="1" label="默认定损率(1.3)"></el-option>
                <el-option value="2" label="大于1.3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="是否超耗：">
              <el-select clearable v-model="searchForm.overSum_filter" placeholder="请选择是否超耗" size="small">
                <el-option value="0" label="全部"></el-option>
                <el-option value="1" label="未超耗"></el-option>
                <el-option value="2" label="有超耗"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" class="outbox">
            <el-form-item label="导出损耗类型：">
              <el-select clearable v-model="searchForm.export_type" placeholder="请选择导出损耗类型" size="small">
                <el-option value="0" label="基础统计"></el-option>
                <el-option value="1" label="炼厂损耗"></el-option>
                <el-option value="2" label="承运商损耗"></el-option>
                <el-option value="3" label="省市损耗"></el-option>
                <el-option value="4" label="全部(耗时长)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                @click="searchTable('search')">搜索
              </el-button>
              <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                @click="searchTable('reset')">重置
              </el-button>
              <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                @click="searchTable('search')">导出
              </el-button>
              <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                @click="searchTable('search')">导出损耗
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" type="index" width="55">
        </el-table-column>
        <el-table-column align="center" label="计划月份" prop="monthly" width="100">
        </el-table-column>
        <el-table-column align="center" label="发运时间" prop="startTime" width="100">
        </el-table-column>
        <el-table-column align="center" label="收卸时间" prop="arrivalTime" width="100">
        </el-table-column>
        <el-table-column align="center" label="在途天数" prop="onLineDays" width="100">
        </el-table-column>
        <el-table-column align="center" label="处理时间" prop="arrivalTimeEntering" width="100">
        </el-table-column>
        <el-table-column align="center" label="发港" prop="startTitle" width="120">
        </el-table-column>
        <el-table-column align="center" label="属性" prop="KUNTPNAME" width="100">
        </el-table-column>
        <el-table-column align="center" label="流向" prop="belongIdName" width="100">
        </el-table-column>
        <el-table-column align="center" label="到达港" prop="destOilTitle" width="120">
        </el-table-column>
        <el-table-column align="center" label="油库" prop="oilWarehouseTitle" width="120">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oilTypeIdName" width="180">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="shipName" width="100">
        </el-table-column>
        <el-table-column align="center" label="承运公司" prop="subtitleName" width="100">
        </el-table-column>
        <el-table-column align="center" label="下达量" prop="oilTon" width="100">
        </el-table-column>
        <el-table-column align="center" label="原发量" prop="shipErpAmount" width="100">
        </el-table-column>
        <el-table-column align="center" label="出厂船板量" prop="plankingTon" width="100">
        </el-table-column>
        <el-table-column align="center" label="到库船板量" prop="oildischargeplankingTon" width="100">
        </el-table-column>
        <el-table-column align="center" label="实收量" prop="realTon" width="100">
        </el-table-column>
        <el-table-column align="center" label="定损率" prop="confirmSumPercent" width="100">
        </el-table-column>
        <el-table-column align="center" label="定损量" prop="confirmSum" width="100">
        </el-table-column>
        <el-table-column align="center" label="损耗量" prop="totalSum" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.totalSum > 0" style="color: black;">{{ scope.row.totalSum }}</span>
            <span v-else style="color: red;">{{ scope.row.totalSum }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="损耗率" prop="totalSumPercent" width="100">
        </el-table-column>
        <el-table-column align="center" label="超耗量" prop="overclaimSum" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.overclaimSum < 10" style="color: black;">{{ scope.row.overclaimSum }}</span>
            <span v-else style="color: red;">{{ scope.row.overclaimSum }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="超耗率" prop="overclaimSumPercent" width="100">
        </el-table-column>
        <el-table-column align="center" label="计划状态" prop="statesName" width="100">
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="goDetails(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="goEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">计划月份</th>
              <th class="tg-0lax">发运时间</th>
              <th class="tg-0lax">收卸时间</th>
              <th class="tg-0lax">在途天数</th>
              <th class="tg-0lax">处理时间</th>
              <th class="tg-0lax">发港</th>
              <th class="tg-0lax">属性</th>
              <th class="tg-0lax">流向</th>
              <th class="tg-0lax">到达港</th>
              <th class="tg-0lax">油库</th>
              <th class="tg-0lax">品号</th>
              <th class="tg-0lax">船名</th>
              <th class="tg-0lax">承运公司</th>
              <th class="tg-0lax">下达量</th>
              <th class="tg-0lax">原发量</th>
              <th class="tg-0lax">出厂船板量</th>
              <th class="tg-0lax">到库船板量</th>
              <th class="tg-0lax">实收量</th>
              <th class="tg-0lax">定损率</th>
              <th class="tg-0lax">定损量</th>
              <th class="tg-0lax">损耗量</th>
              <th class="tg-0lax">损耗率</th>
              <th class="tg-0lax">超耗量</th>
              <th class="tg-0lax">超耗率</th>
              <th class="tg-0lax">计划状态</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.monthly }}</th>
            <th class="tg-0lax">{{ item.startTime }}</th>
            <th class="tg-0lax">{{ item.arrivalTime }}</th>
            <th class="tg-0lax">{{ item.onLineDays }}</th>
            <th class="tg-0lax">{{ item.arrivalTimeEntering }}</th>
            <th class="tg-0lax">{{ item.startTitle }}</th>
            <th class="tg-0lax">{{ item.KUNTPNAME }}</th>
            <th class="tg-0lax">{{ item.belongIdName }}</th>
            <th class="tg-0lax">{{ item.destOilTitle }}</th>
            <th class="tg-0lax">{{ item.oilWarehouseTitle }}</th>
            <th class="tg-0lax">{{ item.oilTypeIdName }}</th>
            <th class="tg-0lax">{{ item.shipName }}</th>
            <th class="tg-0lax">{{ item.subtitleName }}</th>
            <th class="tg-0lax">{{ item.oilTon }}</th>
            <th class="tg-0lax">{{ item.shipErpAmount }}</th>
            <th class="tg-0lax">{{ item.plankingTon }}</th>
            <th class="tg-0lax">{{ item.oildischargeplankingTon }}</th>
            <th class="tg-0lax">{{ item.realTon }}</th>
            <th class="tg-0lax">{{ item.confirmSumPercent }}</th>
            <th class="tg-0lax">{{ item.confirmSum }}</th>
            <th class="tg-0lax">{{ item.totalSum }}</th>
            <th class="tg-0lax">{{ item.totalSumPercent }}</th>
            <th class="tg-0lax">{{ item.overclaimSum }}</th>
            <th class="tg-0lax">{{ item.overclaimSumPercent }}</th>
            <th class="tg-0lax">{{ item.statesName }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import staticData from "@/utils/static-data.js"
export default {
  name: "BoatData",
  data() {
    return {
      multipleSelection: [],
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      searchForm: {
        KUNTPLIST_new: '',
        belongId_new: '',
        carrierList_new: '',
        destPort_new: '',
        arrivalTimeEnteringStart: '',
        arrivalTimeEnteringEnd: '',
        oilList_new: '',
        shipList_new: '',
        startPort_new: '',
        states: '100',
        feerate_filter: '0',
        shipErpAmount: '',
        export_type: '0'
      },
      dict: {//数据字典
        carrierList: [],
        companyList: [],
        monthlyList: [],
        oilList: [],
        shipList: [],
        shipPortBegin: [],
        shipPortEnd: [],
        shipPortList: [],
        oilWarehouse: [],
        staicData: [],
        approveStates: []
      },
      tableData: [],
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = now.getDate();
    if (date < 10) date = '0' + date;
    this.searchForm.arrivalTimeEnteringStart = year + '-' + month + '-' + date
    this.searchForm.arrivalTimeEnteringEnd = year + '-' + month + '-' + date
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getDict()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let config = [
            { field: 'monthly', width: 80, align: 'center', title: '计划月份' },
            { field: 'startTime', width: 80, align: 'center', title: '发运时间' },
            { field: 'arrivalTime', width: 80, align: 'center', title: '收卸时间' },
            { field: 'onLineDays', width: 150, align: 'center', title: '在途天数' },
            { field: 'arrivalTimeEntering', width: 150, align: 'center', title: '处理时间' },
            { field: 'startTitle', width: 150, align: 'center', title: '发港' },
            { field: 'KUNTPNAME', width: 80, align: 'center', title: '属性' },
            { field: 'belongIdName', width: 80, align: 'center', title: '流向' },
            { field: 'destOilTitle', width: 80, align: 'center', title: '到达港' },
            { field: 'oilWarehouseTitle', width: 80, align: 'center', title: '油库' },
            { field: 'oilTypeIdName', width: 100, align: 'center', title: '品号' },
            { field: 'shipName', width: 80, align: 'center', title: '船名' },
            { field: 'subtitleName', width: 160, align: 'center', title: '承运公司' },
            { field: 'oilTon', width: 160, align: 'center', title: '下达量' },
            { field: 'shipErpAmount', width: 160, align: 'center', title: '原发量' },
            { field: 'plankingTon', width: 200, align: 'center', title: '出厂船板量' },
            { field: 'oildischargeplankingTon', width: 200, align: 'center', title: '到库船板量' },
            { field: 'realTon', width: 160, align: 'center', title: '实收量' },
            { field: 'confirmSumPercent', width: 160, align: 'center', title: '定损率' },
            { field: 'confirmSum', width: 160, align: 'center', title: '定损量' },
            { field: 'totalSum', width: 160, align: 'center', title: '损耗量' },
            { field: 'totalSumPercent', width: 160, align: 'center', title: '损耗率' },
            { field: 'overclaimSum', width: 160, align: 'center', title: '超耗量' },
            { field: 'overclaimSumPercent', width: 160, align: 'center', title: '超耗率' },
            { field: 'statesName', width: 160, align: 'center', title: '计划状态' },
          ]
          let obj = {
            name: '航次数据',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(this.tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    goDetails(row) {
      this.$router.push({ name: 'boatPlanDateils', params: { id: row.id } })
    },
    goEdit(row) {
      this.$router.push({ name: 'boatDataEdit', params: { id: row.id } })
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if (month < 10) month = '0' + month;
        let date = now.getDate();
        if (date < 10) date = '0' + date;
        this.searchForm.arrivalTimeEnteringStart = year + '-' + month + '-' + date
        this.searchForm.arrivalTimeEnteringEnd = year + '-' + month + '-' + date
        this.searchForm.states = '100'
        this.searchForm.feerate_filter = '0'
        this.searchForm.export_type = '0'
        this.getData()
      }
    },
    getDict() {
      this.$Api.shipPlan_Dict().then(res => {
        if (res.code == 200) {
          this.dict.carrierList = res.data.carrierList
          this.dict.companyList = res.data.companyList
          this.dict.monthlyList = res.data.monthlyList
          this.dict.oilList = res.data.oilList
          this.dict.shipList = res.data.shipList
          this.dict.shipPortBegin = res.data.shipPortBegin
          this.dict.shipPortEnd = res.data.shipPortEnd
          this.dict.shipPortList = res.data.shipPortList
        }
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        ...this.searchForm
      }
      this.tableLoading = true
      this.$Api.ShipPlan_ListData(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.list.total) this.page.total = Number(res.data.list.total);
          if (res.data.list.list.length > 0) {
            if (this.dict.companyList.length > 0) {
              res.data.list.list.map(item => {
                item.statesName = '未知'
                if (item.states == 9) {
                  if (item.cancelDesc != null && item.cancelDesc.length > 5) {
                    item.statesName = staticData.shipPlanStates[item.states]
                  } else {
                    item.statesName = staticData.shipPlanStates[item.states] + "(" + item.cancelDesc + ")"
                  }
                } else {
                  item.statesName = staticData.shipPlanStates[item.states]
                }
                item.KUNTPNAME = '未知'  //属性 
                item.belongIdName = '未知' //流向
                this.dict.companyList.map(key => {
                  if (item.KUNTP == key.KUNTP) {
                    item.KUNTPNAME = key.subtitle
                  }
                  if (item.belongId == key.id) {
                    item.belongIdName = key.subtitle
                  }
                })
              })
            }
            if (this.dict.oilList.length > 0) {
              res.data.list.list.map(item => {
                item.oilTypeIdName = '未知' //品号
                this.dict.oilList.map(key => {
                  if (item.oilTypeId == key.id) {
                    item.oilTypeIdName = key.title
                  }
                })
              })
            }
            if (this.dict.carrierList.length > 0) {
              res.data.list.list.map(item => {
                item.subtitleName = '未知' //承运公司
                this.dict.carrierList.map(key => {
                  if (item.companyId == key.id) {
                    item.subtitleName = key.subtitle
                  }
                })
              })
            }
          }
          this.tableData = res.data.list.list
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.BoatData {
  height: initial;
  min-height: 100%;

  .searchBox {
    padding-top: 15px;

    /deep/.el-form-item__label {
      width: 100px;
    }

    /deep/.el-form--inline .el-form-item {
      margin-bottom: 5px;
    }

    /deep/.el-input__inner {
      width: 200px;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 200px;
    }

    .outbox {
      /deep/.el-form-item__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .container {
    width: 100%;
    height: 100%;
    // height: 1px;
    // flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }


  }

  /deep/.el-col-10 {
    display: flex;
    align-items: flex-end;
  }
}
</style>