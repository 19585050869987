<template>
    <div class="page lossArateService">
       <div class="searchBox">
          <el-form :model="searchForm" class="search-form" inline>
            <el-form-item label="始发港：">
              <el-select clearable v-model="searchForm.is_hid"  placeholder="请选择始发港"  size="small">
                <el-option :value="0" label="已确认价格"></el-option>
                <el-option :value="1" label="未确认价格"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目的港：">
              <el-select clearable v-model="searchForm.is_hid"  placeholder="请选择目的港"  size="small">
                <el-option :value="0" label="可生成赔付单"></el-option>
                <el-option :value="1" label="不可生成赔付单"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                @click="searchTable('search')">搜索
              </el-button>
              <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                @click="searchTable('reset')">重置
              </el-button>
            </el-form-item>
          </el-form>
       </div>
       <div class="container">
        <div class="operateBox">
          <div class="left">
          </div>
          <div class="right">
            <!-- <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
                <el-dropdown trigger="click" size="small" >
                <el-button  icon="el-icon-menu" size="mini"></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-checkbox-group v-model="checkList" @change="dropdownChange" style="display: flex;flex-direction: column; padding: 5px 10px;">
                        <el-checkbox v-for="(item,index) in checkListData" :key="index" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-dropdown-menu>
                </el-dropdown>
            </el-tooltip> -->
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
                <el-dropdown trigger="click" size="small"  @command="commandClick">
                <el-button  icon="el-icon-document-copy" size="mini"></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                    <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
                <el-button  icon="el-icon-printer" size="mini" @click="print"></el-button>
            </el-tooltip>
          </div>
        </div>
          <!--列表-->
          <el-table  ref="multipleTable" border v-loading="tableLoading" :data="tableData"  tooltip-effect="dark"
             height="520px" max-height="(100%-104px)"
             style="width: 98%;margin:0 auto;" 
              header-row-class-name="header-table" >
              <el-table-column align="center" label="序号" width="60" prop="id"  v-if="checkList.indexOf('序号')!=-1">
              </el-table-column>
              <el-table-column align="center" label="始发港"  prop="book_title"  v-if="checkList.indexOf('始发港')!=-1">
              </el-table-column>
              <el-table-column align="center" label="目的港" prop="add_time"  v-if="checkList.indexOf('目的港')!=-1">
              </el-table-column>
              <el-table-column align="center" label="定损率"  prop="book_title"  v-if="checkList.indexOf('定损率')!=-1">
              </el-table-column>
              <el-table-column align="center" label="起始时间" prop="book_title"  v-if="checkList.indexOf('起始时间')!=-1">
              </el-table-column>
              <el-table-column align="center" label="截至时间"  prop="book_title"  v-if="checkList.indexOf('截至时间')!=-1">
              </el-table-column>
              <el-table-column align="center" label="操作" width="180" fixed="right">
                  <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="editerow(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
         <!-- 分页 -->
          <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
              <el-pagination 
                  :page-sizes="[10, 15, 20, 25,50,100]"
                  :current-page="page.currentPage" 
                  :page-size="page.pageSize" 
                  :total="page.total"
                  @current-change="changePage"
                  @size-change="handleSizeChange"
                  layout="total,sizes ,prev, pager, next, jumper" >
              </el-pagination>
          </div>
       </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LossArateService",
    data() {
      return {
        checkList: ['序号','始发港','目的港','定损率','起始时间','截至时间'],
        checkListData:['序号','始发港','目的港','定损率','起始时间','截至时间'],
        multipleSelection:[],
        searchForm: {
          name: '',
          is_hid: [],
          dateResult:""
        },
        tableLoading:false,
        page: {
            currentPage: 1,
            pageSize: 15,
            total: 0,
            lastPage: 1,
        },
        tableData:[],
      }
    },
    created(){
      let that = this
      document.onkeydown = function(e) {
        var key = e.keyCode;
        if (key == 13) {
          that.searchTable('search')
        }
      }
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    methods: {
      addRole(){
        console.log('添加');
      },
      handleSelectionChange(){
        this.multipleSelection = val;
      },
      editerow(){
        console.log('editerow');
      },
      deleterow(){
  
      },
      handleClick(tab) {
        switch (tab.name) {
          case 'first':
            this.getData()
            break;
          case 'second':
            
            break;
          case 'third':
            
            break;
          default:
            break;
        }
      },
      dropdownChange(e){
        console.log(e);
      },
      commandClick(e) {
        if (e === 'Csv') {
            console.log('Csv');
        }
        if (e === 'Excel') {
        console.log('Excel');
        }
     },
     print(){
      let printableContent = document.getElementById('tabbox').innerHTML
      console.log(printableContent,'printableContent',555);
      let printWindow = window.open('http://localhost:8080/#/userInfo', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title></head><body>' + ${printableContent} + '</body></html>`);
      
      // 执行打印操作
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)
      // printWindow.print();
    },
      // ----------------------------------------------------------------
      handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
      changePage(val) { //选择第几页
          this.page.currentPage = val;
          this.getData()
      },
      searchTable(t) { // 搜索/重置
        if (t === 'search') {
          this.page.currentPage = 1
          this.getData()
        } else {
          for (const key in this.searchForm) {
            if(this.searchForm[key] instanceof Array){
              this.searchForm[key] = []
            }else{
              this.searchForm[key] = ''
            }
          }
          this.getData()
        }
      },
      getData(){ // 获取数据
          let data = {
            branch_office_id:  10,
            book_title: '',
            book_zddm: '',
            supplier_id: '',
            head_office_apply_status: '',
            page:  1
          }
          this.tableLoading = true
          this.$http.get('/backend/struct_order/list',{
              params:data
          }).then(res=>{
              this.tableLoading = false
              if (res.code == 1) {
                  if (res.data.list.total) this.page.total = res.data.list.total;
                  if(res.data.list.last_page) this.page.lastPage = res.data.list.last_page
                  this.tableData = res.data.list.data
              }
          })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .lossArateService{
      .container{
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        ::-webkit-scrollbar {  
            width: 6px;  
        }
        ::-webkit-scrollbar-track {  
            background: #f1f1f1; 
            width: 6px; 
        }
        ::-webkit-scrollbar-thumb {  
            background: rgb(214,216,219);
            border-radius: 4px;  
        }
          // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }
        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }
       .operateBox{
        margin: 0px auto 10px;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
        }
        .right{
          display: flex;
          align-items: center;
          .item{
          margin-left: 10px;
          /deep/.el-checkbox-group{
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
        }
       }
        .tip{
          width: 100%;
          height: 42px;
          line-height: 42px;
          padding: 0 15px;
          border-bottom: 1px solid #f6f6f6;
          color: #333;
          border-radius: 2px 2px 0 0;
          font-size: 14px;
          display: flex;
          align-items: center;
          i{
            color: #1e9fff;
            font-size: 18px;
            margin-right: 6px;
          }
        }
      }
    }
  </style>