<template>
    <div class="page details">
        <el-page-header @back="goBack" content="详情">
        </el-page-header>
        <el-form ref="form" :model="form" label-width="140px">
            <el-form-item label="所有用户:">
            </el-form-item>
        </el-form>
    </div>
</template>
  
<script>
export default {
    name: "details",
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.details {
    padding-top: 20px;
    padding-left: 20px;
}
</style>