var staticData = {
    transport: ["未知", "内河", "干线", "进川"],
    aliType: ["未知", "柴油", "汽油","煤油"],
    approveStates: ["未提交", "待一审", "待二审", "待三审", "通过", "拒绝", "取消"],
    approveLocalStates: ["未发布", "审核中", "已发布", "拒绝"],
    //状态，0未开始，1 船舶待检查，2船舶检查不通过，3.船舶检查通过，待装油，4已装油，发运申请中，5 发运申请驳回，6 发运申请通过，待卸油 ，7 已卸油，8 结束，9已取消 10已转港分卸
    //计划类型 0 正常航次 1改港航次，2分卸航次，3自提计划 4 外采计划  5  华东调运计划
    shipPlanType: ["正常航次", "改港航次", "分卸航次", "自提计划", "外采计划","华东调运计划"],
    shipPlanStates: ["未开始", "船舶待检查", "船舶检查不通过", "待装油", "发运信息审批中", "发运信息驳回", "待卸油", "已卸油", "结束","取消", "已转港分卸"],
    shipPlanChageType: ["改港", "分卸"],
    shipStates: ['空载','重载'],
    shipOutStates: ['未出行', '在外', '已回'],
    shipApproveType: ['上线', '复航', '下线', '停航'],
    shipRepairStates: ['未开始', '未进厂', '检修中', '检修完成'],
    shipSiteState: ["重载上行", "重载下行", "重载待卸", "重载等闸", "重载过闸", "重载抛锚", "空载上行", "空载下行", "空载待装", "空载等闸", "空载过闸", "空载抛锚", "正在卸油", "正在装油", "检修", "年检"],
    docType: ['通知', '通报','外部来文'],
    goOutStates: ['中化', '华东', '中石化', '中石油', '中海油', '其他'],
} 
export default staticData