<template>
    <div class="page positionship" :class="fullscreen ? 'quanpin' : ''">
        <!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="searchVal" @input="mapSearch"
            class="mapSearch" size="medium"></el-input> -->
        <el-autocomplete prefix-icon="el-icon-search" class="mapSearch" size="medium" v-model="searchVal"
            :fetch-suggestions="querySearchAsync" placeholder="请输入内容" @select="handleSelect"></el-autocomplete>
        <!-- 顶部菜单 -->
        <div class="menuBox">
            <div class="menuItem" :class="defaultMenu.indexOf(index) != -1 ? 'menuItemActive' : ''"
                v-for="(item, index) in meunList" :key="index" @click="handleOpen(index)">
                <i :class="item.icon"></i>
                <span>{{ item.txt }}</span>
            </div>
        </div>
        <!-- 意见反馈，客服 -->
        <div class="giveServiceto">
            <el-tooltip class="item" effect="dark" content="全屏" placement="left" v-if="!fullscreen">
                <div class="giveServiceto_item giveServiceto_item1" @click="fullScreen">
                    <img src="../../assets/img/exitfullScreen.png">
                </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="退出全屏" placement="left" v-else>
                <div class="giveServiceto_item giveServiceto_item1" @click="exitFullscreen">
                    <img src="../../assets/img/exitfullScreen.png">
                </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="意见反馈" placement="left">
                <div class="giveServiceto_item giveServiceto_item2" @click="opinionOpen">
                    <img src="../../assets/img/opinion.png">
                </div>
            </el-tooltip>
            <el-popover placement="right" width="200" trigger="click">
                <div style=" margin: 0">
                    <div class="customerItem"
                        style="display: flex;align-items: center;margin-bottom: 7px; margin-top: 7px;">
                        <div class="tip" style="color: #999;margin-right: 10px;">客服1</div><a href="tel:12369586985"
                            style="color:#409EFF;">12369586985</a>
                    </div>
                    <div class="customerItem"
                        style="display: flex;align-items: center;margin-bottom: 7px; margin-top: 7px;">
                        <div class="tip" style="color: #999;margin-right: 10px;">客服2</div><a href="tel:12369586985"
                            style="color:#409EFF;">12369586985</a>
                    </div>
                    <div class="customerItem"
                        style="display: flex;align-items: center;margin-bottom: 7px; margin-top: 7px;">
                        <div class="tip" style="color: #999;margin-right: 10px;">客服电话</div><a
                            href="tel:12369586985">010-56369856</a>
                    </div>
                </div>
                <el-tooltip class="item" effect="dark" content="在线客服" placement="left" slot="reference">
                    <div class="giveServiceto_item giveServiceto_item3">
                        <img src="../../assets/img/customer.png">
                    </div>
                </el-tooltip>
            </el-popover>
        </div>
        <div class="markServer">
            <el-tooltip class="item" effect="dark" content="标记管理" placement="left">
                <img src="../../assets/img/markMange.png">
            </el-tooltip>
            <el-popover placement="left" width="260" v-model="visibleAddres" title="坐标定位">
                <el-form :model="position" :rules="positionrules" ref="positionruleForm" label-width="60px"
                    class="demo-ruleForm" style="margin-top: 25px;">
                    <el-form-item label="经度" prop="lng">
                        <el-input v-model="position.lng"></el-input>
                    </el-form-item>
                    <el-form-item label="纬度" prop="lat">
                        <el-input v-model="position.lat"></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visibleAddres = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="restPostion">确定</el-button>
                </div>
                <el-tooltip class="item" effect="dark" content="重新定位" placement="left" slot="reference">
                    <img src="../../assets/img/position.png" style="width: 24px;">
                </el-tooltip>
            </el-popover>

        </div>

        <div class="shipType">
            <el-tag v-for="item in shipType" :key="item.label" :type="item.type" effect="plain"
                @click="shipTypeOpen(item.label, item.ships)">
                {{ item.label }}<span style="color: red;font-weight: 600;margin-left: 3px;">{{ item.number || ''
                    }}</span>
            </el-tag>
        </div>

        <!-- 地图 -->
        <div id="mapDiv"></div>

        <!-- 意见反馈 -->
        <el-dialog title="意见反馈" :visible.sync="opinionChange" width="30%" center>
            <el-form :model="opinionForm" :rules="rules" ref="ruleFormyijianfankui" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="电子邮箱" prop="email">
                    <el-input v-model="opinionForm.email"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="opinionForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="建议内容" prop="desc">
                    <el-input type="textarea" v-model="opinionForm.desc"
                        :autosize="{ minRows: 4, maxRows: 5 }"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="opinionChange = false">取 消</el-button>
                <el-button type="primary" @click="submitleavemessage">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 船舶状态 -->
        <el-dialog :close-on-click-modal="false" title="船舶状态" :visible.sync="shipStates" destroy-on-close top="30vh"
            width="550px" @close="shipStateClose">
            <div class="">
                <el-table ref="multipleTable" border :data="shipStatetableData" tooltip-effect="dark"
                    style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                    <el-table-column align="center" label="船舶状态" prop="name">
                    </el-table-column>
                    <el-table-column align="center" label="数量" prop="number">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <!-- 船舶信息列表 -->
        <el-drawer title="船舶信息" :visible.sync="shipInfodrawer" :modal="false" :direction="'rtl'" @close="shipinfoClose">
            <el-tree :data="shipInfoData.data" node-key="id" @node-click="shipInfoNode" :load="loadNode" lazy
                :props="shipInfoData.defaultProps">
            </el-tree>
        </el-drawer>
        <!-- 船舶信息 -->
        <el-dialog :close-on-click-modal="false" title="船舶信息" :visible.sync="shipInfovibaty" destroy-on-close top="18vh"
            width="750px">
            <el-divider content-position="left">{{ shipInfoData.currShipName || '' }}</el-divider>
            <el-descriptions class="margin-top" :column="2" border>
                <el-descriptions-item label="承运商">{{ shipInfoData.currShipData.cys || '' }}</el-descriptions-item>
                <el-descriptions-item label="名称">{{ shipInfoData.currShipData.mc || '' }}</el-descriptions-item>
                <el-descriptions-item label="发港">{{ shipInfoData.currShipData.fagang || '' }}</el-descriptions-item>
                <el-descriptions-item label="油号">{{ shipInfoData.currShipData.youhao || '' }}</el-descriptions-item>
                <el-descriptions-item label="运输类型">{{ shipInfoData.currShipData.leixing || '' }}</el-descriptions-item>
                <el-descriptions-item label="到达港">{{ shipInfoData.currShipData.daodagang || '' }}</el-descriptions-item>
                <el-descriptions-item label="状态">{{ shipInfoData.currShipData.zhuangtai || '' }}</el-descriptions-item>
                <el-descriptions-item label="船名">{{ shipInfoData.currShipData.chuanming || '' }}</el-descriptions-item>
                <el-descriptions-item label="数量(吨)">{{ shipInfoData.currShipData.shuliang || ''
                    }}</el-descriptions-item>
                <el-descriptions-item label="所在城市">{{ shipInfoData.currShipData.chengshi || '' }}</el-descriptions-item>
                <el-descriptions-item label="船迹向">{{ shipInfoData.currShipData.cog || '' }}</el-descriptions-item>
                <el-descriptions-item label="船首向">{{ shipInfoData.currShipData.hdg || '' }}</el-descriptions-item>
                <el-descriptions-item label="类型">{{ shipInfoData.currShipData.lx || '' }}</el-descriptions-item>
                <el-descriptions-item label="纬度">{{ shipInfoData.currShipData.wd }}</el-descriptions-item>
                <el-descriptions-item label="状态">{{ shipInfoData.currShipData.shipZt }}</el-descriptions-item>
                <el-descriptions-item label="经度">{{ shipInfoData.currShipData.jd }}</el-descriptions-item>
                <el-descriptions-item label="吃水">{{ shipInfoData.currShipData.draught || '' }}</el-descriptions-item>
                <el-descriptions-item label="更新时间">{{ shipInfoData.currShipData.shijians }}</el-descriptions-item>
            </el-descriptions>
            <span slot="footer" class="dialog-footer">
                <el-popover placement="left" width="425" trigger="click" style="margin-right: 12px;">
                    <el-date-picker v-model="guijiTime" type="datetimerange" range-separator="-" format="yyyy-MM-DD"
                        value-format="yyyy-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期"
                        @change="guijiTImeSure">
                    </el-date-picker>
                    <el-button slot="reference" type="primary">查看轨迹</el-button>
                </el-popover>
                <el-button type="primary" @click="openLasthangci">上航次计划</el-button>
                <el-button type="primary" @click="lookInfo">查看视频</el-button>
                <el-button @click="shipInfovibaty = false">关 闭</el-button>
            </span>
        </el-dialog>
        <!-- 菜单 -->
        <el-drawer title="菜单" :visible.sync="menuBox" :modal="false" :direction="'rtl'" @close="menuClose"
            :size="'150px'">
            <el-menu class="el-menu-vertical-demo" @select="handleOpenMenu">
                <el-menu-item index="1">
                    <span slot="title">船舶计划</span>
                </el-menu-item>
                <el-menu-item index="2">
                    <span slot="title">调度计划</span>
                </el-menu-item>
                <el-menu-item index="3">
                    <span slot="title">水位信息</span>
                </el-menu-item>
                <!-- <el-menu-item index="4">
                    <span slot="title">导航四</span>
                </el-menu-item> -->
            </el-menu>
        </el-drawer>
        <!-- 左下角点击弹窗 -->
        <el-dialog :close-on-click-modal="false" :title="leftTitle" :visible.sync="leftVisibility" destroy-on-close
            top="30vh" width="350px">
            <div class="dialogDiv">
                <div class="leftdialogItem" v-for="(item, index) in currShipType" :key="index">
                    <el-button type="primary" style="width: 115px;"
                        @click="showchuanbo(item.id, 0, 0, item.title, item.cys, item.ais, item.sfqx, item.carriersId)">{{
                            item.title
                        }}</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 上航次计划 -->
        <el-dialog :close-on-click-modal="false" title="上航次计划" :visible.sync="hangciVisibility" destroy-on-close
            top="30vh" width="60%">
            <el-table ref="multipleTable" border :data="hangciTableData" tooltip-effect="dark" style="width: 100%;"
                header-row-class-name="header-table">
                <el-table-column align="center" label="序列号" type="index">
                </el-table-column>
                <el-table-column align="center" label="船舶" prop="chuanname">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="cysname">
                </el-table-column>
                <el-table-column align="center" label="油类别" prop="youlb">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="oilTon">
                </el-table-column>
                <el-table-column align="center" label="发港" prop="startPortName">
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPortName">
                </el-table-column>
                <el-table-column align="center" label="出发时间" prop="startTime">
                </el-table-column>
                <el-table-column align="center" label="船板量" prop="plankingTon">
                </el-table-column>
                <el-table-column align="center" label="装船量" prop="shipmentTon">
                </el-table-column>
                <el-table-column align="center" label="到港时间" prop="arrivalTime">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="states">
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 轨迹范围公里数 -->
        <el-dialog :close-on-click-modal="false" title="范围公里数" :visible.sync="fanweiVisibility" destroy-on-close
            top="30vh" width="350px">
            <!-- <div class="dialogDiv"> -->
            <el-form :model="fanweiData" :rules="fanweirules" ref="positionruleForm" label-width="60px"
                class="demo-ruleForm" style="margin-top: 25px;">
                <el-form-item label="范围" prop="lng">
                    <el-input v-model="fanweiData.number" placeholder="请输入公里数" :maxlength="11">
                        <template slot="append">公里</template>
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="fanweiVisibility = false">关 闭</el-button>
                <el-button type="primary" @click="fangweiSubmit">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import imgBaseUrl from "../../api/imgUrlConfig"
import axios from "axios"
import WebControl from "../../utils/webcontrol.js"
export default {
    name: "positionship",
    data() {
        return {
            map: null,
            mapZoom: 7,
            lineToolChange: false,
            meunList: [
                { icon: "el-icon-star-on", txt: '坝上' },
                { icon: "el-icon-star-on", txt: '坝下' },
                { icon: "el-icon-star-on", txt: '重载船舶' },
                { icon: "el-icon-star-on", txt: '船舶状态' },
                { icon: "el-icon-s-promotion", txt: '标记' },
                { icon: "el-icon-thumb", txt: '鼠标测距' },
                { icon: "el-icon-ship", txt: '船舶信息' },
                { icon: "el-icon-takeaway-box", txt: '油库' },
                { icon: "el-icon-star-on", txt: '炼油厂' },
                { icon: "el-icon-star-on", txt: '菜单' }
            ],
            defaultMenu: [0, 1, 7, 8],
            opinionChange: false,
            opinionForm: {
                email: "",
                phone: "",
                desc: ""
            },
            rules: {
                email: [
                    { required: true, message: '请填写邮箱', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请填写手机号码', trigger: 'change' }
                ],
                desc: [
                    { required: true, message: '请填写建议内容', trigger: 'change' }
                ],
            },
            position: {
                lng: '',
                lat: ''
            },
            positionrules: {
                lng: [
                    { required: true, message: '请填写经度', trigger: 'change' }
                ],
                lat: [
                    { required: true, message: '请填写纬度', trigger: 'change' }
                ],
            },
            visibleAddres: false,
            shipStates: false,
            restaurants: [],
            searchVal: '',
            shipStatetableData: [{
                name: "坝上船舶",
                number: 0
            }, {
                name: "坝下船舶",
                number: 0
            }, {
                name: "重载船舶",
                number: 0
            }, {
                name: "空载船舶",
                number: 0
            }, {
                name: "装卸作业",
                number: 0
            }, {
                name: "检修",
                number: 0
            }],
            shipInfodrawer: false,
            shipInfovibaty: false,
            shipInfoData: {
                data: [{
                    id: 1,
                    label: '一级 1',
                    children: [{
                        id: 4,
                        label: '二级 1-1',
                        children: [{
                            id: 9,
                            label: '三级 1-1-1'
                        }, {
                            id: 10,
                            label: '三级 1-1-2'
                        }]
                    }]
                }, {
                    id: 2,
                    label: '一级 2',
                    children: [{
                        id: 5,
                        label: '二级 2-1'
                    }, {
                        id: 6,
                        label: '二级 2-2'
                    }]
                }, {
                    id: 3,
                    label: '一级 3',
                    children: [{
                        id: 7,
                        label: '二级 3-1'
                    }, {
                        id: 8,
                        label: '二级 3-2'
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                currShipName: '测试船舶名称',
                currShipData: {
                    mc: '',
                    cys: '',
                    fagang: '',
                    youhao: '',
                    leixing: '',
                    daodagang: '',
                    zhuangtai: '',
                    chuanming: '',
                    shuliang: '',
                    cog: '',
                    hdg: '',
                    draught: '',
                    chengshi: '',
                    lx: "",
                    shipZt: '',
                    shijians: '',
                    jd: '',
                    wd: '',
                    shipid: '',
                    cid: '',
                    carriersId: ''
                }
            },
            leftTitle: '',
            leftVisibility: false,
            currShipType: [],
            shipType: [{ type: '', label: '空载下行', number: 0, ships: [] },
            { type: '', label: '空载等闸', number: 0, ships: [] },
            { type: '', label: '重载待卸', number: 0, ships: [] },
            { type: '', label: '装卸作业', number: 0, ships: [] },
            { type: '', label: '重载船舶', number: 0, ships: [] },
            { type: '', label: '检修', number: 0, ships: [] }],
            menuBox: false,//菜单显示隐藏
            youkuResult: null,
            indexData: {},//首页数据
            guijiTime: [],//轨迹时间
            markersguiji: [],//轨迹点集合
            hangciVisibility: false,//上航次计划显示隐藏
            hangciTableData: [],//上航次计划列表数据
            gangkoulist: '',//港口数据
            fanweiVisibility: false,//范围输入框显示隐藏
            fanweiData: {
                number: "",
                latitude: "",
                longitude: "",
            },
            fanweirules: {
                number: [
                    { required: true, message: '请输入公里数', trigger: 'change' }
                ],
            },
            fullscreen: false,//
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        window.onresize = function () {
            if (!that.checkFull()) {
                // 退出全屏后要执行的动作
                that.exitFullscreen()
            }
        }
    },
    mounted() {
        this.loadTiandituScript()

    },
    methods: {
        loadTiandituScript() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=60bb3cdc0793b86debd74e09315483d6';
                script.onload = resolve;
                script.onerror = e => reject(e.error);
                document.body.appendChild(script);
            }).then(() => {
                setTimeout(() => {
                    let that = this;
                    that.map = new T.Map('mapDiv');
                    const map = that.map
                    that.map.centerAndZoom(new T.LngLat(112.92, 29.92), this.mapZoom);
                    // 添加控件
                    var ctrl = new T.Control.MapType([
                        {
                            title: "地图", //地图控件上所要显示的图层名称
                            icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小80x80）
                            layer: TMAP_NORMAL_MAP //地图类型对象，即MapType。
                        },
                        {
                            title: "卫星",
                            icon:
                                "http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
                            layer: TMAP_SATELLITE_MAP
                        },
                        {
                            title: "卫星混合",
                            icon:
                                "http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellitepoi.png",
                            layer: TMAP_HYBRID_MAP
                        },
                        {
                            title: "地形",
                            icon:
                                " http://api.tianditu.gov.cn/v4.0/image/map/maptype/terrain.png",
                            layer: TMAP_TERRAIN_MAP
                        },
                        {
                            title: "地形混合",
                            icon:
                                " http://api.tianditu.gov.cn/v4.0/image/map/maptype/terrainpoi.png",
                            layer: TMAP_TERRAIN_HYBRID_MAP
                        }
                    ]);
                    that.map.addControl(ctrl);
                    ctrl.setPosition(T_ANCHOR_BOTTOM_RIGHT)
                    //创建缩放平移控件对象 放大缩小
                    that.control = new T.Control.Zoom();
                    that.control.setPosition(T_ANCHOR_BOTTOM_RIGHT)
                    //添加缩放平移控件
                    that.map.addControl(that.control);
                    //创建比例尺控件对象
                    let scale = new T.Control.Scale();
                    //添加比例尺控件
                    that.map.addControl(scale);
                    var config = {
                        showLabel: true
                    };
                    //创建标注工具对象
                    that.lineTool = new T.PolylineTool(that.map, config)
                    //创建标注工具对象
                    that.markerTool = new T.MarkTool(that.map, { follow: true });

                    // 监听zoom变化
                    that.map.addEventListener('zoomend', function (event) {
                        var zoom = map.getZoom();
                        if (zoom >= 10) {
                            that.hide_chuanbo()
                            that.setShipTubiao()
                        } else {
                            that.hide_chuanbo()
                            that.getShipPotions()
                        }
                    });

                    //创建图片对象
                    window.myIconyouku = new T.Icon({ iconUrl: `${imgBaseUrl}yk.png`, iconSize: new T.Point(15, 25), iconAnchor: new T.Point(10, 25) });
                    window.myIconlianyouchang = new T.Icon({ iconUrl: `${imgBaseUrl}lc.png`, iconSize: new T.Point(15, 25), iconAnchor: new T.Point(10, 25) });
                    window.myIcon = new T.Icon({ iconUrl: `${imgBaseUrl}hangxing.png`, iconSize: new T.Point(20, 32), iconAnchor: new T.Point(10, 25) });
                    window.myIconlv = new T.Icon({ iconUrl: `${imgBaseUrl}lv.png`, iconSize: new T.Point(20, 32), iconAnchor: new T.Point(10, 25) });
                    window.myIconhui = new T.Icon({ iconUrl: `${imgBaseUrl}hui.png`, iconSize: new T.Point(20, 32), iconAnchor: new T.Point(10, 25) });
                    window.myIconhong = new T.Icon({ iconUrl: `${imgBaseUrl}hong.png`, iconSize: new T.Point(20, 32), iconAnchor: new T.Point(10, 25) });
                    window.myIconhuang = new T.Icon({ iconUrl: `${imgBaseUrl}huang.png`, iconSize: new T.Point(20, 32), iconAnchor: new T.Point(10, 25) });
                    window.myIconzhuanxiang = new T.Icon({ iconUrl: `${imgBaseUrl}zhuanxiang.png`, iconSize: new T.Point(15, 25), iconAnchor: new T.Point(10, 25) });
                    window.myIconbj = new T.Icon({ iconUrl: `${imgBaseUrl}bj.png`, iconSize: new T.Point(10, 20), iconAnchor: new T.Point(10, 25) });
                    window.myIconbz3 = new T.Icon({ iconUrl: `${imgBaseUrl}bz3.png`, iconSize: new T.Point(35, 35), iconAnchor: new T.Point(10, 25) });
                    window.youkuList = {} //所有油库的标记点
                    window.chuanboList = {}//所有船舶数据的标记点
                    window.chuanboPostion = {}//所有船舶的标记点
                    window.fanweichaunbo = {
                        data: {},
                        marker: {},
                        label: {}
                    }//范围内船舶
                    window.markersfanwei = [];
                    window.shipidss = [];
                    window.markericondas = [];
                    window.markericonxiaos = [];
                    window.markersbz = [];
                    window.markersshow = [];
                    window.lablesbz = [];
                    window.showyoukuxq = this.showyoukuxq
                    window.showchuanbo = this.showchuanbo
                    this.getIndexData()
                    this.getShipPotions() //获取船舶标记点信息
                    this.getYouKu() //获取油库
                    this.getShipStates() //获取船舶状态
                    this.getCarrier() //获取所有承运商列表
                    this.getGangkou()//获取港口
                }, 100)
                // API 加载完成，现在可以使用了  

            }).catch(error => {
                console.error('Failed to load Tianditu API:', error);
            });
        },
        submitleavemessage() {
            this.$refs['ruleFormyijianfankui'].validate((validib) => {
                if (validib) {
                    this.$message.success('提交成功，感谢您的意见！')
                    this.opinionChange = false
                }
            })
        },
        fullScreen() {
            //全屏
            var full = document.documentElement;
            this.launchIntoFullscreen(full)
            this.fullscreen = true
        },
        //退出全屏封装
        exitFullscreen() {
            if (document.exitFullscreen)
                document.exitFullscreen()
            else if (document.mozCancelFullScreen)
                document.mozCancelFullScreen()
            else if (document.webkitExitFullscreen)
                document.webkitExitFullscreen()
            this.fullscreen = false
        },
        //全屏封装
        launchIntoFullscreen(element) {
            if (element.requestFullscreen)
                element.requestFullscreen()
            else if (element.mozRequestFullScreen)
                element.mozRequestFullScreen()
            else if (element.webkitRequestFullscreen)
                element.webkitRequestFullscreen()
            else if (element.msRequestFullscreen)
                element.msRequestFullscreen()
        },
        checkFull() {
            //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
            //火狐浏览器//谷歌浏览器及Webkit内核浏览器
            var isFull = document.mozFullScreen || document.fullScreen || document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled
            if (isFull === undefined)
                isFull = false
            return isFull
        },
        editMarker() {
            var markers = this.markerTool.getMarkers()
            for (var i = 0; i < markers.length; i++) {
                markers[i].enableDragging();
            }
        },
        endeditMarker() {
            var markers = this.markerTool.getMarkers()
            for (var i = 0; i < markers.length; i++) {
                markers[i].disableDragging();
            }
        },
        fangweiSubmit() {
            let _ = this;
            let data = {
                jd: this.fanweiData.longitude,
                wd: this.fanweiData.latitude,
                jl: Number(this.fanweiData.number) * 1000
            }
            this.$Api.settle_largescreenShowshipsfanwei(data).then(res => {
                if (res.code == 200) {
                    const statements = res.data.split('\n').map(statement => statement.trim().replace(/;$/, ''));
                    statements.forEach(statement => {
                        let executeCode = new Function(statement);
                        // 调用函数执行代码
                        executeCode();
                    });
                    _.$message.success('重载船舶成功')
                }
            })
            _.fanweiVisibility = false;
            // 定义该矩形的显示区域
            var circle = new T.Circle(new T.LngLat(data.jd, data.wd), data.jl, { color: "blue", weight: 5, opacity: 0.5, fillColor: "blue", fillOpacity: 0.3, lineStyle: "solid" });
            //向地图上添加圆

            this.map.addOverLay(circle);
            window.markersfanwei.push(circle);
        },
        fanwei() { //开启地图点击事件
            this.$message({
                message: '请击地图选择范围中心点',
                type: 'success',
                duration: 3000
            });
            this.map.addEventListener('click', this.xuanzezxd);
        },
        xuanzezxd(e) { //输入范围的公里数
            var _ = this;
            var LngLatwo = new T.LngLat(e.lnglat.getLng(), e.lnglat.getLat());
            _.fanweiData.latitude = e.lnglat.getLat()
            _.fanweiData.longitude = e.lnglat.getLng()
            var myIconwo = new T.Icon({ iconUrl: `${imgBaseUrl}bz1.png`, iconSize: new T.Point(38, 38), iconAnchor: new T.Point(10, 25) });
            var markerwofw = new T.Marker(LngLatwo, { icon: myIconwo });
            var overlays = _.map.getOverlays();
            var bj = overlays.length - 1;
            _.fanweiVisibility = true;
            _.map.removeEventListener('click', _.xuanzezxd);
        },
        shanchufanwei()//清楚范围
        {
            var fLenl = window.markersfanwei.length;
            for (i = 0; i < fLenl; i++) {
                this.map.removeOverLay(window.markersfanwei[i]);
            }
        },
        lookInfo() {//查看视频
            let row = {}
            row.id = this.shipInfoData.currShipData.carriersId
            let that = this;
            if (row.id == 10) { //武汉金甲
                window.open('https://video.cnsunrun.com:8443/wuhanjinjia/wuhanjinjia.html', '_blank')
            } else if (row.id == 11) { // 重庆凯美特
                window.open('https://video.cnsunrun.com:8443/wuhanjinjia/cqkaimeite.html', '_blank')
            } else if (row.id == 14) { //江苏泰和
                window.open('https://video.cnsunrun.com:8443/wuhanjinjia/jiangsutaihe.html', '_blank')
            } else if (row.id == 93) { //九江振鑫
                window.open('https://nyai.sinotrans-csc.com/nyzh/vapp', '_blank')
            } else if (row.id == 94) {//江隆宏元
                var oWebControl = new WebControl({
                    szPluginContainer: "playWnd",
                    iServicePortStart: 15900,
                    iServicePortEnd: 15900,
                    szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11",   // 用于IE10使用ActiveX的clsid
                    cbConnectSuccess: function () {
                        console.log('已安装');
                        that.isInstallWebControl = true;
                        window.open('https://video.cnsunrun.com:8443/chongqingchangyun/jianglonghongyuan.html', '_blank')
                    },
                    cbConnectError: function () {
                        console.log('未安装');
                        that.isInstallWebControl = false;
                        that.$confirm('检测到您未安装视频插件，是否安装视频插件?', '提示', {
                            confirmButtonText: '下载',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            // 创建一个a标签元素  
                            const link = document.createElement('a');
                            link.href = 'https://video.cnsunrun.com:8443/chongqingchangyun/VideoWebPlugin.exe';
                            link.download = 'ISVWebControl.exe'; // 设置下载文件的名称  
                            link.style.display = 'none'; // 隐藏a标签  
                            // 将a标签添加到DOM中  
                            document.body.appendChild(link);
                            // 模拟点击a标签  
                            link.click();
                            // 移除a标签  
                            document.body.removeChild(link);
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消安装'
                            });
                        });
                    },
                    cbConnectClose: function (bNormalClose) {
                        console.log('未安装');
                        that.isInstallWebControl = false;
                        that.$confirm('检测到您未安装视频插件，是否安装视频插件?', '提示', {
                            confirmButtonText: '下载',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            // 创建一个a标签元素  
                            const link = document.createElement('a');
                            link.href = 'https://video.cnsunrun.com:8443/chongqingchangyun/VideoWebPlugin.exe';
                            link.download = 'ISVWebControl.exe'; // 设置下载文件的名称  
                            link.style.display = 'none'; // 隐藏a标签  
                            // 将a标签添加到DOM中  
                            document.body.appendChild(link);
                            // 模拟点击a标签  
                            link.click();
                            // 移除a标签  
                            document.body.removeChild(link);
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消安装'
                            });
                        });
                    }
                });
            } else if (row.id == 2 || row.id == 3 || row.id == 4 || row.id == 6 || row.id == 9) {
                window.open('https://video.cnsunrun.com:8443/test.html?carrierId=' + row.id, '_blank')
            } else {
                this.$message.warning('暂未接入')
            }
        },
        openLasthangci() { // 打开航次计划
            let data = {
                chuanid: this.shipInfoData.currShipData.cid,
                page: 1,
                limit: 15
            }
            this.$Api.settle_largescreenDataBindDs(data).then(res => {
                if (res.code == 200 && Number(res.data.total != 0)) {
                    this.hangciTableData[0] = res.data.list[0]
                    for (let index = 0; index < this.gangkoulist.length; index++) {
                        const element = this.gangkoulist[index];
                        if (this.hangciTableData[0].startPort == element.id) {
                            this.hangciTableData[0].startPortName = element.title
                        }
                        if (this.hangciTableData[0].destPort == element.id) {
                            this.hangciTableData[0].destPortName = element.title
                        }
                    }
                    this.hangciVisibility = true;
                } else {
                    this.$message.warning('暂无数据')
                }
            })
        },
        getGangkou() {//获取港口
            this.$Api.oilWarehouseInfo_Dict().then(res => {
                this.gangkoulist = res.data.shipPort
            })
        },
        guijiTImeSure() { // 轨迹时间确定
            this.showguiji()
        },
        qingchuguiji()  //清楚轨迹
        {
            var fLenl = this.markersguiji.length;
            for (let i = 0; i < fLenl; i++) {
                this.map.removeOverLay(this.markersguiji[i]);
            }
        },
        async showguiji(shipid) { //显示轨迹
            let that = this;
            var ks = this.guijiTime[0];
            var js = this.guijiTime[1]
            that.qingchuguiji();//先清除以前的轨迹
            var day1 = new Date(js);
            var timestamp2 = Math.round(day1.setTime(day1.getTime() + 24 * 60 * 60 * 1000) / 1000);
            var oldTime = Math.round(new Date(ks).getTime() / 1000);
            let params = {
                k: 'a0d913be61fa4a3aa59502436c0ea215',
                cut: 1,
                id: this.shipInfoData.currShipData.shipid,
                btm: oldTime,
                etm: timestamp2
            }
            let res = await axios.get('https://api.shipxy.com/apicall/GetShipTrack', { params })
            if (res != "") {
                var guijixx = res//obj.employees[1].firstName
                var zt = guijixx.status;
                if (zt == 0) {
                    var packJson = guijixx.points;
                    var points = [];
                    var myIconks = new T.Icon({ iconUrl: `${imgBaseUrl}guiji3.png`, iconSize: new T.Point(20, 26), iconAnchor: new T.Point(10, 25) });
                    var myIconjs = new T.Icon({ iconUrl: `${imgBaseUrl}guiji2.png`, iconSize: new T.Point(20, 26), iconAnchor: new T.Point(10, 25) });
                    for (var k = 0; k < packJson.length; k++) {//遍历packJson 数组时，i为索引

                        var gjjd = packJson[k].lon / 1000000;
                        var gjwd = packJson[k].lat / 1000000;

                        var datess = new Date(parseInt(packJson[k].utc) * 1000).toLocaleString('chinese', { hour12: false }).replace(/:\d{1,2}$/, ' ');
                        if ((k % 10) == 0)// 位置
                        {
                            // alert(gjjd + " " + gjwd);
                            var mygjP0 = new T.LngLat(gjjd, gjwd);
                            var markergj0 = new T.Marker(mygjP0, { icon: myIconks });
                            that.map.addOverLay(markergj0);
                            that.map.centerAndZoom(mygjP0, 12);

                            that.markersguiji.push(markergj0);


                            //  var optsssgj =  new T.LngLat(nrs[0], nrs[1]) ;//'开始'+
                            var labelssgj = new T.Label({ text: datess, position: mygjP0, offset: new T.Point(0, -10) });
                            //  labelssgj.setBorderColor(color:'#144804');
                            that.map.addOverLay(labelssgj);
                            that.markersguiji.push(labelssgj);
                            //var labelgj0 = new T.Label({text: " + shijian + ",position: mygjP0,offset: new T.Point(-6, 0)});
                            // map.addOverLay(labelgj0);
                            // // alert(packJson[k].lon + " " + packJson[k].lat);
                        }
                        //if(k)
                        /**/

                        points.push(new T.LngLat(gjjd, gjwd));
                    }


                    var liness = new T.Polyline(points, {
                        color: '#0e5d01',
                        weight: 2,
                        opacity: 1
                    });
                    that.map.addOverLay(liness);
                    that.markersguiji.push(liness);

                }
                else {
                    // alert(msg);
                }
            }
        },
        shipInfoNode(data, nonde, ele) {
            if (!data?.children) {
                console.log(data);
                this.showchuanbo(data.id, 0, 0, data.title, data.carriers.title, data.ais, 0, data.carriers.id)
            }
        },
        loadNode(node, resolve) {
            console.log(node);
            if (node.level === 0) {
                return resolve(this.shipInfoData.data);
            }
            if (node.level > 1) return resolve([]);
            resolve(node.data.children);
            setTimeout(() => {
                const data = node.data.children
                resolve(data);
            }, 200);
        },
        async getCarrier() {//获取所有承运商列表
            let data = {
                page: 1,
                limit: 100,
                title: '',
                groupType: 2,
                pid: 0,
                states: 1,
                show_type: 2
            }
            let res = await this.$Api.getDict(data)
            this.shipInfoData.data = res.data.data
            this.shipInfoData.data.forEach((item) => {
                item.children = []
            })
            this.getShips()
        },
        getShips() { // 获取所有船舶列表
            let data = {
                page: 1,
                limit: 10000,
                title: '',
                states: 1,
                carriersId: ''
            }
            this.$Api.CarriersShip_getPageShip(data).then(res => {
                if (res.code == 200) {
                    let result = res.data.data
                    this.shipInfoData.data.forEach(carrier => {
                        result.forEach(ship => {
                            if (carrier.id == ship.carriers.id) {
                                carrier.children.push(ship)
                            }
                        })
                    })
                }
            })
        },
        getShipStates() {//获取船舶状态
            this.$Api.settle_largescreenShipStatusindex().then(res => {
                let result = res.data
                this.shipStatetableData[2].number = result.loadedCount[0].total
                this.shipStatetableData[3].number = result.emptyCount[0].total
                this.shipStatetableData[4].number = result.loadingUnloadingCount[0].total
                this.shipStatetableData[5].number = result.maintenanceCount[0].total
            })
        },
        shipTypeOpen(name, ships) { //打开船舶状态下的船舶列表
            console.log(name, ships);
            this.leftTitle = name
            this.currShipType = ships
            this.leftVisibility = true
        },
        getIndexData() { //获取页面参数
            console.log(this.$store);
            let data = {
                id: this.$store.state.userInfo.membertype
            }
            this.$Api.settle_largescreenShipIndex(data).then(res => {
                this.indexData = res.data
                this.shipType[0].number = this.indexData.kzxx
                this.shipType[0].ships = this.indexData.kongzaixiaxings
                this.shipType[1].number = this.indexData.kzdz
                this.shipType[1].ships = this.indexData.kongzaidengzhas
                this.shipType[2].number = this.indexData.zzdx
                this.shipType[2].ships = this.indexData.zhongzaidaixies
                this.shipType[3].number = this.indexData.zxzy
                this.shipType[3].ships = this.indexData.zhuangxiezuoyes
                this.shipType[4].number = this.indexData.zzcb
                this.shipType[4].ships = this.indexData.zhongzaichuanbos
                this.shipType[5].number = this.indexData.jx
                this.shipType[5].ships = this.indexData.jianxius
            })
        },
        bashang(bashangbz) { // 坝上的船舶显示隐藏
            let that = this
            var fLen = window.markersbz.length;
            if (bashangbz == 1) {
                for (i = 0; i < fLen; i++) {
                    var dianjwd = window.markersbz[i].getLngLat();// 
                    var dianjd = dianjwd.getLng();//
                    if (dianjd < 111.003768) {
                        window.markersbz[i].hide();
                        window.lablesbz[i].hide();
                    }
                }
            }
            else {
                let fdjibie = that.map.getZoom();
                var fLen = window.markersshow.length;
                let arr = []
                for (var i = 0; i < fLen; i++) // 
                {
                    if (window.markersshow[i] == 1)//说明要显示的
                    {
                        var dianjwd = window.markersbz[i].getLngLat();// 
                        var dianjd = dianjwd.getLng();// 
                        if (dianjd < 111.003768) {
                            arr.push(1)
                            window.markersbz[i].show();
                            window.lablesbz[i].show();
                            // if (fdjibie >= 10) {
                            //     window.lablesbz[i].show();
                            // }
                        }
                    }
                }
                this.shipStatetableData[0].number = arr.length
            }
        },
        baxia(baxiabz) { //坝下的船舶显示隐藏
            let that = this;
            var fLen = window.markersbz.length;
            if (baxiabz == 1) {
                for (i = 0; i < fLen; i++) {
                    var dianjwd = window.markersbz[i].getLngLat();// 
                    var dianjd = dianjwd.getLng();// 
                    if (dianjd < 111.003768) {
                    }
                    else {

                        window.markersbz[i].hide();
                        window.lablesbz[i].hide();
                    }
                }
            }
            else {
                let fdjibie = that.map.getZoom();
                var fLen = window.markersshow.length;
                let arr = []
                for (var i = 0; i < fLen; i++) // 
                {

                    if (window.markersshow[i] == 1)//说明要显示的
                    {
                        var dianjwd = window.markersbz[i].getLngLat();// 
                        var dianjd = dianjwd.getLng();// 

                        if (dianjd < 111.003768) {
                            //markersbz[i].hide();
                            //lablesbz[i].hide();                 
                        }
                        else {
                            window.markersbz[i].show();
                            window.lablesbz[i].show();
                            arr.push(1)
                        }
                    }
                }
                this.shipStatetableData[1].number = arr.length
            }

        },
        async showchuanbo(cid, jd, wd, mc, cys, shipid, sfsp, carriersId) {//点击船舶的弹窗
            console.log(cid, jd, wd, mc, cys, shipid, sfsp, carriersId);
            var that = this;
            let params = {
                k: 'a0d913be61fa4a3aa59502436c0ea215',
                enc: 1,
                id: shipid
            }
            const shipxx = await axios.get('https://api.shipxy.com/apicall/GetSingleShip', { params })
            var zt = shipxx.status;
            if (zt == 0) {
                that.huoqujhbz(cid)
                that.huoquchengshi(jd, wd)
                var ShipID = shipxx.data[0].ShipID;
                var shiptype = shipxx.data[0].shiptype;
                var From = shipxx.data[0].From;
                var imo = shipxx.data[0].imo;
                var name = shipxx.data[0].name;

                var callsign = shipxx.data[0].callsign;
                var changdu = shipxx.data[0].length / 10;
                var kuandu = shipxx.data[0].width / 10;
                var left = shipxx.data[0].left;
                var trail = shipxx.data[0].trail;
                var draught = shipxx.data[0].draught / 1000; //吃水



                var dest = shipxx.data[0].dest;
                var dest_std = shipxx.data[0].dest_std;
                var destcode = shipxx.data[0].destcode;
                var navistat = shipxx.data[0].navistat;
                var eta_std = shipxx.data[0].eta_std;
                var eta = shipxx.data[0].eta;
                wd = shipxx.data[0].lat / 1000000;
                jd = shipxx.data[0].lon / 1000000;
                var sog = shipxx.data[0].sog;
                var cog = shipxx.data[0].cog / 100;
                var hdg = shipxx.data[0].hdg / 100;
                var rot = shipxx.data[0].rot;
                var lasttime = shipxx.data[0].lasttime;
                //alert(ShipID);
                var lx = "其他";
                switch (shiptype) {
                    case 50:
                        lx = "引航船";
                        break;
                    case 51:
                        lx = "搜救船";
                        break;
                    case 52:
                        lx = "拖轮";
                        break;
                    case 53:
                        lx = "港口供应船";
                        break;
                    case 54:
                        lx = "载有防污染装置和设备的船舶";
                        break;
                    case 55:
                        lx = "执法艇";
                        break;
                    case 100:
                        lx = "集装箱";
                }
                if (shiptype >= 70 && shiptype <= 79) {
                    lx = "货船";
                }
                if (shiptype >= 80 && shiptype <= 89) {
                    lx = "油轮";
                }
                if (shiptype >= 60 && shiptype <= 69) {
                    lx = "客船";
                }
                if (shiptype >= 40 && shiptype <= 49) {
                    lx = "高速船";
                }
                if (shiptype >= 20 && shiptype <= 29) {
                    lx = "地效应船";
                }
                var zhuangtai = "在航";
                switch (navistat) {
                    case 2:
                        zhuangtai = "失控";
                        break;
                    case 3:
                        zhuangtai = "操纵受限";
                        break;
                    case 4:
                        zhuangtai = "吃水受限";
                        break;
                    case 5:
                        zhuangtai = "靠泊";
                        break;
                    case 7:
                        zhuangtai = "捕捞作业";
                        break;
                    case 8:
                        zhuangtai = "靠帆船提供动力";
                        break;
                    case 6:
                        zhuangtai = "搁浅";
                }
                var shijians = new Date(parseInt(lasttime) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
                //hdg 船首向  cog 航迹向   sog 速度  rot 转向率  left 左舷距   trail 尾距  draught 吃水，毫米  dest 目的地  eta_std 标准化后的预到时间
                that.shipInfovibaty = true
                that.shipInfoData.currShipName = mc
                that.shipInfoData.currShipData.shipid = shipid
                that.shipInfoData.currShipData.cys = cys
                that.shipInfoData.currShipData.mc = mc
                that.shipInfoData.currShipData.hdg = hdg
                that.shipInfoData.currShipData.cog = cog
                that.shipInfoData.currShipData.sog = sog
                that.shipInfoData.currShipData.rot = rot
                that.shipInfoData.currShipData.left = left
                that.shipInfoData.currShipData.trail = trail
                that.shipInfoData.currShipData.draught = draught
                that.shipInfoData.currShipData.dest = dest
                that.shipInfoData.currShipData.eta_std = eta_std
                that.shipInfoData.currShipData.lx = lx
                that.shipInfoData.currShipData.shipZt = zhuangtai
                that.shipInfoData.currShipData.wd = wd
                that.shipInfoData.currShipData.jd = jd
                that.shipInfoData.currShipData.shijians = shijians
                that.shipInfoData.currShipData.cid = cid
                that.shipInfoData.currShipData.carriersId = carriersId
            }
        },
        huoqujhbz(cid) { //获取船舶信息
            let that = this;
            that.$Api.settle_largescreenShipPlanindex({ id: cid }).then(res => {
                var aa = res.data.split(">");
                that.shipInfoData.currShipData.fagang = aa[0]
                that.shipInfoData.currShipData.daodagang = aa[1]
                that.shipInfoData.currShipData.youhao = aa[2]
                that.shipInfoData.currShipData.leixing = aa[3]
                that.shipInfoData.currShipData.zhuangtai = aa[4]
                that.shipInfoData.currShipData.chuanming = aa[5]
                that.shipInfoData.currShipData.shuliang = aa[7]
            })
        },
        async huoquchengshi(jd, wd) { //获取城市
            let params = {
                tk: '60bb3cdc0793b86debd74e09315483d6',
                type: 'geocode',
                postStr: {
                    lon: jd,
                    lat: wd,
                    ver: 1
                }
            }
            const chengshi = await axios.get('http://api.tianditu.gov.cn/geocoder', { params })
            let dizhi = chengshi.result.addressComponent.city;
            this.shipInfoData.currShipData.chengshi = dizhi
        },
        mapaddOverLay_youku() { //添加油库标记点
            let that = this;
            for (const key in window.youkuList) {
                that.map.addOverLay(window.youkuList[key])
            }
        },
        hide_youku() {//隐藏油库
            for (const key in window.youkuList) {
                let maker = window.youkuList[key]
                maker.hide()
            }
        },
        show_youku() {
            for (const key in window.youkuList) {
                let maker = window.youkuList[key]
                maker.show()
            }
        },
        mapaddOverLay_chuanbo() { //添加船舶标记点
            let that = this;
            for (const key in window.chuanboPostion) {
                that.map.addOverLay(window.chuanboPostion[key])
            }
        },
        show_chuanbo() { // 显示船舶标记点
            const regex = /markership(\d+)/;
            Object.keys(window.chuanboPostion)
                .filter(key => key.startsWith('markership'))
                .map(key => {
                    let marker = window.chuanboPostion[key]
                    if (marker instanceof T.Marker) { // 确保marker是T.Marker的实例  
                        marker.show()
                    }
                });
        },
        hide_chuanbo() { // 隐藏船舶标记点
            const regex = /markership(\d+)/;
            Object.keys(window.chuanboPostion)
                .filter(key => key.startsWith('markership'))
                .map(key => {
                    let marker = window.chuanboPostion[key]
                    if (marker instanceof T.Marker) { // 确保marker是T.Marker的实例  
                        // marker.hide()
                        this.map.removeOverLay(marker);
                    }
                });
        },
        delshiplablebj() { //添加圆点标记点
            let that = this;
            const regex = /markership(\d+)/;
            Object.keys(window.chuanboPostion)
                .filter(key => key.startsWith('markership'))
                .map(key => {
                    let match = key.match(regex);
                    window.chuanboPostion[key].setIcon(window.chuanboList[`myIconshipx${match[1]}`]);
                });
        },
        shipdelshiplablebj() { // 添加船舶放大后的船舶图标
            let that = this;
            const regex = /markership(\d+)/;
            Object.keys(window.chuanboPostion)
                .filter(key => key.startsWith('markership'))
                .map(key => {
                    let match = key.match(regex);
                    let marker = window.chuanboPostion[key]
                    console.log(marker);
                    if (marker instanceof T.Marker) { // 确保marker是T.Marker的实例  
                        let newIcon = new T.Icon({ iconUrl: window.chuanboList[`myIconship${match[1]}`].options.iconUrl, iconSize: new T.Point(48, 56), iconAnchor: new T.Point(10, 25) })
                        marker.setIcon(newIcon);
                    }
                    console.log(marker);
                    // window.chuanboPostion[`${key}`] = new T.Marker(window.chuanboList[`pt${match[1]}`], { icon: window.chuanboList[`myIconship${match[1]}`] });
                });
            that.show_chuanbo()
            that.mapaddOverLay_chuanbo()
        },
        getYouKu() { // 获取油库
            this.$Api.settle_largescreenDatayouku().then(res => {
                const statements = res.data.split('\n').map(statement => statement.trim().replace(/;$/, ''));
                statements.forEach(statement => {
                    let executeCode = new Function(statement);
                    // 调用函数执行代码
                    executeCode();
                });
                this.mapaddOverLay_youku()//添加油库标记点
            })
        },
        getShipPotions() { // 获取船舶
            this.$Api.settle_largescreenDataoperation().then(res => {
                const statements = res.data.split('\n').map(statement => statement.trim().replace(/;$/, ''));
                statements.forEach(statement => {
                    let executeCode = new Function(statement);
                    // 调用函数执行代码
                    executeCode();
                });
                this.mapaddOverLay_chuanbo() //添加船舶标记点
                this.delshiplablebj()
                this.bashang(0)
                this.baxia(0)
            })
        },
        setShipTubiao() {
            this.$Api.settle_largescreenDatashowshiptdtjiukouxin().then(res => {
                const statements = res.data.split('\n').map(statement => statement.trim().replace(/;$/, ''));
                statements.forEach(statement => {
                    let executeCode = new Function(statement);
                    // 调用函数执行代码
                    executeCode();
                });
                this.mapaddOverLay_chuanbo()
                this.shipdelshiplablebj()
            })
        },
        showyoukuxq(id) {
            let that = this;
            console.log("油库详情", id);
        },
        handleOpenMenu(e) {
            switch (e) {
                case '1':
                    this.$router.push({ path: '/vesseMonitor/jihuas' })
                    break;
                case '2':
                    this.$router.push({ path: "/vesseMonitor/huoqujihua" })
                    break;
                case '3':
                    this.$router.push({ path: "/vesseMonitor/huoquxxsw" })
                    break;
                default:
                    break;
            }
        },
        handleCloseMenu(e) {
            console.log(e);
        },
        querySearchAsync(queryString, cb) {
            if (queryString) {
                const searchService = new T.MapService(); // 创建搜索服务
                // 发起关键字搜索请求
                searchService.queryByKeyword(queryString, (e) => {
                    if (e.getStatus() === 0) {
                        const result = e.getResult();
                        console.log(result);
                    }
                });
            } else {
                this.restaurants = []; // 清空搜索结果
            }
        },
        handleSelect(item) {
            console.log(item, '111');
        },
        restPostion() {//重新定位
            this.$refs['positionruleForm'].validate((valid) => {
                if (valid) {
                    this.map.centerAndZoom(new T.LngLat(this.position.lng, this.position.lat), this.mapZoom);
                    this.visibleAddres = false;
                } else {
                    return false;
                }
            });
        },
        opinionOpen() {
            this.opinionChange = !this.opinionChange
        },
        handleOpen(key) {
            if (this.defaultMenu.indexOf(key) == -1) {
                this.defaultMenu.push(key)
            } else {
                let index = this.defaultMenu.indexOf(key)
                this.defaultMenu.splice(index, 1)
            }
            switch (key) {
                case 0:
                    if (this.defaultMenu.indexOf(0) != -1) {
                        this.bashang(0)
                    } else {
                        this.bashang(1)
                    }
                    break;
                case 1:
                    if (this.defaultMenu.indexOf(1) != -1) {
                        this.baxia(0)
                    } else {
                        this.baxia(1)
                    }
                    break;
                case 2:
                    this.fanwei()
                    setTimeout(() => {
                        let index = this.defaultMenu.indexOf(2)
                        this.defaultMenu.splice(index, 1)
                    }, 100)
                    break;
                case 3:
                    if (this.defaultMenu.indexOf(3) != -1) {
                        this.shipStates = true
                    } else {
                        this.shipStates = false
                    }
                    break;
                case 4:
                    this.markerTool.open()
                    setTimeout(() => {
                        let index = this.defaultMenu.indexOf(4)
                        this.defaultMenu.splice(index, 1)
                    }, 100)
                    break;
                case 5:
                    if (this.defaultMenu.indexOf(5) != -1) {
                        this.lineTool.open()
                    } else {
                        this.lineTool.close()
                    }
                    break;
                case 6:
                    if (this.defaultMenu.indexOf(6) != -1) {
                        this.shipInfodrawer = true
                    } else {
                        this.shipInfodrawer = false
                    }
                    break;
                case 7:
                    if (this.defaultMenu.indexOf(7) != -1) {
                        this.show_youku()
                    } else {
                        this.hide_youku()
                    }

                    break;
                case 8:

                    break;
                case 9:
                    if (this.defaultMenu.indexOf(9) != -1) {
                        this.menuBox = true
                    } else {
                        this.menuBox = false
                    }
                    break;
                default:
                    break;
            }

        },
        shipStateClose() {
            let index = this.defaultMenu.indexOf(3)
            this.defaultMenu.splice(index, 1)
        },
        shipinfoClose() {
            let index = this.defaultMenu.indexOf(6)
            this.defaultMenu.splice(index, 1)
        },
        menuClose() {
            let index = this.defaultMenu.indexOf(9)
            this.defaultMenu.splice(index, 1)
        },
        handleClose() { },
    },
    beforeDestroy() {
        delete window.showyoukuxq
    }
}
</script>

<style>
.quanpin {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 99 !important;
}
</style>
<style lang="scss" scoped>
.positionship {
    height: inherit;
    position: relative;

    #mapDiv {
        width: 100%;
        height: 100%;
    }

    .mapSearch {
        position: absolute;
        top: 15px;
        left: 20px;
        width: 300px !important;
        z-index: 999 !important;
    }

    .menuBox {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 999 !important;
        background-color: #FFF;
        padding: 0 12px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 8px #999;

        .menuItem {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            margin-right: 20px;
            color: #999;
            cursor: pointer;
            transition: all 0.3s;

            span {
                margin-left: 6px;
            }
        }

        .menuItem:last-child {
            margin-right: 0;
        }



        .menuItemActive {
            color: #409EFF;
        }
    }

    .giveServiceto {
        position: absolute;
        top: 95px;
        right: 20px;
        z-index: 999 !important;

        .giveServiceto_item {
            padding: 7px;
            background-color: #FFF;
            border-radius: 6px;
            box-shadow: 1px 1px 8px #999;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            transition: all 0.3s;
            cursor: pointer;

            img {
                width: 20px;
            }
        }

        .giveServiceto_item:hover {
            transform: scale(1.1);
        }

        .box {
            width: 400px;

            .top {
                text-align: center;
            }

            .left {
                float: left;
                width: 60px;
            }

            .right {
                float: right;
                width: 60px;
            }

            .bottom {
                clear: both;
                text-align: center;
            }

            .item {
                margin: 4px;
            }

            .left .el-tooltip__popper,
            .right .el-tooltip__popper {
                padding: 8px 10px;
            }
        }

    }

    .customerItem {
        display: flex;



        .tip {
            color: #999;
            margin-right: 10px;
        }

        a {
            text-decoration: none;
        }
    }

    .markServer {
        position: absolute;
        bottom: 250px;
        right: 20px;
        z-index: 999 !important;
        background-color: #FFF;
        padding: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 1px 1px 8px #999;

        img {
            width: 20px;
            cursor: pointer;
            transition: all 0.3s;
            margin-top: 7px;
            margin-bottom: 7px;
        }

        img:hover {
            transform: scale(1.1);
        }
    }

    .shipType {
        width: 100px;
        position: absolute;
        bottom: 75px;
        left: 10px;
        z-index: 999 !important;
        background: transparent;
        border: 1px solid #E5EFF9;
        ;
        box-shadow: 0px 0px 16px 0px #80aedc;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 7px;
        padding-bottom: 2px;
        border-radius: 6px;

        /deep/.el-tag {
            width: 85px;
            text-align: center;
            cursor: pointer;
            margin-bottom: 5px;
        }
    }

    /deep/.el-button--primary {
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
    }

    /deep/.el-button--primary:hover {
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
    }

    /deep/.el-drawer {
        width: 20%;
        padding-left: 20px;
    }

    .dialogDiv {
        height: 40vh;
        overflow-y: auto;

        .leftdialogItem {
            width: 50%;
            display: inline-block;
            text-align: center;
            margin-bottom: 12px;
        }
    }

    /deep/.el-tree-node__children {
        .el-tree-node__expand-icon {
            display: none;
        }
    }
}
</style>