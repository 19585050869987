<template>
  <div class="page province">
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small" >
              <el-button  icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange" style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox label="名称"></el-checkbox>
                  <el-checkbox label="简称"></el-checkbox>
                  <el-checkbox label="编码"></el-checkbox>
                  <el-checkbox label="属性"></el-checkbox>
                  <el-checkbox label="创建时间"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table v-if="checkloding" id="tabbox" ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="(100% - 104px)" row-key="id" default-expand-all
        :tree-props="{ children: '_child', hasChildren: 'hasChildren' }" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column label="名称" prop="title" v-if="checkList.indexOf('名称') != -1">
        </el-table-column>
        <el-table-column align="center" label="简称" prop="subtitle" v-if="checkList.indexOf('简称') != -1">
        </el-table-column>
        <el-table-column align="center" label="编码" prop="groupCode" v-if="checkList.indexOf('编码') != -1">
        </el-table-column>
        <el-table-column align="center" label="属性" prop="KUNTP" v-if="checkList.indexOf('属性') != -1">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" v-if="checkList.indexOf('创建时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="20vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="名称/全称：" prop="title">
            <el-input v-model="createdData.title" placeholder="请输入名称/全称"></el-input>
          </el-form-item>
          <el-form-item label="简称：" prop="subtitle">
            <el-input v-model="createdData.subtitle" placeholder="请输入简称"></el-input>
          </el-form-item>
          <el-form-item label="属性：" prop="KUNTP">
            <el-input v-model="createdData.KUNTP" placeholder="请输入属性"></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="groupCode">
            <el-input v-model="createdData.groupCode" placeholder="请输入编码"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑 - ' + createdData.title"
        :visible.sync="dialogVisible2" destroy-on-close top="15vh" width="70%">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="100px" inline>
          <el-form-item label="名称/全称：" prop="title">
            <el-input v-model="createdData.title"></el-input>
          </el-form-item>
          <el-form-item label="简称：" prop="subtitle">
            <el-input v-model="createdData.subtitle"></el-input>
          </el-form-item>
          <el-form-item label="属性：" prop="KUNTP">
            <el-input v-model="createdData.KUNTP"></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="groupCode">
            <el-input v-model="createdData.groupCode"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialogCont" v-if="createdData.pid==0">
          <el-divider content-position="left">油库信息</el-divider>
          <el-table ref="multipleTable2" border :data="oilTableData" tooltip-effect="dark"
            style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
            <el-table-column align="center" label="油库名称" prop="title">
            </el-table-column>
            <el-table-column align="center" label="省市公司" prop="sstitle">
            </el-table-column>
            <el-table-column align="center" label="港口名称" prop="gktitle">
            </el-table-column>
            <el-table-column align="center" label="港口类型" prop="transType">
            </el-table-column>
            <el-table-column align="center" label="更新时间" prop="updateTime">
            </el-table-column>
            <el-table-column align="center" label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="oilediterow(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
        <el-dialog width="500px" :title="'编辑'" :visible.sync="innerVisible" append-to-body>
          <div class="oildialog">
            <el-form ref="oilform" :model="oilCreateData" :rules="oildrules" label-width="100px">
              <el-form-item label="名称：" prop="ssTitle">
                <el-input v-model="oilCreateData.ssTitle"></el-input>
              </el-form-item>
              <el-form-item label="省市公司：" prop="parentId">
                <el-select v-model="oilCreateData.parentId" placeholder="请选择" disabled style="width: 100%;">
                  <el-option v-for="item in unitData" :key="item.id" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="编码：" prop="code">
                <el-input v-model="oilCreateData.code"></el-input>
              </el-form-item>
              <el-form-item label="港口名称：" prop="gkName">
                <el-input v-model="oilCreateData.gkName"></el-input>
              </el-form-item>
              <el-form-item label="港口类型：" prop="transType">
                <el-select v-model="oilCreateData.transType" placeholder="请选择" style="width: 100%;">
                  <el-option v-for="item in gkTypeData" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="港口编码：" prop="gkcode">
                <el-input v-model="oilCreateData.gkcode"></el-input>
              </el-form-item>
              <el-form-item label="靠泊能力：" prop="berthing">
                <el-input v-model="oilCreateData.berthing"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="oilsubRefuse">确 定</el-button>
          </span>
        </el-dialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "Province",
  data() {
    return {
      checkloding:true,
      isAdd: false,
      checkList: ['名称', '简称', '编码', '属性', '创建时间'],
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      createdData: {
        id: '',
        groupCode: '',
        groupType: 3,
        pid: 0,
        subtitle: '',
        title: '',
        KUNTP: ''
      },
      oilCreateData: {
        ssTitle: '',
        id: '',
        parentId: '',
        code: '',
        gkName: '',
        transType: '',
        gkcode: '',
        berthing: '',
        gkid: ''
      },
      rules: {
        groupCode: {
          required: true, message: '请输入编码', trigger: 'blur'
        },
        subtitle: {
          required: true, message: '请输入简称', trigger: 'blur'
        },
        KUNTP: {
          required: true, message: '请输入属性', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称/全称', trigger: 'blur'
        },
      },
      oildrules: {

      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      innerVisible: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 1,
        lastPage: 1,
      },
      tableData: [],
      oilTableData: [],
      unitData: [],
      gkTypeData: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getunit()
    that.getgkData()
  },
  activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
  methods: {
    print() {
      let printableContent = document.getElementById('tabbox').innerHTML
      let printWindow = window.open('http://localhost:8080/#/userInfo', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title></head><body>' + ${printableContent} + '</body></html>`);
      // 执行打印操作
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)
    },
    oilsubRefuse() {
      let data = {
        ssTitle: this.oilCreateData.ssTitle,
        id: this.oilCreateData.id,
        parentId: this.oilCreateData.parentId,
        code: this.oilCreateData.code,
        gkName: this.oilCreateData.gkName,
        transType: this.oilCreateData.transType,
        gkcode: this.oilCreateData.gkcode,
        berthing: this.oilCreateData.berthing,
        gkid: this.oilCreateData.gkid,
      }
      this.$Api.oilWarehouseInfo_InsertOrUpdateOilWareHouseNew(data).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.innerVisible = false
        }
      })
    },
    oilediterow(row) {
      let data = {
        id: row.id,
      }
      this.$Api.getOneByIdForYk(data).then(res => {
        if (res.code == 200) {
          this.innerVisible = true
          this.oilCreateData = {
            ssTitle: res.data[0].ssTitle,
            id: res.data[0].id,
            parentId: res.data[0].parentId,
            code: res.data[0].code,
            gkName: res.data[0].gkName,
            transType: res.data[0].transType,
            gkcode: res.data[0].gkcode,
            berthing: res.data[0].berthing,
            gkid: res.data[0].shipPortId
          }
        }
      })
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            groupCode: this.createdData.groupCode,
            groupType: 3,
            pid: this.createdData.pid,
            subtitle: this.createdData.subtitle,
            subtitle2: this.createdData.subtitle,
            title: this.createdData.title,
            KUNTP: this.createdData.KUNTP
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.provinceAddorupdate(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.dialogVisible2 = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
    },
    dropdownChange(e) {
      this.checkloding = false
      this.$nextTick(()=>{
        this.checkloding = true
      })
    },
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        console.log('Excel');
      }
    },
    editerow(row) {
      this.createdData = {
        id: row.id,
        groupCode: row.groupCode,
        groupType: 2,
        pid: row.pid,
        subtitle: row.subtitle,
        title: row.title,
        KUNTP: row.KUNTP,
      }
      this.isAdd = false
      this.dialogVisible2 = true
      let data = {
        pid: row.id,
      }
      this.$Api.getListCrew(data).then(res => {
        this.oilTableData = res.data.list
      })
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.oilWarehouseInfo_delete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        groupCode: '',
        groupType: 2,
        pid: 0,
        subtitle: '',
        title: '',
        KUNTP: ''
      }
      this.$refs['form'].resetFields();
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getgkData() {
      this.$Api.publics_transportType().then(res => {
        let ss = []
        for (var key in res.data) {
          ss.push(res.data[key])
        }
        this.gkTypeData = ss
      })
    },
    getunit() {
      let data = {
        groupType: 3
      }
      this.$Api.memberGroupGary_ListByType(data).then(res => {
        this.unitData = res.data
      })
    },
    getData() { // 获取数据
      let data = {
        curpage: this.page.currentPage,
        pagesize: this.page.pageSize,
        groupType: 3,
        states: 1,
        show_type: 1
      }
      this.tableLoading = true
      this.$Api.getDict(data).then(res => {
        this.tableLoading = false
        this.tableData = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.province {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .dialogCont {
      height: 50vh;
      overflow-y: scroll;

      /deep/.el-divider__text.is-left {
        font-size: 18px;
      }
    }

    .oildialog {
      /deep/.el-select {
        width: 100%;
      }
    }

  }
}
</style>