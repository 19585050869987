<template>
    <div class="page ChangeHarborDivideUnload">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="发运时间范围：">
                    <el-date-picker v-model="searchForm.dateResult" type="daterange" size="small" range-separator="-"
                        value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否关联：">
                    <el-select v-model="searchForm.sfgl" clearable placeholder="请选择是否关联" size="small">
                        <el-option value="0" label="全部"></el-option>
                        <el-option value="2" label="已关联"></el-option>
                        <el-option value="1" label="未关联"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="承运商：">
                    <el-select v-model="searchForm.carrierList_new" clearable placeholder="请选择承运商" size="small">
                        <el-option v-for="(item, index) in dict.carrierList" :key="index" :value="item.id"
                            :label="item.subtitle"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运价时间：">
                    <el-date-picker v-model="searchForm.faretime" type="date" value-format="yyyy-MM-dd"
                        placeholder="请选择运价时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                max-height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index">
                </el-table-column>
                <el-table-column align="center" label="流向" prop="belongIdName">
                </el-table-column>
                <el-table-column align="center" label="始发" prop="startPortName">
                </el-table-column>
                <el-table-column align="center" label="目的" prop="destPortName">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="shipName" width="200">
                </el-table-column>
                <el-table-column align="center" label="发运日期" prop="startTimeData" width="200">
                </el-table-column>
                <el-table-column align="center" label="调整后目的" prop="adjustDestPortName" width="150">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="oilTypeName" width="250">
                </el-table-column>
                <el-table-column align="center" label="完成数" prop="realTon">
                </el-table-column>
                <el-table-column align="center" label="单价" prop="originalPrice">
                </el-table-column>
                <el-table-column align="center" label="运费" prop="originalPriceYUNJIA">
                </el-table-column>
                <el-table-column align="center" label="调整后单价" prop="adjustPrice" width="100">
                </el-table-column>
                <el-table-column align="center" label="调整后完成数" prop="TrealTon" width="200">
                </el-table-column>
                <el-table-column align="center" label="调整后运费" prop="adjustPriceYUNJIA" width="200">
                    <template slot-scope="scope">
                        <div>{{ (scope.row.adjustPriceYUNJIA).toFixed(3) }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="退补运费" prop="originalPriceYUNJIA">
                    <template slot-scope="scope">
                        <div>{{ (scope.row.adjustPriceYUNJIA - scope.row.originalPriceYUNJIA).toFixed(3) }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="关联状态" prop="sfglName">
                    <template slot-scope="scope">
                        <div v-if="scope.row.sfglName == '未关联'" style="color:red;">未关联</div>
                        <div v-else>已关联</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="关联交货单" prop="revErpVbeln" width="200">
                </el-table-column>
                <el-table-column align="center" label="清单号" prop="KIDNO" width="200">
                </el-table-column>
                <el-table-column align="center" label="财务共享号" prop="Shareno" width="200">
                </el-table-column>
                <el-table-column align="center" label="操作" width="160" fixed="right">
                    <template slot-scope="scope">
                        <!-- <el-button type="primary" size="mini" @click="editerow(scope.row)">查看</el-button> -->
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">查看退补明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 查看退补明细 -->
            <el-dialog :close-on-click-modal="false" title="查看退补明细" :visible.sync="dialogVisible" destroy-on-close
                top="12vh" width="65%">
                <div class="addoreidt">
                    <div class="tip">原计划</div>
                    <el-table ref="multipleTable" border v-loading="tableLoading2" :data="tableData2" tooltip-effect="dark"
                        header-row-class-name="header-table">
                        <el-table-column align="center" type="index" width="55">
                        </el-table-column>
                        <el-table-column align="center" label="流向" prop="belongIdName">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="startPortName">
                        </el-table-column>
                        <el-table-column align="center" label="发运日期" prop="startTimeData">
                        </el-table-column>
                        <el-table-column align="center" label="目的" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="oilTypeName">
                        </el-table-column>
                        <el-table-column align="center" label="完成数" prop="realTon">
                        </el-table-column>
                        <el-table-column align="center" label="单价" prop="originalPrice">
                        </el-table-column>
                        <el-table-column align="center" label="原始运费" prop="originalPriceYUNJIA">
                        </el-table-column>
                    </el-table>
                    <div class="tip">调整后计划</div>
                    <el-table ref="multipleTable" border v-loading="tableLoading2" :data="tableData3" tooltip-effect="dark"
                        header-row-class-name="header-table">
                        <el-table-column align="center" type="index" width="55">
                        </el-table-column>
                        <el-table-column align="center" label="流向" prop="belongIdName">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="startPortName">
                        </el-table-column>
                        <el-table-column align="center" label="目的" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="oilTypeName">
                        </el-table-column>
                        <el-table-column align="center" label="调整后完成数" prop="TrealTon">
                        </el-table-column>
                        <el-table-column align="center" label="调整后单价" prop="originalPrice">
                        </el-table-column>
                        <el-table-column align="center" label="调整后运费" prop="originalPriceYUNJIA">
                        </el-table-column>
                    </el-table>
                    <div class="tip">运费计算参数（按较远目的地单价计算）</div>
                    <el-table ref="multipleTable" border v-loading="tableLoading2" :data="tableData4" tooltip-effect="dark"
                        header-row-class-name="header-table">
                        <el-table-column align="center" type="index" width="55">
                        </el-table-column>
                        <el-table-column align="center" label="流向" prop="belongIdName">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="startPortName">
                        </el-table-column>
                        <el-table-column align="center" label="目的" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="oilTypeName">
                        </el-table-column>
                        <el-table-column align="center" label="调整后完成数" prop="priceRealTon">
                        </el-table-column>
                        <el-table-column align="center" label="调整后单价" prop="adjustPrice">
                        </el-table-column>
                        <el-table-column align="center" label="调整后运费" prop="adjustPriceTotal">
                        </el-table-column>
                    </el-table>
                    <div class="priceTip"><span>原始运价:{{ ysyj }}</span><span>调整后运价:{{ tzhyj }}</span><span>退补运价:{{ tbyj
                    }}</span></div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="dialogVisible = false">关 闭</el-button>
                </span>
            </el-dialog>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">流向</th>
                            <th class="tg-0lax">始发</th>
                            <th class="tg-0lax">目的</th>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">发运日期</th>
                            <th class="tg-0lax">调整后目的</th>
                            <th class="tg-0lax">油品</th>
                            <th class="tg-0lax">完成数</th>
                            <th class="tg-0lax">单价</th>
                            <th class="tg-0lax">运费</th>
                            <th class="tg-0lax">调整后单价</th>
                            <th class="tg-0lax">调整后完成数</th>
                            <th class="tg-0lax">调整后运费</th>
                            <th class="tg-0lax">退补运费</th>
                            <th class="tg-0lax">关联状态</th>
                            <th class="tg-0lax">关联交货单</th>
                            <th class="tg-0lax">清单号</th>
                            <th class="tg-0lax">财务共享号</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.belongIdName }}</th>
                        <th class="tg-0lax">{{ item.startPortName }}</th>
                        <th class="tg-0lax">{{ item.destPortName }}</th>
                        <th class="tg-0lax">{{ item.shipName }}</th>
                        <th class="tg-0lax">{{ item.startTimeData }}</th>
                        <th class="tg-0lax">{{ item.adjustDestPortName }}</th>
                        <th class="tg-0lax">{{ item.oilTypeName }}</th>
                        <th class="tg-0lax">{{ item.realTon }}</th>
                        <th class="tg-0lax">{{ item.originalPrice }}</th>
                        <th class="tg-0lax">{{ item.originalPriceYUNJIA }}</th>
                        <th class="tg-0lax">{{ item.adjustPrice }}</th>
                        <th class="tg-0lax">{{ item.TrealTon }}</th>
                        <th class="tg-0lax">{{ (item.adjustPriceYUNJIA).toFixed(3) }}</th>
                        <th class="tg-0lax">{{ (item.adjustPriceYUNJIA - item.originalPriceYUNJIA).toFixed(3) }}</th>
                        <th class="tg-0lax">{{ item.sfglName == '未关联' ? '未关联' : '已关联' }}</th>
                        <th class="tg-0lax">{{ item.revErpVbeln }}</th>
                        <th class="tg-0lax">{{ item.KIDNO }}</th>
                        <th class="tg-0lax">{{ item.Shareno }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ChangeHarborDivideUnload",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                dateResult: [],
                sfgl: '0',
                carrierList_new: '',
                faretime: ''
            },
            tableLoading: false,
            dialogVisible: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
            tableData2: [],
            tableData3: [],
            tableData4: [],
            tableLoading2: false,
            dict: {//数据字典
                carrierList: [],
                companyList: [],
                monthlyList: [],
                oilList: [],
                shipList: [],
                shipPortBegin: [],
                shipPortEnd: [],
                shipPortList: [],
            },
            ysyj: 0,
            tzhyj: 0,
            tbyj: 0
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getDict()
        this.getData()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let tableData = []
                    this.tableData.map(item => {
                        item.tbyflg = (item.adjustPriceYUNJIA - item.originalPriceYUNJIA).toFixed(3)
                        item.glztlg = item.sfglName == '未关联' ? '未关联' : '已关联'
                        tableData.push(item)
                    })
                    let config = [
                        { field: 'belongIdName', width: 80, align: 'center', title: '流向' },
                        { field: 'startPortName', width: 80, align: 'center', title: '始发' },
                        { field: 'destPortName', width: 80, align: 'center', title: '目的' },
                        { field: 'shipName', width: 150, align: 'center', title: '船名' },
                        { field: 'startTimeData', width: 150, align: 'center', title: '发运日期' },
                        { field: 'adjustDestPortName', width: 150, align: 'center', title: '调整后目的' },
                        { field: 'oilTypeName', width: 80, align: 'center', title: '油品' },
                        { field: 'realTon', width: 80, align: 'center', title: '完成数' },
                        { field: 'originalPrice', width: 80, align: 'center', title: '单价' },
                        { field: 'originalPriceYUNJIA', width: 80, align: 'center', title: '运费' },
                        { field: 'adjustPrice', width: 100, align: 'center', title: '调整后单价' },
                        { field: 'TrealTon', width: 80, align: 'center', title: '调整后完成数' },
                        { field: 'adjustPriceYUNJIA', width: 160, align: 'center', title: '调整后运费' },
                        { field: 'tbyflg', width: 160, align: 'center', title: '退补运费' },
                        { field: 'glztlg', width: 160, align: 'center', title: '关联状态' },
                        { field: 'revErpVbeln', width: 200, align: 'center', title: '关联交货单' },
                        { field: 'KIDNO', width: 200, align: 'center', title: '清单号' },
                        { field: 'Shareno', width: 160, align: 'center', title: '财务共享号' },
                    ]
                    let obj = {
                        name: '改港分卸',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        addRole() {
            console.log('添加');
        },
        getDict() {
            this.$Api.shipPlan_Dict().then(res => {
                if (res.code == 200) {
                    this.dict.carrierList = res.data.carrierList             
                    this.dict.companyList = res.data.companyList
                    this.dict.monthlyList = res.data.monthlyList
                    this.dict.oilList = res.data.oilList
                    this.dict.shipList = res.data.shipList  
                    this.dict.shipPortBegin = res.data.shipPortBegin
                    this.dict.shipPortEnd = res.data.shipPortEnd
                    this.dict.shipPortList = res.data.shipPortList
                }
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        editerow(row) {
            let data = {
                shipplanid: row.shipPlanid,
                dATAB: this.searchForm.faretime
            }
            this.tableLoading2 = true
            this.$Api.settle_getsettleinfoListPid(data).then(res => {
                this.tableLoading2 = false
                if (res.code == 200) {
                    this.tableData2 = res.data.Ydata
                    this.tableData3 = res.data.Tdata
                    this.tableData4 = res.data.Pdata
                    this.ysyj = 0
                    this.tzhyj = 0
                    this.tbyj = 0
                    for (var i = 0; i < res.data.Ydata.length; i++) {
                        this.ysyj += res.data.Ydata[i].originalPriceYUNJIA;
                    }
                    for (var i = 0; i < res.data.Pdata.length; i++) {
                        this.tzhyj += res.data.Pdata[i].adjustPriceTotal;
                    }
                    this.tbyj = (this.tzhyj - this.ysyj).toFixed(2)
                }
                this.dialogVisible = true
            })
        },
        deleterow() {

        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
            console.log(`每页 ${val} 条`);
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.searchForm.sfgl = '0'
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                startTimeStart: this.searchForm.dateResult[0] ? this.searchForm.dateResult[0] : '',
                startTimeOver: this.searchForm.dateResult[1] ? this.searchForm.dateResult[1] : '',
                sfgl: this.searchForm.sfgl,
                dATAB: this.searchForm.faretime,
                companyId: this.searchForm.carrierList_new
            }
            this.tableLoading = true
            this.$Api.settle_getshipplanparent(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = Number(res.data.total);
                    this.tableData = res.data.list
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.ChangeHarborDivideUnload {
    height: initial;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        /deep/.el-table {
            height: 800px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .addoreidt {
            width: 100%;
            height: 50vh;
            overflow-x: hidden;
            overflow-y: scroll;

            /deep/.el-table {
                height: auto;
            }

            .tip {
                margin: 12px 0;
                font-size: 14px;
            }

            .priceTip {
                font-size: 21px;

                span {
                    margin-right: 12px;
                }
            }
        }

    }
}</style>