<template>
  <div class="page userContacts">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="手机号：">
          <el-input v-model="searchForm.phone" placeholder="请输入手机号" size="small"></el-input>
        </el-form-item>
        <el-form-item label="单位：">
          <el-cascader v-model="searchForm.groupId" size="small"
            :props="{ value: 'id', label: 'subtitle', children: '_child' }" placeholder="选择或搜索选择单位" :options="SSList"
            filterable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <!--列表-->
      <el-table v-if="ready" ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="姓名" prop="userName">
        </el-table-column>
        <el-table-column align="center" label="单位" prop="groupIdName">
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phoneNum">
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="30vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="姓名：" prop="title">
            <el-input v-model="createdData.title" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phoneNum">
            <el-input v-model="createdData.phoneNum" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "UserContacts",
  data() {
    return {
      ready: true,
      isAdd: false,
      multipleSelection: [],
      searchForm: {
        phone: '',
        groupId: [],
      },
      createdData: {
        id: '',
        phoneNum: '',
        title: ''
      },
      rules: {
        title: {
          required: true, message: '请输入姓名', trigger: 'blur'
        },
        phoneNum: {
          required: true, message: '请输入手机号', trigger: 'blur'
        },
      },
      tableLoading: false,
      dialogVisible: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      SSList: []
    }
  },
  activated() {
    this.ready = false
    this.$nextTick(() => {
      this.ready = true
      this.$refs.multipleTable.doLayout()
    })
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getSS()
  },
  methods: {
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            phoneNum: this.createdData.phoneNum,
            userName: this.createdData.title,
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.member_editUserPhoneGroup(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        phoneNum: '',
        title: ''
      }
      this.$refs['form'].resetFields();
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.isAdd = false
      this.createdData = {
        id: row.id,
        title: row.userName,
        phoneNum: row.phoneNum
      }
      this.dialogVisible = true;
    },
    deleterow() {
      this.$confirm('确定删除该联系人吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.member_deleteUserPhoneGroup(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // --------------------------公共方法--------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getSS() {
      let data = {
        states: 1,
        show_type: 2,
        page: 1,
        limit: 999
      }
      this.$Api.getDict(data).then(res => {
        this.SSList = res.data.data;
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        phone: this.searchForm.phone,
        groupId: this.searchForm.groupId[this.searchForm.groupId.length - 1],
      }
      this.tableLoading = true
      this.$Api.member_getUserContactList(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        if (res.data.data.length != 0) {
          res.data.data.map(item => {
            this.SSList.map(key => {
              if (item.groupId == key.id) {
                item.groupIdName = key.subtitle
              }
            })
          })
        }
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.userContacts {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>