<template>
    <div class="page">
        <el-tabs type="border-card">
            <el-tab-pane :label="item.type" v-for="(item, index) in tableData" :key="index">
                <div>
                    <el-form ref="form"  label-position="left">
                        <el-form-item :label="i.title" v-for="(i, v) in item.list" :key="i.id" @click="had(i.id)">
                            <template v-if="i.form_type === 'text'">
                                <el-input v-model="i.value"></el-input>
                            </template>
                            <template v-if="i.form_type === 'textarea'">
                                <el-input type="textarea" rows="3" v-model="i.value"></el-input>
                            </template>
                            <template v-if="i.form_type === 'image'">
                                <el-upload class="avatar-uploader" :action="baseURL" :show-file-list="false"
                                    :on-success="i['handlePreview' + index + v]">
                                    <img v-if="i.value" :src="i.value" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </template>
                            <template v-if="i.form_type === 'select'">
                                <el-select v-model="i.value" placeholder="请选择">
                                    <el-option v-for="t in i.selectList" :key="t.value" :label="t.value" :value="t.label">
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-if="i.form_type === 'radio'">
                                <el-radio-group v-model="i.value">
                                    <el-radio :label="t.label" v-for="t in i.radioList" :key="t.value">{{ t.value
                                    }}</el-radio>
                                </el-radio-group>
                            </template>
                            <template v-if="i.form_type === 'checkbox'">
                                <el-checkbox-group v-model="i.valueList">
                                    <el-checkbox :label="t.label" v-for="t in i.checkboxList" :key="t.value">{{ t.value
                                    }}</el-checkbox>
                                </el-checkbox-group>
                            </template>
                            <template v-if="i.form_type === 'editor'">
                                <ckeditor :editor="editor" v-model="i.value" :config="editorConfig"></ckeditor>
                            </template>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit(item.list)">立即保存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Configuration",
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            loading: false,
            tableData: [],
            tabList: [],
            baseURL: ''
        }
    },
    created() {
        this.getData()
        this.baseURL = this.$http.defaults.baseURL + '/backend/upload'
    },
    methods: {
        had(e) {
            console.log(e);
        },
        beforeAvatarUpload(i) {
            console.log(i);
        },
        hendClick(i) {
            console.log(i);
        },
        onSubmit(list) {
            let arr = list.map(item => {
                if (item.form_type === 'checkbox') {
                    item.value = item.valueList.join(',')
                }
                return {
                    id: item.id,
                    value: item.value
                }
            })
            this.$http.post('/backend/config/set', {
                data: JSON.stringify(arr)
            }).then(res => {
                if (res.code === 1) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getData()
                }
            })
        },
        getData() {
            this.loading = true
            this.$http.get('/backend/config/all_list').then(res => {
                if (res.code === 1) {
                    let arr = res.data.list.map(item => {
                        return item.type
                    })
                    this.tableData = [...new Set(arr)].map((item, index) => {
                        return {
                            type: item,
                            list: res.data.list.filter((i, v) => {
                                switch (i.form_type) {
                                    case 'image':
                                        // console.log(v,index, 'i');
                                        // i['handlePreview' + v + index] = (res, file) => {
                                        //     console.log(1111);
                                        //     // 写回显
                                        //     if (res.code == 1) {
                                        //         console.log(res, 'file');
                                        //         this.$nextTick(() => {
                                        //             i.value = res.data.full_path
                                        //         })
                                        //     }
                                        // }
                                        break;
                                    case 'radio':
                                        i.radioList = []
                                        i.options.split(/[\n,]/g).forEach(item => {
                                            i.radioList.push({
                                                label: item.split(':')[0],
                                                value: item.split(':')[1]
                                            })
                                        })
                                        break;
                                    case 'select':
                                        i.selectList = []
                                        i.options.split(/[\n,]/g).forEach(item => {
                                            i.selectList.push({
                                                label: item.split(':')[0],
                                                value: item.split(':')[1]
                                            })
                                        })
                                        break;
                                    case 'checkbox':
                                        i.checkboxList = []
                                        i.valueList = i.value?.split(',') || []
                                        i.options.split(/[\n,]/g).forEach(item => {
                                            i.checkboxList.push({
                                                label: item.split(':')[0],
                                                value: item.split(':')[1]
                                            })
                                        })
                                        break;
                                }
                                return i.type === item
                            })
                        }
                    })
                    this.tableData.map((item,index)=>{
                        item.list.forEach((i,e)=>{
                            if(i.form_type === 'image'){
                                i['handlePreview' + index + e] = (res, file) => {
                                    // 写回显
                                    if (res.code == 1) {
                                        console.log(res, 'file');
                                        this.$nextTick(() => {
                                            i.value = res.data.full_path
                                        })
                                    }
                                }
                            }
                        })
                    })
                    console.log(this.tableData, 'this.tableData');
                }
                this.loading = false
            })
        },
        handleAvatarSuccess(e, ee) {
            console.log(e, ee);

        },
        onReady(editor) {
            console.log('CKEditor5 Vue Component is ready to use!', editor);
            CKEditorInspector.attach(editor);
        },
        onChange(data) {
            console.log(data);
        },
    }
}
</script>

<style lang="scss" scoped>
/deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
/deep/.el-form-item__label-wrap{
    margin-right: 20px;
}
/deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

/deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit:contain;
}

.page {
    padding: 24px 32px;
}

.el-table {
    margin-top: 24px;

    .o-btn {
        color: #409EFF;
        cursor: pointer;

        ~.o-btn {
            margin-left: 12px;
        }
    }
}

.create-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
</style>
