<template>
  <div class="page DailyStandardMaintenance">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
            @click="searchTable('search')">下载导入模板
          </el-button>
          <!-- <el-button class="u-custom-btn-primary" icon="el-icon-upload2" size="small" type="primary"
            @click="searchTable('reset')">导入认证检查标准
          </el-button> -->
        </el-form-item>
        <el-form-item>
          <el-upload class="upload-demo" :action="'/api/security/safetyitemUpload'" :data="{ type: 1 }"
            :before-upload="beforeUpload" :on-success="onsuccess" :show-file-list="false" :headers="headers">
            <el-button icon="el-icon-upload2" size="small" type="primary" v-loading="fileLoading">导入认证检查标准</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="success"
            @click="addRole">新增认证检查标准</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox label="序号"></el-checkbox>
                  <el-checkbox label="项目"></el-checkbox>
                  <el-checkbox label="类别"></el-checkbox>
                  <el-checkbox label="检查内容"></el-checkbox>
                  <el-checkbox label="检查要点"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table id="tabbox" v-if="checkloding" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
        tooltip-effect="dark" height="(100% - 104px)" row-key="id" default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" label="序号" width="60" prop="sort" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="项目" prop="itemName" v-if="checkList.indexOf('项目') != -1">
        </el-table-column>
        <el-table-column align="center" label="类别" width="200" prop="check_type_text"
          v-if="checkList.indexOf('类别') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查内容" prop="checkContent" v-if="checkList.indexOf('检查内容') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查要点" prop="checkPoint" v-if="checkList.indexOf('检查要点') != -1">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
    <!-- 添加船舶 -->
    <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑 - ' + '认证检查标准'" :visible.sync="dialogVisible"
      destroy-on-close top="20vh" width="450px">
      <div class="addoreidt">
        <el-form ref="form" :model="createData" :rules="rules" label-width="100px">
          <el-form-item label="序号：" prop="sort">
            <el-input v-model="createData.sort" clearable placeholder="请输入序号" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="项目：" prop="itemName">
            <el-input v-model="createData.itemName" clearable placeholder="请输入项目" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="类别：" prop="checkType">
            <el-select v-model="createData.checkType" placeholder="请选择类型" style="width: 220px;">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="检查内容：" prop="checkContent">
            <el-input v-model="createData.checkContent" clearable placeholder="请输入检查内容" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="检查要点：" prop="checkPoint">
            <el-input v-model="createData.checkPoint" clearable placeholder="请输入检查要点" style="width: 220px;"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subRefuShip">确 定</el-button>
      </span>
    </el-dialog>
    <div id="detailInfo" v-show="false">
      <table id="sumTableId" class="tg">
        <thead>
          <tr>
            <th class="tg-0lax">项目</th>
            <th class="tg-0lax">类别</th>
            <th class="tg-0lax">检查内容</th>
            <th class="tg-0lax">检查要点</th>
          </tr>
        </thead>
        <tr v-for="(item, index) in tableData">
          <th class="tg-0lax">{{ item.itemName }}</th>
          <th class="tg-0lax">{{ item.check_type_text }}</th>
          <th class="tg-0lax">{{ item.checkContent }}</th>
          <th class="tg-0lax">{{ item.checkPoint }}</th>
        </tr>
      </table>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "DailyStandardMaintenance",
  data() {
    return {
      headers: {},
      checkList: ['序号', '项目', '类别', '检查内容', '检查要点'],
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      typeList: [
        // {
        //   name: '日常检查',
        //   id: 1,
        // },
        {
          name: '认证检查',
          id: 0,
        },
      ],
      tableLoading: false,
      fileLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      dialogVisible: false,
      checkloding: true,
      isAdd: false,
      createData: {
        itemName: '',
        checkType: '',
        checkContent: '',
        checkPoint: '',
        sort: '',
      },
      rules: {
        itemName: {
          required: true, message: '请输入项目名称', trigger: 'blur'
        },
      },
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.headers['api-token'] = localStorage.getItem('token')
    this.getData()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let config = [
            { field: 'itemName', width: 80, align: 'center', title: '项目' },
            { field: 'check_type_text', width: 80, align: 'center', title: '类别' },
            { field: 'checkContent', width: 80, align: 'center', title: '检查内容' },
            { field: 'checkPoint', width: 150, align: 'center', title: '检查要点' },
          ]
          let obj = {
            name: '认证检查标准维护',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(this.tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    onsuccess(response, file, fileList) {
      if (response.code == 200) {
        console.log(response, file);
      } else {
        this.$message({
          type: 'warning',
          message: response.message
        });
      }
      this.fileLoading = false
    },
    beforeUpload(file) {
      this.fileLoading = true
      if ((file.name.split('.'))[1] == 'xls' || (file.name.split('.'))[1] == 'xlsx') {

      } else {
        this.$message({
          type: 'info',
          message: '请上传excel表格'
        });
        this.fileLoading = false
        return false
      }
    },
    subRefuShip() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = this.createData
          if (this.isAdd) {
            this.$Api.SCM_addshipCheckRule(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          } else {
            this.$Api.SCM_updateshipCheckRule(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    addRole() {
      console.log('添加');
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createData = {
        itemName: '',
        checkType: '',
        checkContent: '',
        checkPoint: '',
        sort: '',
      }
      this.$refs['form'].resetFields();
    },
    dropdownChange(e) {
      this.checkloding = false
      this.$nextTick(() => {
        this.checkloding = true
      })
    },
    editerow(row) {
      console.log(row, 'editerow');
      this.createData = {
        id: row.id,
        itemName: row.itemName,
        checkType: row.checkType,
        checkContent: row.checkContent,
        checkPoint: row.checkPoint,
        sort: row.sort,
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.SCM_deleteshipCheckRule(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      console.log(`每页 ${val} 条`);
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        checkType: 0,
        page: this.page.currentPage,
        pagesize: this.page.pageSize,
      }
      this.tableLoading = true
      this.$Api.SCM_getshipCheckRule(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.DailyStandardMaintenance {
  .searchBox::before {
    content: '操作面板';
    display: block;
    background-color: #FFF;
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #6b6b6b;
    left: 30px;
    z-index: 2;
  }

  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>