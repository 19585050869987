import axios from "axios"
import { Message } from 'element-ui'
import store from "@/store"
import VueRouter from "vue-router"
import router from '@/router'
import baseUrl from "../api/config"
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : baseUrl
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
console.log(axios.defaults.headers);
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (config.noHead){
        delete config.headers['api-token']
        return config
    } 
    token ? config.headers['api-token'] = token : ''
    return config
}, function (error) {
    Message.error('网络异常')
    return Promise.reject(error)
})
axios.interceptors.response.use(function (response) {
    let data = response.data
    let code = Number(data.code) || Number(data.status)
    data.code = code
    if(code === 200 || code === 0){
        return data
    }else if(code == 401){
		Message.closeAll()
        Message.error({
            message:data.message,
            duration:4000
        })
        store.commit('logout')
        setTimeout(()=>{
			router.push('/login')
        },1000)
        return Promise.reject('error')
    }else if(code == 555){
		Message.closeAll()
        Message.error(data.message)
        store.commit('logout')
        setTimeout(()=>{
			console.log('maintain')
			router.push('/maintain')
        },1000)
        return Promise.reject('error')
    }else{
        Message.error(data.message)
        return data
    }
}, function (error) {
    Message.error('网络异常')
    return Promise.reject(error)
})
export default axios