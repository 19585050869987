<template>
    <div class="page">
        <el-form :model="searchForm" class="search-form" inline>
            <el-form-item label="标题：">
                <el-input v-model="searchForm.title" placeholder="请输入配置名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
                <el-select clearable v-model="searchForm.is_hid" placeholder="配置状态" size="small">
                    <el-option :value="0" label="启用"></el-option>
                    <el-option :value="1" label="禁用"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                    @click="searchTable('search')">搜索</el-button>
                <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                    @click="searchTable('reset')">重置
                </el-button>
            </el-form-item>
        </el-form>
        <div class="operate-box u-flex u-col-center" style="justify-content: space-between;">
            <div>
                <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary" @click="create">新增
                </el-button>
                <!-- <el-button icon="el-icon-sort" plain size="small" type="info" @click="expandTable">展开/折叠</el-button> -->
            </div>
            <div>
                <el-switch style="display: block; float: right;" v-model="site_open" active-color="#13ce66"
                    inactive-color="#ff4949" active-text="系统开" inactive-text="系统关" @change="setSite">
                </el-switch>
            </div>
        </div>
        <el-table v-if="ready" v-loading="loading" :data="tableData" :default-expand-all="is_expand"
            :tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
            header-row-class-name="header-table">
            <el-table-column label="配置标题" prop="title">
            </el-table-column>
            <el-table-column label="配置标识" prop="name">
            </el-table-column>
            <!-- <el-table-column label="数据值" prop="value">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top" width="500">
                        <p style="overflow-y:auto;min-height:20px;max-height:800px;">{{ scope.row.value }}</p>
                        <div slot="reference" class="name-wrapper">
                            <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ scope.row.value }}
                            </div>
                        </div>
                    </el-popover>
                </template>
            </el-table-column> -->
            <el-table-column label="类型" prop="type">
            </el-table-column>
            <el-table-column label="表单类型" prop="form_type">
            </el-table-column>
            <el-table-column label="状态" prop="is_hid" width="80">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_hid === 0 ? true : false" :disabled="scope.row.loading"
                        active-color="#13ce66" inactive-color="#ff4949" @change="statusChange(scope.row, scope.$index)">
                    </el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column label="类型" width="70" align="center">
				<template slot-scope="scope">
					<el-tag size="small" plain type="primary" v-if="scope.row.type === 'M'">目录</el-tag>
					<el-tag size="small" plain type="success" v-if="scope.row.type === 'C'">配置</el-tag>
					<el-tag size="small" plain type="danger" v-if="scope.row.type === 'F'">按钮</el-tag>
				</template>
			</el-table-column> -->
            <!--      <el-table-column-->
            <!--          label="创建时间"-->
            <!--          show-overflow-tooltip-->
            <!--          prop="add_time">-->
            <!--      </el-table-column>-->
            <el-table-column label="操作" width="210" fixed="right">
                <template slot-scope="scope">
                    <span class="o-btn" @click="edit(scope.row)">
                        <el-icon class="el-icon-edit"></el-icon>
                        编辑
                    </span>
                    <!-- <span class="o-btn o-btn-add" @click="add(scope.row)">
                        <el-icon class="el-icon-plus"></el-icon>
                        新增
                    </span> -->
                    <span class="o-btn o-btn-red" @click="remove(scope.row.id)">
                        <el-icon class="el-icon-delete"></el-icon>
                        删除
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <div class="u-flex u-row-end u-p-t-32 u-p-b-24">
            <el-pagination :current-page="page.currentPage" :page-size="page.pageSize" :total="page.total"
                layout="total, prev, pager, next, jumper" @current-change="changePage">
            </el-pagination>
        </div>
        <el-dialog destroy-on-close :close-on-click-modal="false" :visible.sync="dialogVisible"
            :title="mode === 'add' ? '添加配置' : '编辑配置'" width="600px" @closed="resetCreateData">
            <div>
                <el-form ref="createForm" :model="createData" :rules="createRules" label-width="80px">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="createData.title" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="标识" prop="name">
                        <el-input v-model="createData.name" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="类型" prop="type">
                        <el-input v-model="createData.type" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="选项" prop="options">
                        <el-input v-model="createData.options" size="small" type="textarea" rows="5"></el-input>
                    </el-form-item>
                    <el-form-item label="表单类型" prop="form_type">
                        <el-select v-model="createData.form_type" placeholder="请选择表单类型">
                            <el-option :label="item.title" :value="item.id" v-for="item in configTypes"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item :label="configTypes.find(item => item.id === createData.type).title + '名称'"
                                prop="name">
                                <el-input v-model="createData.name" size="small"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="显示排序">
                                <el-input-number v-model="createData.listsort" :min="0" :precision="0"
                                    controls-position="right" size="small" style="width: 100%;"></el-input-number>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                    <!-- <el-row>
                        <el-col :span="12" v-if="createData.type !== 'F'">
                            <el-form-item label="路由地址" prop="url">
                                <el-input v-model="createData.url" size="small"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="配置状态">
                                <el-radio-group v-model="createData.is_hid">
                                    <el-radio v-for="item in configRoots" :label="item.id">{{ item.title }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="u-custom-btn-primary" type="primary" @click="createConfig">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "Config",
    data() {
        return {
            site_open: true,
            ready: true,
            is_expand: false,
            mode: 'add',
            loading: false,
            dialogVisible: false,
            id: '',
            createData: {
                title: '',
                name: '',
                options: '',
                type: '',
                form_type: '',
            },
            page: {
                currentPage: 1,
                pageSize: 20,
                total: 1
            },
            createRules: {
                name: {
                    required: true,
                    message: '标识不能为空',
                    trigger: ['blur', 'change']
                },
                title: {
                    required: true,
                    message: '标题不能为空',
                    trigger: ['blur', 'change']
                },
                type: {
                    required: true,
                    message: '类型不能为空',
                    trigger: ['blur', 'change']
                },
                region: { required: true, message: '请选择活动区域', trigger: 'change' },
            },
            configRoots: [{
                title: '正常',
                id: 0
            }, {
                title: '禁用',
                id: 1
            }],
            configTypes: [
                {
                    title: '输入框',
                    id: 'text'
                }, {
                    title: '单选框',
                    id: 'radio'
                }, {
                    title: '多选框',
                    id: 'checkbox'
                }, {
                    title: '下拉框',
                    id: 'select'
                }, {
                    title: '编辑器',
                    id: 'editor'
                },
                {
                    title: '文本域',
                    id: 'textarea'
                },
                {
                    title: '图片',
                    id: 'image'
                }
            ],
            searchForm: {
                title: '',
                is_hid: '',
            },
            tableData: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        changePage(val) {
            this.page.currentPage = val;
            this.getData()
            // console.log(`当前页: ${val}`);
        },
        setSite() {
            let obj = {
                value: this.site_open ? 0 : 1
            }
            this.$http.post('/backend/config/site', obj).then(res => {
                if (res.code === 1) {
                    console.log(res)
                    this.$message.success('设置成功')
                }
            })
        },
        statusChange(e, index) {
            let obj = {
                id: e.id,
                is_hid: e.is_hid === 0 ? 1 : 0
            }
            this.tableData[index].loading = true
            this.$http.post('/backend/config/status', obj).then(res => {
                if (res.code == 1) {
                    this.tableData[index].is_hid = obj.is_hid
                }
                this.tableData[index].loading = false
            })
        },
        searchTable(t) {
            if (t === 'search') {
                this.getData()
            } else {
                this.searchForm = {
                    title: '',
                    is_hid: '',
                }
                this.getData()
            }
        },
        expandTable() {
            this.ready = false
            this.is_expand = !this.is_expand
            this.$nextTick(() => {
                this.ready = true
            })
        },
        edit(e) {
            this.id = e.id
            this.mode = 'edit'
            for (let k in e) {
                if (this.createData.hasOwnProperty(k)) {
                    this.createData[k] = e[k]
                }
            }
            this.dialogVisible = true
        },
        add(e) {
            this.mode = 'add'
            this.createData.parent_id = e.id
            this.dialogVisible = true
        },
        create() {
            this.mode = 'add'
            this.dialogVisible = true
        },
        remove(id) {
            this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id
                }
                this.$http.post('/backend/config/delete', data).then(res => {
                    if (res.code === 1) {
                        this.$message.success('删除成功')
                        this.getData()
                    }
                })
            })

        },
        resetCreateData() {
            this.createData = {
                title: '',
                name: '',
                options: '',
                type: '',
                form_type: '',
            }
            this.$refs['createForm'].resetFields()
        },
        createConfig() {
            if (this.mode === 'add') {
                this.$refs['createForm'].validate(valid => {
                    if (valid) {
                        this.$http.post('/backend/config/create', this.createData).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    }
                })
            } else {
                this.$refs['createForm'].validate(valid => {
                    if (valid) {
                        this.createData.id = this.id
                        this.$http.post('/backend/config/update', this.createData).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    }
                })
            }

        },
        getData() {
            this.loading = true
            let obj = {
                page: this.page.currentPage,
                title: this.searchForm.title,
                is_hid: this.searchForm.is_hid,
            }
            this.$http.get('/backend/config/list', {params:obj}).then(res => {
                if (res.code === 1) {
                    if (res.data.list.total) this.page.total = res.data.list.total;
                    this.tableData = res.data.list.data
                }
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 24px 32px;
}

.el-table {
    margin-top: 24px;

    .o-btn {
        color: #409EFF;
        cursor: pointer;

        ~.o-btn {
            margin-left: 12px;
        }
    }
}

.create-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
</style>
