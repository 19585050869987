<template>
    <div class="page monthlyPlan_AddFinish">
        <el-page-header @back="goBack" content="新增完成数">
        </el-page-header>
        <div class="container">
            <div class="searchBox">
                <el-form :model="searchForm" class="search-form" inline>
                    <el-form-item label="类型：">
                        <el-select clearable v-model="searchForm.planType" placeholder="请选择类型" size="small">
                            <el-option value="0" label="其他计划"></el-option>
                            <el-option value="1" label="交货单"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择月份：">
                        <el-date-picker v-model="searchForm.monthly" clearable value-format="yyyy-MM" type="month"
                            size="small" placeholder="请选择月份"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                            @click="searchTable('search')">搜索
                        </el-button>
                        <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
                            type="primary" @click="searchTable('reset')">重置
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                header-row-class-name="header-table">
                <el-table-column align="center" label="标题" prop="title">
                </el-table-column>
                <el-table-column align="center" label="承运公司" prop="carrier">
                </el-table-column>
                <el-table-column align="center" label="船舶" prop="ship">
                </el-table-column>
                <el-table-column align="center" label="炼厂" prop="start_port_name">
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPort">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="oilTypeId">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="num">
                </el-table-column>
                <el-table-column align="center" label="状态">
                    <template slot-scope="scope">
                        <div v-html="scope.row.CHECKED_STATUS"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="addfinish(scope.row)">添加完成数</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "monthlyPlan_AddFinish",
    data() {
        return {
            searchForm: {
                planType: "0",
                monthly: ''
            },
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            oilTypes: [],
            member: [],
            typeList: []
        }
    },
    created() {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        this.searchForm.monthly = '' + year + '-' + month
        this.rowData = this.$route.params
        this.getData()
    },
    activated() {
        this.rowData = this.$route.params
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        addfinish(row) {
            this.$confirm('确定要同步此条数据到月度计划吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    planType: row.plan_type,
                    otherId: row.id,
                    monthId: this.rowData.id
                }
                this.$Api.plan_monthly_AddOtherPlanToMonthPlan(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.searchForm.planType = '0'
                this.getData()
            }
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/planDispatch/monthlyPlan_AddFinish')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                planType: this.searchForm.planType,
                monthly: this.searchForm.monthly
            }
            this.tableLoading = true
            this.$Api.plan_monthly_ShipPlanOther(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total
                    this.tableData = res.data.data
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.monthlyPlan_AddFinish {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    height: initial;

    .container {
        width: 100%;
        // border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .title {
            margin: 0px 0 12px;
            padding-left: 15px;
            font-size: 16px;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
</style>