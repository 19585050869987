<template>
    <div class="page LogManage">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="事件：">
                    <el-input v-model="searchForm.title" placeholder="请输入事件" size="small"></el-input>
                </el-form-item>
                <el-form-item label="时间范围：">
                    <el-date-picker v-model="searchForm.dateResult" type="daterange" size="small" range-separator="-"
                    value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="操作类型：">
                    <el-select clearable v-model="searchForm.handleType" placeholder="请选择操作类型：" size="small">
                        <el-option v-for="(item,index) in handle_typeList" :key="index" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="业务类型：">
                    <el-select clearable v-model="searchForm.dataType" placeholder="请选择业务类型：" size="small">
                        <el-option v-for="(item,index) in data_typeList" :key="index" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                        @click="searchTable('reset')">导出
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="业务类型" prop="data_type_text">
                </el-table-column>
                <el-table-column align="center" label="操作" prop="title">
                </el-table-column>
                <el-table-column align="center" label="操作用户" prop="account">
                </el-table-column>
                <el-table-column align="center" label="操作类型" prop="handle_type_text">
                </el-table-column>
                <el-table-column align="center" label="IP地址" prop="ipaddress">
                </el-table-column>
                <el-table-column align="center" label="操作时间" prop="createtime">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="account">
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.account==''">操作失败</el-tag>
                        <el-tag type="success" v-else>操作成功</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LogManage",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                title: '',
                dataType:'',
                handleType:'',
                dateResult: '',
                startTime: '',
                endtime: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            data_typeList:[],
            handle_typeList: [],
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        that.getData()
        that.gethandle_type()
        that.getdata_type()
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    methods: {
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize
            }
            this.tableLoading = true
            this.$Api.getLogData(data).then(res => {
                this.tableLoading = false
                if (res.data.total) this.page.total = res.data.total;
                this.tableData = res.data.data
            })
        },
        gethandle_type(){
            this.$Api.getLogData_handle_type().then(res => {
                this.handle_typeList = res.data
            })
        },
        getdata_type(){
            this.$Api.getLogData_data_type().then(res => {
                this.data_typeList = res.data
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.LogManage {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }


    }
}
</style>