import { render, staticRenderFns } from "./assessmentTasks.vue?vue&type=template&id=973a271e&scoped=true&"
import script from "./assessmentTasks.vue?vue&type=script&lang=js&"
export * from "./assessmentTasks.vue?vue&type=script&lang=js&"
import style0 from "./assessmentTasks.vue?vue&type=style&index=0&id=973a271e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "973a271e",
  null
  
)

export default component.exports