<template>
	<div class="page">
		<el-form :model="searchForm" class="search-form" inline>
			<el-form-item label="角色名称：">
				<el-input v-model="searchForm.name" placeholder="请输入角色名称" size="small"></el-input>
			</el-form-item>
			<el-form-item label="状态：">
				<el-select clearable v-model="searchForm.is_hid" placeholder="角色状态" size="small">
					<el-option :value="0" label="启用"></el-option>
					<el-option :value="1" label="禁用"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
					@click="searchTable('search')">搜索
				</el-button>
				<el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
					@click="searchTable('reset')">重置
				</el-button>
			</el-form-item>
		</el-form>
		<div class="operate-box u-flex u-col-center">
			<el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary" @click="create">新增
			</el-button>
			<el-button icon="el-icon-sort" plain size="small" type="info" @click="expandTable">展开/折叠</el-button>
		</div>
		<el-table v-if="ready_table" v-loading="loading" :data="tableData" :default-expand-all="is_expand_table"
			:tree-props="{children: 'child', hasChildren: 'hasChildren'}" row-key="id" style="width: 100%;" header-row-class-name="header-table">
			<el-table-column label="角色编号" prop="id">
			</el-table-column>
			<el-table-column label="角色名称" prop="name">
			</el-table-column>
			<el-table-column label="权限字符" prop="rolekey">
			</el-table-column>
			<el-table-column label="显示顺序" prop="listsort" width="80" align="center">
			</el-table-column>
			<el-table-column label="状态" width="80" align="center">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.is_hid === 0" size="small">正常</el-tag>
					<el-tag v-else size="small" type="info">禁用</el-tag>
				</template>
				<!--          <el-switch v-model="scope.row.check" @change="e => switchStatus(e,scope.row.id)"></el-switch>-->
			</el-table-column>
			<el-table-column label="创建时间" prop="add_time" width="180">
			</el-table-column>
			<el-table-column label="备注" prop="role" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="操作" width="210" fixed="right">
				<template slot-scope="scope">
					<template v-if="scope.row.parent_id !== 0">
						<span class="o-btn" @click="edit(scope.row)" v-if="userid==scope.row.create_user_id">
							<el-icon class="el-icon-edit"></el-icon>
							编辑
						</span>
						<span class="o-btn o-btn-red" @click="remove(scope.row.id)" v-if="userid==scope.row.create_user_id">
							<el-icon class="el-icon-delete"></el-icon>
							删除
						</span>
						<span class="o-btn" @click="editRoot(scope.row)">
							<el-icon class="el-icon-menu"></el-icon>
							权限
						</span>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :close-on-click-modal="false" :title="mode === 'add' ? '添加角色' : '编辑角色'" :visible.sync="dialogVisible"
			destroy-on-close width="600px" @closed="resetCreateData">
			<div>
				<el-form ref="createForm" :model="createData" :rules="createRules" class="create-form"
					label-width="80px">
					<el-form-item label="角色名称" prop="name">
						<el-input v-model="createData.name" size="small"></el-input>
					</el-form-item>
					<el-form-item label="权限字符" prop="rolekey">
						<el-input v-model="createData.rolekey" size="small"></el-input>
					</el-form-item>
					<el-form-item label="上级" prop="parent_id">
						<tree-select v-model="createData.parent_id" :list="roleList"></tree-select>
					</el-form-item>
					<el-row>
						<el-col :span="12">
							<el-form-item label="显示排序">
								<el-input-number v-model="createData.listsort" :min="0" :precision="0"
									controls-position="right" size="small"></el-input-number>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="角色状态">
								<el-radio-group v-model="createData.is_hid">
									<el-radio v-for="item in menuRoots" :label="item.id">{{ item.title }}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="备注">
						<el-input v-model="createData.note" placeholder="请输入备注" type="textarea"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="u-custom-btn-primary" type="primary" @click="createRole">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" :visible.sync="showRoot" destroy-on-close title="权限配置" width="600px"
			@closed="rootClose">
			<div>
				<el-form ref="createForm" class="create-form" label-width="80px" @submit.native.prevent>
					<el-form-item label="数据权限" required>
						<el-select v-model="data_scope" size="small">
							<el-option v-for="item in data_list" :label="item.title" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="菜单权限">
						<el-row>
							<el-col :span="8">
								<el-checkbox v-model="is_expand">展开/折叠</el-checkbox>
							</el-col>
							<el-col :span="8">
								<el-checkbox v-model="is_all_check">全选/全不选</el-checkbox>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<el-tree v-if="ready" ref="treeRef" :data="treeList" :default-checked-keys="menu_ids"
									:default-expand-all="is_expand" :props="defaultProps" check-strictly
									class="tree-border" node-key="id" show-checkbox @check="selectTree">
								</el-tree>
							</el-col>
						</el-row>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editRootConfirm">确 定</el-button>
				<el-button @click="showRoot = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "Role",
		data() {
			return {
				showRoot: false,
				is_expand_table: true,
				ready_table: true,
				ready: true,
				is_expand: false,
				mode: 'add',
				loading: false,
				dialogVisible: false,
				id: '',
				createData: {
					name: '',
					rolekey: '',
					listsort: 0,
					note: '',
					is_hid: 0,
					parent_id: ''
				},
				createRules: {
					name: {
						required: true,
						message: '请输入角色名称',
						trigger: ['blur', 'change']
					},
					rolekey: {
						required: true,
						message: '请输入权限字符',
						trigger: ['blur', 'change']
					},
					parent_id: {
						required: true,
						message: '请选择上级',
						trigger: 'change'
					}
				},
				menuRoots: [{
					title: '正常',
					id: 0
				}, {
					title: '禁用',
					id: 1
				}],
				searchForm: {
					name: '',
					is_hid: '',
					is_tree: 1
				},
				tableData: [],
				treeList: [],
				roleList: [],
				defaultProps: {
					children: 'child',
					label: 'name',
					value: 'id'
				},
				menu_ids: [],
				allArr: [],
				data_list: [],
				data_scope: '',
				userid:''
			}
		},
		watch: {
			is_expand(val) {
				this.ready = false
				this.$nextTick(() => {
					this.ready = true
				})
			}
		},
		computed: {
			is_all_check: {
				set(val) {
					this.ready = false
					if (val) {
						this.menu_ids = []
						this.$nextTick(() => {
							this.setMenuIds(this.treeList)
						})
					} else {
						this.menu_ids = []
					}
					this.$nextTick(() => {
						this.ready = true
					})
				},
				get() {
					return this.allArr.every(id => this.menu_ids.indexOf(id) !== -1)
				}
			}
		},
		created() {
			this.getData()
			this.getTree()
			this.getRoleList()
			let vuex = localStorage.getItem('vuex')
        	this.userid = JSON.parse(vuex).userInfo.id
		},
		methods: {
			expandTable() {
				this.ready_table = false
				this.is_expand_table = !this.is_expand_table
				this.$nextTick(() => {
					this.ready_table = true
				})
			},
			rootClose() {
				this.ready = false
				this.menu_ids = []
				this.$nextTick(() => {
					this.ready = true
				})
			},
			setArr(list) {
				list.map(item => {
					this.allArr.push(item.id)
					if (item.child) {
						this.setArr(item.child)
					}
				})
			},
			setMenuIds(arr) {
				arr.map(item => {
					this.menu_ids.push(item.id)
					if (item.child) {
						this.setMenuIds(item.child)
					}
				})
			},
			editRootConfirm() {
				if (!this.data_scope) return this.$message.warning('请选择数据权限')
				this.menu_ids = this.$refs.treeRef.getCheckedKeys(false)
				let data = {
					id: this.id,
					menu_ids: this.menu_ids.join(','),
					data_scope: this.data_scope
				}
				this.$http.post('/backend/role/auth', data).then(res => {
					if (res.code === 1) {
						this.showRoot = false
						this.getData()
					}
					this.data_scope = ''
				})
			},
			editRoot(e) {
				this.id = e.id
				let arr = []
				e['menu'].map(item => {
					arr.push(item.id)
				})
				this.menu_ids = arr
				this.data_scope = e.data_scope
				this.showRoot = true
			},
			create() {
				this.mode = 'add'
				this.dialogVisible = true
			},
			selectTree() {
				this.menu_ids = this.$refs.treeRef.getCheckedNodes(false, true)
			},
			getTree() {
				this.treeList = this.$store.state.userInfo.tree_role_menu
				this.setArr(this.treeList)
				// let data = {
				//   name: '',
				//   is_hid: 0,
				//   is_tree: 1
				// }
				// this.$http.get('/backend/menu/list', {params: data}).then(res => {
				//   if (res.code === 1) {
				//     this.treeList = res.data.list
				//     this.setArr(this.treeList)
				//   }
				//   this.loading = false
				// })
			},
			switchStatus(e, id) {
				let data = {
					id,
					is_hid: Number(e)
				}
				this.$http.post('/backend/role/status', data).then(res => {
					if (res.code === 1) {
						let obj = this.tableData.find(item => item.id === id)
					}
				})
				// console.log(id)
				// let data = this.tableData.find(item => item.id === id)
				// data.is_hid = e ? 1 : 0
				// this.$forceUpdate()
			},
			searchTable(t) {
				if (t === 'search') {
					this.getData()
				} else {
					this.searchForm = {
						name: '',
						is_hid: ''
					}
					this.getData()
				}
			},
			edit(e) {
				this.id = e.id
				this.mode = 'edit'
				for (let k in e) {
					if (this.createData.hasOwnProperty(k)) {
						this.createData[k] = e[k]
					}
				}
				this.dialogVisible = true
			},
			remove(id) {
				this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id
					}
					this.$http.post('/backend/role/delete', data).then(res => {
						if (res.code === 1) {
							this.$message.success('删除成功')
							this.getData()
						}
					})
				})
			},
			resetCreateData() {
				this.createData = {
					name: '',
					rolekey: '',
					listsort: 0,
					note: '',
					parent_id: '',
					is_hid: 0
				}
				this.$refs['createForm'].resetFields()
			},
			createRole() {
				if (this.mode === 'add') {
					this.$refs['createForm'].validate(valid => {
						if (valid) {
							this.$http.post('/backend/role/create', this.createData).then(res => {
								if (res.code === 1) {
									this.dialogVisible = false
									this.getData()
								}
							})
						}
					})
				} else {
					this.$refs['createForm'].validate(valid => {
						if (valid) {
							this.createData.id = this.id
							this.$http.post('/backend/role/update', this.createData).then(res => {
								if (res.code === 1) {
									this.dialogVisible = false
									this.getData()
								}
							})
						}
					})
				}
			},
			getData() {
				this.loading = true
				this.$http.get('/backend/role/list', {
					params: this.searchForm
				}).then(res => {
					if (res.code === 1) {
						this.tableData = res.data.list
						this.data_list = res.data.data_list
						// this.tableData.map((item, index) => this.$set(this.tableData[index], 'check', !Boolean(item.is_hid)))
					}
					this.loading = false
				})
				// let data = {
				//   is_tree: 1,
				//   // has_self: 1
				// }
				// this.$http.get('/backend/role/child', {params: data}).then(res => {
				//   if (res.code === 1) {
				//     this.tableData = res.data.list
				//   }
				//   this.loading = false
				// })
			},
			getRoleList() {
				let data = {
					is_tree: 1,
					has_self: 1
				}
				this.$http.get('/backend/role/child', {
					params: data
				}).then(res => {
					if (res.code === 1) {
						this.roleList = res.data.list
					}
					this.loading = false
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding: 24px 32px;
	}

	.el-table {
		margin-top: 24px;

		.o-btn {
			color: #409EFF;
			cursor: pointer;

			~.o-btn {
				margin-left: 12px;
			}
		}
	}

	.create-form {
		.el-form-item {
			margin-bottom: 20px;
		}

		.tree-border {
			padding: 4px;
			margin-top: 5px;
			border: 1px solid #e5e6e7;
			background: #fff none;
			border-radius: 4px;
		}
	}
</style>
