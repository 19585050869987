import {debounce, throttle} from './utils'
import store from "@/store"
export default {
    computed: {
        toFixedNum() {
            return function (num) {
                return (num * 10000 / 10000).toFixed(2)
                // return Number((num).toString().match(/^\d+(?:\.\d{0,2})?/))
            }
        },
        totalPrice() {
            let total = 0
            this.info.grade.map(item => {
                item.book.map(c => total += this.toFixedNum(c.number * c.price) * 1)
            })
            return total.toFixed(2)
        },
        totalGradePrice() {
            return function (e) {
                let total = 0
                e.book.map(item => {
                    total += this.toFixedNum(item.number * item.price) * 1
                })
                return total.toFixed(2)
            }
        },
        totalBookPrice() {
            return function (e) {
                return this.toFixedNum(e.number * e.price)
            }
        },
        hasButtonRoot() {
            return function (perms) {
                let arr = this.$store.state.userInfo.role_menu || []
                return arr.some(item => item.type === 'F' && item.perms === perms)
            }
        },
        userType() {
            return this.$store.state.userInfo.type
        }
    },
    directives: {
        btnRoot: {
            inserted(el, binding, vnode){
                const {value: perms} = binding
                let arr = store.state.userInfo.role_menu || []
                let flag = arr.some(item => item.type === 'F' && item.perms === perms)
                if (!flag) {
                    el.parentNode && el.parentNode.removeChild(el)
                }
            }
        }
    },
    methods: {
        openPage(url = '', data = {}) {
            if (!url) return
            this.$router.push({
                path: url,
                query: data
            })
        },
        _deepClone(obj) {
            // 对常见的“非”值，直接返回原来值
            if ([null, undefined, NaN, false].includes(obj)) return obj
            if (typeof obj !== "object" && typeof obj !== 'function') {
                //原始类型直接返回
                return obj
            }
            let o = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {}
            for (let i in obj) {
                if (obj.hasOwnProperty(i)) {
                    o[i] = typeof obj[i] === "object" ? this._deepClone(obj[i]) : obj[i]
                }
            }
            return o
        },
        _debounce: debounce,
        _throttle: throttle
    }
}