<template>
    <div class="page TranspotationByFinance">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form">
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="运费状态：">
                            <el-select v-model="searchForm.TAG" placeholder="请选择运费状态" clearable size="small">
                                <el-option label="已处理" value="0"></el-option>
                                <el-option label="已财务制证" value="1"></el-option>
                                <el-option label="已办结" value="100"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="承运商：">
                            <el-select v-model="searchForm.companyId" clearable placeholder="请选择承运商" size="small">
                                <el-option v-for="(item, index) in dict.carrierList" :key="index" :value="item.id"
                                    :label="item.subtitle"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="发运时间：">
                            <el-date-picker v-model="searchForm.sendDate" type="daterange" size="small" range-separator="-"
                                value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="处理时间：">
                            <el-date-picker v-model="searchForm.handleDate" type="daterange" size="small"
                                range-separator="-" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-col :span="4"> -->
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
                <!-- </el-col> -->
                <el-row style="display: flex;align-items: flex-end;">
                    <el-col :span="5">
                        <el-form-item label="处理时间：">
                            <el-date-picker size="small" v-model="searchForm.dateResult" type="date"
                                value-format="yyyy-MM-dd" placeholder="请选择处理时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item>
                            <el-button class="u-custom-btn-primary" icon="el-icon-coordinate" size="small" type="primary"
                                @click="searchTable('search')">抽数
                            </el-button>
                            <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                                @click="searchTable('reset')">导出报表
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-s-promotion" size="small" type="primary"
                        @click="addRole">发起通知</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-c-scale-to-original" size="small" type="primary"
                        @click="withdrawalNoticeBatch">撤回通知</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                        @click="addRole">下载发票</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
                        @click="searchTable('search')">刷新本地</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-monitor" size="small" type="primary"
                        @click="financialsharingBatch">财务共享</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-suitcase" size="small" type="primary"
                        @click="businessCompletionBatch">业务办结</el-button>
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                @selection-change="handleSelectionChange" height="800px" style="width: 98%;margin:0 auto;"
                header-row-class-name="header-table">
                <el-table-column align="center" type="selection" width="55">
                </el-table-column>
                <el-table-column align="center" label="清单号" prop="KIDNO" fixed="left" width="200">
                </el-table-column>
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="StateName">
                </el-table-column>
                <el-table-column align="center" label="发货日期" prop="BLDATdata">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="carrierstitle" width="200">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="shiptitle" width="200">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="MATNRName" width="150">
                </el-table-column>
                <el-table-column align="center" label="发站" prop="LIFNR_ZHName" width="250">
                </el-table-column>
                <el-table-column align="center" label="到站" prop="RSTATPortName">
                </el-table-column>
                <el-table-column align="center" label="原始单价" prop="OriginalPrice">
                </el-table-column>
                <el-table-column align="center" label="当前单价" prop="ThisPrice">
                    <template slot-scope="scope">
                        <div>{{ (scope.row.WRBTR / scope.row.QTY).toFixed(3) }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="数量" prop="QTY" width="100">
                </el-table-column>
                <el-table-column align="center" label="运杂费" prop="WRBTR" width="100">
                </el-table-column>
                <el-table-column align="center" label="财务共享号" prop="Shareno" width="200">
                </el-table-column>
                <el-table-column align="center" label="运费状态" prop="tagName">
                </el-table-column>
                <el-table-column align="center" label="处理时间" prop="ZZDRQdata">
                </el-table-column>
                <el-table-column align="center" label="操作" width="480" fixed="right">
                    <template slot-scope="scope">
                        <div v-if="scope.row.State == 10">
                            <div v-if="scope.row.TypeGL == 0">
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                            </div>
                            <div v-else>
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                                <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 20">
                            <div v-if="scope.row.TypeGL == 0">
                                <el-button type="primary" size="mini" @click="withdrawalNotice(scope.row)">撤回通知</el-button>
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                            </div>
                            <div v-else>
                                <el-button type="primary" size="mini" @click="withdrawalNotice(scope.row)">撤回通知</el-button>
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                                <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 30">
                            <div v-if="scope.row.TypeGL == 0">
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                                <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                <el-button type="primary" size="mini" @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                            </div>
                            <div v-else>
                                <el-button type="primary" size="mini" @click="relatedvoyages(scope.row)">关联航次</el-button>
                                <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                <el-button type="primary" size="mini" @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 31">
                            <div v-if="scope.row.Shareno == '' || scope.row.Shareno == null">
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 32">
                            <div v-if="scope.row.Shareno == '' || scope.row.Shareno == null">
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 33">
                            <div v-if="scope.row.Shareno == '' || scope.row.Shareno == null">
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                    <el-button size="mini" @click="businessCompletion(scope.row)">业务办结</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                    <el-button size="mini" @click="businessCompletion(scope.row)">业务办结</el-button>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                    <el-button size="mini" @click="businessCompletion(scope.row)">业务办结</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                    <el-button size="mini" @click="financialsharing(scope.row)">财务共享</el-button>
                                    <el-button size="mini" @click="businessCompletion(scope.row)">业务办结</el-button>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="scope.row.State == 50">
                            <div v-if="scope.row.Shareno == '' || scope.row.Shareno == null">
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.TypeGL == 0">
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                </div>
                                <div v-else>
                                    <el-button type="primary" size="mini"
                                        @click="relatedvoyages(scope.row)">关联航次</el-button>
                                    <el-button type="primary" size="mini" @click="lookDetail(scope.row)">查看明细</el-button>
                                    <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看发票</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="withdrawinvoice(scope.row)">撤回发票</el-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            无需操作
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 关联航次 -->
            <el-dialog :close-on-click-modal="false" title="关联航次" :visible.sync="dialogVisible" destroy-on-close top="12vh"
                width="65%">
                <div class="addoreidt">
                    <div class="searchBox">
                        <el-form :model="searchForm2" class="search-form" inline>
                            <el-form-item label="开始时间：">
                                <el-date-picker size="small" v-model="searchForm2.startTimeStart" type="date"
                                    value-format="yyyy-MM-dd" placeholder="请选择开始时间：">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="结束时间：">
                                <el-date-picker size="small" v-model="searchForm2.startTimeOver" type="date"
                                    value-format="yyyy-MM-dd" placeholder="请选择结束时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        <el-form :model="searchForm2" class="search-form" inline>
                            <el-form-item label="关联类型：">
                                <el-select v-model="searchForm2.statusType" placeholder="请选择关联类型" clearable size="small">
                                    <el-option label="改港/分卸" value="2"></el-option>
                                    <el-option label="运价调整" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="运价时间：">
                                <el-date-picker size="small" :disabled="searchForm2.statusType == '2'"
                                    value-format="yyyy-MM-dd" v-model="searchForm2.dATAB" type="date" placeholder="请选择运价时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                                    @click="searchTable2('search')">搜索
                                </el-button>
                                <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
                                    type="primary" @click="searchTable2('reset')">重置
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!--列表-->
                    <el-table ref="multipleTable" border v-loading="tableLoading2" :data="tableData2" tooltip-effect="dark"
                        style="width: 100%;margin:0 auto;" @selection-change="handleSelectionChange2"
                        header-row-class-name="header-table">
                        <el-table-column align="center" type="selection" width="55">
                        </el-table-column>
                        <el-table-column align="center" type="index">
                        </el-table-column>
                        <el-table-column align="center" label="承运商" prop="title">
                        </el-table-column>
                        <el-table-column align="center" label="船舶" prop="shipName">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="startPortName">
                        </el-table-column>
                        <el-table-column align="center" label="原始目的港" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="最终目的港" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="发运日期" prop="startTimeData">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="oilTypeName">
                        </el-table-column>
                        <el-table-column align="center" label="完成数" prop="realTon">
                        </el-table-column>
                        <el-table-column align="center" label="原始单价" prop="originalPrice">
                        </el-table-column>
                        <el-table-column align="center" label="当前单价" prop="adjustPrice">
                        </el-table-column>
                        <el-table-column align="center" label="原始运费" prop="originalPriceYUNJIA">
                        </el-table-column>
                        <el-table-column align="center" label="调整后运费" prop="adjustPriceYUNJIA">
                        </el-table-column>
                        <el-table-column align="center" label="退补金额" width="120" fixed="right" prop="tbYunFei">
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page2.currentPage"
                            :page-size="page2.pageSize" :total="page2.total" @current-change="changePage2"
                            @size-change="handleSizeChange2" layout="total,sizes ,prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                    <el-form class="search-form" inline style="width:100%;">
                        <el-form-item label="关联备注：" style="width:100%;">
                            <el-input placeholder="请输入备注" v-model="searchForm2.remark" clearable style="width:100%;">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <h2>总退补金额：{{ searchForm2.backFillPrice }}，选中航次的退补金额：{{ xztbje }}</h2>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="completed">处理完成</el-button>
                    <el-button type="danger" @click="dialogVisible = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 查看明细 -->
            <el-dialog :close-on-click-modal="false" title="查看明细" :visible.sync="dialogVisible2" destroy-on-close top="12vh"
                width="65%">
                <div class="addoreidt">
                    <div class="searchBox">
                        <el-form :model="searchForm3" class="search-form" inline>
                            <el-form-item label="开始时间：">
                                <el-date-picker size="small" v-model="searchForm3.startTimeStart" type="date"
                                    value-format="yyyy-MM-dd" disabled>
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="结束时间：">
                                <el-date-picker size="small" v-model="searchForm3.startTimeOver" type="date"
                                    value-format="yyyy-MM-dd" disabled>
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        <el-form :model="searchForm3" class="search-form" inline>
                            <el-form-item label="关联类型：">
                                <el-select v-model="searchForm3.statusType" disabled size="small">
                                    <el-option label="改港/分卸" value="2"></el-option>
                                    <el-option label="运价调整" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="运价时间：">
                                <el-date-picker size="small" disabled value-format="yyyy-MM-dd" v-model="searchForm3.dATAB"
                                    type="date" placeholder="请选择运价时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!--列表-->
                    <el-table ref="multipleTable" border :data="tableData3" tooltip-effect="dark"
                        style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                        <el-table-column align="center" type="index">
                        </el-table-column>
                        <el-table-column align="center" label="承运商" prop="title">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="startPortName">
                        </el-table-column>
                        <el-table-column align="center" label="原始目的港" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="最终目的港" prop="destPortName">
                        </el-table-column>
                        <el-table-column align="center" label="发运日期" prop="startTimeData">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="oilTypeName">
                        </el-table-column>
                        <el-table-column align="center" label="完成数" prop="realTon">
                        </el-table-column>
                        <el-table-column align="center" label="原始单价" prop="originalPrice">
                        </el-table-column>
                        <el-table-column align="center" label="当前单价" prop="adjustPrice">
                        </el-table-column>
                        <el-table-column align="center" label="原始运费" prop="originalPriceYUNJIA">
                        </el-table-column>
                        <el-table-column align="center" label="调整后运费" prop="adjustPriceYUNJIA">
                        </el-table-column>
                        <el-table-column align="center" label="退补金额" width="120" fixed="right" prop="tbYunFei">
                        </el-table-column>
                    </el-table>
                    <el-form class="search-form" inline style="width:100%;">
                        <el-form-item label="备注：" style="width:100%;">
                            <el-input v-model="searchForm3.remark" style="width:100%;" disabled>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <h2>总退补金额：{{ searchForm3.total }}</h2>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="resetting">重置</el-button>
                    <el-button type="danger" @click="dialogVisible2 = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!-- 查看发票 -->
            <el-dialog :close-on-click-modal="false" title="查看发票" :visible.sync="dialogVisible3" destroy-on-close top="12vh"
                width="65%">
                <div class="addoreidt">
                    <!--列表-->
                    <el-table ref="multipleTable" border :data="tableData4" tooltip-effect="dark"
                        style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                        <el-table-column align="center" type="index">
                        </el-table-column>
                        <el-table-column align="center" label="清单号" prop="KIDNO">
                        </el-table-column>
                        <el-table-column align="center" label="始发" prop="LIFNR_ZHName">
                        </el-table-column>
                        <el-table-column align="center" label="目的" prop="RSTATPortName">
                        </el-table-column>
                        <el-table-column align="center" label="发运时间" prop="BLDATdata">
                        </el-table-column>
                        <el-table-column align="center" label="油品" prop="MATNRName">
                        </el-table-column>
                        <el-table-column align="center" label="完成数" prop="QTY">
                        </el-table-column>
                        <el-table-column align="center" label="原始单价" prop="OriginalPrice">
                        </el-table-column>
                        <el-table-column align="center" label="当前单价" prop="ThisPrice">
                            <template slot-scope="scope">
                                 <div>{{ (parseFloat(Number(scope.row.WRBTR/scope.row.QTY))).toFixed(3) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="运费" prop="WRBTR">
                        </el-table-column>
                        <el-table-column align="center" label="状态"  prop="StateName">
                        </el-table-column>
                    </el-table>
                    <h4 style="width:100%;text-align:right;margin:15px 15px 0px 0px;padding-right:25px;">合计金额：{{ searchForm4.yunzafei }}</h4>
                    <el-divider></el-divider>
                    <el-form :model="searchForm4" class="search-form" inline >
                        <el-form-item label="发票号码：">
                            <el-input  v-model="searchForm4.invoiceNumber" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发票代码：">
                            <el-input  v-model="searchForm4.invoiceCode" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发票金额：">
                            <el-input  v-model="searchForm4.invoiceMoney" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-form :model="searchForm4" class="search-form" inline>
                        <el-form-item label="发票税率：">
                            <el-input  v-model="searchForm4.taxRate" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="销方税号：">
                            <el-input  v-model="searchForm4.Annuleinjust" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发票税额：">
                            <el-input  v-model="searchForm4.taxNumber" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-form :model="searchForm4" class="search-form" inline>
                        <el-form-item label="价税总额：">
                            <el-input  v-model="searchForm4.priceToatl" size="small" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发票类型：">
                            <el-select v-model="searchForm4.InvoiceType" disabled size="small">
                                <el-option label="增值税普通发票" value="增值税普通发票"></el-option>
                                <el-option label="增值税普通发票" value="增值税普通发票"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发票图片：">
                            <div v-if="searchForm4.picPath == ''">未上传图片</div>
                            <div v-else>已上传附件！<el-button  type="primary" icon="el-icon-view" size="mini" @click="lookFile(searchForm4.picPath)">查看</el-button></div>
                        </el-form-item>
                    </el-form>
                    <el-divider></el-divider>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="dialogVisible3 = false">关 闭</el-button>
                </span>
            </el-dialog>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">清单号</th>
                            <th class="tg-0lax">状态</th>
                            <th class="tg-0lax">发货日期</th>
                            <th class="tg-0lax">承运商</th>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">油品</th>
                            <th class="tg-0lax">发站</th>
                            <th class="tg-0lax">到站</th>
                            <th class="tg-0lax">原始单价</th>
                            <th class="tg-0lax">当前单价</th>
                            <th class="tg-0lax">数量</th>
                            <th class="tg-0lax">运杂费</th>
                            <th class="tg-0lax">财务共享号</th>
                            <th class="tg-0lax">运费状态</th>
                            <th class="tg-0lax">处理时间</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.KIDNO }}</th>
                        <th class="tg-0lax">{{ item.StateName }}</th>
                        <th class="tg-0lax">{{ item.BLDATdata }}</th>
                        <th class="tg-0lax">{{ item.carrierstitle }}</th>
                        <th class="tg-0lax">{{ item.shiptitle }}</th>
                        <th class="tg-0lax">{{ item.MATNRName }}</th>
                        <th class="tg-0lax">{{ item.LIFNR_ZHName }}</th>
                        <th class="tg-0lax">{{ item.RSTATPortName }}</th>
                        <th class="tg-0lax">{{ item.OriginalPrice }}</th>
                        <th class="tg-0lax">{{ (item.WRBTR / item.QTY).toFixed(3) }}</th>
                        <th class="tg-0lax">{{ item.QTY }}</th>
                        <th class="tg-0lax">{{ item.WRBTR }}</th>
                        <th class="tg-0lax">{{ item.Shareno }}</th>
                        <th class="tg-0lax">{{ item.tagName }}</th>
                        <th class="tg-0lax">{{ item.ZZDRQdata }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TranspotationByFinance",
    data() {
        return {
            multipleSelection: [],
            multipleSelection2: [],
            searchForm: {
                TAG: '',
                companyId: '',
                sendDate: [],
                handleDate: []
            },
            searchForm2: {
                remark: '',
                vBELN: "",
                originalPrice: "",
                backFillPrice: "",
                shipplanid: '',
                statusType: '2',
                dATAB: '',
                startTimeStart: '',
                startTimeOver: ''
            },
            searchForm3: {
                remark: '',
                statusType: '',
                dATAB: '',
                startTimeStart: '',
                startTimeOver: '',
                total: '',
                vBELN: ''
            },
            searchForm4: {
                picPath:'',
                invoiceNumber:'',
                invoiceCode:'',
                invoiceMoney:'',
                taxRate:'',
                Annuleinjust:'',
                taxNumber:'',
                priceToatl:'',
                InvoiceType:'',
                yunzafei:0
            },
            tableLoading: false,
            tableLoading2: false,
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3:false,
            page: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
                lastPage: 1,
            },
            page2: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            page3: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
            tableData2: [],
            tableData3: [],
            tableData4: [],
            xztbje: 0,
            dict: {//数据字典
                carrierList: [],
                companyList: [],
                monthlyList: [],
                oilList: [],
                shipList: [],
                shipPortBegin: [],
                shipPortEnd: [],
                shipPortList: [],
            },
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getDict()
        this.getData()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        addRole() {
            console.log('添加');
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        lookInvoice(row){
            let data = {
                vBELN:row.VBELN
            }
            this.$Api.settle_getinvoiceInfoByVBELN(data).then(res=>{
                if(res.code == 200){
                    this.tableData4 = res.data.YZFdata
                    this.searchForm4.picPath = res.data.FPdata[0].picPath
                    this.searchForm4.invoiceNumber = res.data.FPdata[0].invoiceNumber
                    this.searchForm4.invoiceCode = res.data.FPdata[0].invoiceCode
                    this.searchForm4.invoiceMoney = res.data.FPdata[0].invoiceMoney
                    this.searchForm4.taxRate = res.data.FPdata[0].taxRate
                    this.searchForm4.Annuleinjust = res.data.FPdata[0].Annuleinjust
                    this.searchForm4.taxNumber = res.data.FPdata[0].taxNumber
                    this.searchForm4.priceToatl = Number(res.data.FPdata[0].taxNumber) + Number(res.data.FPdata[0].invoiceMoney)
                    this.searchForm4.InvoiceType = res.data.FPdata[0].InvoiceType
                    this.searchForm4.yunzafei = 0
                    if(this.tableData4.length > 0){
                        let p = 0
                        this.tableData4.map(item=>{
                            p+=parseFloat(item.WRBTR)
                        })
                        this.searchForm4.yunzafei = p
                    }
                    this.dialogVisible3 = true
                }
            })
        },
        resetting() {
            this.$confirm('确定要重置已关联的航次吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    vBELN: this.searchForm3.vBELN
                }
                this.$Api.settle_deleteErpVbelnRveShipplan(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        lookDetail(row) {
            let data = {
                vBELN: row.VBELN
            }
            this.searchForm3.vBELN = row.VBELN
            this.$Api.settle_geterpVbelnRveShipplanByVBELN(data).then(res => {
                if (res.code == 200) {
                    this.searchForm3.remark = res.data.remark
                    this.searchForm3.statusType = res.data.statusType
                    this.searchForm3.dATAB = res.data.dATABdata.split(' ').length > 1 ? res.data.dATABdata.split(' ')[0] : res.data.dATABdata
                    this.searchForm3.startTimeStart = res.data.startTimeStartdata.split(' ').length > 1 ? res.data.startTimeStartdata.split(' ')[0] : res.data.startTimeStartdata
                    this.searchForm3.startTimeOver = res.data.startTimeOverdata.split(' ').length > 1 ? res.data.startTimeOverdata.split(' ')[0] : res.data.startTimeOverdata
                    this.tableData3 = res.data.jsonText != '' && res.data.jsonText !== null ? JSON.parse(res.data.jsonText) : []
                    this.searchForm3.total = 0
                    if (this.tableData3.length > 0) {
                        let price = 0
                        this.tableData3.map(item => {
                            price += item.tbYunFei
                        })
                        this.searchForm3.total = price
                    }
                    this.dialogVisible2 = true
                }
            })
        },
        withdrawinvoice(row) {
            this.$confirm('是否要撤回该记录关联的所有发票信息？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    vBELN: row.VBELN
                }
                this.$Api.settle_deleteinvoice(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        completed() {
            if (this.multipleSelection2.length == 0) {
                this.$message.warning('未选中航次，无法提交')
                return
            }
            if (this.searchForm2.statusType == '1' && this.searchForm2.dATAB == '') {
                this.$message.warning('处理运价调整必须要选择运价时间')
                return
            }
            if (this.searchForm2.backFillPrice != this.xztbje) {
                this.$confirm('退补金额与关联金额不相等，是否继续关联？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        vBELN: this.searchForm2.vBELN,
                        shipplanid: [this.searchForm2.shipplanid],
                        originalPrice: this.searchForm2.originalPrice,
                        remark: this.searchForm2.remark,
                        dATAB: this.searchForm2.dATAB,
                        statusType: this.searchForm2.statusType,
                        startTimeStart: this.searchForm2.startTimeStart,
                        startTimeOver: this.searchForm2.startTimeOver,
                        jsonText: JSON.stringify(this.multipleSelection2)
                    }
                    this.$Api.settle_adderpVbelnRveShipplan(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.getData()
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            } else {
                let data = {
                    vBELN: this.searchForm2.vBELN,
                    shipplanid: [this.searchForm2.shipplanid],
                    originalPrice: this.searchForm2.originalPrice,
                    remark: this.searchForm2.remark,
                    dATAB: this.searchForm2.dATAB,
                    statusType: this.searchForm2.statusType,
                    startTimeStart: this.searchForm2.startTimeStart,
                    startTimeOver: this.searchForm2.startTimeOver,
                    jsonText: JSON.stringify(this.multipleSelection2)
                }
                this.$Api.settle_adderpVbelnRveShipplan(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }
        },
        relatedvoyages(row) {
            this.searchForm = {
                remark: '',
                vBELN: "",
                originalPrice: "",
                backFillPrice: "",
                shipplanid: '',
                statusType: '2',
                dATAB: '',
                startTimeStart: '',
                startTimeOver: ''
            }
            this.tableData2 = []
            this.searchForm2.vBELN = row.vBELN
            this.searchForm2.shipplanid = row.Shipplanid
            this.searchForm2.backFillPrice = parseFloat(((Number(row.WRBTR) - (Number(row.OriginalPrice) * Number(row.QTY)))).toFixed(2))
            this.dialogVisible = true;
        },
        handleSelectionChange2(val) {
            this.multipleSelection2 = val;
            if (this.multipleSelection2.length > 0) {
                let price = 0
                this.multipleSelection2.map(item => {
                    price = price + item.tbYunFei
                })
                this.xztbje = (price).toFixed(2)
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        businessCompletion(row) {
            this.$confirm('确定业务办结吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let vBELN = []
                vBELN.push(row.VBELN)
                let data = {
                    vBELN: vBELN
                }
                this.$Api.settle_BatchUpdateerpVbelnSettle(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        businessCompletionBatch() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning('未选中清单，无法处理业务办结！')
                return
            }
            this.$confirm('确定业务办结吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let vBELN = []
                this.multipleSelection.map(item => {
                    vBELN.push(item.VBELN)
                })
                let data = {
                    vBELN: vBELN
                }
                this.$Api.settle_BatchUpdateerpVbelnSettle(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        financialsharing(row) {
            this.$prompt('请输入财务共享号', `清单号:${row.KIDNO}`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (value) => { //非空验证
                    if (!value) {
                        return '输入不能为空'
                    }
                },
            }).then(({ value }) => {
                let vBELN = []
                vBELN.push(row.VBELN)
                let data = {
                    vBELN: vBELN,
                    shareno: value
                }
                this.$Api.settle_updateErpVbelnShareno(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        financialsharingBatch() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning('未选中清单，无法保存共享号！')
                return
            }
            this.$prompt('请输入财务共享号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (value) => { //非空验证
                    if (!value) {
                        return '输入不能为空'
                    }
                },
            }).then(({ value }) => {
                let vBELN = []
                this.multipleSelection.map(item => {
                    vBELN.push(item.VBELN)
                })
                let data = {
                    vBELN: vBELN,
                    shareno: value
                }
                this.$Api.settle_updateErpVbelnShareno(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        withdrawalNotice(row) {
            this.$confirm('确定要撤回记录吗? 撤回通知后，需要重新发送通知，发票需要重新上传，请谨慎操作？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let vBELN = []
                let shipplanid = []
                vBELN.push(row.VBELN)
                shipplanid.push(row.Shipplanid)
                let data = {
                    vBELN: vBELN,
                    shipplanid: shipplanid,
                    state: 10
                }
                this.$Api.settle_updateState(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        withdrawalNoticeBatch() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning('未选中清单，无法撤回通知！')
                return
            }
            this.$confirm('确定要撤回记录吗? 撤回通知后，需要重新发送通知，发票需要重新上传，请谨慎操作？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let vBELN = []
                let shipplanid = []
                this.multipleSelection.map(item => {
                    vBELN.push(item.VBELN)
                    shipplanid.push(item.Shipplanid)
                })
                let data = {
                    vBELN: vBELN,
                    shipplanid: shipplanid,
                    state: 10
                }
                this.$Api.settle_updateState(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let tableData = []
                    this.tableData.map(item => {
                        item.dqdjlg = (item.WRBTR / item.QTY).toFixed(3)
                        tableData.push(item)
                    })
                    let config = [
                        { field: 'KIDNO', width: 80, align: 'center', title: '清单号' },
                        { field: 'StateName', width: 80, align: 'center', title: '状态' },
                        { field: 'BLDATdata', width: 80, align: 'center', title: '发货日期' },
                        { field: 'carrierstitle', width: 200, align: 'center', title: '承运商' },
                        { field: 'shiptitle', width: 150, align: 'center', title: '船名' },
                        { field: 'MATNRName', width: 180, align: 'center', title: '油品' },
                        { field: 'LIFNR_ZHName', width: 180, align: 'center', title: '发站' },
                        { field: 'RSTATPortName', width: 180, align: 'center', title: '到站' },
                        { field: 'OriginalPrice', width: 80, align: 'center', title: '原始单价' },
                        { field: 'dqdjlg', width: 100, align: 'center', title: '当前单价' },
                        { field: 'QTY', width: 80, align: 'center', title: '数量' },
                        { field: 'WRBTR', width: 160, align: 'center', title: '运杂费' },
                        { field: 'Shareno', width: 160, align: 'center', title: '财务共享号' },
                        { field: 'tagName', width: 160, align: 'center', title: '运费状态' },
                        { field: 'ZZDRQdata', width: 200, align: 'center', title: '处理时间' },
                    ]
                    let obj = {
                        name: '运杂费结算(财务)',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        getDict() {
            this.$Api.shipPlan_Dict().then(res => {
                if (res.code == 200) {
                    this.dict.carrierList = res.data.carrierList
                    this.dict.companyList = res.data.companyList
                    this.dict.monthlyList = res.data.monthlyList
                    this.dict.oilList = res.data.oilList
                    this.dict.shipList = res.data.shipList
                    this.dict.shipPortBegin = res.data.shipPortBegin
                    this.dict.shipPortEnd = res.data.shipPortEnd
                    this.dict.shipPortList = res.data.shipPortList
                }
            })
        },
        // ----------------------------------------------------------------
        handleSizeChange2(val) { //切换每页条数
            this.page2.pageSize = val
            this.getData2()
        },
        changePage2(val) { //选择第几页
            this.page2.currentPage = val;
            this.getData2()
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        searchTable2(t) { // 搜索/重置
            if (t === 'search') {
                this.page2.currentPage = 1
                this.getData2()
            } else {
                for (const key in this.searchForm2) {
                    if (this.searchForm2[key] instanceof Array) {
                        this.searchForm2[key] = []
                    } else {
                        this.searchForm2[key] = ''
                    }
                }
                this.searchForm2.statusType = '2'
                this.getData2()
            }
        },
        getData2() {//
            let data = {
                page: this.page2.currentPage,
                limit: this.page2.pageSize,
                startTimeStart: this.searchForm2.startTimeStart,
                startTimeOver: this.searchForm2.startTimeOver,
                dATAB: this.searchForm2.dATAB,
                statusType: this.searchForm2.statusType,
                shipplanid: this.searchForm2.shipplanid
            }
            this.tableLoading2 = true
            this.$Api.settle_getCarrierAssociationShipPlan(data).then(res => {
                this.tableLoading2 = false
                if (res.code == 200) {
                    if (res.data.total) this.page2.total = Number(res.data.total)
                    this.tableData2 = res.data.list
                }
            })
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                TAG: this.searchForm.TAG,
                companyId: this.searchForm.companyId,
                startTimeStart: this.searchForm.sendDate[0] ? this.searchForm.sendDate[0] : '',
                startTimeOver: this.searchForm.sendDate[1] ? this.searchForm.sendDate[1] : '',
                startzZDRQ: this.searchForm.handleDate[0] ? this.searchForm.handleDate[0] : '',
                overzZDRQ: this.searchForm.handleDate[1] ? this.searchForm.handleDate[1] : ''
            }
            this.tableLoading = true
            this.$Api.settle_getsettleinfoList(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = Number(res.data.total);
                    this.tableData = res.data.list
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.TranspotationByFinance {
    height: auto;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        height: 800px;
        // flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .el-table {
            height: 800px;
        }

        .addoreidt {
            width: 100%;
            height: 50vh;
            overflow-y: scroll;
            overflow-x: hidden;

            /deep/.el-table {
                height: auto;
            }

            /deep/.el-form-item__content {
                width: 100%;
            }

            .tip {
                margin: 12px 0;
                font-size: 14px;
            }

            .priceTip {
                font-size: 21px;

                span {
                    margin-right: 12px;
                }
            }
        }
    }
}
</style>