<template>
    <div class="page tonnage">
        <el-page-header @back="goBack" content="吨位编辑">
        </el-page-header>
        <div class="tip">
            <span>柴油</span>
            <span class="red">不需要维护的吃水深度，填写数字0</span>
        </div>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tabData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column prop="tonnage" align="center" :label="(2 + ((index + 1) / 10)) + ' 吃水米'"
                    v-for="(item, index) in 15">
                    <template slot-scope="scope">
                        <div>吨位</div>
                        <el-input v-model="tonnage1[index]" placeholder="请输入内容" type="number" :min="0"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tabData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column prop="tonnage" align="center" :label="(3.5 + ((index + 1) / 10)) + ' 吃水米'"
                    v-for="(item, index) in 15">
                    <template slot-scope="scope">
                        <div>吨位</div>
                        <el-input v-model="tonnage1[(15 + index)]" placeholder="请输入内容" type="number" :min="0"></el-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="tip">
            <span>汽油</span>
            <span class="red">不需要维护的吃水深度，填写数字0</span>
        </div>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tabData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column prop="tonnage" align="center" :label="(2 + ((index + 1) / 10)) + ' 吃水米'"
                    v-for="(item, index) in 15">
                    <template slot-scope="scope">
                        <div>吨位</div>
                        <el-input v-model="tonnage2[index]" placeholder="请输入内容" type="number" :min="0"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tabData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column prop="tonnage" align="center" :label="(3.5 + ((index + 1) / 10)) + ' 吃水米'"
                    v-for="(item, index) in 15">
                    <template slot-scope="scope">
                        <div>吨位</div>
                        <el-input v-model="tonnage2[(15 + index)]" placeholder="请输入内容" type="number" :min="0"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="submit">
                <el-button type="primary" @click="subRefuse">保存</el-button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "tonnage",
    data() {
        return {
            rowData: {},
            tableLoading: false,
            isread: true,
            tabData: [{ tonnage: '' }],
            tonnage1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            tonnage2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
    },
    created() {
        this.rowData = this.$route.params
        if (this.rowData?.id) {
            this.getData()
        }
    },
    activated() {
        this.rowData = this.$route.params
        if (this.rowData?.id) {
            this.getData()
        }
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        demo(index) {
            console.log(index);
        },
        deleterow(row) {
            this.$confirm('确定删除该项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.carriers_deleteAssessItem2(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        subRefuse() {
            let data = {
                id: this.rowData.id,
                tonnage1: this.tonnage1.join(','),
                tonnage2: this.tonnage2.join(',')
            }
            this.$Api.CarriersShip_ShipTonnageTable_IsUpdate(data).then(res => {
                if (res.data.canModify == 1) {//修改需要审批
                    this.$prompt('请输入申请修改原因', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value }) => {
                        if (value == '') {
                            this.$message.warning('修改原因不能为空')
                            return
                        }
                        let obj = {
                            shipId: this.rowData.id,
                            resean: value
                        }
                        this.$Api.CarriersShip_addEditTonApprovel(obj).then(res => {
                            if (res.code == 200) {
                                this.$message.success('已提交审核,请等待审核')
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消输入'
                        });
                    });
                } else if (res.data.canModify == 2) { //正在审核中
                    this.$alert('正在审批中，请等待', {
                        confirmButtonText: '确定',
                    });
                } else {//不需要审核直接保存
                    this.$Api.CarriersShip_ShipTonnageTable_Save(data).then(res => {
                        if (res.code == 200) {
                            this.$message.success('操作成功')
                        }
                    })
                }
            })
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/carrier/tonnage')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        getData() { // 获取数据
            let data = {
                id: this.rowData.id,
            }
            this.tableLoading = true
            this.isread = false
            this.$Api.CarriersShip_ShipTonnageTable(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.tonnage1 = res.data.tonnage1.split(',')
                    this.tonnage2 = res.data.tonnage2.split(',')
                }
            })
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isread = true
                })
            })

        },
    }
}
</script>
  
<style lang="scss" scoped>
.tonnage {
    padding-top: 20px;
    padding-left: 20px;
    height: initial;

    .tip {
        margin: 10px 0;

        .red {
            color: red;
            margin-left: 10px;
        }
    }

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0;
        }


    }
}
</style>