<template>
    <div class="page BoatQuery">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="时间范围：">
                    <el-date-picker v-model="searchForm.dateResult" value-format="yyyy-MM-dd" type="daterange" size="small"
                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="shiptitle">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="carrierstitle">
                </el-table-column>
                <el-table-column align="center" label="属性" prop="belongName">
                </el-table-column>
                <el-table-column align="center" label="始发港" prop="startPortName" width="200">
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPortName" width="200">
                </el-table-column>
                <el-table-column align="center" label="调整后到港" prop="changePort">
                </el-table-column>
                <el-table-column align="center" label="油库" prop="oilWarehouseTitle">
                </el-table-column>
                <el-table-column align="center" label="品号" prop="oilTypetitle">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="oilTon">
                </el-table-column>
                <el-table-column align="center" label="发运时间" prop="startTime">
                </el-table-column>
                <el-table-column align="center" label="装船量" prop="shipmentTon">
                </el-table-column>
                <el-table-column align="center" label="船板量" prop="plankingTon">
                </el-table-column>
                <el-table-column align="center" label="到港时间" prop="arrivalTime" width="180">
                </el-table-column>
                <el-table-column align="center" label="卸货量" prop="realTon">
                </el-table-column>
                <el-table-column align="center" label="航次状态" prop="statesName">
                </el-table-column>
                <el-table-column align="center" label="操作" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="pass(scope.row)"
                            v-if="scope.row.companyTonConfirmStatus == 0 && scope.row.states == 7">确认收油</el-button>
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">查看回执单</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">承运商</th>
                            <th class="tg-0lax">属性</th>
                            <th class="tg-0lax">始发港</th>
                            <th class="tg-0lax">到港</th>
                            <th class="tg-0lax">调整后到港</th>
                            <th class="tg-0lax">油库</th>
                            <th class="tg-0lax">品号</th>
                            <th class="tg-0lax">数量</th>
                            <th class="tg-0lax">发运时间</th>
                            <th class="tg-0lax">装船量</th>
                            <th class="tg-0lax">船板量</th>
                            <th class="tg-0lax">到港时间</th>
                            <th class="tg-0lax">卸货量</th>
                            <th class="tg-0lax">航次状态</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.shiptitle }}</th>
                        <th class="tg-0lax">{{ item.carrierstitle }}</th>
                        <th class="tg-0lax">{{ item.belongName }}</th>
                        <th class="tg-0lax">{{ item.startPortName }}</th>
                        <th class="tg-0lax">{{ item.destPortName }}</th>
                        <th class="tg-0lax">{{ item.changePort }}</th>
                        <th class="tg-0lax">{{ item.oilWarehouseTitle }}</th>
                        <th class="tg-0lax">{{ item.oilTypetitle }}</th>
                        <th class="tg-0lax">{{ item.oilTon }}</th>
                        <th class="tg-0lax">{{ item.startTime }}</th>
                        <th class="tg-0lax">{{ item.shipmentTon }}</th>
                        <th class="tg-0lax">{{ item.plankingTon }}</th>
                        <th class="tg-0lax">{{ item.arrivalTime }}</th>
                        <th class="tg-0lax">{{ item.realTon}}</th>
                        <th class="tg-0lax">{{ item.statesName }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BoatQuery",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                dateResult: [],
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getData()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let config = [
                        { field: 'shiptitle', width: 80, align: 'center', title: '船名' },
                        { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
                        { field: 'belongName', width: 80, align: 'center', title: '属性' },
                        { field: 'startPortName', width: 150, align: 'center', title: '始发港' },
                        { field: 'destPortName', width: 150, align: 'center', title: '到港' },
                        { field: 'changePort', width: 150, align: 'center', title: '调整后到港' },
                        { field: 'oilWarehouseTitle', width: 80, align: 'center', title: '油库' },
                        { field: 'oilTypetitle', width: 80, align: 'center', title: '品号' },
                        { field: 'oilTon', width: 80, align: 'center', title: '数量' },
                        { field: 'startTime', width: 80, align: 'center', title: '发运时间' },
                        { field: 'shipmentTon', width: 100, align: 'center', title: '装船量' },
                        { field: 'plankingTon', width: 80, align: 'center', title: '船板量' },
                        { field: 'arrivalTime', width: 160, align: 'center', title: '到港时间' },
                        { field: 'realTon', width: 160, align: 'center', title: '卸货量' },
                        { field: 'statesName', width: 160, align: 'center', title: '航次状态' },
                    ]
                    let obj = {
                        name: '航次查询',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(this.tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        pass(row) {
            this.$confirm('是否确认收油?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.shipPlanId
                }
                this.$Api.several_quality_updateshipplan(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        deleterow() {

        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                startTime: this.searchForm.dateResult[0] ? this.searchForm.dateResult[0] : '',
                arrivalTime: this.searchForm.dateResult[1] ? this.searchForm.dateResult[1] : ''
            }
            this.tableLoading = true
            this.$Api.several_quality_getshipPlantolist(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = Number(res.data.total);
                    this.tableData = res.data.payments?res.data.payments:[]
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.BoatQuery {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form-item__label {
            width: 100px;
        }

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }


    }
}
</style>