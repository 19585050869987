<template>
    <div class="page details">
        <el-page-header @back="goBack" content="船舶计划">
        </el-page-header>
        <div class="searchBox" style="margin-top: 20px;">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="名称：">
                    <el-input v-model="searchForm.name" placeholder="请输入名称" size="small"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
                        type="primary" @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <!--列表-->
        <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
            height="520px" max-height="(100%-104px)" style="width: 100%;margin:0 auto;"
            header-row-class-name="header-table">
            <el-table-column align="center" label="序列号" prop="id">
            </el-table-column>
            <el-table-column align="center" label="船舶" prop="chuanname">
            </el-table-column>
            <el-table-column align="center" label="承运商" prop="cysname">
            </el-table-column>
            <el-table-column align="center" label="油类别" prop="youlb">
            </el-table-column>
            <el-table-column align="center" label="数量" prop="oilTon">
            </el-table-column>
            <el-table-column align="center" label="出发时间" prop="startTime">
            </el-table-column>
            <el-table-column align="center" label="船板量" prop="plankingTon">
            </el-table-column>
            <el-table-column align="center" label="装船量" prop="shipmentTon">
            </el-table-column>
            <el-table-column align="center" label="到港时间" prop="arrivalTime">
            </el-table-column>
            <el-table-column align="center" label="状态" prop="states">
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
            <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "details",
    data() {
        return {
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
            searchForm: {
                name: ''
            },
            tableLoading: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                chuanid: this.searchForm.name,
            }
            this.tableLoading = true
            this.$Api.settle_largescreenDataBindDs(data).then(res => {
                this.tableLoading = false
                if (res.data.total) this.page.total = Number(res.data.total);
                this.tableData = res.data.list
            })
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
            }
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/vesseMonitor/jihuas')
        }
    }
}
</script>

<style lang="scss" scoped>
.details {
    padding-top: 20px;
    padding-left: 20px;
}
</style>