<template>
  <div class="page ExceedClaimant">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="时间范围：">
          <el-date-picker size="small" v-model="searchForm.dateResult" type="daterange" align="right" unlink-panels
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="价格：">
          <el-select clearable v-model="searchForm.isconfirmprice" placeholder="请选择价格" size="small">
            <el-option value="1" label="已确认价格"></el-option>
            <el-option value="2" label="未确认价格"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承运商：">
          <el-select clearable v-model="searchForm.carrierId" placeholder="请搜索或选择承运商" size="small">
            <el-option v-for="(item, index) in dict.carrierList" :key="index" :value="item.id"
              :label="item.subtitle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赔付单：">
          <el-select clearable v-model="searchForm.ispaysingle" placeholder="请选择赔付单" size="small">
            <el-option value="1" label="未生成"></el-option>
            <el-option value="2" label="已生成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
            @click="bacth">生成赔付通知单</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox v-for="(item, index) in checkListData" :key="index" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column type="selection" @selection-change="handleSelectionChange" width="55" align="center">
        </el-table-column>
        <el-table-column align="center" type="index" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="月份" width="100" prop="monthlydate" v-if="checkList.indexOf('月份') != -1">
        </el-table-column>
        <el-table-column align="center" label="发运时间" width="120" prop="startTime" v-if="checkList.indexOf('发运时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="收卸时间" width="120" prop="arrivalTime"
          v-if="checkList.indexOf('收卸时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="在途天数" width="120" prop="onLineDays" v-if="checkList.indexOf('在途天数') != -1">
        </el-table-column>
        <el-table-column align="center" label="处理时间" width="120" prop="arrivalTimeEntering"
          v-if="checkList.indexOf('处理时间') != -1">
          <template slot-scope="scope">
            <div>{{ scope.row.arrivalTimeEntering.split(' ')[0] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船名" width="120" prop="shiptitle" v-if="checkList.indexOf('船名') != -1">
        </el-table-column>
        <el-table-column align="center" label="发港" width="120" prop="startPortName" v-if="checkList.indexOf('发港') != -1">
        </el-table-column>
        <el-table-column align="center" label="到港" width="120" prop="destPortName" v-if="checkList.indexOf('到港') != -1">
        </el-table-column>
        <el-table-column align="center" label="收卸库" width="120" prop="closeddischargelibrary"
          v-if="checkList.indexOf('收卸库') != -1">
        </el-table-column>
        <el-table-column align="center" label="流向" width="80" prop="belongIdName" v-if="checkList.indexOf('流向') != -1">
        </el-table-column>
        <el-table-column align="center" label="油品" width="150" prop="oilTypeName" v-if="checkList.indexOf('油品') != -1">
        </el-table-column>
        <el-table-column align="center" label="原发量" width="120" prop="shipErpAmount"
          v-if="checkList.indexOf('原发量') != -1">
        </el-table-column>
        <el-table-column align="center" label="出厂船板量" width="120" prop="plankingTon"
          v-if="checkList.indexOf('出厂船板量') != -1">
        </el-table-column>
        <el-table-column align="center" label="到库船板量" width="120" prop="oildischargeplankingTon"
          v-if="checkList.indexOf('到库船板量') != -1">
        </el-table-column>
        <el-table-column align="center" label="验收量" width="80" prop="realTon" v-if="checkList.indexOf('验收量') != -1">
        </el-table-column>
        <el-table-column align="center" label="定损量" width="80" prop="confirmSum" v-if="checkList.indexOf('定损量') != -1">
        </el-table-column>
        <el-table-column align="center" label="定损率" width="80" prop="freeRatePercent"
          v-if="checkList.indexOf('定损率') != -1">
        </el-table-column>
        <el-table-column align="center" label="损溢量" width="80" prop="totalSum" v-if="checkList.indexOf('损溢量') != -1">
        </el-table-column>
        <el-table-column align="center" label="损溢率" width="80" prop="totalSumPercent"
          v-if="checkList.indexOf('损溢率') != -1">
        </el-table-column>
        <el-table-column align="center" label="超耗量" width="80" prop="overclaimSum" v-if="checkList.indexOf('超耗量') != -1">
        </el-table-column>
        <el-table-column align="center" label="超耗率" width="80" prop="overclaimSumPercent"
          v-if="checkList.indexOf('超耗率') != -1">
        </el-table-column>
        <el-table-column align="center" label="索赔量" width="80" prop="claimSum" v-if="checkList.indexOf('索赔量') != -1">
          <template slot-scope="scope">
            <div>{{ scope.row.claimSum || 0 }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="承运商" width="80" prop="carrierstitle" v-if="checkList.indexOf('承运商') != -1">
        </el-table-column>
        <el-table-column align="center" label="索赔单价" width="80" prop="practicalprice"
          v-if="checkList.indexOf('索赔单价') != -1">
          <template slot-scope="scope">
            <div>{{ scope.row.practicalprice || 0 }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="索赔金额" width="80" prop="practicalprice"
          v-if="checkList.indexOf('索赔金额') != -1">
          <template slot-scope="scope">
            <div>{{ (scope.row.claimSum * scope.row.practicalprice).toFixed(2) || 0 }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="索赔状态" width="80" prop="book_title" v-if="checkList.indexOf('索赔状态') != -1">
        </el-table-column> -->
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.forbidClaim == 1" type="primary" size="mini"
              @click="spbh(scope.row)">索赔驳回</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 0" type="primary" size="mini"
              @click="fqsp(scope.row)">发起索赔</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 10" type="primary" size="mini"
              @click="cyssp(scope.row)">承运商审批</el-button>
            <el-button
              v-else-if="scope.row.claimcompensationInfostate == 11 || scope.row.claimcompensationInfostate == 13 || scope.row.claimcompensationInfostate == 15 || scope.row.claimcompensationInfostate == 18"
              type="primary" size="mini" @click="xgsp(scope.row)">修改索赔</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 16" type="primary" size="mini"
              @click="qrjg(scope.row)">录入单价</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 17" type="primary" size="mini"
              @click="xgdj(scope.row)">修改单价</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 19" type="primary" size="mini"
              @click="updatePz(scope.row)">上传凭证</el-button>
            <el-button v-else-if="scope.row.claimcompensationInfostate == 21" type="primary" size="mini"
              @click="surePlay(scope.row)">确认赔付</el-button>
            <el-button v-loading="vbtnloading8 == scope.row.id" type="primary" size="mini" @click="lookStep(scope.row)">查看进度</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div> -->

      <!-- 发起索赔 -->
      <el-dialog :close-on-click-modal="false" title="发起索赔" :visible.sync="dialogVisible" destroy-on-close top="30vh"
        width="550px">
        <div class="">
          <el-form ref="formAdd" :model="createData" label-width="130px">
            <el-form-item label="索赔量：" prop="claimSum">
              <el-input v-model="createData.claimSum" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="fqspSubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 承运商审批索赔申请 -->
      <el-dialog :close-on-click-modal="false" title="承运商审批索赔申请" :visible.sync="dialogVisible2" destroy-on-close
        top="30vh" width="550px">
        <div class="">
          <el-form ref="formAdd" :model="createData" label-width="130px">
            <el-form-item label="索赔量：" prop="claimSum">
              <el-input v-model="createData.claimSum" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item label="审核备注：" prop="auditremark">
              <el-input v-model="createData.auditremark" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="danger" @click="cysspReject">不通过</el-button>
          <el-button type="primary" @click="cysspPass">通过</el-button>
        </span>
      </el-dialog>
      <!-- 修改索赔申请 -->
      <el-dialog :close-on-click-modal="false" title="修改索赔申请" :visible.sync="dialogVisible3" destroy-on-close top="30vh"
        width="550px">
        <div class="">
          <el-form ref="formAdd" :model="createData" :rules="rulesxgsp" label-width="130px">
            <el-form-item label="索赔量：" prop="claimSum">
              <el-input v-model="createData.claimSum" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item label="审核意见：" prop="auditremark">
              <el-input v-model="createData.auditremark" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="cysxgspubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 综合部填写单价 -->
      <el-dialog :close-on-click-modal="false" title="综合部填写单价" :visible.sync="dialogVisible4" destroy-on-close top="30vh"
        width="550px">
        <div class="">
          <el-form ref="formAdd" :model="createData" :rules="rulestxdj" label-width="130px">
            <el-form-item label="索赔单价：" prop="practicalprice">
              <el-input v-model="createData.practicalprice" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible4 = false">取 消</el-button>
          <el-button type="primary" @click="txdjsubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 修改赔付单价 -->
      <el-dialog :close-on-click-modal="false" title="修改赔付单价" :visible.sync="dialogVisible5" destroy-on-close top="30vh"
        width="550px">
        <div class="addoreidt">
          <el-form ref="formAdd" :model="createData" :rules="rulestxdj" label-width="130px">
            <el-form-item label="索赔单价：" prop="practicalprice">
              <el-input v-model="createData.practicalprice" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible5 = false">取 消</el-button>
          <el-button type="primary" @click="txdjsubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 承运商上传凭证 -->
      <el-dialog :close-on-click-modal="false" title="承运商上传凭证" :visible.sync="dialogVisible6" destroy-on-close top="30vh"
        width="550px">
        <div class="">
          <el-form ref="formAdd" :model="createData" :rules="rulescysscpz" label-width="130px">
            <el-form-item label="索赔金额：" prop="practicalprice">
              <el-input v-model="createData.practicalprice" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="赔付凭证：" prop="accessory">
              <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                <el-button type="primary" style="margin: 8px 10px;" v-loading="btnLoading" size="mini"
                  icon="el-icon-upload2">上传附件</el-button>
              </el-upload>
              <span v-if="createData.accessory != ''" @click="lookFile(createData.accessory)"
                style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
            </el-form-item>
            <el-form-item label="凭证说明：" prop="pzexplain">
              <el-input v-model="createData.pzexplain" placeholder="请输入凭证说明" size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible6 = false">取 消</el-button>
          <el-button type="primary" @click="cysscpzsubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 确认赔付 -->
      <el-dialog :close-on-click-modal="false" title="确认赔付" :visible.sync="dialogVisible7" destroy-on-close top="30vh"
        width="550px">
        <div class="addoreidt">
          <el-form ref="formAdd" :model="createData" label-width="130px">
            <el-form-item label="索赔金额：" prop="practicalprice">
              <el-input v-model="createData.practicalprice" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="赔付凭证：" prop="accessory">
              <span v-if="createData.accessory != ''" @click="lookFile(createData.accessory)"
                style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
            </el-form-item>
            <el-form-item label="凭证说明：" prop="pzexplain">
              <el-input v-model="createData.pzexplain" placeholder="请输入凭证说明" disabled size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible7 = false">取 消</el-button>
          <el-button type="primary" @click="sueplaysubmit">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 查看进度 -->
      <el-dialog :close-on-click-modal="false" title="查看进度" :visible.sync="dialogVisible8" destroy-on-close top="15vh">
        <div class="addoreidt">
          <el-steps direction="vertical" :active="1" :space="80" v-if="logData.length != 0">
            <el-step v-for="(item, index) in logData" :title="item.createTime" :description="item.title"></el-step>
          </el-steps>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible8 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible8 = false">确 定</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">月份</th>
              <th class="tg-0lax">发运时间</th>
              <th class="tg-0lax">收卸时间</th>
              <th class="tg-0lax">在途天数</th>
              <th class="tg-0lax">处理时间</th>
              <th class="tg-0lax">船名</th>
              <th class="tg-0lax">发港</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">收卸库</th>
              <th class="tg-0lax">流向</th>
              <th class="tg-0lax">油品</th>
              <th class="tg-0lax">原发量</th>
              <th class="tg-0lax">出厂船板量</th>
              <th class="tg-0lax">到库船板量</th>
              <th class="tg-0lax">验收量</th>
              <th class="tg-0lax">定损量</th>
              <th class="tg-0lax">定损率</th>
              <th class="tg-0lax">损溢量</th>
              <th class="tg-0lax">损溢率</th>
              <th class="tg-0lax">超耗量</th>
              <th class="tg-0lax">超耗率</th>
              <th class="tg-0lax">索赔量</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">索赔单价</th>
              <th class="tg-0lax">索赔金额</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.monthlydate }}</th>
            <th class="tg-0lax">{{ item.startTime }}</th>
            <th class="tg-0lax">{{ item.arrivalTime }}</th>
            <th class="tg-0lax">{{ item.onLineDays }}</th>
            <th class="tg-0lax">{{ item.arrivalTimeEntering }}</th>
            <th class="tg-0lax">{{ item.shiptitle }}</th>
            <th class="tg-0lax">{{ item.startPortName }}</th>
            <th class="tg-0lax">{{ item.destPortName }}</th>
            <th class="tg-0lax">{{ item.closeddischargelibrary }}</th>
            <th class="tg-0lax">{{ item.belongIdName }}</th>
            <th class="tg-0lax">{{ item.oilTypeName }}</th>
            <th class="tg-0lax">{{ item.shipErpAmount }}</th>
            <th class="tg-0lax">{{ item.plankingTon }}</th>
            <th class="tg-0lax">{{ item.oildischargeplankingTon }}</th>
            <th class="tg-0lax">{{ item.realTon}}</th>
            <th class="tg-0lax">{{ item.confirmSum }}</th>
            <th class="tg-0lax">{{ item.freeRatePercent }}</th>
            <th class="tg-0lax">{{ item.totalSum }}</th>
            <th class="tg-0lax">{{ item.totalSumPercent }}</th>
            <th class="tg-0lax">{{ item.overclaimSum }}</th>
            <th class="tg-0lax">{{ item.overclaimSumPercent }}</th>
            <th class="tg-0lax">{{ item.claimSum }}</th>
            <th class="tg-0lax">{{ item.carrierstitle }}</th>
            <th class="tg-0lax">{{ item.practicalprice || 0 }}</th>
            <th class="tg-0lax">{{ (item.claimSum * item.practicalprice).toFixed(2) || 0 }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ExceedClaimant",
  data() {
    return {
      checkList: ['序号', '月份', '发运时间', '收卸时间', '在途天数', '处理时间', '船名', '发港', '到港', '收卸库', '流向', '油品', '原发量', '出厂船板量', '到库船板量', '验收量', '定损量', '定损率', '损溢量', '损溢率', '超耗量', '超耗率', '索赔量', '承运商', '索赔单价', '索赔金额'],
      checkListData: ['序号', '月份', '发运时间', '收卸时间', '在途天数', '处理时间', '船名', '发港', '到港', '收卸库', '流向', '油品', '原发量', '出厂船板量', '到库船板量', '验收量', '定损量', '定损率', '损溢量', '损溢率', '超耗量', '超耗率', '索赔量', '承运商', '索赔单价', '索赔金额'],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      userInfo: {},
      multipleSelection: [],
      searchForm: {
        isconfirmprice: '',
        ispaysingle: '',
        carrierId: '',
        dateResult: []
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        lastPage: 1,
      },
      btnLoading: false,
      tableData: [],
      dict: {//数据字典
        carrierList: [],
        companyList: [],
        monthlyList: [],
        oilList: [],
        shipList: [],
        shipPortBegin: [],
        shipPortEnd: [],
        shipPortList: []
      },
      createData: {
        rowData: {},
        shipErpAmount: '',
        realTon: '',
        feerate: '',
        claimSum: '',
        auditremark: '',
        practicalprice: '',
        accessory: '',
        pzexplain: '',
        id: "",
        claimMoney: '',
        type: ''
      },
      rulesFqsp: {
        claimSum: { required: true, message: '必填项不能为空', trigger: 'blur' },
        auditremark: { required: true, message: '必填项不能为空', trigger: 'blur' },
      },
      rulesxgsp: {
        claimSum: { required: true, message: '必填项不能为空', trigger: 'blur' },
      },
      rulestxdj: {
        practicalprice: { required: true, message: '必填项不能为空', trigger: 'blur' },
      },
      rulescysscpz: {
        accessory: { required: true, message: '必填项不能为空', trigger: 'blur' },
        pzexplain: { required: true, message: '必填项不能为空', trigger: 'blur' }
      },
      logData: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      vbtnloading8:-1,
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = now.getDate();
    if (date < 10) date = '0' + date;
    this.searchForm.dateResult[0] = year + '-' + month + '-' + date
    this.searchForm.dateResult[1] = year + '-' + month + '-' + date
    let vuex = localStorage.getItem('vuex')
    this.userInfo = JSON.parse(vuex).userInfo
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getDict()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    lookStep(row) {
      console.log(row);
      this.vbtnloading8 = row.id
      let data = {
        id: row.id,
        tableName: 'claimcompensationInfo'
      }
      this.$Api.several_quality_getsys_LogDataByid(data).then(res => {
        this.vbtnloading8 = -1
        if (res.code == 200) {
          this.logData = res.data
          this.dialogVisible8 = true
        }
      })
    },
    sueplaysubmit() {
      let data = {
        auditid: this.userInfo.id,
        id: this.createData.rowData.claimcompensationInfoId,
        state: 22
      }
      this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
          this.dialogVisible7 = false
        }
      })
    },
    cysscpzsubmit() {
      if (this.createData.accessory == '') {
        this.$message, warning('请先上传赔付凭证')
        return
      }
      if (this.createData.pzexplain == '') {
        this.$message, warning('请先输入凭证说明')
        return
      }
      let data = {
        auditid: this.userInfo.id,
        id: this.createData.rowData.claimcompensationInfoId,
        state: 21,
        accessory: this.createData.accessory,
        pzexplain: this.createData.pzexplain
      }
      this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
          this.dialogVisible6 = false
        }
      })
    },
    beforeUpload() {
      this.btnLoading = true
    },
    handleError() {
      this.$message.error('上传失败，请重新上传！')
      this.btnLoading = false
    },
    onsuccess(response) {
      if (response.code == 200) {
        this.createData.accessory = response.data.save_path
      }
      this.btnLoading = false
    },
    lookFile(url) {
      window.open(url, '_blank');
    },
    txdjsubmit() {
      let data = {
        id: this.createData.rowData.claimcompensationInfoId,
        state: 17,
        auditid: this.userInfo.id,
        practicalprice: this.createData.practicalprice
      }
      this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
          this.dialogVisible4 = false
        }
      })
    },
    cysxgspubmit() {
      let data = {
        id: this.createData.rowData.claimcompensationInfoId,
        state: 10,
        auditid: this.userInfo.id,
        claimSum: this.createData.claimSum
      }
      this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
          this.dialogVisible3 = false
        }
      })
    },
    cysspReject() {
      if (this.createData.auditremark == '') {
        this.$message.warning('审核备注为空，无法提交！')
        return
      }
      this.$confirm('确认要不通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: this.createData.rowData.claimcompensationInfoId,
          state: 11,
          auditremark: this.createData.auditremark,
          auditid: this.userInfo.id
        }
        this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
            this.dialogVisible2 = false
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    cysspPass() {
      this.$confirm('确认要通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: this.createData.rowData.claimcompensationInfoId,
          state: 12,
          auditid: this.userInfo.id,
          auditremark: this.createData.auditremark,
          titleremark: this.createData.rowData.shiptitle + "[" + this.createData.rowData.startPortName + "-" + this.createData.rowData.destPortName + "]"
        }
        this.$Api.several_quality_updateclaimcompensationInfo(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
            this.dialogVisible2 = false
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    fqspSubmit() {
      if (this.createData.claimSum == '') {
        this.$message.warning('请输入必填项')
        return
      }
      let data = {
        shipplanId: this.createData.id,
        claimcompensation: this.createData.claimMoney,
        claimSum: this.createData.claimSum,
        practicalprice: 0
      }
      this.$Api.several_quality_insertClaimCompensationInfo(data).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.getData()
          this.dialogVisible = false
        }
      })
    },
    fqsp(row) {
      this.createData.rowData = row
      this.createData.claimSum = ((row.shipErpAmount - row.realTon) - (row.feerate * row.shipErpAmount)).toFixed(3)
      this.createData.id = row.id
      this.createData.claimMoney = row.claimMoney
      // this.createData.auditremark = row.auditremark
      this.dialogVisible = true
    },
    cyssp(row) {
      this.createData.rowData = row
      this.createData.claimSum = row.claimSum
      this.createData.auditremark = ''
      this.dialogVisible2 = true
    },
    xgsp(row) {
      this.createData.rowData = row
      this.createData.claimSum = row.claimSum
      this.createData.auditremark = row.auditremark
      this.dialogVisible3 = true
    },
    qrjg(row) {
      this.createData.rowData = row
      this.createData.practicalprice = row.practicalprice
      this.dialogVisible4 = true
    },
    xgdj(row) {
      this.createData.rowData = row
      this.createData.practicalprice = row.practicalprice
      this.dialogVisible5 = true
    },
    updatePz(row) {
      this.createData.rowData = row
      this.createData.practicalprice = (row.practicalprice * row.claimSum).toFixed(2)
      this.createData.pzexplain = ''
      this.createData.accessory = ''
      this.dialogVisible6 = true
    },
    surePlay(row) {
      this.createData.rowData = row
      this.createData.practicalprice = row.practicalprice
      this.createData.pzexplain = row.pzexplain
      this.createData.accessory = row.accessory
      this.dialogVisible7 = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    bacth() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选数据项')
        return
      }
      let data = {
        payments: this.multipleSelection
      }
      this.$Api.several_quality_getshipPlanGroupBycarrierstitle(data).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功")
          this.getData()
        }
      })
    },
    dropdownChange(e) {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout()
      })
    },
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.clsjlg = item.arrivalTimeEntering.split(' ')[0]
            item.spjelg = (item.claimSum * item.practicalprice).toFixed(2) || 0
            tableData.push(item)
          })
          let config = [
            { field: 'monthlydate', width: 80, align: 'center', title: '月份' },
            { field: 'startTime', width: 80, align: 'center', title: '发运时间' },
            { field: 'arrivalTime', width: 80, align: 'center', title: '收卸时间' },
            { field: 'onLineDays', width: 150, align: 'center', title: '在途天数' },
            { field: 'clsjlg', width: 150, align: 'center', title: '处理时间' },
            { field: 'shiptitle', width: 150, align: 'center', title: '船名' },
            { field: 'startPortName', width: 80, align: 'center', title: '发港' },
            { field: 'destPortName', width: 80, align: 'center', title: '到港' },
            { field: 'closeddischargelibrary', width: 80, align: 'center', title: '收卸库' },
            { field: 'belongIdName', width: 80, align: 'center', title: '流向' },
            { field: 'oilTypeName', width: 100, align: 'center', title: '油品' },
            { field: 'shipErpAmount', width: 80, align: 'center', title: '原发量' },
            { field: 'plankingTon', width: 160, align: 'center', title: '出厂船板量' },
            { field: 'oildischargeplankingTon', width: 160, align: 'center', title: '到库船板量' },
            { field: 'realTon', width: 160, align: 'center', title: '验收量' },
            { field: 'confirmSum', width: 200, align: 'center', title: '定损量' },
            { field: 'freeRatePercent', width: 200, align: 'center', title: '定损率' },
            { field: 'totalSum', width: 160, align: 'center', title: '损溢量' },
            { field: 'totalSumPercent', width: 160, align: 'center', title: '损溢率' },
            { field: 'overclaimSum', width: 160, align: 'center', title: '超耗量' },
            { field: 'overclaimSumPercent', width: 160, align: 'center', title: '超耗率' },
            { field: 'claimSum', width: 160, align: 'center', title: '索赔量' },
            { field: 'carrierstitle', width: 160, align: 'center', title: '承运商' },
            { field: 'practicalprice', width: 160, align: 'center', title: '索赔单价' },
            { field: 'spjelg', width: 160, align: 'center', title: '索赔金额' },
          ]
          let obj = {
            name: '超耗索赔',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if (month < 10) month = '0' + month;
        let date = now.getDate();
        if (date < 10) date = '0' + date;
        this.searchForm.dateResult[0] = year + '-' + month + '-' + date
        this.searchForm.dateResult[1] = year + '-' + month + '-' + date
        this.getData()
      }
    },
    getDict() {
      this.$Api.shipPlan_Dict().then(res => {
        if (res.code == 200) {
          this.dict.carrierList = res.data.carrierList
          this.dict.companyList = res.data.companyList
          this.dict.monthlyList = res.data.monthlyList
          this.dict.oilList = res.data.oilList
          this.dict.shipList = res.data.shipList
          this.dict.shipPortBegin = res.data.shipPortBegin
          this.dict.shipPortEnd = res.data.shipPortEnd
          this.dict.shipPortList = res.data.shipPortList
        }
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: 999,
        startdate: this.searchForm.dateResult[0] ? this.searchForm.dateResult[0] : '',
        overdate: this.searchForm.dateResult[1] ? this.searchForm.dateResult[1] : '',
        isconfirmprice: this.searchForm.isconfirmprice,
        ispaysingle: this.searchForm.ispaysingle,
        carrierId: this.searchForm.carrierId,
      }
      this.tableLoading = true
      this.$Api.several_quality_getshipPlan(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = Number(res.data.total);
          this.tableData = res.data.list
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.ExceedClaimant {
  height: initial;

  .container {
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }

  .addoreidt {
    height: 50vh;
    overflow-y: scroll;

    /deep/.el-steps {
      height: auto !important;
    }
  }
}
</style>