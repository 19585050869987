<template>
	<div class="page u-flex">
		<div class="left">
			<el-scrollbar class="u-row-nowrap" style="height: calc(100vh - 50px)">
				<el-tree :data="treeList" :expand-on-click-node="false" :default-expand-all="true" :props="defaultProps"
					node-key="id" @node-click="selectTree"></el-tree>
			</el-scrollbar>
		</div>
		<el-scrollbar class="u-row-nowrap" style="height: calc(100vh - 50px); width:100%">
			<div class="right">
				<el-form :model="searchForm" class="search-form" inline>
					<el-form-item label="用户名称：">
						<el-input v-model="searchForm.name" placeholder="请输入用户名称" size="small"></el-input>
					</el-form-item>
					<el-form-item label="状态：">
						<el-select clearable v-model="searchForm.is_hid" placeholder="用户状态" size="small">
							<el-option :value="0" label="启用"></el-option>
							<el-option :value="1" label="禁用"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户角色：">
						<el-select clearable v-model="searchForm.role_id" placeholder="请选择用户角色" size="small">
							<el-option v-for="item in roleList" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
							@click="searchTable('search')">搜索
						</el-button>
						<el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
							type="primary" @click="searchTable('reset')">重置
						</el-button>
					</el-form-item>
				</el-form>
				<div class="operate-box u-flex u-col-center">
					<el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
						@click="create">新增
					</el-button>
				</div>
				<el-table v-loading="loading" :data="tableData"
					:tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
					header-row-class-name="header-table">
					<el-table-column label="用户编号" prop="id" width="90">
					</el-table-column>
					<el-table-column label="登录名称" prop="user_name">
					</el-table-column>
					<el-table-column label="用户昵称" prop="nickname">
					</el-table-column>
					<el-table-column label="组织部门">
						<span slot-scope="scope">{{ scope.row.struct && scope.row.struct.name }}</span>
					</el-table-column>
					<el-table-column label="角色分组">
						<span slot-scope="scope">{{ scope.row.role && scope.row.role[0].name }}</span>
					</el-table-column>
					<el-table-column label="手机号" prop="mobile" width="120">
					</el-table-column>
					<el-table-column label="状态" width="64" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.is_hid === 0" size="small">正常</el-tag>
							<el-tag v-else size="small" type="info">禁用</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" prop="add_time">
					</el-table-column>
					<el-table-column label="操作" width="150" fixed="right">
						<template slot-scope="scope">
							<span class="o-btn" @click="edit(scope.row)">
								<el-icon class="el-icon-edit"></el-icon>
								编辑
							</span>
							<span v-if="scope.row.id !== $store.state.userInfo.id" class="o-btn o-btn-red"
								@click="remove(scope.row.id)">
								<el-icon class="el-icon-delete"></el-icon>
								删除
							</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-flex u-row-end u-p-t-24 u-p-b-24">
					<el-pagination :current-page="page" :page-size="size" :total="total"
						layout="total,prev, pager, next, jumper" @current-change="changePage">
					</el-pagination>
				</div>
			</div>
		</el-scrollbar>
		<el-dialog :close-on-click-modal="false" :title="mode === 'add' ? '添加用户' : '编辑用户'" :visible.sync="dialogVisible"
			destroy-on-close width="600px" @closed="resetCreateData">
			<div>
				<el-form ref="createForm" :model="createData" :rules="createRules" class="create-form" label-width="80px">
					<el-row>
						<el-col :span="11">
							<el-form-item label="用户昵称" prop="nickname">
								<el-input v-model="createData.nickname" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11" :offset="2">
							<el-form-item label="角色分组" prop="role_ids">
								<el-select v-model="createData.role_ids" placeholder="请选择用户角色" size="small">
									<el-option v-for="item in roleList" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

					</el-row>
					<el-row>
						<el-col :span="11">
							<el-form-item label="手机号码" prop="mobile">
								<el-input v-model="createData.mobile" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11" offset="2">
							<el-form-item label="邮箱" prop="email">
								<el-input v-model="createData.email" size="small"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex" class="row-bg" justify="space-between">

						<el-col :span="11" v-show="(userType == 'system') || (rolekey == 'sd_admin')">
							<el-form-item label="归属部门" prop="struct_id">
								<tree-select v-model="createData.struct_id" :list="treeList"></tree-select>
								<!-- <el-cascader v-model="createData.struct_id"
									:props="{ label: 'name', value: 'parent_id', children: 'child' }" :options="treeList"
									:show-all-levels="false" filterable></el-cascader> -->
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<!-- 暂时注释 -->
							<!-- <el-form-item >
								<el-select v-model="createData.department_ids" clearable  filterable multiple placeholder="请选择图书部门" size="small">
									<el-option v-for="item in department" :label="item.label" :value="item.id"></el-option>
								</el-select>
							</el-form-item> -->
						</el-col>

					</el-row>
					<el-row>
						<el-col :span="11">
							<el-form-item label="登录名称" prop="user_name">
								<el-input v-model="createData.user_name" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11" offset="2">
							<el-form-item label="用户密码" prop="password">
								<el-input v-model="createData.password" show-password size="small" type="password">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="用户状态">
						<el-radio-group v-model="createData.is_hid">
							<el-radio v-for="item in menuRoots" :label="item.id">{{ item.title }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="用户类型" v-show="userType == 'system'">
						<el-radio-group v-model="createData.type" :disabled="userType !== 'system'">
							<el-radio v-for="item in typeList" :label="item.id">{{ item.title }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="u-custom-btn-primary" type="primary" @click="createMenu">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "User",
	data() {
		let checkMobile = function (rule, value, callback) {
			let reg = /^((\+|00)86)?1\d{10}$/
			let _reg = /^(0\d{2,3}\-)?([2-9]\d{6,7})+(\-\d{1,6})?$/
			if (value && !reg.test(value) && !_reg.test(value)) return callback(new Error('手机号格式不符合规范'))
			callback()
		}
		let checkEmail = function (rule, value, callback) {
			let reg = /^\w+@[a-zA-Z\d]{2,10}(?:\.[a-z]{2,4}){1,3}$/
			if (value && !reg.test(value)) return callback(new Error('邮箱格式不符合规范'))
			callback()
		}
		return {
			page: 1,
			size: 20,
			total: 0,
			mode: 'add',
			loading: false,
			dialogVisible: false,
			id: '',
			treeList: [],
			roleList: [],
			createData: {
				type: 'system',
				user_name: '',
				struct_id: '',
				role_ids: '',
				password: '',
				nickname: '',
				mobile: '',
				email: '',
				is_hid: 0,
				department_ids: []
			},
			createRules: {
				name: {
					required: true,
					message: '请输入部门名称',
					trigger: ['blur', 'change']
				},
				nickname: {
					required: true,
					message: '请输入用户昵称',
					trigger: ['blur', 'change']
				},
				struct_id: {
					required: true,
					message: '请选择归属部门',
					trigger: 'change'
				},
				role_ids: {
					required: true,
					message: '请选择角色',
					trigger: 'change'
				},
				user_name: {
					required: true,
					message: '请输入用户名称',
					trigger: 'change'
				},
				password: {
					required: true,
					message: '请输入登录密码',
					trigger: 'change'
				},
				mobile: {
					required: true,
					message: '请输入手机号',
					trigger: 'change'
				},
				email: {
					validator: checkEmail,
					trigger: 'blur'
				}
			},
			defaultProps: {
				children: 'child',
				label: 'name',
				value: 'id'
			},
			typeList: [{
				title: '管理员',
				id: 'system'
			}, {
				title: '学校',
				id: 'school'
			}, {
				title: '书店',
				id: 'bstore'
			}, {
				id: 'bookman',
				title: '出版社(书商)'
			}, {
				id: 'supplier',
				title: '供应商'
			}
			],
			menuRoots: [{
				title: '正常',
				id: 0
			}, {
				title: '禁用',
				id: 1
			}],
			searchForm: {
				name: '',
				struct_id: '',
				role_id: '',
				is_hid: ''
			},
			department: [],
			tableData: [],
			struct_ids: [],
			rolekey: '',
			user_Type: ''
		}
	},
	created() {
		this.createData.type = this.userType
		let vuex = localStorage.getItem('vuex')
		this.rolekey = JSON.parse(vuex).userInfo.role[0].rolekey //获取角色信息
		this.user_Type = JSON.parse(vuex).userInfo.type
		// this.getData()
		this.getInfo()
		this.getdepartment()
		console.log('刷新');
	},
	activated() {
		this.createData.type = this.userType
		let vuex = localStorage.getItem('vuex')
		this.rolekey = JSON.parse(vuex).userInfo.role[0].rolekey //获取角色信息
		this.user_Type = JSON.parse(vuex).userInfo.type
		// this.getData()
		this.getInfo()
		this.getdepartment()
		console.log('刷新A');
	},
	methods: {
		setArr(o) {
			if (o.child) {
				o.child.map(v => this.setArr(v))
			}
			this.struct_ids.push(o.id)
		},
		selectTree(e) {
			this.struct_ids = []
			this.setArr(e)
			this.searchForm.struct_id = this.struct_ids.join(',')
			// this.searchForm.struct_id = e.id
			this.page = 1
			this.getData()
		},
		getInfo() {
			let data = {
				name: '',
				is_hid: 0,
				is_tree: 1,
				has_self: 1
			}
			let _data = {
				name: '',
				is_hid: 0
			}
			let arr = [this.$http.get('/backend/struct/child', {
				params: data
			}), this.$http.get('/backend/role/list', {
				params: _data
			})]
			Promise.all(arr).then(res => {
				if (res[0].code === 1) {
					this.treeList = res[0].data.list
					this.setArr(this.treeList[0])
					console.log(!this.treeList[0]?.child);
					if (!this.treeList[0]?.child) {
						this.createData.struct_id = this.treeList[0].id
					}
					this.searchForm.struct_id = this.struct_ids.join(',')
					// this.searchForm.struct_id = this.treeList[0].id
					this.getData()
				}
				if (res[1].code === 1) {
					this.roleList = res[1].data.list
				}
				console.log(this.treeList, 'this.treeList');
			})
		},
		searchTable(t) {
			this.page = 1
			if (t === 'search') {
				this.getData()
			} else {
				this.searchForm = {
					name: '',
					struct_id: '',
					role_id: '',
					is_hid: ''
				}
				this.getData()
			}
		},
		edit(e) {
			this.id = e.id
			this.mode = 'edit'
			delete this.createRules.password
			if (Object.prototype.toString.call(e.role) === '[object Array]' && e.role.length > 0) {
				this.createData.role_ids = e.role[0].id
			}
			if (Object.prototype.toString.call(e.struct) === '[object Object]') {
				this.createData.struct_id = e.struct.id
			}
			for (let k in e) {
				if (this.createData.hasOwnProperty(k) && k !== 'password') {
					this.createData[k] = e[k]
				}
			}
			this.createData.department_ids = e.department_ids.split(',').map(Number)
			this.dialogVisible = true
		},
		create() {
			this.mode = 'add'
			this.createRules.password = {
				required: true,
				message: '请输入登录密码',
				trigger: 'change'
			}
			if (this.rolekey == 'sd_admin' || this.user_Type == 'system') {

				if (this.searchForm.struct_id != '') {
					let ss = this.searchForm.struct_id.split(',')
					if (ss.length == 1) {
						this.createData.struct_id = this.searchForm.struct_id
					}

				}
			}
			this.dialogVisible = true
		},
		remove(id) {
			this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id
				}
				this.$http.post('/backend/role_user/delete', data).then(res => {
					if (res.code === 1) {
						this.$message.success('删除成功')
						this.getData()
					}
				})
			})

		},
		resetCreateData() {
			this.createData = {
				type: this.userType,
				user_name: '',
				struct_id: '',
				role_ids: '',
				password: '',
				nickname: '',
				mobile: '',
				email: '',
				is_hid: 0
			}
			if (!this.treeList[0]?.child) {
				this.createData.struct_id = this.treeList[0].id
			}
			this.$refs['createForm'].resetFields()
		},
		createMenu() {
			let data = JSON.parse(JSON.stringify(this.createData))
			if (data?.department_ids) {
				if (data.department_ids.length != 0) {
					data.department_ids = data.department_ids.join(',')
				} else {
					data.department_ids = ''
				}
			}
			if (this.mode === 'add') {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.$http.post('/backend/role_user/create', data).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
							}
						})
					}
				})
			} else {
				if (!this.createData.password) {
					delete this.createData.password
				}
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						this.createData.id = this.id
						this.$http.post('/backend/role_user/update', this.createData).then(res => {
							if (res.code === 1) {
								this.dialogVisible = false
								this.getData()
							}
						})
					}
				})
			}

		},
		getData() {
			this.loading = true
			this.searchForm.page = this.page
			this.$http.get('/backend/role_user/list', {
				params: this.searchForm
			}).then(res => {
				if (res.code === 1) {
					let {
						data: list,
						total,
						per_page: size
					} = res.data.list
					this.tableData = list
					this.total = total
					this.size = size
				}
				this.loading = false
			})
		},
		getdepartment() {//获取图书部门
			this.$http.get('/api/department').then(res => {
				this.department = res.data.list.data
			})
		},
		changePage(e) {
			this.page = e
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	height: calc(100vh - 50px);
	overflow: hidden;
}

.left {
	min-height: calc(100vh - 50px);
	width: 240px;
	background: #fff;
	padding: 10px;

	.el-tree {
		padding-bottom: 20px;
	}
}

.right {
	min-height: calc(100vh - 50px);
	padding: 20px;
	flex: 1;
	overflow: hidden;
}

.el-table {
	margin-top: 24px;

	.o-btn {
		color: #409EFF;
		cursor: pointer;

		~.o-btn {
			margin-left: 12px;
		}
	}
}

.create-form {
	.el-form-item {
		margin-bottom: 20px;
	}
}
</style>
