<template>
  <div class="page shipQualifications">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="标题：">
          <el-input v-model="searchForm.search_appr_title" placeholder="请输入角色名称" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-check" size="small" type="primary"
            @click="batchpass">批量通过</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-close" size="small" type="danger"
            @click="batchrefuse">批量拒绝</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="类型" prop="approvalName">
        </el-table-column>
        <el-table-column align="center" label="标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="approve_states_text">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="myapproval.user_outcome_states_text">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookData(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row)">日志</el-button>
            <!-- <el-button type="primary" size="mini" @click="editerow(scope.row)">审批表</el-button> -->
            <el-button type="success" size="mini" @click="pass(scope.row)"
              v-if="scope.row.myapproval.user_outcome == 3 || (scope.row.approveStates == scope.row.myapproval.aprv_level && scope.row.myapproval.user_outcome == 1)">通过</el-button>
            <el-button type="danger" size="mini" @click="refuse(scope.row)"
              v-if="scope.row.approveStates == scope.row.myapproval.aprv_level">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 查看 -->
      <el-dialog :close-on-click-modal="false" title="查看" :visible.sync="dialogVisible" destroy-on-close top="15vh"
        width="650px">
        <div class="addoreidt">
          <el-form ref="formShip" :model="createData" label-width="130px">
            <el-form-item label="证书号码：" prop="No">
              <span>{{ createData.No || '' }}</span>
            </el-form-item>
            <el-form-item label="有效期至：" prop="Exp">
              <span>{{ createData.Exp || '' }}</span>
            </el-form-item>
            <el-form-item label="证书文件：" prop="file">
              <el-button type="primary" icon="el-icon-view" size="mini" @click="lookFile(createData.file)">文件预览</el-button>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
        <div class="addoreidt">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level_text">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="user_outcome_text">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="update_time">
              <template slot-scope="scope">
                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "shipQualifications",
  data() {
    return {
      multipleSelection: [],
      searchForm: {
        search_appr_title: '',
      },
      createData: {
        No: '',
        Exp: '',
        File: ''
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible3: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      approvalSet: [],
      carriers: [],
      myapprovalStatesList: [],
      logData: {}
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getDict()
    this.getType()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    lookFile(url) {
      window.open(url, '_blank');
    },
    getType() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.myapprovalStatesList = res.data.myapprovalStatesList
        }
      })
    },
    looklog(row) {
      let data = {
        id: row.id,
        type: 2
      }
      this.$Api.MyAppRovalPublic_LogList(data).then(res => {
        let level = ['未知', '一级', '二级', '三级'];
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
            if (this.myapprovalStatesList.length > 0) {
              this.myapprovalStatesList.map((key, kindex) => {
                if (item.user_outcome == kindex) {
                  item.user_outcome_text = key
                }
              })
            }
            item.aprv_level_text = level[item.aprv_level]
          })
        }
        res.data.logList = res.data.logList
        this.logData = res.data
        this.dialogVisible3 = true;
      })
    },
    batchpass() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选选项')
        return
      }
      let arr = []
      this.multipleSelection.map(item => {
        arr.push(item.id)
      })
      let data = {
        msg: '通过',
        type: 2,
        ids: arr.join(',')
      }
      this.$Api.myAppRovalPublicOkMyAppRovalList(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
        }
      })
    },
    batchrefuse() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选选项')
        return
      }
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: this.validator,
        inputErrorMessage: '拒绝原因不能为空'
      }).then(({ value }) => {
        if (value == '' || value == null) {
          this.$message.warning('请输入拒绝原因')
          return
        }
        let arr = []
        this.multipleSelection.map(item => {
          arr.push(item.id)
        })
        let data = {
          msg: value,
          type: 2,
          ids: arr.join(',')
        }
        this.$Api.myAppRovalPublicFailMyAppRovalList(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    pass(row) {
      let data = {
        table_id: row.id,
        user_outcome: 2,
        type: 2,
        msg: '通过'
      }
      this.$Api.MyAppRovalPublic_OkMyAppRoval(data).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.getData()
        }
      })
    },
    refuse(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value == '' || value == null) {
          this.$message.warning('请输入拒绝原因')
          return
        }
        let data = {
          table_id: row.id,
          user_outcome: 3,
          type: 2,
          msg: value
        }
        this.$Api.MyAppRovalPublic_FailMyAppRoval(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    lookData(row) {
      let fileType = row.fieldName.slice(0, -6)
      let data = {
        id: row.dataId,
        field1: fileType + 'No',
        field2: fileType + 'Exp',
        field3: fileType + 'File'
      }
      this.$Api.ShipApproval_view11ship(data).then(res => {
        if (res.code == 200) {
          this.createData.No = res.data[fileType + 'No']
          this.createData.Exp = res.data[fileType + 'Exp']
          this.createData.File = res.data[fileType + 'File']
          this.dialogVisible = true
        }
      })
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getDict() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.approvalSet = res.data.approvalSet
        }
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        type: 2,
        search_appr_title: this.searchForm.search_appr_title,
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      this.tableLoading = true
      this.$Api.MyAppRovalPublic_List(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.dataList.total) this.page.total = res.data.dataList.total;
          if (res.data.dataList.data.length > 0 && this.approvalSet.length > 0) {
            res.data.dataList.data.map(item => {
              this.approvalSet.map(key => {
                if (item.approveId == key.id) {
                  item.approvalName = key.title
                }
              })

            })
          }
          if (res.data.dataList.data.length > 0 && res.data.myapproval.length > 0) {
            res.data.dataList.data.map(item => {
              res.data.myapproval.map(key => {
                if (item.id == key.table_id) {
                  item.myapproval = key
                }
              })
            })
          }
          this.tableData = res.data.dataList.data
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.shipQualifications {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
    .addoreidt {
      height: 50vh;
      overflow-y: scroll;
      /deep/.el-steps{
        height: auto !important;
      }
    }
  }
}
</style>