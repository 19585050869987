<template>
    <div class="page Approva" v-loading="fullscreenLoading" element-loading-text="加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.4)">
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="类型" prop="approveId_text">
                </el-table-column>
                <el-table-column align="center" label="标题" prop="title">
                </el-table-column>
                <el-table-column align="center" label="审核状态" prop="approve_states_text">
                </el-table-column>
                <el-table-column align="center" label="我的审核" prop="states_text">
                </el-table-column>
                <el-table-column align="center" label="创建时间" prop="createTime">
                </el-table-column>
                <el-table-column align="center" label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">查看</el-button>
                        <el-button type="primary" size="mini" @click="looklog(scope.row)">日志</el-button>
                        <el-button
                            v-if="myapproval[scope.$index].user_outcome == 3 || (myapproval[scope.$index].user_outcome == 1 && scope.row.approveStates == myapproval[scope.$index].aprv_level)"
                            type="success" size="mini" @click="pass(scope.row, 2)">通过</el-button>
                        <el-button v-if="scope.row.approveStates == myapproval[scope.$index].aprv_level" type="danger"
                            size="mini" @click="pass(scope.row, 3)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加/编辑 -->
            <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加审批流程' : '编辑审批流程'" :visible.sync="dialogVisible"
                destroy-on-close top="15vh" width="600px">

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuse">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 日志 -->
            <el-dialog :close-on-click-modal="false" title="审核日志" :visible.sync="dialogVisible2" destroy-on-close
                top="15vh">
                <div class="dialogCont">
                    <el-steps direction="vertical" :active="1" :space="80">
                        <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
                            :description="item.username + '  ' + item.log_info"></el-step>
                    </el-steps>
                    <!-- 列表 -->
                    <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
                        tooltip-effect="dark" header-row-class-name="header-table">
                        <el-table-column align="center" label="审核级别" prop="aprv_level_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核人员" prop="username">
                        </el-table-column>
                        <el-table-column align="center" label="审核结果" prop="user_outcome_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核时间" prop="update_time">
                            <template slot-scope="scope">
                                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 查看资质 -->
            <el-dialog :close-on-click-modal="false" title="查看公文" :visible.sync="dialogVisible3" destroy-on-close top="20vh"
                width="700px">
                <div class="dialogCont">
                    <el-form :model="infoData" label-width="120px">
                        <el-form-item label="所有用户：" prop="receiverArr">
                            <span>{{ infoData.receiverArr || '暂无数据' }}</span>
                        </el-form-item>
                        <el-form-item label="公开范围：" prop="publicScopeArr">
                            <span>{{ infoData.publicScopeArr || '暂无数据' }}</span>
                        </el-form-item>
                        <el-form-item label="创建时间：" prop="createTime">
                            <span>{{ infoData.createTime }}</span>
                        </el-form-item>
                        <el-form-item label="已读用户：" prop="document_readArr">
                            <span>{{ infoData.document_readArr || '暂无数据' }}</span>
                        </el-form-item>
                        <el-form-item label="主题：" prop="title">
                            <span>{{ infoData.title }}</span>
                        </el-form-item>
                        <el-form-item label="正文：" prop="content">
                            <span v-html="infoData.content"></span>
                        </el-form-item>
                        <el-form-item label="附件：" prop="fileList" v-if="JSON.stringify(infoData) != '{}'">
                            <el-table v-if="infoData.fileList.length > 0" ref="multipleTable" border
                                :data="infoData.fileList" tooltip-effect="dark" style="width: 100%;margin:0 auto;"
                                header-row-class-name="header-table">
                                <el-table-column align="center" label="文件名" prop="title">
                                </el-table-column>
                                <el-table-column align="center" label="操作">
                                    <template slot-scope="scope">
                                        <div style="display: flex;align-items: center;justify-content: center;">
                                            <el-button type="primary" size="mini"
                                                @click="download(scope.row)">下载</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <span v-else>无附件</span>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
    
<script>
export default {
    name: "Approva",
    data() {
        return {
            fullscreenLoading: false,
            isAdd: false,
            createdData: {
            },
            rules: {

            },
            tableLoading: false,
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
            myapproval: [],
            approvalSet: [],
            approvalStatesList: [],
            myapprovalStatesList: [],
            logData: {},
            infoData: {},
            member: []
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        that.getDict()
        that.getmember()
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    mounted() {

    },
    methods: {
        download(row) {
            let alink = document.createElement('a');
            alink.style.display = 'none';
            alink.href = row.file;
            alink.target = '_blank';
            document.body.appendChild(alink);
            alink.click();
            document.body.removeChild(alink); //下载完成移除元素
        },
        looklog(row) {
            let data = {
                id: row.id,
            }
            this.$Api.documentGary_LogList(data).then(res => {
                let level = ['未知', '一级', '二级', '三级'];
                if (res.data.logList.length != 0) {
                    res.data.logList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                    })
                }
                if (res.data.outcomeList.length != 0) {
                    res.data.outcomeList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                        if (this.myapprovalStatesList.length > 0) {
                            this.myapprovalStatesList.map(key => {
                                if (item.user_outcome == key.id) {
                                    item.user_outcome_text = key.title
                                }
                            })
                        }
                        item.aprv_level_text = level[item.aprv_level]
                    })
                }
                res.data.logList = res.data.logList.reverse()
                this.logData = res.data
                this.dialogVisible2 = true;
            })
        },
        pass(row, id) {
            if (id == 2) {
                this.$confirm('确定是否通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        table_id: row.id,
                        user_outcome: id
                    }
                    this.$Api.documentGary_OkMyAppRoval(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.getData()
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            } else {
                this.$prompt('请输入拒绝原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: this.validator,
                    inputErrorMessage: '拒绝原因不能为空'
                }).then(({ value }) => {
                    if (value == '' || value == null) {
                        this.$message.warning('请输入拒绝原因')
                        return
                    }
                    let data = {
                        table_id: row.id,
                        user_outcome: 3,
                        msg: value
                    }
                    this.$Api.documentGary_FailMyAppRoval(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.getData()
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        },
        validator(e) {
            if (e == '' || e == null) {
                return false
            } else {
                return true
            }
        },
        subRefuse() {

        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow(row) {
            let data = {
                documentId: row.id
            }
            this.fullscreenLoading = true
            this.$Api.documentGary_SetDocumentRead(data).then(info => {  //代表已查看
                this.getData().then(data => {
                    let data2 = {
                        id: row.id,
                        table_name: 'document'
                    }
                    this.$Api.bases_getOne(data2).then(res => {
                        if (res.data.receiver != '' && res.data.receiver != 0 && res.data.receiver != null) {
                            let receiver = res.data.receiver.split(',')
                            let receiverArr = []
                            receiver.map(item => {
                                this.member.map(key => {
                                    if (item == key.id) {
                                        receiverArr.push(key.title)
                                    }
                                })
                            })
                            res.data.receiverArr = receiverArr.join(',')
                        }
                        if (res.data.publicScope != '' && res.data.publicScope != null && res.data.publicScope != 0) {
                            let publicScope = res.data.publicScope.split(',')
                            let publicScopeArr = []
                            publicScope.map(item => {
                                this.member.map(key => {
                                    if (item == key.id) {
                                        publicScopeArr.push(key.title)
                                    }
                                })
                            })
                            res.data.publicScopeArr = publicScopeArr.join(',')
                        }
                        if (data.data.documentList.data.length > 0) {
                            data.data.documentList.data.map(items => {
                                if (items.id == row.id) {
                                    if (items.document_read.length > 0) {
                                        let document_readArr = []
                                        items.document_read.map(item => {
                                            this.member.map(key => {
                                                if (item.memberId == key.id) {
                                                    document_readArr.push(key.title)
                                                }
                                            })
                                        })
                                        res.data.document_readArr = document_readArr.join(',')
                                    }
                                }
                            })
                        }
                        if (res.data.fileList != '') {
                            res.data.fileList = JSON.parse(res.data.fileList)
                        }
                        this.infoData = res.data
                        this.fullscreenLoading = false
                        this.dialogVisible3 = true
                    })
                })
            })

        },
        clearCreateData() {
            this.createdData = {
                id: "",
                title: '',
                type: 15,
                user1: [],
                user2: [],
                user3: []
            }
            this.$refs['form'].resetFields();
        },
        deleterow(row) {
            this.$confirm('确定删除该项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.approval_Delete(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        if (this.tableData.length == 1) this.page.currentPage = 1
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // --------------------------公共方法--------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getmember() {
            this.$Api.publicCharacter_Dict().then(res => {
                this.member = res.data.member
            })
        },
        getDict() {
            this.$Api.documentGary_DictMyAppRoval().then(res => {
                if (res.code == 200) {
                    this.approvalSet = res.data.approvalSet
                    this.approvalStatesList = res.data.approvalStatesList
                    this.myapprovalStatesList = res.data.myapprovalStatesList
                }
                this.getData()
            })
        },
        getData() { // 获取数据
            return new Promise((resolve, reject) => {
                let data = {
                    page: this.page.currentPage,
                    limit: this.page.pageSize
                }
                this.tableLoading = true
                this.$Api.documentGary_ListMyAppRoval(data).then(res => {
                    this.tableLoading = false
                    if (res.code == 200) {
                        if (res.data.documentList.total) this.page.total = res.data.documentList.total
                        if (res.data.documentList?.total) {
                            if (res.data.documentList.data.length > 0) {
                                res.data.documentList.data.map(item => {
                                    if (this.approvalSet.length > 0) {
                                        this.approvalSet.map(key => {
                                            if (item.approveId == key.id) {
                                                item.approveId_text = key.title
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        this.tableData = res.data.documentList.data
                        this.myapproval = res.data.myapproval
                        resolve(res)
                    }
                })
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.Approva {
    height: calc(100vh - 125px) !important;
    overflow-y: scroll;

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .menuBox {}

        .userBox {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .addoreidt {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-cascader {
                width: 100% !important;
            }
        }

        .dialogCont {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-steps--vertical {
                height: initial;
            }
        }
    }

}
</style>