<template>
    <div class="page UnloadOil">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="船舶名称：">
                    <el-input size="small" placeholder="请输入船舶名称" v-model="searchForm.shiptitle" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="承运商：">
                    <el-select v-model="searchForm.subtitleid" clearable placeholder="请选择承运商" size="small">
                        <el-option v-for="(item, index) in groupAll" :key="index" :value="item.id"
                            :label="item.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否已经卸油：" size="small">
                    <el-select v-model="searchForm.sfxy" clearable placeholder="请选择是否已经卸油" size="small">
                        <el-option value="1" label="是"></el-option>
                        <el-option value="2" label="否"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="到站：" size="small">
                    <el-select v-model="searchForm.destPort" clearable placeholder="请选择到站">
                        <el-option v-for="(item, index) in dict.shipPortEnd" :key="index" :value="item.id"
                            :label="item.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收卸时间：">
                    <el-date-picker v-model="searchForm.date" type="daterange" size="small" range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                max-height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="shiptitle">
                </el-table-column>
                <el-table-column align="center" label="省市公司" prop="belongIdName">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="carrierstitle">
                </el-table-column>
                <el-table-column align="center" label="下达时间" prop="shipPlancreateTimedata">
                </el-table-column>
                <el-table-column align="center" label="发港" prop="startPortName">
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPortName">
                </el-table-column>
                <el-table-column align="center" label="品号" prop="oilTypetitle">
                </el-table-column>
                <el-table-column align="center" label="计划量" prop="oilTon">
                </el-table-column>
                <el-table-column align="center" label="船板量" prop="plankingTon">
                </el-table-column>
                <el-table-column align="center" label="原发量" prop="shipErpAmount">
                </el-table-column>
                <el-table-column align="center" label="实收量" prop="realTon">
                </el-table-column>
                <el-table-column align="center" label="定损率" prop="feeratePercent">
                </el-table-column>
                <el-table-column align="center" label="定损量" prop="confirmSum">
                </el-table-column>
                <el-table-column align="center" label="损耗量" prop="totalSum">
                </el-table-column>
                <el-table-column align="center" label="损耗率" prop="totalSumPercent">
                </el-table-column>
                <el-table-column align="center" label="超耗量" prop="overclaimSum">
                </el-table-column>
                <el-table-column align="center" label="超耗率" prop="overclaimSumPercent">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="statesName">
                </el-table-column>
                <el-table-column align="center" label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="enter(scope.row)"
                            v-if="scope.row.states == 6">录入卸油量</el-button>
                        <div v-if="scope.row.states == 7 || scope.row.states == 8">
                            <el-button type="primary" size="mini" @click="lookDetails(scope.row)">查看</el-button>
                            <el-button type="primary" size="mini" @click="editeInfo(scope.row)"
                                v-if="scope.row.companyTonConfirmStatus == 2 || scope.row.companyTonConfirmStatus == 0">修改信息</el-button>
                            <el-button type="success" size="mini" @click="againEnter(scope.row)"
                                v-if="scope.row.companyTonConfirmStatus == 2">重新录入</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">省市公司</th>
                            <th class="tg-0lax">承运商</th>
                            <th class="tg-0lax">下达时间</th>
                            <th class="tg-0lax">发港</th>
                            <th class="tg-0lax">到港</th>
                            <th class="tg-0lax">品号</th>
                            <th class="tg-0lax">计划量</th>
                            <th class="tg-0lax">船板量</th>
                            <th class="tg-0lax">原发量</th>
                            <th class="tg-0lax">实收量</th>
                            <th class="tg-0lax">定损率</th>
                            <th class="tg-0lax">定损量</th>
                            <th class="tg-0lax">损耗量</th>
                            <th class="tg-0lax">损耗率</th>
                            <th class="tg-0lax">超耗量</th>
                            <th class="tg-0lax">超耗率</th>
                            <th class="tg-0lax">状态</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.shiptitle }}</th>
                        <th class="tg-0lax">{{ item.belongIdName }}</th>
                        <th class="tg-0lax">{{ item.carrierstitle }}</th>
                        <th class="tg-0lax">{{ item.shipPlancreateTimedata }}</th>
                        <th class="tg-0lax">{{ item.startPortName }}</th>
                        <th class="tg-0lax">{{ item.destPortName }}</th>
                        <th class="tg-0lax">{{ item.oilTypetitle }}</th>
                        <th class="tg-0lax">{{ item.oilTon }}</th>
                        <th class="tg-0lax">{{ item.plankingTon }}</th>
                        <th class="tg-0lax">{{ item.shipErpAmount }}</th>
                        <th class="tg-0lax">{{ item.realTon }}</th>
                        <th class="tg-0lax">{{ item.feeratePercent }}</th>
                        <th class="tg-0lax">{{ item.confirmSum }}</th>
                        <th class="tg-0lax">{{ item.totalSum }}</th>
                        <th class="tg-0lax">{{ item.totalSumPercent }}</th>
                        <th class="tg-0lax">{{ item.overclaimSum }}</th>
                        <th class="tg-0lax">{{ item.overclaimSumPercent }}</th>
                        <th class="tg-0lax">{{ item.statesName }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "UnloadOil",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                date: [],
                shiptitle: '',
                subtitleid: '',
                sfxy: '',
                destPort: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            memberGroup: [],
            groupAll: [],
            dict: {//数据字典
                carrierList: [],
                companyList: [],
                monthlyList: [],
                oilList: [],
                shipList: [],
                shipPortBegin: [],
                shipPortEnd: [],
                shipPortList: [],
            },
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getmemberGroup()
        this.getmemberGroupAll()
        this.getDict()
        this.getData()
    },
    activated() {
        this.getData()
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let config = [
                        { field: 'shiptitle', width: 80, align: 'center', title: '船名' },
                        { field: 'belongIdName', width: 80, align: 'center', title: '省市公司' },
                        { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
                        { field: 'shipPlancreateTimedata', width: 150, align: 'center', title: '下达时间' },
                        { field: 'startPortName', width: 150, align: 'center', title: '发港' },
                        { field: 'destPortName', width: 150, align: 'center', title: '到港' },
                        { field: 'oilTypetitle', width: 80, align: 'center', title: '品号' },
                        { field: 'oilTon', width: 80, align: 'center', title: '计划量' },
                        { field: 'plankingTon', width: 80, align: 'center', title: '船板量' },
                        { field: 'shipErpAmount', width: 80, align: 'center', title: '原发量' },
                        { field: 'realTon', width: 100, align: 'center', title: '实收量' },
                        { field: 'feeratePercent', width: 80, align: 'center', title: '定损率' },
                        { field: 'confirmSum', width: 160, align: 'center', title: '定损量' },
                        { field: 'totalSum', width: 160, align: 'center', title: '损耗量' },
                        { field: 'totalSumPercent', width: 160, align: 'center', title: '损耗率' },
                        { field: 'overclaimSum', width: 200, align: 'center', title: '超耗量' },
                        { field: 'overclaimSumPercent', width: 200, align: 'center', title: '超耗率' },
                        { field: 'statesName', width: 200, align: 'center', title: '状态' },
                    ]
                    let obj = {
                        name: '卸油',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(this.tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        againEnter(row) {
            row.editStates = 'enter'
            this.$router.push({ name: 'unloadOilEdit', params: row })
        },
        editeInfo(row) {
            row.editStates = 'editeInfo'
            this.$router.push({ name: 'unloadOilEdit', params: row })
        },
        lookDetails(row) {
            row.editStates = 'lookDetails'
            this.$router.push({ name: 'unloadOilEdit', params: row })
        },
        enter(row) {
            row.editStates = 'enter'
            this.$router.push({ name: 'unloadOilEdit', params: row })
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getDict() {
            this.$Api.shipPlan_Dict().then(res => {
                if (res.code == 200) {
                    this.dict.carrierList = res.data.carrierList
                    this.dict.companyList = res.data.companyList
                    this.dict.monthlyList = res.data.monthlyList
                    this.dict.oilList = res.data.oilList
                    this.dict.shipList = res.data.shipList
                    this.dict.shipPortBegin = res.data.shipPortBegin
                    this.dict.shipPortEnd = res.data.shipPortEnd
                    this.dict.shipPortList = res.data.shipPortList
                }
            })
        },
        getmemberGroup() {
            this.$Api.several_quality_getmemberGroup().then(res => {
                if (res.code == 200) {
                    this.memberGroup = res.data
                }
            })
        },
        getmemberGroupAll() {
            this.$Api.several_quality_getmemberGroupAll().then(res => {
                if (res.code == 200) {
                    this.groupAll = res.data
                }
            })
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                arrivalTimestart: this.searchForm.date[0] ? this.searchForm.date[0] : '',
                arrivalTimeover: this.searchForm.date[1] ? this.searchForm.date[1] : '',
                shiptitle: this.searchForm.shiptitle,
                subtitleid: this.searchForm.subtitleid,
                sfxy: this.searchForm.sfxy,
                destPort: this.searchForm.destPort
            }
            this.tableLoading = true
            this.$Api.several_quality_getshipplanoildischarge(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = Number(res.data.total);
                    this.tableData = res.data.list
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.UnloadOil {
    height: initial;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form-item__label {
            width: 120px;
        }

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table {
            height: 800px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }


    }
}</style>