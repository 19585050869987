<template>
  <div class="page home">
    <div class="container">
      <div class="tip">
        <i class="el-icon-notebook-1" v-permission="'add|添加'"></i> 提醒事件
      </div>
      <template>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="未读公文" name="first"></el-tab-pane>
          <el-tab-pane label="船舶证件" name="second"></el-tab-pane>
          <el-tab-pane label="考核评分" name="third"></el-tab-pane>
        </el-tabs>
      </template>
      <!-- 未读公文 -->
      <el-table v-if="activeName == 'first'" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
        style="width: 98%;margin:0 auto;" tooltip-effect="dark" header-row-class-name="header-table">
        <el-table-column label="内容" prop="title">
        </el-table-column>
        <el-table-column align="center" label="创建时间" width="200" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'first'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 船舶证件 -->
      <el-table v-if="activeName == 'second'" ref="multipleTable" border v-loading="tableLoading" :data="tableData2"
        style="width: 98%;margin:0 auto;" tooltip-effect="dark" header-row-class-name="header-table">
        <el-table-column align="center" label="承运商名称" prop="Pcarrierstitle">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="Ptitle">
        </el-table-column>
        <el-table-column align="center" label="证书名称" prop="Ppapers">
        </el-table-column>
        <el-table-column align="center" label="证书有效期" prop="PcreateTime">
        </el-table-column>
        <el-table-column align="center" label="状态" prop="title">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.Pstates == 1">正常</el-tag>
            <el-tag type="danger" v-else>过期</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'second'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page2.currentPage"
          :page-size="page2.pageSize" :total="page2.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 考核评分 -->
      <el-table v-if="activeName == 'third'" ref="multipleTable" border v-loading="tableLoading" :data="tableData3"
        style="width: 98%;margin:0 auto;" tooltip-effect="dark" header-row-class-name="header-table">
        <el-table-column align="center" label="考核标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="考核时间" prop="startTime">
        </el-table-column>
        <el-table-column align="center" label="结束时间" prop="endTime">
        </el-table-column>
        <el-table-column align="center" label="考核类型" prop="title">
          <template slot-scope="scope">
            <span v-if="scope.row.assessType==1">月度考核</span>
            <span v-else-if="scope.row.assessType==2">季度考核</span>
            <span v-else>年度考核</span>  
          </template>
        </el-table-column>
        <el-table-column align="center" label="范围" prop="sumDate">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'third'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page3.currentPage"
          :page-size="page3.pageSize" :total="page3.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      searchForm: {
        name: '',
        is_hid: [],
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page2: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page3: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      tableData2: [],
      tableData3: [],
      activeName: 'first'
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    editerow(row) {
      this.$router.push({ path: '/details', params: row })
    },
    deleterow() {

    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':
          this.getData2()
          break;
        case 'third':
          this.getData3()
          break;
        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据 -未读公文
      let data = {
        curpage: this.page.currentPage,
        pagesize: this.page.pageSize
      }
      this.tableLoading = true
      this.$Api.getListMyUnReadDocument(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = Number(res.data.total[0].count);
          this.tableData = res.data.list
        }
      })
    },
    getData2() {
      let data = {
        curpage: this.page2.currentPage,
        pagesize: this.page2.pageSize
      }
      this.tableLoading = true
      this.$Api.getListListShipPapers(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page2.total = Number(res.data.total[0].count);
          this.tableData2 = res.data.shipLists
        }
      })
    },
    getData3() {
      let data = {
        curpage: this.page3.currentPage,
        pagesize: this.page3.pageSize
      }
      this.tableLoading = true
      this.$Api.getMemberAssessSocerList(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page3.total = Number(res.data.total[0].count);
          this.tableData3 = res.data.list
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: inherit;

  .container {
    width: 100%;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>