<template>
  <div class="page carrier">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="名称：">
          <el-input v-model="searchForm.title" placeholder="请输入名称" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-delete" size="small" type="danger"
            @click="batchDelete">删除</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column align="center" label="名称" prop="title">
        </el-table-column>
        <el-table-column align="center" label="简称" prop="subtitle">
        </el-table-column>
        <el-table-column align="center" label="简称2" prop="subtitle2">
        </el-table-column>
        <el-table-column align="center" label="编码" prop="groupCode">
        </el-table-column>
        <el-table-column align="center" label="资质" prop="book_title">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.qod == 1">生效中</el-tag>
            <el-tag type="danger" v-else>已失效</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookInfo(scope.row)">查看资质</el-button>
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="20vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="名称/全称：" prop="title">
            <el-input v-model="createdData.title" placeholder="请输入称/全称"></el-input>
          </el-form-item>
          <el-form-item label="简称：" prop="subtitle">
            <el-input v-model="createdData.subtitle" placeholder="请输入简称"></el-input>
          </el-form-item>
          <el-form-item label="简称2：" prop="subtitle2">
            <el-input v-model="createdData.subtitle2" placeholder="请输入简称2"></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="groupCode">
            <el-input v-model="createdData.groupCode" placeholder="请输入编码"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查看资质 -->
      <el-dialog :close-on-click-modal="false" title="查看资质" :visible.sync="dialogVisible2" destroy-on-close top="20vh"
        width="700px">
        <template>
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="营业执照" name="first"></el-tab-pane>
            <el-tab-pane label="符合证明及年度签注页" name="second"></el-tab-pane>
            <el-tab-pane label="水路运输许可证" name="third"></el-tab-pane>
          </el-tabs>
        </template>
        <el-form :model="infoData" label-width="120px" v-show="activeName == 'first'">
          <el-form-item label="证件编号：" prop="yyzzCertNo">
            <span>{{ infoData.yyzzCertNo }}</span>
          </el-form-item>
          <el-form-item label="有效期至：" prop="yyzzCertExp">
            <span>{{ infoData.yyzzCertExp }}</span>
          </el-form-item>
          <el-form-item label="证件附件：" prop="yyzzCertFile">
            <el-button class="u-custom-btn-primary" icon="el-icon-view" size="small" type="info" plain
              @click="openFile(infoData.yyzzCertFile)">文件预览
            </el-button>
          </el-form-item>
        </el-form>
        <el-form :model="infoData" label-width="120px" v-show="activeName == 'second'">
          <el-form-item label="证件编号：" prop="fhzmndqzCertNo">
            <span>{{ infoData.fhzmndqzCertNo }}</span>
          </el-form-item>
          <el-form-item label="有效期至：" prop="fhzmndqzCertExp">
            <span>{{ infoData.fhzmndqzCertExp }}</span>
          </el-form-item>
          <el-form-item label="证件附件：" prop=" fhzmndqzCertFile">
            <el-button class="u-custom-btn-primary" icon="el-icon-view" size="small" type="info" plain
              @click="openFile(infoData.fhzmndqzCertFile)">文件预览
            </el-button>
          </el-form-item>
        </el-form>
        <el-form :model="infoData" label-width="120px" v-show="activeName == 'third'">
          <el-form-item label="证件编号：" prop="slysxkCertNo">
            <span>{{ infoData.slysxkCertNo }}</span>
          </el-form-item>
          <el-form-item label="有效期至：" prop="slysxkCertExp">
            <span>{{ infoData.slysxkCertExp }}</span>
          </el-form-item>
          <el-form-item label="证件附件：" prop="slysxkCertFile">
            <el-button class="u-custom-btn-primary" icon="el-icon-view" size="small" type="info" plain
              @click="openFile(infoData.slysxkCertFile)">文件预览
            </el-button>
          </el-form-item>
        </el-form>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Carrier",
  data() {
    return {
      activeName: 'first',
      multipleSelection: [],
      searchForm: {
        title: '',
        states: '',
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      isAdd: false,
      createdData: {
        id: '',
        groupCode: '',
        groupType: 2,
        pid: 0,
        subtitle: '',
        subtitle2: '',
        title: ''
      },
      rules: {
        groupCode: {
          required: true, message: '请输入编码', trigger: 'blur'
        },
        subtitle: {
          required: true, message: '请输入简称', trigger: 'blur'
        },
        subtitle2: {
          required: true, message: '请输入简称2', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称/全称', trigger: 'blur'
        },
      },
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      infoData: {}
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
  },
  activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
  methods: {
    openFile(url) {
      window.open(url, "_blank");
    },
    lookInfo(row) {
      let data = {
        carriersId: row.id
      }
      this.$Api.carriermemberGroupInfo(data).then(res => {
        if (res.code == 200) {
          this.infoData = res.data
          this.dialogVisible2 = true
          this.activeName = 'first'
        }
      })
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请勾选删除项')
        return
      }
      let ids = []
      this.multipleSelection.map(item => {
        ids.push(item.id)
      })
      let data = {
        id: ids,
      }
      this.$Api.carriermemberGroupdelete(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          if (ids.length == this.tableData.length) this.page.currentPage = 1
          this.getData()
        }
      })
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            groupCode: this.createdData.groupCode,
            groupType: 2,
            pid: this.createdData.pid,
            subtitle: this.createdData.subtitle,
            subtitle2: this.createdData.subtitle2,
            title: this.createdData.title
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.carrierAddorupdate(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        groupCode: '',
        groupType: 2,
        pid: 0,
        subtitle: '',
        subtitle2: '',
        title: ''
      }
      this.$refs['form'].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createdData = {
        id: row.id,
        groupCode: row.groupCode,
        groupType: 2,
        pid: row.pid,
        subtitle: row.subtitle,
        subtitle2: row.subtitle2,
        title: row.title
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.carriermemberGroupdelete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      console.log(`每页 ${val} 条`);
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.searchForm.title,
        groupType: 2,
        pid: 0,
        states: 1,
        show_type: 2
      }
      this.tableLoading = true
      this.$Api.getDict(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.carrier {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>