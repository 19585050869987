<template>
  <div class="page BoatPlan">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="5">
            <el-form-item label="月份：">
              <el-date-picker size="small" value-format="yyyy-MM" v-model="searchForm.monthly" type="month"
                placeholder="请选择月份">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="流向：">
              <el-select clearable v-model="searchForm.belongId_new" placeholder="请选择流向" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="属性：">
              <el-select clearable v-model="searchForm.KUNTPLIST_new" placeholder="请选择属性" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.groupCode"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="发港：">
              <el-select clearable v-model="searchForm.startPort_new" placeholder="请选择发港" filterable size="small">
                <el-option v-for="(item, index) in dict.shipPortBegin" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="到港：">
              <el-select clearable v-model="searchForm.destPort_new" placeholder="请选择到港" filterable size="small">
                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="5">
            <el-form-item label="发运开始：">
              <el-date-picker size="small" v-model="searchForm.fy_begtime" value-format="yyyy-MM-dd" type="date"
                placeholder="请选择发运开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="发运结束：">
              <el-date-picker size="small" v-model="searchForm.fy_endtime" value-format="yyyy-MM-dd" type="date"
                placeholder="请选择发运结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="品号：">
              <el-select clearable v-model="searchForm.oilList_new" placeholder="请选择品号" filterable size="small">
                <el-option v-for="(item, index) in dict.oilList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="下达开始：">
              <el-date-picker size="small" v-model="searchForm.begin_time" type="date" value-format="yyyy-MM-dd"
                placeholder="请选择下达开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="下达结束：">
              <el-date-picker size="small" v-model="searchForm.end_time" type="date" value-format="yyyy-MM-dd"
                placeholder="请选择下达结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg">
          <el-col :span="5">
            <el-form-item label="承运商：">
              <el-select clearable v-model="searchForm.carrierList_new" placeholder="请选择承运商" filterable size="small">
                <el-option v-for="(item, index) in dict.carrierList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="船舶：">
              <el-select clearable v-model="searchForm.shipList_new" placeholder="请选择船舶" filterable size="small">
                <el-option v-for="(item, index) in dict.shipList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="计划状态：">
              <el-select clearable v-model="searchForm.states" placeholder="请选择计划状态" size="small">
                <el-option value="-1" label="全部"></el-option>
                <el-option value="0" label="未提交/未通过"></el-option>
                <el-option value="1" label="已通过"></el-option>
                <el-option value="2" label="检查不通过"></el-option>
                <el-option value="3" label="检查通过"></el-option>
                <el-option value="4" label="发运审批中"></el-option>
                <el-option value="6" label="待卸油"></el-option>
                <el-option value="7" label="已卸油"></el-option>
                <el-option value="10" label="已改港/分卸"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="回执单：">
              <el-select clearable v-model="searchForm.receiptStetes" placeholder="请选择回执单" size="small">
                <el-option value="-1" label="全部"></el-option>
                <el-option value="0" label="未上传"></el-option>
                <el-option value="2" label="已上传，未确认"></el-option>
                <el-option value="3" label="已上传，已确认"></el-option>
                <el-option value="4" label="已驳回"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="完成数：">
              <el-select clearable v-model="searchForm.shipErpAmount_new" placeholder="请选择完成数" size="small">
                <el-option value="0" label="全部"></el-option>
                <el-option value="100" label="已处理"></el-option>
                <el-option value="200" label="未处理"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg">
          <el-col :span="5">
            <el-form-item label="确认时间：">
              <el-date-picker size="small" v-model="searchForm.receiptStates_time" value-format="yyyy-MM-dd" type="date"
                placeholder="请选择确认时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                @click="searchTable('search')">搜索
              </el-button>
              <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                @click="searchTable('reset')">重置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="showData">

          <!--列表-->
          <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData2" tooltip-effect="dark"
            style="width: 50%;" header-row-class-name="header-table">
            <el-table-column align="center" label="运输方式" prop="name">
            </el-table-column>
            <el-table-column align="center" label="预排数量" prop="num1">
            </el-table-column>
            <el-table-column align="center" label="已排数量" prop="num2">
            </el-table-column>
          </el-table>
          <div class="left">
            <div class="item">已下达计划:{{ dataScale.allPlan }}</div>
            <div class="item">已下达比例:{{ dataScale.allPlanScale }}</div>
            <div class="item">已完成计划量:{{ dataScale.finishPlan }}</div>
            <div class="item">已完成计划比例:{{ dataScale.finishPlanScale }}</div>
          </div>
        </div>

      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">增加航次计划</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-finished" size="small" type="primary"
            @click="batchProcess">批量提交审核 </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-menu" size="small" type="primary"
            @click="zhineng">智能排船</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
            @click="scyncNumber">同步完成数</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
            @click="awitNumber">手动同步完成数</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
            @click="exportPull">导出</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="800px"
        style="width: 98%;margin:0 auto;" @selection-change="handleSelectionChange" header-row-class-name="header-table">
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column align="center" label="月份" prop="monthly" width="80">
        </el-table-column>
        <el-table-column align="center" label="发港" prop="startPortName" width="150">
        </el-table-column>
        <el-table-column align="center" label="方式" prop="transTypeName" width="80">
        </el-table-column>
        <el-table-column align="center" label="属性" prop="KUNTPNAME" width="80">
        </el-table-column>
        <el-table-column align="center" label="流向" prop="startTimeData" width="80">
        </el-table-column>
        <el-table-column align="center" label="到港" prop="destPortName" width="150">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oilTypeIdName" width="200">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="shipName" width="150">
        </el-table-column>
        <el-table-column align="center" label="承运公司" prop="subtitleName" width="150">
        </el-table-column>
        <el-table-column align="center" label="下达量" prop="oilTon" width="100">
        </el-table-column>
        <el-table-column align="center" label="原发量" prop="shipErpAmount" width="100">
        </el-table-column>
        <el-table-column align="center" label="发运时间" prop="startTime" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.startTime.split(' ')[0] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="收卸时间" prop="arrivalTime" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.arrivalTime.split(' ')[0] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="调整后到港" prop="changePortName" width="150">
        </el-table-column>
        <el-table-column align="center" label="实收量" prop="realTon" width="80">
        </el-table-column>
        <el-table-column align="center" label="在途" prop="onLineDays" width="80">
        </el-table-column>
        <el-table-column align="center" label="定损率" prop="confirmSumPercent" width="80">
        </el-table-column>
        <el-table-column align="center" label="定损量" prop="confirmSum" width="80">
        </el-table-column>
        <el-table-column align="center" label="损耗量" prop="totalSum" width="100">
        </el-table-column>
        <el-table-column align="center" label="损耗率" prop="totalSumPercent" width="80">
        </el-table-column>
        <el-table-column align="center" label="超耗量" prop="overclaimSum" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.overclaimSum < 10" style="color:black;">{{ scope.row.overclaimSum }}</span>
            <span v-else style="color:red;">{{ scope.row.overclaimSum }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="超耗率" prop="overclaimSumPercent" width="100">
        </el-table-column>
        <el-table-column align="center" label="下达时间" prop="createTime" width="100">
        </el-table-column>
        <el-table-column align="center" label="计划状态" prop="statesName" width="100">
        </el-table-column>
        <el-table-column align="center" label="回执单状态" prop="oildischargeStatus" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.oildischargeStatus == 0" type="danger">未上传</el-tag>
            <el-tag v-else-if="scope.row.oildischargeStatus == 2" type="danger">已驳回</el-tag>
            <el-tag v-else-if="scope.row.oildischargeStatus == 1 && scope.row.tonConfirmStatus == 0">已上传，未确认</el-tag>
            <el-tag v-else type="success">已确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="确认时间" prop="tonConfirmTime" width="100">
        </el-table-column>
        <el-table-column align="center" label="审核结果" prop="approveStatesName" width="100">
        </el-table-column>
        <el-table-column align="center" label="操作" width="380" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="goDetails(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="finish(scope.row)"
              v-if="scope.row.states == 3 || scope.row.states == 4 || scope.row.states == 6 || scope.row.states == 7 || scope.row.states == 8 || scope.row.states == 10">完成数</el-button>
            <el-button type="primary" size="mini" @click="setLx(scope.row)"
              v-if="(scope.row.states == 6 || scope.row.states == 7 || scope.row.states == 8 || scope.row.states == 10) && scope.row.KUNTP == '72'">省市</el-button>
            <!-- <el-button type="primary" size="mini" @click="editerow(scope.row)"
              v-if="(!scope.row.approveStates || scope.row.approveStates == 5) && scope.row.states != 9">编辑</el-button> -->
            <el-button type="primary" size="mini" @click="editerow(scope.row)"
              v-if="scope.row.approveStates == 4">编辑</el-button>
            <el-button type="primary" size="mini" @click="submitProcess(scope.row)"
              v-if="!scope.row.approveStates || scope.row.approveStates == 5">提交审核</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row)"
              v-if="scope.row.approveStates > 0">日志</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)" v-if="scope.row.states < 6">删除</el-button>
            <el-button type="danger" size="mini" @click="receipt(scope.row)"
              v-if="scope.row.oildischargeStatus == 1 && scope.row.tonConfirmStatus == 0">回执单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 新增检查 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加航次计划' : '编辑航次计划'" :visible.sync="dialogVisible"
        destroy-on-close top="12vh" width="650px">
        <div class="addBox">
          <el-form ref="formAdd" :model="createData" :rules="rules" label-width="130px">
            <el-form-item label="计划月份：" prop="monthly">
              <el-input v-model="createData.monthly" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="选择流向：" prop="belongId">
              <el-select v-model="createData.belongId" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出发港：" prop="startPort">
              <el-select v-model="createData.startPort" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in dict.shipPortBegin" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到达港：" prop="destPort">
              <el-select v-model="createData.destPort" placeholder="请搜索或选择内容" filterable @change="destPortChange"
                size="small">
                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属油库：" prop="oliName">
              <el-input v-model="createData.oliName" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="选择承运商：" prop="companyId">
              <el-select v-model="createData.companyId" placeholder="请搜索或选择内容" filterable @change="carrierChange"
                size="small">
                <el-option v-for="(item, index) in dict.carrierList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择船：" prop="shipId">
              <el-select v-model="createData.shipId" placeholder="请搜索或选择内容" filterable size="small" @change="shipChange">
                <el-option v-for="(item, index) in createDataShip" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="油品：" prop="oilTypeId">
              <el-select clearable v-model="createData.oilTypeId" placeholder="请搜索或选择内容" filterable size="small"
                @change="oilTypeIdChange">
                <el-option v-for="(item, index) in dict.oilList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-row type="flex" class="row-bg" justify="space-between" style="width: 100%;">
              <el-col :span="12">
                <el-form-item label="数量：" prop="oilTon">
                  <el-input v-model="createData.oilTon" type="number" size="small" @focus='oilTonFocus'
                    @blur="oilTonBlur"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="预计吃水(米)：" prop="draft">
                  <el-input v-model="createData.draft" disabled size="small" v-loading="draftLoading"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">提交</el-button>
        </span>
      </el-dialog>
      <!-- 设置流向 -->
      <el-dialog :close-on-click-modal="false" title="设置流向" :visible.sync="dialogVisible2" destroy-on-close top="12vh"
        width="650px">
        <div class="addBox">
          <el-form ref="formAdd" :model="createDataLx" label-width="130px">
            <el-form-item label="选择流向：" prop="belongId">
              <el-select v-model="createDataLx.belongId" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出发港：" prop="startPort">
              <el-select v-model="createDataLx.startPort" placeholder="请搜索或选择内容" disabled size="small">
                <el-option v-for="(item, index) in dict.shipPortBegin" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到达港：" prop="destPort">
              <el-select v-model="createDataLx.destPort" placeholder="请搜索或选择内容" disabled size="small">
                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择承运商：" prop="companyId">
              <el-select v-model="createDataLx.companyId" placeholder="请搜索或选择内容" disabled size="small">
                <el-option v-for="(item, index) in dict.carrierList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择船：" prop="shipId">
              <el-select v-model="createDataLx.shipId" placeholder="请搜索或选择内容" disabled size="small">
                <el-option v-for="(item, index) in createDataShip" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="油品：" prop="oilTypeId">
              <el-select clearable v-model="createDataLx.oilTypeId" placeholder="请搜索或选择内容" disabled size="small">
                <el-option v-for="(item, index) in dict.oilList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数量：" prop="oilTon">
              <el-input v-model="createDataLx.oilTon" type="number" size="small" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="submitLx">提交</el-button>
        </span>
      </el-dialog>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
        <div class="addoreidt">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level_text">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="approveStatesName">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="update_time">
              <template slot-scope="scope">
                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">月份</th>
              <th class="tg-0lax">发港</th>
              <th class="tg-0lax">方式</th>
              <th class="tg-0lax">属性</th>
              <th class="tg-0lax">流向</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">品号</th>
              <th class="tg-0lax">船名</th>
              <th class="tg-0lax">承运公司</th>
              <th class="tg-0lax">下达量</th>
              <th class="tg-0lax">原发量</th>
              <th class="tg-0lax">发运时间</th>
              <th class="tg-0lax">收卸时间</th>
              <th class="tg-0lax">调整后到港</th>
              <th class="tg-0lax">实收量</th>
              <th class="tg-0lax">在途</th>
              <th class="tg-0lax">定损率</th>
              <th class="tg-0lax">定损量</th>
              <th class="tg-0lax">损耗量</th>
              <th class="tg-0lax">损耗率</th>
              <th class="tg-0lax">超耗量</th>
              <th class="tg-0lax">超耗率</th>
              <th class="tg-0lax">下达时间</th>
              <th class="tg-0lax">计划状态</th>
              <th class="tg-0lax">回执单状态</th>
              <th class="tg-0lax">确认时间</th>
              <th class="tg-0lax">审核结果</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.monthly }}</th>
            <th class="tg-0lax">{{ item.startPortName }}</th>
            <th class="tg-0lax">{{ item.transTypeName }}</th>
            <th class="tg-0lax">{{ item.KUNTPNAME }}</th>
            <th class="tg-0lax">{{ item.startTimeData }}</th>
            <th class="tg-0lax">{{ item.destPortName }}</th>
            <th class="tg-0lax">{{ item.oilTypeIdName }}</th>
            <th class="tg-0lax">{{ item.shipName }}</th>
            <th class="tg-0lax">{{ item.subtitleName }}</th>
            <th class="tg-0lax">{{ item.oilTon }}</th>
            <th class="tg-0lax">{{ item.shipErpAmount }}</th>
            <th class="tg-0lax">{{ item.startTime.split(' ')[0] }}</th>
            <th class="tg-0lax">{{ item.arrivalTime.split(' ')[0]  }}</th>
            <th class="tg-0lax">{{ item.changePortName }}</th>
            <th class="tg-0lax">{{ item.realTon}}</th>
            <th class="tg-0lax">{{ item.onLineDays }}</th>
            <th class="tg-0lax">{{ item.confirmSumPercent }}</th>
            <th class="tg-0lax">{{ item.confirmSum }}</th>
            <th class="tg-0lax">{{ item.totalSum }}</th>
            <th class="tg-0lax">{{ item.totalSumPercent }}</th>
            <th class="tg-0lax">{{ item.overclaimSum }}</th>
            <th class="tg-0lax">{{ item.overclaimSumPercent }}</th>
            <th class="tg-0lax">{{ item.createTime }}</th>
            <th class="tg-0lax">{{ item.statesName }}</th>
            <th class="tg-0lax">{{ item.oildischargeStatus == 0?'未上传':item.oildischargeStatus==2?'已驳回':(item.oildischargeStatus==1&&item.tonConfirmStatus==0)?'已上传，未确认':'已确认' }}</th>
            <th class="tg-0lax">{{ item.tonConfirmTime }}</th>
            <th class="tg-0lax">{{ item.approveStatesName }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BoatPlan",
  data() {
    return {
      logData: {},
      dialogVisible2: false,
      dialogVisible3: false,
      isAdd: false,
      draftLoading: false,
      createDataShip: [],
      changeShipTipColor: "",
      changeShipTip: '',
      createDataLx: {
        belongId: '',//流向
        companyId: '',//承运商
        oilTon: '',//数量
        startPort: '',
        destPort: '',
        shipId: '',
        oilTypeId: '',//油ID
        id: ''
      },
      createData: {
        belongId: '',//流向
        companyId: '',//承运商
        destPort: '',
        draft: '',//预计吃水(米)
        monthly: '',
        oilTon: '',//数量
        oilType: '',//油种类ID
        oilTypeId: '',//油ID
        oliName: '',//所属油库
        shipId: '',
        startPort: '',
        id: ''
      },
      rules: {
        monthly: { required: true, message: '必填项不能为空', trigger: 'blur' },
        belongId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        startPort: { required: true, message: '必填项不能为空', trigger: 'blur' },
        destPort: { required: true, message: '必填项不能为空', trigger: 'blur' },
        companyId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        shipId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        oilTypeId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        oilTon: { required: true, message: '必填项不能为空', trigger: 'blur' },
        draft: { required: true, message: '必填项不能为空', trigger: 'blur' },
      },
      dialogVisible: false,
      multipleSelection: [],
      searchForm: {
        KUNTPLIST_new: '',
        begin_time: '',
        belongId_new: '',
        carrierList_new: '',
        destPort_new: '',
        end_time: '',
        fy_begtime: '',
        fy_endtime: '',
        monthly: '',
        oilList_new: '',
        receiptStates_time: '',
        receiptStetes: '-1',
        shipErpAmount_new: '0',
        shipList_new: '',
        startPort_new: '',
        states: '-1'
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      tableData2: [],
      dataScale: {
        allPlan: 0,
        finishPlan: 0,
        allPlanScale: '0%',
        finishPlanScale: '0%'
      },
      dict: {//数据字典
        carrierList: [],
        companyList: [],
        monthlyList: [],
        oilList: [],
        shipList: [],
        shipPortBegin: [],
        shipPortEnd: [],
        shipPortList: [],
        transportType: [{
          id: 1,
          name: "内河"
        }, {
          id: 2,
          name: "干线"
        }, {
          id: 3,
          name: "进川"
        }],
        oilWarehouse: [],
        staicData: [],
        approveStates: []
      },
      myapprovalStatesList: [],
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = now.getDate();
    if (date < 10) date = '0' + date;
    this.searchForm.monthly = year + '-' + month
    this.searchForm.begin_time = year + '-' + month + '-' + date
    this.searchForm.end_time = year + '-' + month + '-' + date
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getstaicData()
    this.getTransportType()
    this.getDict()
    this.getoilWarehouseInfo()
    this.getType()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)
    },
    submitLx() {
      let data = {
        belongId: this.createDataLx.belongId,
        id: this.createDataLx.id
      }
      this.$Api.shipPlan_UpdateForLX(data).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.dialogVisible2 = false
          this.getData()
        }
      })
    },
    setLx(row) {
      this.createDataLx = {
        belongId: row.belongId,//流向
        companyId: row.companyId,//承运商 
        destPort: row.destPort,//
        oilTon: row.oilTon,//数量
        oilTypeId: row.oilTypeId,//油ID
        shipId: row.shipId,
        startPort: row.startPort,
        id: row.id
      }
      this.dialogVisible2 = true
    },
    finish(row) {
      this.$router.push({ name: 'boatPlanFinish', params: row })
    },
    getType() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.myapprovalStatesList = res.data.myapprovalStatesList
        }
      })
    },
    looklog(row) {
      let data = {
        id: row.id,
        type: 6
      }
      this.$Api.MyAppRovalPublic_LogList(data).then(res => {
        let level = ['未知', '一级', '二级', '三级'];
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
            if (this.myapprovalStatesList.length > 0) {
              this.myapprovalStatesList.map((key, kindex) => {
                if (item.user_outcome == kindex) {
                  item.user_outcome_text = key
                }
              })
            }
            item.aprv_level_text = level[item.aprv_level]
          })
        }
        res.data.logList = res.data.logList
        this.logData = res.data
        this.dialogVisible3 = true;
      })
    },
    goDetails(row) {
      this.$router.push({ name: 'boatPlanDateils', params: { id: row.id } })
    },
    submitProcess(row) {
      this.$confirm('是否确认提交审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.shipPlan_Push(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    submit() {
      this.$refs['formAdd'].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.changeShipTipColor == 'red') {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `<strong style="color:${this.changeShipTipColor}">${this.changeShipTip}</strong>`
              });
              return
            }
            let data = this.createData
            data.planType = 0
            delete data.id
            this.$Api.shipPlan_Add(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          } else {
            let data = this.createData
            this.$Api.shipPlan_Update(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    oilTypeIdChange(e) {
      if (this.dict.oilList.length > 0) {
        this.dict.oilList.map(item => {
          if (item.id == e) {
            this.createData.oilType = item.oilType
          }
        })

      }

    },
    oilTonFocus() {
      this.draftLoading = true
    },
    oilTonBlur() {
      if (this.createData.shipId == '') {
        this.$message.warning('由于没有选择船舶，无法获取预计吃水数据')
        this.draftLoading = false
        return
      }
      let data = {
        oilTypeId: this.createData.oilTypeId,
        shipId: this.createData.shipId,
        oilTon: this.createData.oilTon
      }
      this.$Api.plan_monthly_GetTonInfo(data).then(res => {
        if (res.code == 200) {
          this.createData.draft = res.data
        }
        this.draftLoading = false
      })
    },
    shipChange(e) {
      let data = {
        shipId: e
      }
      this.$Api.plan_monthly_getShipState(data).then(res => {
        if (res.code == 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `<strong style="color:${res.data.color}">${res.data.msg}</strong>`
          });
          this.changeShipTipColor = res.data.color
          this.changeShipTip = res.data.msg
        }
      })
    },
    carrierChange(e) {//承运商切换获取下面的船舶列表
      this.createData.shipId = ''
      this.createDataShip = []
      if (this.dict.shipList.length > 0) {
        this.dict.shipList.map(item => {
          if (item.carriersId == e) {
            this.createDataShip.push(item)
          }
        })
      }
    },
    destPortChange(e) {//到达港切换获取油库;
      let id = ''
      this.createData.oliName = ''
      this.dict.shipPortEnd.map(item => {
        if (item.id == e) {
          id = item.id
        }
      })
      if (id != '') {
        this.dict.oilWarehouse.map(item => {
          if (item.shipPortId == id) {
            this.createData.oliName = item.title
          }
        })
      }
    },
    zhineng() {

    },
    scyncNumber() { },
    awitNumber() { },
    exportPull() { },
    batchProcess() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择需要提交的数据')
        return
      }
      let arr = []
      this.multipleSelection.map(item => {
        arr.push(item.id)
      })
      let data = {
        ids: arr.join(',')
      }
      this.$Api.shipPlan_PushList(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
        }
      })
    },
    addRole() {
      this.isAdd = true;
      this.createDataShip = []
      this.changeShipTipColor = ""
      this.changeShipTip = ''
      this.createData = {
        belongId: '',//流向
        companyId: '',//承运商
        destPort: '',
        draft: '',//预计吃水(米)
        monthly: this.searchForm.monthly,
        oilTon: '',//数量
        oilType: '',//油种类ID
        oilTypeId: '',//油ID
        oliName: '',//所属油库
        shipId: '',
        startPort: '',
        id: ''
      }
      this.dialogVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.isAdd = false;
      this.createData = {
        belongId: row.belongId,//流向
        companyId: row.companyId,//承运商 
        destPort: row.destPort,//
        draft: '',//预计吃水(米)
        monthly: row.monthly,
        oilTon: row.oilTon,//数量
        oilType: row.oilType,//油种类ID
        oilTypeId: row.oilTypeId,//油ID
        oliName: '',//所属油库
        shipId: row.shipId,
        startPort: row.startPort,
        id: row.id,
        states: row.states
      }
      this.destPortChange(row.destPort)
      let data = {
        oilTypeId: row.oilTypeId,
        shipId: row.shipId,
        oilTon: row.oilTon
      }
      this.$Api.plan_monthly_GetTonInfo(data).then(res => {
        if (res.code == 200) {
          this.createData.draft = res.data
        }
      })
      this.dialogVisible = true
    },
    receipt(row) {
      this.$prompt('请输入意见(驳回必填)', '提示', {
        confirmButtonText: '驳回',
        cancelButtonText: '确认',
      }).then(({ value }) => {
        if (value == '' || value == null) {
          this.$message.warning('请输入意见')
          return
        }
        let data = {
          id: row.id,
          states: 0,
          tonConfirmDesc: value
        }
        this.$Api.shipPlan_updateshipplan(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        let data = {
          id: row.id,
          states: 0,
          tonConfirmDesc: ''
        }
        this.$Api.shipPlan_updateshipplan(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      });
    },
    deleterow(row) {
      this.$confirm(`确定要删除此条数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.shipPlan_Delete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.receiptStetes = '-1'
        this.shipErpAmount_new = '0'
        this.states = '-1'
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if (month < 10) {
          month = '0' + month
        }
        let date = now.getDate();
        if (date < 10) {
          date = '0' + date
        }
        this.searchForm.monthly = year + '-' + month
        this.searchForm.begin_time = year + '-' + month + '-' + date
        this.searchForm.end_time = year + '-' + month + '-' + date
        this.getData()
      }
    },
    getoilWarehouseInfo() {
      this.$Api.oilWarehouseInfo_Dict2().then(res => {
        if (res.code == 200) {
          this.dict.oilWarehouse = res.data.oilWarehouse
        }
      })
    },
    getstaicData() {
      this.$Api.publics_staicData().then(res => {
        if (res.code == 200) {
          this.dict.staicData = res.data.shipPlanStates
          this.dict.approveStates = res.data.approveStates
        }
      })
    },
    getTransportType() {
      this.$Api.publics_transportType().then(res => {
        if (res.code = 200) {
          let ss = []
          for (var key in res.data) {
            ss.push(res.data[key])
          }
          this.dict.transportType = ss
          if (ss.length > 0) {
            let ts = []
            ss.map(item => {
              ts.push({
                name: item.name,
                id: item.id,
                num1: '0',
                num2: '0',
              })
            })
            this.tableData2 = ts
          }
        }
      })
    },
    getDict() {
      this.$Api.shipPlan_Dict().then(res => {
        if (res.code == 200) {
          this.dict.carrierList = res.data.carrierList
          this.dict.companyList = res.data.companyList
          this.dict.monthlyList = res.data.monthlyList
          this.dict.oilList = res.data.oilList
          this.dict.shipList = res.data.shipList
          this.dict.shipPortBegin = res.data.shipPortBegin
          this.dict.shipPortEnd = res.data.shipPortEnd
          this.dict.shipPortList = res.data.shipPortList
        }
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        ...this.searchForm
      }
      this.tableLoading = true
      this.$Api.shipPlan_List(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.list.total) this.page.total = Number(res.data.list.total);
          if (res.data.list.list.length > 0) {
            if (this.dict.shipPortList.length > 0) {
              res.data.list.list.map(item => {
                item.startPortName = '未知' //发港
                item.destPortName = '未知' //到港
                this.dict.shipPortList.map(key => {
                  if (item.startPort == key.id) {
                    item.startPortName = key.title
                  }
                  if (item.destPort == key.id) {
                    item.destPortName = key.title
                    this.dict.transportType.map(transtype => {
                      if (key.transType == transtype.id) {
                        item.transTypeName = transtype.name
                      }
                    })
                  }
                })
              })
            }
            if (this.dict.companyList.length > 0) {
              res.data.list.list.map(item => {
                item.KUNTPNAME = '未知'  //属性 
                item.belongIdName = '未知' //流向
                this.dict.companyList.map(key => {
                  if (item.KUNTP == key.KUNTP) {
                    item.KUNTPNAME = key.subtitle
                  }
                  if (item.belongId == key.id) {
                    item.belongIdName = key.subtitle
                  }
                })
              })
            }
            if (this.dict.oilList.length > 0) {
              res.data.list.list.map(item => {
                item.oilTypeIdName = '未知' //品号
                this.dict.oilList.map(key => {
                  if (item.oilTypeId == key.id) {
                    item.oilTypeIdName = key.title
                  }
                })
              })
            }
            if (this.dict.carrierList.length > 0) {
              res.data.list.list.map(item => {
                item.subtitleName = '未知' //承运公司
                this.dict.carrierList.map(key => {
                  if (item.companyId == key.id) {
                    item.subtitleName = key.subtitle
                  }
                })
              })
            }
            if (res.data.ChangeList.length > 0) {
              res.data.list.list.map(item => {
                item.changePortName = '' //调整后到港
                res.data.ChangeList.map(key => {
                  if (item.id == key.pid) {
                    if (this.dict.shipPortList.length > 0) {
                      this.dict.shipPortList.map(ship => {
                        if (ship.id == key.destPort) {
                          item.changePortName = ship.title
                        }
                      })
                    }
                  }
                })
              })
            }
          }
          if (this.dict.staicData.length > 0) {
            res.data.list.list.map(item => {
              item.statesName = '未知' //计划状态
              item.statesName = this.dict.staicData[item.states]
            })
          }
          if (this.dict.approveStates.length > 0) {
            res.data.list.list.map(item => {
              item.approveStatesName = '未知' //审核状态
              item.approveStatesName = this.dict.approveStates[item.approveStates]
            })
          }
          if (Object.values(res.data.statistics).length != 0 && res.data.statistics != null) {
            if (this.tableData2.length > 0) {
              this.tableData2.map(item => {
                for (const key in res.data.statistics) {
                  if (res.data.statistics[key].transType == item.id) {
                    item.num1 = res.data.statistics[key].numa
                    item.num2 = res.data.statistics[key].numb
                  }
                }
              })
            }
          }
          this.dataScale.allPlan = res.data.scaleInfo.allPlan
          this.dataScale.finishPlan = res.data.scaleInfo.finishPlan
          if (res.data.scaleInfo.monthlySum == 0) {
            this.dataScale.allPlanScale = '0%'
            this.dataScale.finishPlanScale = '0%'
          } else {
            this.dataScale.allPlanScale = (res.data.scaleInfo.allPlan / res.data.scaleInfo.monthlySum * 100).toFixed(2) + '%';
            this.dataScale.finishPlanScale = (res.data.scaleInfo.finishPlan / res.data.scaleInfo.monthlySum * 100).toFixed(2) + '%';
          }
          this.tableData = res.data.list.list
        }
      })
    },
  }
}
</script>
    
<style lang="scss" scoped>
.BoatPlan {
  height: auto;

  .searchBox {
    padding-top: 15px;

    /deep/.el-form-item__label {
      width: 100px;
    }

    /deep/.el-form--inline .el-form-item {
      margin-bottom: 5px;
    }

    /deep/.el-input__inner {
      width: 200px;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 200px;
    }

    .outbox {
      /deep/.el-form-item__label {
        width: 120px;
      }
    }

    /deep/.el-table .el-table__cell {
      padding: 4px 0;
    }

    .showData {
      display: flex;
      align-items: center;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .item {
          margin: 0 10px 5px 0;
        }
      }
    }
  }

  .container {
    width: 100%;
    height: 800px;
    // flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .addBox {
      height: 50vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .el-select {
        width: 100%;
      }
    }

    .el-table {
      height: 800px;
    }
  }

  .addoreidt {
    height: 50vh;
    overflow-y: scroll;

    /deep/.el-steps {
      height: auto !important;
    }
  }
}
</style>