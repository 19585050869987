<template>
  <div class="page UserRole" id="pagetop">
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
            @click="getData">刷新列表</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" @click="addRole">添加角色</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTables" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column label="ID" align="center" prop="id" width="60">
        </el-table-column>
        <el-table-column align="center" label="角色名称" prop="roleName">
        </el-table-column>
        <el-table-column align="center" label="状态" prop="states">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.states == 0">启用</el-tag>
            <el-tag type="danger" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="280" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)"
              style="margin-right: 12px;">编辑角色</el-button>
            <span v-if="scope.row.id != 1">
              <el-button  size="mini" @click="changeStates(scope.row, 0)"
                v-if="scope.row.states != 0">启用角色</el-button>
              <el-button type="danger" size="mini" @click="changeStates(scope.row, 1)" v-else>禁用角色</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!--列表-->
      <div class="operateBox" id="operateBox" style="margin: 30px auto;" v-show="ready">
        <div class="left">
          <div style="white-space:nowrap;">角色名称：</div>
          <el-input v-model="createData.roleName" placeholder="请输入角色名称" size="small"
            style="margin-right: 20px;"></el-input>
          <el-button class="u-custom-btn-primary" icon="el-icon-check" size="small" type="primary"
            @click="sumit">提交</el-button>
        </div>
        <div class="right">
          <el-button class="u-custom-btn-primary" icon="el-icon-sort" size="small" type="info"
            @click="expandTable">展开/折叠</el-button>
        </div>
      </div>
      <el-table v-if="ready" ref="multipleTable" border v-loading="menuListLoading" :data="menuList" tooltip-effect="dark"
        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" :default-expand-all="is_expand"
        @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="菜单名称" prop="name" width="200">
        </el-table-column>
        <el-table-column label="按钮" prop="buttons">
          <template slot-scope="scope">
            <el-checkbox-group v-model="scope.row.buttonResult" size="small">
              <el-checkbox :label="item.value" border v-for="(item, index) in scope.row.buttons">{{ item.name
              }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column align="center" label="选中" type="selection" width="55">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>
  
<script>
export default {
  name: "UserRole",
  data() {
    return {
      ready: false,
      is_expand: true,
      isAdd: true,
      searchForm: {
        name: '',
        is_hid: [],
      },
      createData: {
        roleName: '',
        roleId: ""
      },
      editeData: [],
      tableLoading: false,
      menuListLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      menuList: [],
      multipleSelection: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getmenuList()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted() {
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    sumit() {
      let data = {
        roleName: this.createData.roleName,
        buttons: []
      }
      if (this.isAdd) {
        data.id = '0'
      } else {
        data.id = this.createData.roleId
      }
      if (this.multipleSelection.length != 0) {
        this.multipleSelection.map(item => {
          data.buttons.push({
            button: item.buttonResult.join(','),
            menuId: item.id,
            roleId: data.id
          })
        })
      }
      this.$Api.member_role_insertOrUpdate(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
          this.ready = false
        }
      })
    },
    expandTable() {
      this.ready = false
      this.is_expand = !this.is_expand
      this.$nextTick(() => {
        this.ready = true
      })
    },
    addRole() {
      this.ready = true
      this.$nextTick(() => {
        var el = document.getElementById('operateBox')
        if (el) el.scrollIntoView({ behavior: 'smooth' })
      })
      this.isAdd = true
      this.createData.roleName = ''
      this.createData.roleId = ''
      this.multipleSelection = []
      this.getmenuList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.ready = true
      this.$nextTick(() => {
        var el = document.getElementById('operateBox')
        if (el) el.scrollIntoView({ behavior: 'smooth' })
      })
      this.isAdd = false
      this.createData.roleName = row.roleName
      this.createData.roleId = row.id
      let data = {
        id: row.id
      }
      this.$Api.member_role_getRoleMenu(data).then(res => {
        this.editeData = res.data
        this.getmenuList()
      })
    },
    changeStates(row, states) {
      let data = {
        id: row.id,
        states
      }
      this.$Api.member_role_setStates(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
        }
      })
    },
    // --------------------------公共方法--------------------------------------
    handleSizeChange(val) { //切换每页条数
     this.page.pageSize = val;
this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getmenuList() {
      this.menuListLoading = true
      this.$Api.menuList().then(res => {
        this.menuListLoading = false
        res.data.map((item, index) => {
          if (this.isAdd) {
            item.buttonResult = []
            if (item.child) this.setButtonResult(item.child)
          } else {
            item.buttonResult = []
            if (this.editeData.length != 0) {
              this.editeData.map(key => {
                if (item.id == key.menuId) {
                  item.buttonResult = key.button.split(',')
                  this.$nextTick(() => {
                    this.$refs.multipleTable.toggleRowSelection(item, true);
                  })
                }
              })
              if (item.child) this.setButtonResult(item.child)
            }
          }
          item.butttonItem = item.button.split(',')
          let ss = []
          item.butttonItem.forEach(element => {
            let cc = element.split('|')
            ss.push({
              name: cc[1],
              value: cc[0]
            })
            item.buttons = ss
          });
        })
        this.menuList = res.data

      })
    },
    setButtonResult(arr) {
      arr.forEach(element => {
        if (this.isAdd) {
          element.buttonResult = []
        } else {
          element.buttonResult = []
          if (this.editeData.length != 0) {
            this.editeData.map(key => {
              if (element.id == key.menuId) {
                element.buttonResult = key.button.split(',')
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(element, true);
                })
              }
            })
          }
        }
        element.butttonItem = element.button.split(',')
        let ss = []
        element.butttonItem.forEach(elementss => {
          let cc = elementss.split('|')
          ss.push({
            name: cc[1],
            value: cc[0]
          })
          element.buttons = ss
        });
        if (element.child) this.setButtonResult(element.child)
      })
    },
    getData() { // 获取数据
      let data = {
        limit: 999
      }
      this.$Api.member_role_getList(data).then(res => {
        this.tableData = res.data.data;
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.UserRole {
  height: calc(100vh - 125px) !important;
  overflow-y: scroll;

  .container {
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    padding-bottom: 18px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .el-checkbox {
      margin-bottom: 10px;
      margin-left: 0 !important;
    }
  }

}
</style>