<template>
    <div class="page WalkthroughsPlan">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="月份：">
                    <el-date-picker @change="dateChange" v-model="searchForm.monthly" clearable value-format="yyyy-MM"
                        type="month" size="small" placeholder="请选择月份"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-download" size="small" type="primary" @click="searchTable('search')">导出
                    </el-button>
                    <el-button :disabled="saveDisabled" icon="el-icon-check" size="small" type="success"
                        @click="tabSave">保存
                    </el-button>
                    <el-button :disabled="pushDisabled" icon="el-icon-s-promotion" size="small" type="primary"
                        @click="submitLog">提交审批
                    </el-button>
                    <el-button :disabled="logDisabled" icon="el-icon-s-check" size="small" type="primary"
                        @click="showLog">{{ logBtnTxt }}
                    </el-button>
                    <el-button :disabled="pushNoticeDisabled" icon="el-icon-paperclip" size="small" type="warning"
                        @click="pushNoticeOperate">发送预排通知
                    </el-button>
                    <el-button v-show="editShow" icon="el-icon-edit-outline" size="small" type="primary"
                        @click="redoEdite">重新编辑
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                :row-class-name="tableRowClassName" :cell-class-name="tableCellClassName" height="(100%-120px)"
                max-height="(100%-120px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="">
                    <el-table-column align="center" label="名称" prop="name">
                        <template slot-scope="scope">
                            <div
                                style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;flex-direction: column;">
                                <div>{{ scope.row.name }}</div>
                                <el-button v-if="scope.$index != 0" type="primary" size="mini"
                                    @click="editerowRemakes(scope.row)">备注</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="小计" prop="total">
                        <template slot-scope="scope">
                            <span>{{ scope.row.total || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp" style="background-color: blue !important;">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp || 0 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="进川">
                    <el-table-column align="center" label="预排" prop="wts" width="330">
                        <template slot-scope="scope">
                            <el-input v-if="scope.$index != 0" placeholder="请输入内容" v-model="scope.row.wts" min="0"
                                @input="wtsInput($event, scope.$index)" type="number" clearable>
                            </el-input>
                            <span v-else>{{ scope.row.wts || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp2 == 'NaN%' ? '0.00%' : scope.row.gdp2 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="干线">
                    <el-table-column align="center" label="预排" prop="wts2" width="330">
                        <template slot-scope="scope">
                            <el-input v-if="scope.$index != 0" placeholder="请输入内容" v-model="scope.row.wts2" min="0"
                                @input="wtsInput2($event, scope.$index)" type="number" clearable>
                            </el-input>
                            <span v-else>{{ scope.row.wts2 || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp3">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp3 == 'NaN%' ? '0.00%' : scope.row.gdp3 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="内河">
                    <el-table-column align="center" label="预排" prop="wts3" width="330">
                        <template slot-scope="scope">
                            <el-input v-if="scope.$index != 0" placeholder="请输入内容" v-model="scope.row.wts3" min="0"
                                @input="wtsInput3($event, scope.$index)" type="number" clearable>
                            </el-input>
                            <span v-else>{{ scope.row.wts3 || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp4">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp4 == 'NaN%' ? '0.00%' : scope.row.gdp4 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-p-t-15">
            </div>
        </div>
        <!-- 日志 -->
        <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
            <div class="addoreidt">
                <el-steps direction="vertical" :active="1" :space="80">
                    <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
                        :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
                </el-steps>
                <!-- 列表 -->
                <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
                    tooltip-effect="dark" header-row-class-name="header-table">
                    <el-table-column align="center" label="审核级别" prop="aprv_level_text">
                    </el-table-column>
                    <el-table-column align="center" label="审核人员" prop="username">
                    </el-table-column>
                    <el-table-column align="center" label="审核结果" prop="user_outcome_text">
                    </el-table-column>
                    <el-table-column align="center" label="审核时间" prop="update_time">
                        <template slot-scope="scope">
                            <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import { throttle } from "@/utils/utils.js"
export default {
    name: "WalkthroughsPlan",
    data() {
        return {
            logData: {},
            multipleSelection: [],
            searchForm: {
                monthly: '',
            },
            tableLoading: false,
            dialogVisible3: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [{
                name: "总计",
                total: 0,
                gdp: '100%',
                wts: 0,
                gdp2: '100%',
                wts2: 0,
                gdp3: '100%',
                wts3: 0,
                gdp4: '100%',
            }],
            editShow: false,
            saveDisabled: false,
            logBtnTxt: '审核日志',
            logDisabled: false,
            pushNoticeDisabled: false,
            pushDisabled: false,
            myapprovalStatesList: [],
            carrierList:[]
        }
    },
    created() {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if(month < 10) month = '0' + month;
        this.searchForm.monthly = '' + year + '-' + month
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getData()
        this.getType()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        getType() {
            this.$Api.myAppRovalPublicDictState().then(res => {
                if (res.code == 200) {
                    this.myapprovalStatesList = res.data.myapprovalStatesList
                }
            })
        },
        showLog() {
            let data = {
                id: 3,
                type: 7
            }
            this.$Api.plan_PlanWalkthroughs_LogList(data).then(res => {
                let level = ['未知', '一级', '二级', '三级'];
                if (res.data.logList.length != 0) {
                    res.data.logList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                    })
                }
                if (res.data.outcomeList.length != 0) {
                    res.data.outcomeList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                        if (this.myapprovalStatesList.length > 0) {
                            this.myapprovalStatesList.map((key, kindex) => {
                                if (item.user_outcome == kindex) {
                                    item.user_outcome_text = key
                                }
                            })
                        }
                        item.aprv_level_text = level[item.aprv_level]
                    })
                }
                res.data.logList = res.data.logList
                this.logData = res.data
                this.dialogVisible3 = true;
            })
        },
        tabSave(){
            let obj  = {}
            if(this.tableData.length > 0){
                this.tableData.map((item,index)=>{
                    if(index != 0){
                        obj[item.carrierId].carrierId = item.carrierId
                        obj[item.carrierId].num1 = item.wts
                        obj[item.carrierId].num2 = item.wts2
                        obj[item.carrierId].num3 = item.wts3
                    }
                })
            }
            //暂时缺少接口
        },
        submitLog() {
            this.$confirm('确定要发布当前预排吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    monthly: this.searchForm.monthly
                }
                this.$Api.plan_PlanWalkthroughs_PushApprove(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        redoEdite() {
            this.$confirm('确定要重新编辑吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    monthly: this.searchForm.monthly
                }
                this.$Api.plan_PlanWalkthroughs_Reedit(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        pushNoticeOperate() {
            this.$confirm('确定发布预排计划通知吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    monthly: this.searchForm.monthly
                }
                this.$Api.plan_PlanWalkthroughs_PushNotice(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerowRemakes(row) {
            this.$prompt('请输入备注', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if (value == '' || value == null) {
                    this.$message.warning('请输入备注')
                    return
                }
                let data = {
                    remarks: value,
                    id: row.id
                }
                this.$Api.plan_PlanWalkthroughs_remarks(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        dateChange() {
            this.tableData = [{
                name: "总计",
                total: 0,
                gdp: '100%',
                wts: 0,
                gdp2: '100%',
                wts2: 0,
                gdp3: '100%',
                wts3: 0,
                gdp4: '100%',
            }],
                this.getData()
        },
        tableCellClassName(cell) {
            if (cell.column.label == '占比') {
                return 'warning-row';
            }
            return '';
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 0) {
                return 'warning-row';
            }
            return '';
        },
        wtsInput3(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts3 = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts3 += Number(item.wts3)
                    }
                })
                this.tableData[0].wts3 = wts3
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        wtsInput2(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts2 = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts2 += Number(item.wts2)
                    }
                })
                this.tableData[0].wts2 = wts2
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        wtsInput(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts += Number(item.wts)
                    }
                })
                this.tableData[0].wts = wts
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
            console.log(`每页 ${val} 条`);
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                monthly: this.searchForm.monthly
            }
            this.tableLoading = true
            this.$Api.plan_PlanWalkthroughs_List(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.list.length > 0) {
                        res.data.list.map((item, index) => {
                            this.tableData.push({
                                name: item.title,
                                total: Number(item.num1) + Number(item.num2) + Number(item.num3) == 'NaN' ? 0 : Number(item.num1) + Number(item.num2) + Number(item.num3),
                                gdp: '0.00%',
                                wts: item.num1,
                                gdp2: '0.00%',
                                wts2: item.num2,
                                gdp3: '0.00%',
                                wts3: item.num3,
                                gdp4: '0.00%',
                            })
                            this.wtsInput(item.num1 ? item.num1 : 0, index + 1)
                            this.wtsInput2(item.num2 ? item.num2 : 0, index + 1)
                            this.wtsInput3(item.num3 ? item.num3 : 0, index + 1)
                        })
                    }
                    this.editShow = false
                    if (!res.data.approvalData?.id || res.data.approvalData.approveStates == 0) {
                        this.saveDisabled = false
                        this.logBtnTxt = '审批日志(未提交)'
                        this.logDisabled = true
                        this.pushNoticeDisabled = true
                        this.pushDisabled = false
                    } else {
                        this.logDisabled = false
                        this.pushDisabled = true
                        this.logBtnTxt = '审批日志()'
                        if (res.data.approvalData.approveStates != 4) {
                            this.pushNoticeDisabled = true
                            this.editShow = true
                        } else {
                            this.pushNoticeDisabled = false
                            this.editShow = true
                        }
                        if (res.data.approvalData.approveStates != 5) {
                            this.saveDisabled = true
                        }
                    }
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.WalkthroughsPlan {
    height: inherit;

    .searchBox {
        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }
    }

    .container {
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

       

        /deep/.el-table .warning-row {
            background: #FFD700;
        }

        /deep/.warning-row:hover {
            background: #FFD700 !important;
        }

        /deep/.el-table .success-row {
            background: #f0f9eb;
        }
    }

    .addoreidt {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-steps {
                height: auto !important;
            }
        }
}
</style>