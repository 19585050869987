<template>
  <div class="page CertificationInspection">
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="success"
            @click="addRole">新增检查</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox v-for="(item, index) in checkListData" :key="index" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" v-if="isready" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" width="60" type="index" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="待检船舶" prop="ship.title" v-if="checkList.indexOf('待检船舶') != -1">
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="ship.carriers.title" v-if="checkList.indexOf('承运商') != -1">
        </el-table-column>
        <el-table-column align="center" label="上次检查时间" prop="ship.verifyTime" v-if="checkList.indexOf('上次检查时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="当前位置" prop="ship_plan.site" v-if="checkList.indexOf('当前位置') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查录入时间" prop="createTime" v-if="checkList.indexOf('检查录入时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="船舶检查状态" prop="result" v-if="checkList.indexOf('船舶检查状态') != -1">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.result == 1">检查通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.result == 2">检查不通过</el-tag>
            <el-tag type="info" v-else>待检查</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" v-if="scope.row.result == 0" size="mini"
              @click="startRsult(scope.row)">录入结果</el-button>
            <el-button type="primary" v-else size="mini" @click="lookFile(scope.row.originalPath)">查看</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 新增检查 -->
      <el-dialog :close-on-click-modal="false" title="新增认证检查" :visible.sync="dialogVisible" destroy-on-close top="20vh"
        width="550px">
        <el-form ref="formShip" :model="createData" :rules="rules" label-width="130px">
          <el-form-item label="选择船舶：" prop="shipId">
            <el-select v-model="createData.shipId" placeholder="请选择选择船舶" filterable>
              <el-option v-for="(item, index) in shipList" :key="index" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">添 加</el-button>
        </span>
      </el-dialog>
      <!-- 录入认证检查结果 -->
      <el-dialog :close-on-click-modal="false" title="录入认证检查结果" :visible.sync="dialogVisible2" destroy-on-close top="20vh"
        width="550px">
        <el-form ref="formTask" :model="taskCreateData" :rules="taskRules" label-width="130px">
          <el-form-item label="结果:" prop="result">
            <el-select v-model="taskCreateData.result" clearable placeholder="请选择">
              <el-option label="通过" :value="1"></el-option>
              <el-option label="不通过" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附件:" prop="originalPath">
            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
              :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
              <el-button type="primary" style="margin: 8px 10px;" v-loading="btnLoading"
                icon="el-icon-upload2">上传附件</el-button>
            </el-upload>
            <span v-if="taskCreateData.originalPath != ''" @click="lookFile(taskCreateData.originalPath)"
              style="margin-left: 12px;cursor: pointer;">查看附件</span>
          </el-form-item>
          <el-form-item label="时间:" prop="shipverifyTime">
            <el-date-picker v-model="taskCreateData.shipverifyTime" value-format="yyyy-MM-dd" type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="submitPass">保存</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg" v-if="tableData!=''">
          <thead>
            <tr>
              <th class="tg-0lax">待检船舶</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">上次检查时间</th>
              <th class="tg-0lax">当前位置</th>
              <th class="tg-0lax">检查录入时间</th>
              <th class="tg-0lax">船舶检查状态</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.ship.title }}</th>
            <th class="tg-0lax">{{ item.ship.carriers.title }}</th>
            <th class="tg-0lax">{{ item.ship.verifyTime }}</th>
            <th class="tg-0lax">{{ item.ship_plan?.site?item.ship_plan.site:'' }}</th>
            <th class="tg-0lax">{{ item.createTime }}</th>
            <th class="tg-0lax">{{ item.result }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "CertificationInspection",
  data() {
    return {
      isready: true,
      checkList: ['序号', '待检船舶', '承运商', '上次检查时间', '当前位置', '检查录入时间', '船舶检查状态'],
      checkListData: ['序号', '待检船舶', '承运商', '上次检查时间', '当前位置', '检查录入时间', '船舶检查状态'],
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      createData: {
        shipId: '',
        checkType: 0
      },
      rules: {
        shipId: { required: true, message: '请选择船舶', trigger: 'blur' }
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      shipList: [],
      btnLoading: false,
      taskCreateData: {
        result: '',
        originalPath: '',
        shipverifyTime: '',
        fakeid: 123,
        taskId: '',
        shipId: '',
        shipPlanId: 0,
        grossscore: ''
      },
      taskRules: {
        result: { required: true, message: '请选择结果', trigger: 'blur' },
        originalPath: { required: true, message: '请上传检查单原件后再提交整改！', trigger: 'blur' },
        shipverifyTime: { required: true, message: '请录入检查时间后再提交整改！', trigger: 'blur' }
      }
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getData()
    this.getShip()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.cbjcatlg = item.result == 1 ? '检查通过' : item.result == 2 ? '检查不通过' : '待检查'
            tableData.push(item)
          })

          let config = [
            { field: 'ship.title', width: 80, align: 'center', title: '待检船舶' },
            { field: 'ship.carriers.title', width: 80, align: 'center', title: '承运商' },
            { field: 'ship.verifyTime', width: 80, align: 'center', title: '上次检查时间' },
            { field: 'ship_plan.site', width: 150, align: 'center', title: '当前位置' },
            { field: 'createTime', width: 80, align: 'center', title: '检查录入时间' },
            { field: 'checkPoint', width: 150, align: 'center', title: '船舶检查状态' },
          ]
          let obj = {
            name: '认证检查',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(this.tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    lookFile(url) {
      window.open(url, '_blank');
    },
    submitPass() {
      this.$refs['formTask'].validate((valid) => {
        if (valid) {
          let data = this.taskCreateData
          if (data.result == 1) {
            data.id = data.taskId
            this.$Api.security_updateshipCheckTaskbyid(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible2 = false
                this.getData()
              }
            })
          } else {
            this.$Api.security_addshipRectif(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible2 = false
                this.getData()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    startRsult(row) {
      this.taskCreateData = {
        result: '',
        originalPath: '',
        shipverifyTime: '',
        fakeid: 123,
        taskId: row.id,
        shipId: row.shipId,
        shipPlanId: row.ship_plan == null ? 0 : row.ship_plan.id,
        grossscore: row.grossscore
      }
      this.dialogVisible2 = true
    },
    handleError() {
      this.$message.error('上传失败，请重新上传！')
      this.btnLoading = false
    },
    beforeUpload() {
      this.btnLoading = true
    },
    onsuccess(response) {
      if (response.code == 200) {
        this.taskCreateData.originalPath = response.data.save_path
      }
      this.btnLoading = false
    },
    submit() {
      this.$refs['formShip'].validate((valid) => {
        if (valid) {
          let data = this.createData
          this.$Api.security_insertshipCheckTask(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.createData.shipId = ''
      this.dialogVisible = true
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          checkId: row.id
        }
        this.$Api.several_quality_deleteShipCheck(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    dropdownChange(e) {
      this.isready = false
      this.$nextTick(() => {
        this.isready = true
      })
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getShip() {
      this.$Api.security_getshiplist().then(res => {
        if (res.code == 200) {
          this.shipList = res.data
        }
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        checkType: 0,
      }
      this.tableLoading = true
      this.$Api.security_getcertifiedcheck(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.CertificationInspection {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>