<template>
  <div class="page ResidentFactory">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="名称：">
          <el-input v-model="searchForm.title" placeholder="请输入名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="编码：">
          <el-input v-model="searchForm.code" placeholder="请输入编码" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="success"
            @click="getData">刷新</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-delete" size="small" type="danger"
            @click="batchDelete">删除</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" @selection-change="handleSelectionChange"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="名称" prop="title">
        </el-table-column>
        <el-table-column align="center" label="简称" prop="subtitle">
        </el-table-column>
        <el-table-column align="center" label="编码" prop="groupCode">
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="20vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="名称/全称：" prop="title">
            <el-input v-model="createdData.title"  placeholder="请输入名称/全称"></el-input>
          </el-form-item>
          <el-form-item label="简称：" prop="subtitle">
            <el-input v-model="createdData.subtitle"  placeholder="请输入简称"></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="groupCode">
            <el-input v-model="createdData.groupCode"  placeholder="请输入编码"></el-input>
          </el-form-item>
          <el-form-item label="港口：" prop="shipPortId">
            <el-select v-model="createdData.shipPortId" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in gkData" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ResidentFactory",
  data() {
    return {
      isAdd: false,
      multipleSelection: [],
      searchForm: {
        title: '',
        code: '',
      },
      createdData: {
        id: '',
        groupCode: '',
        groupType: 4,
        pid: 0,
        subtitle: '',
        title: '',
        shipPortId: ''
      },
      rules: {
        groupCode: {
          required: true, message: '请输入编码', trigger: 'blur'
        },
        subtitle: {
          required: true, message: '请输入简称', trigger: 'blur'
        },
        shipPortId: {
          required: true, message: '请选择港口', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称/全称', trigger: 'blur'
        },
      },
      tableLoading: false,
      dialogVisible: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 1,
        lastPage: 1,
      },
      tableData: [],
      gkData:[]
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getGkData()
  },
  activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
  methods: {
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请勾选删除项')
        return
      }
      let ids = []
      this.multipleSelection.map(item => {
        ids.push(item.id)
      })
      let data = {
        id: ids,
      }
      this.$Api.residentFactory_delete(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          if (ids.length == this.tableData.length) this.page.currentPage = 1
          this.getData()
        }
      })
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            groupCode: this.createdData.groupCode,
            groupType: 4,
            pid: this.createdData.pid,
            subtitle: this.createdData.subtitle,
            shipPortId: this.createdData.shipPortId,
            title: this.createdData.title
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.residentFactoryaddorupdate(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        groupCode: '',
        groupType: 4,
        pid: 0,
        subtitle: '',
        title: '',
        shipPortId: ''
      }
      this.$refs['form'].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createdData = {
        id: row.id,
        groupCode: row.groupCode,
        groupType: 4,
        pid: row.pid,
        subtitle: row.subtitle,
        title: row.title,
        shipPortId: row.shipPortId
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.residentFactory_delete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getGkData(){
      this.$Api.publics_shipPort().then(res=>{
        this.gkData = res.data;
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.searchForm.title,
        groupType: 4,
        pid: 0
      }
      this.tableLoading = true
      this.$Api.memberGroupGary_ListByType(data).then(res => {
        this.tableLoading = false
        this.tableData = res.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.ResidentFactory {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>