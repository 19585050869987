<template>
	<div class="page menu">
		<div class="operate-box u-flex u-col-center">
			<el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary" @click="create">新增
			</el-button>
			<el-button icon="el-icon-sort" plain size="small" type="info" @click="expandTable">展开/折叠</el-button>
		</div>
		<el-table v-if="ready" v-loading="loading" :data="tableData" :default-expand-all="is_expand"
			:tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
			header-row-class-name="header-table">
			<el-table-column label="菜单名称" prop="name">
			</el-table-column>
			<el-table-column label="图标" align="center">
				<template slot-scope="scope">
					<el-icon v-if="scope.row.icon" :class="scope.row.icon"></el-icon>
				</template>
			</el-table-column>
			<el-table-column label="排序" prop="sort" align="center">
			</el-table-column>
			<el-table-column label="组件路径" prop="href">
			</el-table-column>
			<el-table-column label="创建时间" align="center" prop="createtime">
			</el-table-column>
			<el-table-column label="状态" width="70" align="center">
				<template slot-scope="scope">
					<el-tag size="small" v-if="scope.row.states === 1">正常</el-tag>
					<el-tag size="small" type="info" v-else>禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="210" fixed="right">
				<template slot-scope="scope">
					<span class="o-btn" @click="edit(scope.row)">
						<el-icon class="el-icon-edit"></el-icon>
						编辑
					</span>
					<span class="o-btn o-btn-add" @click="add(scope.row)">
						<el-icon class="el-icon-plus"></el-icon>
						新增
					</span>
					<!-- v-btn-root="'/menu/delete'" -->
					<span class="o-btn o-btn-red" @click="remove(scope.row.id)">
						<el-icon class="el-icon-delete"></el-icon>
						删除
					</span>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog destroy-on-close :close-on-click-modal="false" :visible.sync="dialogVisible"
			:title="mode === 'add' ? '添加菜单' : '编辑菜单'" width="600px" @closed="resetCreateData">
			<div>
				<el-form ref="createForm" :model="createData" :rules="createRules" label-width="80px">
					<el-form-item label="上级菜单" prop="parent_id">
						<tree-select :list="treeList" v-model="createData.parent_id"></tree-select>
					</el-form-item>
					<el-form-item label="菜单图标" v-if="createData.type !== 'F'">
						<el-input v-model="createData.icon" size="small"></el-input>
					</el-form-item>
					<el-form-item label="按钮" v-if="createData.type !== 'F'">
						<el-input v-model="createData.button" size="small"></el-input>
					</el-form-item>
					<el-row>
						<el-col :span="12">
							<el-form-item :label="'菜单名称'" prop="name">
								<el-input v-model="createData.name" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="显示排序">
								<el-input-number v-model="createData.sort" :min="0" :precision="0" controls-position="right"
									size="small" style="width: 100%;"></el-input-number>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" v-if="createData.type !== 'F'">
							<el-form-item label="路由地址" prop="href">
								<el-input v-model="createData.href" size="small"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="菜单状态">
								<el-radio-group v-model="createData.states">
									<el-radio v-for="item in menuRoots" :label="item.id">{{ item.title }}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="u-custom-btn-primary" type="primary" @click="createMenu">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "Menu",
	data() {
		return {
			ready: true,
			is_expand: false,
			mode: 'add',
			loading: false,
			dialogVisible: false,
			id: '',
			treeList: [{
				name: '主类目',
				id: 0,
				child: []
			}],
			createData: {
				button: "",
				createid: "",
				createtime: "",
				href: "",
				icon: "",
				id: "",
				name: "",
				numrow: "",
				parent_id: 0,
				remark: "",
				sort: "",
				states: 1,
				target: "_self",
				updatetime: "",

				// parent_id: 0,
				// name: '',
				// icon: '',
				// type: 'M',
				// perms: '',
				// listsort: 0,
				// note: '',
				// url: '',
				// is_hid: 0
			},
			createRules: {
				name: {
					required: true,
					message: '菜单名称不能为空',
					trigger: ['blur', 'change']
				},
				parent_id: {
					required: true,
					message: '请选择上级菜单',
					trigger: 'change'
				},
				href: {
					required: true,
					message: '路由不能为空',
					trigger: ['blur', 'change']
				}
			},
			menuRoots: [{
				title: '正常',
				id: 1
			}, {
				title: '禁用',
				id: -1
			}],
			menuTypes: [{
				title: '目录',
				id: 'M'
			}, {
				title: '菜单',
				id: 'C'
			}, {
				title: '按钮',
				id: 'F'
			}],
			searchForm: {
				name: '',
				is_hid: '',
				is_tree: 1
			},
			tableData: []
		}
	},
	created() {
		this.getData()
	},
	methods: {
		searchTable(t) {
			if (t === 'search') {
				this.getData()
			} else {
				this.searchForm = {
					name: '',
					is_hid: '',
					is_tree: 1
				}
				this.getData()
			}
		},
		expandTable() {
			this.ready = false
			this.is_expand = !this.is_expand
			this.$nextTick(() => {
				this.ready = true
			})
		},
		edit(e) {
			this.id = e.id
			this.mode = 'edit'
			for (let k in e) {
				if (this.createData.hasOwnProperty(k)) {
					this.createData[k] = e[k]
				}
			}
			this.dialogVisible = true
		},
		add(e) {
			this.mode = 'add'
			this.createData.parent_id = e.id
			this.dialogVisible = true
		},
		create() {
			this.mode = 'add'
			this.dialogVisible = true
		},
		remove(id) {
			this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id
				}
				this.$Api.menuList_delete(data).then(res => {
					this.$message.success('删除成功')
					this.getData()
				})
			})

		},
		resetCreateData() {
			this.createData = {
				button: "",
				createid: "",
				createtime: "",
				href: "",
				icon: "",
				id: "",
				name: "",
				numrow: "",
				parent_id: 0,
				remark: "",
				sort: "",
				states: 1,
				target: "_self",
				updatetime: "",
			}
			this.$refs['createForm'].resetFields()
		},
		createMenu() {
			if (this.mode === 'add') {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						let data = {
							title: this.createData.name,
							pid: this.createData.parent_id,
							icon: this.createData.icon,
							href: this.createData.href,
							target: this.createData.target,
							sort: this.createData.sort,
							button: this.createData.button,
							states: this.createData.states,
						}
						this.$Api.menuList_addorupdate(data).then(res => {
							this.dialogVisible = false
							this.$message.success('操作成功')
							this.getData()
						})
					}
				})
			} else {
				this.$refs['createForm'].validate(valid => {
					if (valid) {
						let data = {
							title: this.createData.name,
							pid: this.createData.parent_id,
							icon: this.createData.icon,
							href: this.createData.href,
							target: this.createData.target,
							sort: this.createData.sort,
							button: this.createData.button,
							states: this.createData.states,
							id: this.id
						}
						this.$Api.menuList_addorupdate(data).then(res => {
							this.dialogVisible = false
							this.$message.success('操作成功')
							this.getData()
						})
					}
				})
			}

		},
		getData() {
			this.loading = true
			this.$Api.menuList().then(res => {
				this.tableData = res.data
				this.treeList[0].child = this._deepClone(res.data)
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	padding: 24px 32px;
}

.menu {
	height: auto;
}

.el-table {
	margin-top: 24px;

	.o-btn {
		color: #409EFF;
		cursor: pointer;

		~.o-btn {
			margin-left: 12px;
		}
	}
}

.create-form {
	.el-form-item {
		margin-bottom: 20px;
	}
}
</style>
