<template>
    <div class="page details">
        <el-page-header @back="goBack" content="调度计划">
        </el-page-header>
        <!--列表-->
        <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
            style="width: 100%;margin:20px auto 0;"
            header-row-class-name="header-table">
            <el-table-column  type="index" width="55">
            </el-table-column>
            <el-table-column align="center" label="标题" prop="bt">
                <template slot-scope="scope">
                    <a :href="scope.row.path1" target="_blank">{{ scope.row.bt }}</a>
                </template>
            </el-table-column>
            <el-table-column align="center" label="日期" prop="rq" width="180">
            </el-table-column>
        </el-table>
    </div>
</template>
 
<script>
export default {
    name: "details",
    data() {
        return {
            tableData: [],
            tableLoading: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.tableLoading = true
            this.$Api.settle_largescreenDatagetPlans().then(res => {
                this.tableLoading = false
                this.tableData = res.data
            })
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/vesseMonitor/huoqujihua')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.details {
    padding-top: 20px;
    padding-left: 20px;
}
</style>