<template>
  <div class="page MonthlyPlan">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="月份：">
          <el-date-picker v-model="searchForm.monthly" clearable value-format="yyyy-MM" type="month" size="small"
            placeholder="请选择月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="流向：">
          <el-select clearable v-model="searchForm.companyId" placeholder="请搜索或选择内容" filterable size="small">
            <el-option v-for="(item, index) in companyList" :key="item.id" :value="item.id"
              :label="item.subtitle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发港：">
          <el-select clearable v-model="searchForm.from_port_code" placeholder="请搜索或选择内容" filterable size="small">
            <el-option v-for="(item, index) in shipPortBegin" :key="item.id" :value="item.code"
              :label="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到港：">
          <el-select clearable v-model="searchForm.to_port_code" placeholder="请搜索或选择内容" filterable size="small">
            <el-option v-for="(item, index) in shipPortEnd" :key="item.id" :value="item.code"
              :label="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品号：">
          <el-select clearable v-model="searchForm.oil_code" placeholder="请搜索或选择内容" filterable size="small">
            <el-option v-for="(item, index) in oilList" :key="item.id" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData2" tooltip-effect="dark"
        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="物料组" prop="name">
        </el-table-column>
        <el-table-column align="center" label="计划数量" prop="num1">
        </el-table-column>
        <el-table-column align="center" label="前期顺延" prop="num2">
        </el-table-column>
        <el-table-column align="center" label="配置计划" prop="num3">
        </el-table-column>
        <el-table-column align="center" label="已完成" prop="num4">
        </el-table-column>
        <el-table-column align="center" label="差额" prop="num5">
        </el-table-column>
        <el-table-column align="center" label="完成进度" prop="num6">
          <template slot-scope="scope">
            <span>{{ scope.row.num6 }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
            @click="addRole">月度计划同步</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" size="small" type="primary"
            @click="getData">刷新</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTables" v-loading="tableLoading" border :data="tableData" tooltip-effect="dark"
        height="(100%-250px)" max-height="(100%-250px)" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="index" width="55">
        </el-table-column>
        <el-table-column align="center" label="流向" prop="companyName">
        </el-table-column>
        <el-table-column align="center" label="发港" prop="from_port_code_name">
        </el-table-column>
        <el-table-column align="center" label="到港" prop="to_port_code_name">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oil_code_type.title">
        </el-table-column>
        <el-table-column align="center" label="油品" prop="oil_type_name">
        </el-table-column>
        <el-table-column align="center" label="配置计划" prop="setting_num">
        </el-table-column>
        <el-table-column align="center" label="变更数量" prop="qty2">
        </el-table-column>
        <el-table-column align="center" label="上月提前" prop="qty3">
        </el-table-column>
        <el-table-column align="center" label="前期顺延" prop="pre_num">
        </el-table-column>
        <el-table-column align="center" label="计划数量" prop="num">
        </el-table-column>
        <el-table-column align="center" label="已完成" prop="finishNum">
        </el-table-column>
        <el-table-column align="center" label="差额">
          <template slot-scope="scope">
            <span>{{ (Number(scope.row.num - Number(scope.row.finishNum)).toFixed(2)) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="完成进度">
          <template slot-scope="scope">
            <span>{{ (Number(scope.row.finishNum) * 100 /
              Number(scope.row.num)).toFixed(2) != 'NaN' ? (Number(scope.row.finishNum) * 100 /
                Number(scope.row.num)).toFixed(2) : '0.00' }}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">下达航次计划</el-button>
            <el-button type="primary" size="mini" @click="record(scope.row)">完成数记录</el-button>
            <el-button type="primary" size="mini" @click="addFinish(scope.row)">新增完成数</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增检查 -->
      <el-dialog :close-on-click-modal="false" title="添加航次计划" :visible.sync="dialogVisible" destroy-on-close top="12vh"
        width="650px">
        <div class="addBox">
          <el-form ref="formAdd" :model="createData" :rules="rules" label-width="130px">
            <el-form-item label="计划月份：" prop="monthly">
              <el-input v-model="createData.monthly" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="选择流向：" prop="belongId">
              <el-select v-model="createData.belongId" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in companyList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出发港：" prop="startPort">
              <el-select v-model="createData.startPort" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in shipPortBegin" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到达港：" prop="destPort">
              <el-select v-model="createData.destPort" placeholder="请搜索或选择内容" filterable @change="destPortChange"
                size="small">
                <el-option v-for="(item, index) in shipPortEnd" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属油库：" prop="oliName">
              <el-input v-model="createData.oliName" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="选择承运商：" prop="companyId">
              <el-select v-model="createData.companyId" placeholder="请搜索或选择内容" filterable @change="carrierChange"
                size="small">
                <el-option v-for="(item, index) in carrierList" :key="item.id" :value="item.id"
                  :label="item.subtitle"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择船：" prop="shipId">
              <el-select v-model="createData.shipId" placeholder="请搜索或选择内容" filterable size="small" @change="shipChange">
                <el-option v-for="(item, index) in createDataShip" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="油品：">
              <el-select clearable v-model="createData.oilTypeId" placeholder="请搜索或选择内容" filterable size="small">
                <el-option v-for="(item, index) in oilList" :key="item.id" :value="item.id"
                  :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-row type="flex" class="row-bg" justify="space-between" style="width: 100%;">
              <el-col :span="12">
                <el-form-item label="数量：" prop="oilTon">
                  <el-input v-model="createData.oilTon" type="number" size="small" @focus='oilTonFocus'
                    @blur="oilTonBlur"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="预计吃水(米)：" prop="draft">
                  <el-input v-model="createData.draft" disabled size="small" v-loading="draftLoading"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">提交</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">流向</th>
              <th class="tg-0lax">发港</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">品号</th>
              <th class="tg-0lax">油品</th>
              <th class="tg-0lax">配置计划</th>
              <th class="tg-0lax">变更数量</th>
              <th class="tg-0lax">上月提前</th>
              <th class="tg-0lax">前期顺延</th>
              <th class="tg-0lax">计划数量</th>
              <th class="tg-0lax">已完成</th>
              <th class="tg-0lax">差额</th>
              <th class="tg-0lax">完成进度</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.companyName }}</th>
            <th class="tg-0lax">{{ item.from_port_code_name }}</th>
            <th class="tg-0lax">{{ item.to_port_code_name }}</th>
            <th class="tg-0lax">{{ item.oil_code_type.title }}</th>
            <th class="tg-0lax">{{ item.oil_type_name }}</th>
            <th class="tg-0lax">{{ item.setting_num }}</th>
            <th class="tg-0lax">{{ item.qty2 }}</th>
            <th class="tg-0lax">{{ item.qty3 }}</th>
            <th class="tg-0lax">{{ item.pre_num }}</th>
            <th class="tg-0lax">{{ item.num }}</th>
            <th class="tg-0lax">{{ item.finishNum }}</th>
            <th class="tg-0lax">{{ (Number(item.num - Number(item.finishNum)).toFixed(2)) }}</th>
            <th class="tg-0lax">{{ (Number(item.finishNum) * 100 /
              Number(item.num)).toFixed(2) != 'NaN' ? (Number(item.finishNum) * 100 /
                Number(item.num)).toFixed(2) : '0.00' }}%</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "MonthlyPlan",
  data() {
    return {
      draftLoading: false,
      createData: {
        belongId: '',//流向
        companyId: '',//承运商
        destPort: '',
        draft: '',//预计吃水(米)
        monthly: '',
        oilTon: '',//数量
        oilType: '',//油种类ID
        oilTypeId: '',//油ID
        oliName: '',//所属油库
        shipId: '',
        startPort: '',
        id: ''
      },
      createDataShip: [],
      changeShipTipColor: "",
      changeShipTip: '',
      rules: {
        monthly: { required: true, message: '必填项不能为空', trigger: 'blur' },
        belongId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        startPort: { required: true, message: '必填项不能为空', trigger: 'blur' },
        destPort: { required: true, message: '必填项不能为空', trigger: 'blur' },
        companyId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        shipId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        oilTypeId: { required: true, message: '必填项不能为空', trigger: 'blur' },
        oilTon: { required: true, message: '必填项不能为空', trigger: 'blur' },
        draft: { required: true, message: '必填项不能为空', trigger: 'blur' },
      },
      dialogVisible: false,
      multipleSelection: [],
      searchForm: {
        monthly: '',
        companyId: '',
        from_port_code: '',
        oil_code: '',
        to_port_code: '',
        is_have_page: 0,//0不分页 1分页
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      tableData2: [{
        name: '柴油',
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        totlaNum: 0
      }, {
        name: '汽油',
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        totlaNum: 0
      }, {
        name: '煤油',
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        totlaNum: 0
      }],
      carrierList: [],
      companyList: [],
      oilList: [],
      oilType: [],
      shipList: [],
      shipPort: [],
      shipPortBegin: [],
      shipPortEnd: [],
      shipPortList: [],
      oilWarehouse: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getDict()
    this.getoilWarehouseInfo()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.celg = (Number(item.num - Number(item.finishNum)).toFixed(2))
            item.wcjdlg = (Number(item.finishNum) * 100 /
              Number(item.num)).toFixed(2) != 'NaN' ? (Number(item.finishNum) * 100 /
                Number(item.num)).toFixed(2) : '0.00' + '%'
            tableData.push(item)
          })
          let config = [
            { field: 'companyName', width: 80, align: 'center', title: '流向' },
            { field: 'from_port_code_name', width: 80, align: 'center', title: '发港' },
            { field: 'to_port_code_name', width: 80, align: 'center', title: '到港' },
            { field: 'oil_code_type.title', width: 150, align: 'center', title: '品号' },
            { field: 'oil_type_name', width: 150, align: 'center', title: '油品' },
            { field: 'setting_num', width: 150, align: 'center', title: '配置计划' },
            { field: 'qty2', width: 80, align: 'center', title: '变更数量' },
            { field: 'qty3', width: 80, align: 'center', title: '上月提前' },
            { field: 'pre_num', width: 80, align: 'center', title: '前期顺延' },
            { field: 'num', width: 80, align: 'center', title: '计划数量' },
            { field: 'finishNum', width: 100, align: 'center', title: '已完成' },
            { field: 'celg', width: 80, align: 'center', title: '差额' },
            { field: 'wcjdlg', width: 160, align: 'center', title: '完成进度' },
          ]
          let obj = {
            name: '月度计划',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    submit() {
      this.$refs['formAdd'].validate((valid) => {
        if (valid) {
          if (this.changeShipTipColor == 'red') {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `<strong style="color:${this.changeShipTipColor}">${this.changeShipTip}</strong>`
            });
            return
          }
          let data = this.createData
          this.$Api.plan_monthly_shipAdd(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    oilTonFocus() {
      this.draftLoading = true
    },
    oilTonBlur() {
      if (this.createData.shipId == '') {
        this.$message.warning('由于没有选择船舶，无法获取预计吃水数据')
        this.draftLoading = false
        return
      }
      let data = {
        oilTypeId: this.createData.oilTypeId,
        shipId: this.createData.shipId,
        oilTon: this.createData.oilTon
      }
      this.$Api.plan_monthly_GetTonInfo(data).then(res => {
        if (res.code == 200) {
          this.createData.draft = res.data
        }
        this.draftLoading = false
      })
    },
    shipChange(e) {
      let data = {
        shipId: e
      }
      this.$Api.plan_monthly_getShipState(data).then(res => {
        if (res.code == 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `<strong style="color:${res.data.color}">${res.data.msg}</strong>`
          });
          this.changeShipTipColor = res.data.color
          this.changeShipTip = res.data.msg
        }
      })
    },
    carrierChange(e) {//承运商切换获取下面的船舶列表
      this.createData.shipId = ''
      this.createDataShip = []
      if (this.shipList.length > 0) {
        this.shipList.map(item => {
          if (item.carriersId == e) {
            this.createDataShip.push(item)
          }
        })
      }
    },
    destPortChange(e) {//到达港切换获取油库;
      let id = ''
      this.createData.oliName = ''
      this.shipPortEnd.map(item => {
        if (item.id == e) {
          id = item.id
        }
      })
      if (id != '') {
        this.oilWarehouse.map(item => {
          if (item.shipPortId == id) {
            this.createData.oliName = item.title
          }
        })
      }
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createData = {
        belongId: row.companyId,//流向
        companyId: '',//承运商
        destPort: row.end_ship_port.id,
        draft: '',//预计吃水(米)
        monthly: this.searchForm.monthly,
        oilTon: '',//数量
        oilType: row.oil_type,//油种类ID
        oilTypeId: row.oil_code_type.id,//油ID
        oliName: '',//所属油库
        shipId: '',
        startPort: row.begin_ship_port.id,
        id: row.id
      }
      this.changeShipTipColor = ""
      this.changeShipTip = ''
      if (this.oilWarehouse.length > 0) {
        this.oilWarehouse.map(item => {
          if (item.shipPortId == row.end_ship_port.id) {
            this.createData.oliName = item.title
          }
        })
      }
      this.dialogVisible = true
    },
    record(row) {
      this.$router.push({ name: 'MonthlyPlanRecord', params: row })
    },
    addFinish(row) {
      this.$router.push({ name: 'monthlyPlanAddFinish', params: row })
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.searchForm.is_have_page = 0
        this.getData()
      }
    },
    getoilWarehouseInfo() {
      this.$Api.oilWarehouseInfo_Dict2().then(res => {
        if (res.code == 200) {
          this.oilWarehouse = res.data.oilWarehouse
        }
      })
    },
    getDict() {
      this.$Api.plan_monthlyDict().then(res => {
        this.carrierList = res.data.carrierList
        this.companyList = res.data.companyList
        this.oilList = res.data.oilList
        this.oilType = res.data.oilType
        this.shipList = res.data.shipList
        this.shipPortBegin = res.data.shipPortBegin
        this.shipPort = res.data.shipPort
        this.shipPortEnd = res.data.shipPortEnd
        this.shipPortList = res.data.shipPortList
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        ...this.searchForm
      }
      this.tableLoading = true
      this.$Api.plan_monthly_List(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.length > 0) {
            if (this.companyList.length > 0) {
              res.data.map(item => {
                this.companyList.map(key => {
                  if (item.companyId == key.id) {
                    item.companyName = key.subtitle
                  }
                })
              })
            }
            if (this.shipPort.length > 0) {
              res.data.map(item => {
                this.shipPort.map(key => {
                  if (item.from_port_code == key.code) {
                    item.from_port_code_name = key.title
                  }
                  if (item.to_port_code == key.code) {
                    item.to_port_code_name = key.title
                  }
                })
              })
            }
            res.data.map(item => {
              if (item.oil_type == 1) { //柴油
                this.tableData2[0].totlaNum = this.tableData2[0].totlaNum + Number(item.num)
                this.tableData2[0].num1 = this.tableData2[0].num1 + Number(item.num)
                this.tableData2[0].num2 = this.tableData2[0].num2 + Number(item.pre_num)
                this.tableData2[0].num3 = this.tableData2[0].num3 + Number(item.setting_num)
                this.tableData2[0].num4 = this.tableData2[0].num4 + Number(item.finishNum)

              } else if (item.oil_type == 2) {  //汽油
                this.tableData2[1].totlaNum = this.tableData2[1].totlaNum + Number(item.num)
                this.tableData2[1].num1 = this.tableData2[1].num1 + Number(item.num)
                this.tableData2[1].num2 = this.tableData2[1].num2 + Number(item.pre_num)
                this.tableData2[1].num3 = this.tableData2[1].num3 + Number(item.setting_num)
                this.tableData2[1].num4 = this.tableData2[1].num4 + Number(item.finishNum)

              } else if (item.oil_type == 3) {//煤油
                this.tableData2[2].totlaNum = this.tableData2[2].totlaNum + Number(item.num)
                this.tableData2[2].num1 = this.tableData2[2].num1 + Number(item.num)
                this.tableData2[2].num2 = this.tableData2[2].num2 + Number(item.pre_num)
                this.tableData2[2].num3 = this.tableData2[2].num3 + Number(item.setting_num)
                this.tableData2[2].num4 = this.tableData2[2].num4 + Number(item.finishNum)

              } else {

              }
            })
            this.tableData2[0].num5 = (this.tableData2[0].num1 - this.tableData2[0].num4).toFixed(2)
            this.tableData2[0].num6 = (this.tableData2[0].num4 * 100 / this.tableData2[0].num1).toFixed(2)
            this.tableData2[1].num5 = (this.tableData2[1].num1 - this.tableData2[1].num4).toFixed(2)
            this.tableData2[1].num6 = (this.tableData2[1].num4 * 100 / this.tableData2[1].num1).toFixed(2)
            this.tableData2[2].num5 = (this.tableData2[2].num1 - this.tableData2[2].num4).toFixed(2)
            this.tableData2[2].num6 = (this.tableData2[2].num4 * 100 / this.tableData2[2].num1).toFixed(2)
          } else {
            this.tableData2 = [{
              name: '柴油',
              num1: 0,
              num2: 0,
              num3: 0,
              num4: 0,
              num5: 0,
              num6: 0,
              totlaNum: 0
            }, {
              name: '汽油',
              num1: 0,
              num2: 0,
              num3: 0,
              num4: 0,
              num5: 0,
              num6: 0,
              totlaNum: 0
            }, {
              name: '煤油',
              num1: 0,
              num2: 0,
              num3: 0,
              num4: 0,
              num5: 0,
              num6: 0,
              totlaNum: 0
            }]
          }
          this.tableData = res.data
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.MonthlyPlan {
  height: auto;

  .searchBox {
    /deep/.el-table .el-table__cell {
      padding: 4px 0;
    }
  }

  .container {
    width: 100%;
    height: 800px;
    // flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }



    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .addBox {
      height: 50vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .el-select {
        width: 100%;
      }
    }

    .el-table {
      height: 800px;
    }
  }
}
</style>