import { render, staticRenderFns } from "./walkthroughsfinish.vue?vue&type=template&id=125af1db&scoped=true&"
import script from "./walkthroughsfinish.vue?vue&type=script&lang=js&"
export * from "./walkthroughsfinish.vue?vue&type=script&lang=js&"
import style0 from "./walkthroughsfinish.vue?vue&type=style&index=0&id=125af1db&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125af1db",
  null
  
)

export default component.exports