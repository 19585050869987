<template>
    <div class="page score_Details">
        <el-page-header @back="goBack">
        </el-page-header>
        <div class="container">
            <el-divider content-position="left">{{ rowData.title }}</el-divider>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="assessItem" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index" width="55">
                </el-table-column>
                <el-table-column align="center" label="考核项目" prop="title" width="200">
                </el-table-column>
                <el-table-column align="center" label="分值" prop="points" width="55">
                </el-table-column>
                <el-table-column align="center" label="细则" prop="roles">
                </el-table-column>
            </el-table>
            <el-divider content-position="left">考核承运商</el-divider>
            <!--列表-->
            <el-table v-if="isread" ref="multipleTable" border v-loading="tableLoading" :data="carriersList"
                tooltip-effect="dark" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" prop="title" width="150">
                </el-table-column>
                <el-table-column align="center" :label="item.title" v-for="(item, index) in assessItem" :key="index">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="submitScore(scope.row, item)">评分</el-button>
                        <div v-if="assessCarriers.length > 0">
                            <span v-for="(key, kindex) in assessCarriers" :key="kindex">
                                <span
                                    v-if="key.carriersId == scope.row.id && key.assessItemId == item.id && key.assessId == item.assessId">{{
                                        key.finalpoints }}</span>
                            </span>
                        </div>
                        <div v-else>暂无信息</div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 考核信息 -->
            <el-dialog :close-on-click-modal="false" title="考核信息" :visible.sync="dialogVisible" destroy-on-close top="20vh"
                width="700px">
                <div class="dialogCont">
                    <el-form :model="createdData" :rules="rules" label-width="120px" ref="form">
                        <el-form-item label="承运商：" prop="show.carriersName">
                            <span>{{ createdData.show.carriersName || '暂无信息' }}</span>
                        </el-form-item>
                        <el-form-item label="考核项：" prop="title">
                            <span>{{ createdData.title || '暂无信息' }}</span>
                        </el-form-item>
                        <el-form-item label="分值：" prop="points">
                            <span>{{ createdData.points || '暂无信息' }}</span>
                        </el-form-item>
                        <el-form-item label="考核细则：" prop="show.assessroles">
                            <span>{{ createdData.show.assessroles || '暂无信息' }}</span>
                        </el-form-item>
                        <el-divider content-position="left">考核信息</el-divider>
                        <el-form-item label="分值：" prop="finalpoints">
                            <el-input placeholder="请输入分值" v-model="createdData.finalpoints" type="number" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备注：" prop="intro">
                            <el-input type="textarea" :rows="6" placeholder="请输入备注内容" v-model="createdData.intro">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuse">提 交</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "score_Details",
    data() {
        return {
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            editId: '',
            createdData: {
                points: '',
                intro: '',
                title: '',
                assessId: '',
                assessItemId: '',
                carriersId: '',
                rater: '',
                finalpoints: '',
                show: {
                    carriersName: '',
                    assessroles: '',
                },
            },
            rules: {
                finalpoints: {
                    required: true, message: '请输入分值', trigger: 'blur'
                },
                intro: {
                    required: true, message: '请输入备注内容', trigger: 'blur'
                },
            },
            assessItem: [],
            carriersList: [],
            assessCarriers: [],
            userInfo: {}
        }
    },
    created() {
        this.rowData = this.$route.params
        if (this.rowData?.id) {
            this.getData()
        }
        this.userInfo = this.$store.state.userInfo
    },
    
    activated() {
        this.rowData = this.$route.params
        if (this.rowData?.id) {
            this.getData()
        }
        this.userInfo = this.$store.state.userInfo
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        submitScore(row, item) {
            let data = {
                assessId: item.assessId,
                assessItemId: item.id,
                carriersId: row.id
            }
            this.$Api.carriers_assessCarriersItem(data).then(res => {
                if (res.code == 200) {
                    this.createdData = {
                        points: res.data.assessItem[0].points,
                        intro: '',
                        title: res.data.assessItem[0].title,
                        assessId: res.data.assessItem[0].assessId,
                        assessItemId: res.data.assessItem[0].id,
                        carriersId: res.data.carriers[0].id,
                        rater: this.userInfo.id,
                        finalpoints: '',
                        show: {
                            carriersName: res.data.carriers[0].title,
                            assessroles: res.data.assessItem[0].roles,
                        },
                    }
                    if (res.data.assessCarriers.length > 0) {
                        this.createdData.intro = res.data.assessCarriers[0].intro
                        this.createdData.finalpoints = res.data.assessCarriers[0].finalpoints
                        this.editId = res.data.assessCarriers[0].id
                        this.isAdd = false
                    } else {
                        this.isAdd = true
                    }
                    this.dialogVisible = true
                }
            })
        },
        subRefuse() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.createdData))
                    delete data.show
                    if (!this.isAdd) data.id = this.editId
                    this.$Api.carriers_saveAssessCarriersScore(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getData()
                            this.dialogVisible = false
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        clearCreateData() {
            this.createdData = {
                id: '',
                title: '',
                assessId: '',
                roles: '',
                points: '',
                itemtype: '',
                rater: '',
            }
            this.$refs['form'].resetFields();
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/carrier/assessmentTasks_Details')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getData() { // 获取数据
            let data = {
                assessId: this.rowData.id,
            }
            this.tableLoading = true
            this.isread = false
            this.$Api.carriers_ScoreitemList(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.assessItem = res.data.assessItem
                    this.carriersList = res.data.carriersList
                    this.assessCarriers = res.data.assessCarriers
                }
            })
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isread = true
                })
            })

        },
    }
}
</script>
  
<style lang="scss" scoped>
.score_Details {
    padding-top: 20px;
    padding-left: 20px;
    height: initial;

    .container {
        width: 100%;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .title {
            margin: 0px 0 12px;
            padding-left: 15px;
            font-size: 16px;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .el-divider__text.is-left {
            font-size: 16px;
            font-weight: 600;
        }

        .tip {
            font-size: 14px;
            color: red;
        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }

        .dialogCont {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-steps--vertical {
                height: initial;
            }
        }
    }
}
</style>