<template>
  <div class="page AssessmentTasks">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="考核标题：">
          <el-input v-model="searchForm.title" placeholder="请输入考核标题" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加考核</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRoleOther">添加其他考核</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="考核标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="考核时间" prop="startTime">
        </el-table-column>
        <el-table-column align="center" label="结束时间" prop="endTime">
        </el-table-column>
        <el-table-column align="center" label="统计方式" prop="assessType">
          <template slot-scope="scope">
            <span v-if="scope.row.assessType == 1">月度</span>
            <span v-else-if="scope.row.assessType == 2">季度</span>
            <span v-else-if="scope.row.assessType == 3">年度</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="范围" prop="sumDate">
        </el-table-column>
        <el-table-column align="center" label="发布状态" prop="is_publish_text">
        </el-table-column>
        <el-table-column align="center" label="操作" width="260" fixed="right">
          <template slot-scope="scope">
            <div v-if="scope.row.assessType != 4">
              <el-button v-if="scope.row.startScore == 0" type="primary" size="mini"
                @click="startScore(scope.row)">开始评分</el-button>
              <el-button v-if="scope.row.assessType != 2" type="primary" size="mini"
                @click="assessment(scope.row)">考核项</el-button>
              <el-button v-if="scope.row.isPublish == 0" type="primary" size="mini"
                @click="editerow(scope.row)">编辑</el-button>
              <el-button v-if="scope.row.isPublish == 0" type="danger" size="mini"
                @click="deleterow(scope.row)">删除</el-button>
            </div>
            <div v-if="scope.row.assessType == 4">
              <el-button type="primary" size="mini" @click="editerow(scope.row)">查看</el-button>
              <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="12vh" width="750px">
        <div class="addoreidt">
          <el-form ref="form" :model="createdData" :rules="rules" label-width="130px">
            <el-form-item label="考核名称：" prop="title">
              <el-input v-model="createdData.title" clearable placeholder="请输入考核名称"></el-input>
            </el-form-item>
            <el-form-item label="考核开始时间：" prop="startTime">
              <el-date-picker v-model="createdData.startTime" type="date" value-format="yyyy-MM-dd"
                placeholder="请选择考核开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核结束时间：" prop="endTime">
              <el-date-picker v-model="createdData.endTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择考核结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核类型：" prop="assessType">
              <el-select v-model="createdData.assessType" placeholder="请选择考核类型" @change="assessTypeChange">
                <el-option label="月度考核" :value="1"></el-option>
                <el-option label="季度考核" :value="2"></el-option>
                <el-option label="年度考核" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="范围(月)" prop="sumDate" v-show="this.createdData.assessType == 1">
              <el-date-picker v-model="createdData.sumDate" type="month" value-format="yyyy-MM" placeholder="请选择范围(月)">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="范围(季)" prop="sumDate" v-show="this.createdData.assessType == 2">
              <el-date-picker v-model="createdData.sumDate" type="year" value-format="yyyy" placeholder="请选择范围(季)">
              </el-date-picker>
              <el-select v-model="createdData.jidu" style="width: 100%;">
                <el-option label="一季度" :value="1"></el-option>
                <el-option label="二季度" :value="2"></el-option>
                <el-option label="三季度" :value="3"></el-option>
                <el-option label="四季度" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="范围(年)" prop="sumDate" v-show="this.createdData.assessType == 3">
              <el-date-picker v-model="createdData.sumDate" type="year" value-format="yyyy" placeholder="请选择范围(年)">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核承运商：" prop="carriersList">
              <el-cascader v-model="createdData.carriersList" :options="carriers"
                :props="{ multiple: true, label: 'subtitle2', value: 'id' }" clearable></el-cascader>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 添加/查看其他考核项 -->
      <el-dialog :close-on-click-modal="false" :title="isAddOther ? '添加其他考核项' : '查看其他考核项'" :visible.sync="dialogVisible2"
        destroy-on-close top="12vh" width="750px">
        <div class="addoreidt">
          <el-form ref="formOter" :model="createdDataOther" :rules="rulesOther" label-width="130px">
            <el-form-item label="考核名称：" prop="title">
              <el-input v-model="createdDataOther.title" clearable placeholder="请输入考核名称"></el-input>
            </el-form-item>
            <el-form-item label="考核开始时间：" prop="startTime">
              <el-date-picker v-model="createdDataOther.startTime" type="date" value-format="yyyy-MM-dd"
                placeholder="请选择考核开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核结束时间：" prop="endTime">
              <el-date-picker v-model="createdDataOther.endTime" type="date" value-format="yyyy-MM-dd"
                placeholder="请选择考核结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核类型：" prop="assessType">
              <el-select v-model="createdDataOther.assessType" disabled placeholder="请选择考核类型">
                <el-option label="其他考核" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="附件：" prop="fileList">
              <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                <el-button icon="el-icon-upload2" size="small" type="primary" style="margin: 8px 0;"
                  v-loading="fileLoading">点击上传</el-button>
              </el-upload>
              <!--列表-->
              <el-table v-if="showReady" ref="multipleTable" border :data="createdDataOther.fileList"
                tooltip-effect="dark" style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="文件名" prop="name">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="status">
                  <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.status == 'success'">上传成功</el-tag>
                    <el-tag type="danger" v-else>上传失败</el-tag>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div style="display: flex;align-items: center;justify-content: center;">
                      <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload2"
                        :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                        <el-button size="mini" v-if="scope.row.status != 'success'" type="primary"
                          style="margin: 8px 10px;" v-loading="fileLoading" @click="cxupload(scope.$index)"
                          icon="el-icon-upload2">重新上传</el-button>
                      </el-upload>
                      <el-button type="danger" size="mini" @click="deleterowOther(scope.row, scope.$index)">删除</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="subRefuseOther">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AssessmentTasks",
  data() {
    return {
      showReady: true,
      isAddOther: false,
      isAdd: false,
      multipleSelection: [],
      searchForm: {
        title: ''
      },
      createdData: {
        assessType: 1,
        carriersList: '',
        endTime: '',
        startTime: '',
        sumDate: '',
        jidu: 1,
        title: '',
        id: ''
      },
      rules: {
        assessType: {
          required: true, message: '请选择考核类型', trigger: 'blur'
        },
        carriersList: {
          required: true, message: '请选择考核承运商', trigger: 'blur'
        },
        endTime: {
          required: true, message: '请选择考核结束时间', trigger: 'blur'
        },
        startTime: {
          required: true, message: '请选择考核开始时间', trigger: 'blur'
        },
        sumDate: {
          required: true, message: '请选择范围', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入考核名称', trigger: 'blur'
        },
      },
      createdDataOther: {
        assessType: 4,
        fileList: [],
        endTime: '',
        startTime: '',
        title: '',
      },
      rulesOther: {
        title: {
          required: true, message: '请输入考核名称', trigger: 'blur'
        },
        endTime: {
          required: true, message: '请选择考核结束时间', trigger: 'blur'
        },
        startTime: {
          required: true, message: '请选择考核开始时间', trigger: 'blur'
        },
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      carriers: [{
        subtitle2: '所有承运商',
        id: 9999,
        children: []
      }],
      cxIndex: '',
      fileLoading: false,
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getCarriersList()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    assessTypeChange() {
      this.createdData.sumDate = ''
    },
    assessment(row) {
      this.$router.push({ name: 'AssessmentTasksDetails', params: row })
    },
    subRefuseOther() {
      this.$refs['formOter'].validate((valid) => {
        if (valid) {
          let data = {
            title: this.createdDataOther.title,
            assessType: 4,
            startTime: this.createdDataOther.startTime,
            endTime: this.createdDataOther.endTime,
            sumDate: '',
            type: 1,
          }
          if (this.createdDataOther.fileList.length > 0) {
            data.FilePath = this.createdDataOther.fileList[0].response.data.save_path
          }
          this.$Api.carriers_saveAssess(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible2 = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    deleterowOther(row, index) {
      this.$confirm('是否确定删除该文件', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.createdDataOther.fileList.splice(index, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    addRoleOther() {
      this.isAddOther = true
      this.dialogVisible2 = true
      this.clearCreateDataOther()
    },
    clearCreateDataOther() {
      this.createdDataOther = {
        assessType: 4,
        fileList: [],
        endTime: '',
        startTime: '',
        title: ''
      }
      this.$refs['formOter'].resetFields();
    },
    beforeUpload() {
      this.cxIndex = ''
      this.fileLoading = true
    },
    beforeUpload2() {
      this.fileLoading = true
      this.showReady = false
    },
    onsuccess(response, file, fileList) {
      if (response.code == 200) {
        if (this.cxIndex === '') {
          this.createdDataOther.fileList.push(file);
        } else {
          this.createdDataOther.fileList[this.cxIndex] = file
          this.showReady = true
        }
      } else {
        file.status = 'faile'
        if (this.cxIndex === '') {
          this.createdDataOther.fileList.push(file);
        } else {
          this.createdDataOther.fileList[this.cxIndex] = file
          this.showReady = true
        }
      }
      this.fileLoading = false
    },
    handleError(err, file, fileList) {
      let errItem = {
        name: file.name,
        status: 'faile'
      }
      if (this.cxIndex === '') {
        this.createdDataOther.fileList.push(errItem);
      } else {
        this.createdDataOther.fileList[this.cxIndex] = errItem
        this.showReady = true
      }
      this.fileLoading = false
    },
    startScore(row) {
      this.$confirm('是否开始评分?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id,
        }
        this.$Api.carriers_updateStartScore(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = this.createdData
          if (this.createdData.carriersList.length > 0) {
            let arr = []
            this.createdData.carriersList.map(item => {
              arr.push(item[1])
            })
            data.carriersList = arr.join(',')
          }
          if (this.createdData.assessType == 2) {
            data.sumDate = this.createdData.sumDate + '-0' + this.createdData.jidu
          }
          delete data.jidu
          if (this.isAdd) delete data.id
          this.$Api.carriers_saveAssess(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.getData()
              if (this.isAdd) {
                // if (this.createdData.assessType == 1 || this.createdData.assessType == 3) {
                  let data = {
                    id: res.data
                  }
                  this.$Api.carriers_assessSummaryTaskitemUpload(data)
                // }
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.isAdd = true
      this.dialogVisible = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createdData = {
        assessType: 1,
        carriersList: '',
        endTime: '',
        startTime: '',
        sumDate: '',
        title: '',
        id: '',
        jidu: 1
      }
      this.$refs['form'].resetFields();
    },
    editerow(row) {
      this.createdData = {
        assessType: row.assessType,
        carriersList: row.carriersList,
        endTime: row.endTime,
        startTime: row.startTime,
        sumDate: row.sumDate,
        title: row.title,
        id: row.id
      }
      if (row.carriersList != '' && row.carriersList != null) {
        let arr = []
        let carr = row.carriersList.split(',')
        carr.map(item => {
          arr.push([9999, item])
        })
        this.createdData.carriersList = arr
      }
      if (row.assessType == 2) {
        let s = row.sumDate.split('-')
        this.createdData.sumDate = s[0]
        this.createdData.jidu = s[1]
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.carriers_deleteAssess(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData();
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getCarriersList() {
      this.$Api.carriers_getCarriersList().then(res => {
        if (res.code == 200) {
          this.carriers[0].children = res.data
        }
      })
    },
    getData() { // 获取数据
      let data = {
        title: this.searchForm.title,
        page: this.page.currentPage,
        limit: this.page.pageSize,
      }
      this.tableLoading = true
      this.$Api.carriers_getAssessList(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = res.data.total
          this.tableData = res.data.data
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.AssessmentTasks {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .addoreidt {
      width: 100%;
      height: 50vh;
      overflow-y: scroll;

      /deep/.el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }

      /deep/.el-select {
        width: 100%;
      }

      /deep/.el-cascader {
        width: 100%;
      }
    }
  }
}
</style>