<template>
    <div class="page OnlineShipInfo">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="选择承运商：">
                    <el-select clearable v-model="searchForm.carriersId" @change="getData" placeholder="请选择承运商"
                        size="small">
                        <el-option v-for="item in selectList" :value="item.id" :label="item.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
                        @click="searchTable('search')">刷新报表
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                        @click="download(1)">导出船舶信息
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                        @click="download(2)">导出船员
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" max-height="620px" :data="tableData"
                tooltip-effect="dark" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index" label="序号">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="title">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="carrierstitle">
                </el-table-column>
                <el-table-column align="center" width="120" label="MMIS" prop="ais">
                </el-table-column>
                <el-table-column align="center" label="船舶编码" prop="acode">
                </el-table-column>
                <el-table-column align="center" label="满载吃水" prop="maxWater">
                </el-table-column>
                <el-table-column align="center" label="装载量(吨)" prop="maxCapacity">
                </el-table-column>
                <el-table-column align="center" label="过坝运营" prop="canCrossBamName">
                </el-table-column>
                <el-table-column align="center" label="类型" prop="shipTypeName">
                </el-table-column>
                <el-table-column align="center" label="长度(m)" prop="shipsize">
                </el-table-column>
                <el-table-column align="center" label="宽度(m)" prop="maxWidth">
                </el-table-column>
                <el-table-column align="center" label="高度(m)" prop="maxHeight">
                </el-table-column>
                <el-table-column align="center" label="装载容积" prop="capacity">
                </el-table-column>
                <el-table-column align="center" width="160" label="认证时间" prop="verifyTime">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.verifyTime) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="建造日期" prop="shipBuildDate">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.shipBuildDate) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="船龄" prop="buildAge">
                </el-table-column>
                <el-table-column align="center" label="船籍港" prop="ShipHomePort">
                </el-table-column>
                <el-table-column align="center" label="船检登记号" prop="ShipInspectionNO">
                </el-table-column>
                <el-table-column align="center" width="160" label="安全环保证书有效期" prop="nhcbjyCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.nhcbjyCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="安全管理证书" prop="aqglCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.aqglCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="船舶最低安全配员证书" prop="cbzdaqypyCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.cbzdaqypyCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="船舶营业运输证" prop="cbyyyszjnshgzCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.cbyyyszjnshgzCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="内河船舶保险单(船舶险)" prop="yhnhcbbxdCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.yhnhcbbxdCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="内河船舶保险单(油污险)" prop="yhnhcbbxdCertExp1">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.yhnhcbbxdCertExp1) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="内河船舶保险单(船员险)" prop="yhnhcbbxdCertExp2">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.yhnhcbbxdCertExp2) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="国家船舶舱容积计量站检定证书" prop="gjcbcrjjszjdCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.gjcbcrjjszjdCertExp) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="国内航行船舶入级证书" prop="gnhxcbrjCertExp">
                    <template slot-scope="scope">
                        {{ getTime(scope.row.gnhxcbrjCertExp) }}
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div> -->
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">序号</th>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">承运商</th>
                            <th class="tg-0lax">MMIS</th>
                            <th class="tg-0lax">船舶编码</th>
                            <th class="tg-0lax">满载吃水</th>
                            <th class="tg-0lax">装载量(吨)</th>
                            <th class="tg-0lax">过坝运营</th>
                            <th class="tg-0lax">类型</th>
                            <th class="tg-0lax">长度(m)</th>
                            <th class="tg-0lax">宽度(m)</th>
                            <th class="tg-0lax">高度(m)</th>
                            <th class="tg-0lax">装载容积</th>
                            <th class="tg-0lax">认证时间</th>
                            <th class="tg-0lax">建造日期</th>
                            <th class="tg-0lax">船龄</th>
                            <th class="tg-0lax">船籍港</th>
                            <th class="tg-0lax">船检登记号</th>
                            <th class="tg-0lax">安全环保证书有效期</th>
                            <th class="tg-0lax">安全管理证书</th>
                            <th class="tg-0lax">船舶最低安全配员证书</th>
                            <th class="tg-0lax">船舶营业运输证</th>
                            <th class="tg-0lax">内河船舶保险单(船舶险)</th>
                            <th class="tg-0lax">内河船舶保险单(油污险)</th>
                            <th class="tg-0lax">内河船舶保险单(船员险)</th>
                            <th class="tg-0lax">国家船舶舱容积计量站检定证书</th>
                            <th class="tg-0lax">国内航行船舶入级证书</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ index + 1 }}</th>
                        <th class="tg-0lax">{{ item.title }}</th>
                        <th class="tg-0lax">{{ item.carrierstitle }}</th>
                        <th class="tg-0lax">{{ item.ais }}</th>
                        <th class="tg-0lax">{{ item.acode }}</th>
                        <th class="tg-0lax">{{ item.maxWater }}</th>
                        <th class="tg-0lax">{{ item.maxCapacity }}</th>
                        <th class="tg-0lax">{{ item.canCrossBamName }}</th>
                        <th class="tg-0lax">{{ item.shipTypeName }}</th>
                        <th class="tg-0lax">{{ item.shipsize }}</th>
                        <th class="tg-0lax">{{ item.maxWidth }}</th>
                        <th class="tg-0lax">{{ item.maxHeight }}</th>
                        <th class="tg-0lax">{{ item.capacity }}</th>
                        <th class="tg-0lax">{{ getTime(item.verifyTime) }}</th>
                        <th class="tg-0lax">{{ getTime(item.shipBuildDate) }}</th>
                        <th class="tg-0lax">{{ item.buildAge }}</th>
                        <th class="tg-0lax">{{ item.ShipHomePort }}</th>
                        <th class="tg-0lax">{{ item.ShipInspectionNO }}</th>
                        <th class="tg-0lax">{{ getTime(item.nhcbjyCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.aqglCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.cbzdaqypyCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.cbyyyszjnshgzCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.yhnhcbbxdCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.yhnhcbbxdCertExp1) }}</th>
                        <th class="tg-0lax">{{ getTime(item.yhnhcbbxdCertExp2) }}</th>
                        <th class="tg-0lax">{{ getTime(item.gjcbcrjjszjdCertExp) }}</th>
                        <th class="tg-0lax">{{ getTime(item.gnhxcbrjCertExp) }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "OnlineShipInfo",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                carriersId: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            selectList: []
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getSelectList()
        this.getData()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        download(e) {
            let obj = {
                carriersId: this.searchForm.carriersId,
            }
            if (e == 1) {
                this.$Api.report_view_getshipdataupload(obj).then(res => {
                    if (res.code == 200) {
                        console.log(res.data.split('/').slice(-1)[0]);
                        var a = document.createElement('a') // 创建一个<a></a>标签
                        a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                        a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                        a.style.display = 'none'           // 隐藏a标签
                        document.body.appendChild(a)       // 将a标签追加到文档对象中
                        a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                        a.remove()                        // 一次性的，用完就删除a标签
                    }
                })
            } else {
                this.$Api.report_view_getshipCYdataupload(obj).then(res => {
                    if (res.code == 200) {
                        console.log(res.data.split('/').slice(-1)[0]);
                        var a = document.createElement('a') // 创建一个<a></a>标签
                        a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                        a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                        a.style.display = 'none'           // 隐藏a标签
                        document.body.appendChild(a)       // 将a标签追加到文档对象中
                        a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                        a.remove()                        // 一次性的，用完就删除a标签
                    }
                })
            }
        },
        getTime(e) {
            return e.split('.')[0];
        },
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let config = [
                        { field: 'title', width: 80, align: 'center', title: '船名' },
                        { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
                        { field: 'ais', width: 80, align: 'center', title: 'MMIS' },
                        { field: 'acode', width: 80, align: 'center', title: '船舶编码' },
                        { field: 'maxWater', width: 80, align: 'center', title: '满载吃水' },
                        { field: 'maxCapacity', width: 80, align: 'center', title: '装载量(吨)' },
                        { field: 'shipsize', width: 80, align: 'center', title: '过坝运营' },
                        { field: 'shipTypeName', width: 80, align: 'center', title: '类型' },
                        { field: 'shipsize', width: 80, align: 'center', title: '长度(m)' },
                        { field: 'maxWidth', width: 80, align: 'center', title: '宽度(m)' },
                        { field: 'maxHeight', width: 80, align: 'center', title: '高度(m)' },
                        { field: 'capacity', width: 80, align: 'center', title: '装载容积' },
                        { field: 'verifyTime', width: 160, align: 'center', title: '认证时间' },
                        { field: 'shipBuildDate', width: 160, align: 'center', title: '建造日期' },
                        { field: 'buildAge', width: 160, align: 'center', title: '船龄' },
                        { field: 'ShipHomePort', width: 160, align: 'center', title: '船籍港' },
                        { field: 'ShipInspectionNO', width: 160, align: 'center', title: '船检登记号' },
                        { field: 'nhcbjyCertExp', width: 160, align: 'center', title: '安全环保证书有效期' },
                        { field: 'aqglCertExp', width: 160, align: 'center', title: '安全管理证书' },
                        { field: 'cbzdaqypyCertExp', width: 160, align: 'center', title: '船舶最低安全配员证书' },
                        { field: 'cbyyyszjnshgzCertExp', width: 160, align: 'center', title: '船舶营业运输证' },
                        { field: 'yhnhcbbxdCertExp', width: 160, align: 'center', title: '内河船舶保险单(船舶险)' },
                        { field: 'yhnhcbbxdCertExp1', width: 160, align: 'center', title: '内河船舶保险单(油污险)' },
                        { field: 'yhnhcbbxdCertExp2', width: 160, align: 'center', title: '内河船舶保险单(船员险)' },
                        { field: 'gjcbcrjjszjdCertExp', width: 160, align: 'center', title: '国家船舶舱容积计量站检定证书' },
                        { field: 'gnhxcbrjCertExp', width: 160, align: 'center', title: '国内航行船舶入级证书' },
                    ]
                    let data = {
                        name: "在线船舶基础资料",
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(this.tableData)
                    }
                    this.$Api.execl_out(data).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        handleClick(tab) {
            switch (tab.name) {
                case 'first':
                    this.getData()
                    break;
                case 'second':

                    break;
                case 'third':

                    break;
                default:
                    break;
            }
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getSelectList() {
            this.$Api.report_view_getcarriers().then(res => {
                if (res.code == 200) {
                    this.selectList = res.data
                }
            })
        },
        getData() { // 获取数据
            let data = {
                carriersId: this.searchForm.carriersId,
            }
            this.tableLoading = true
            this.$Api.report_view_getshipdata(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.tableData = res.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.OnlineShipInfo {
    height: initial;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .searchBox::before {
        content: '操作面板';
        display: block;
        background-color: #FFF;
        position: absolute;
        top: -10px;
        font-size: 14px;
        color: #6b6b6b;
        left: 30px;
        z-index: 2;
    }

    .container {
        width: 100%;
        height: 100%;
        // flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .el-table {
            height: 800px;
        }
    }
}
</style>