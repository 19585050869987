<template>
    <div class="page loadingOilEdit">
        <el-page-header @back="goBack" content="编辑">
        </el-page-header>
        <div class="container">
            <div class="operateBox">
                <div class="left" style="flex-direction: column;">
                    <el-form ref="form" :model="rowData" label-width="130px" inline style="width: 100%;">
                        <el-form-item label="船名：" prop="title">
                            <el-input v-model="rowData.shiptitle" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="承运商：" prop="itemtype">
                            <el-input v-model="rowData.carrierstitle" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="到港：" prop="points">
                            <el-input v-model="rowData.destPortName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="属性：" prop="rater">
                            <el-input v-model="rowData.KUNTPName" disabled></el-input>
                        </el-form-item>
                    </el-form>
                    <el-form ref="formAdd" :model="createdData" :rules="rules" label-width="130px" inline
                        style="width: 100%;">
                        <el-form-item label="船板量：" prop="plankingTon">
                            <el-input v-model="createdData.plankingTon" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="发运时间：" prop="startTime">
                            <el-date-picker v-model="createdData.startTime" type="date" value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="合格证：" prop="certificatePath">
                            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
                                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                                <el-button type="primary" style="margin: 8px 10px;" v-loading="btnLoading" size="small"
                                    icon="el-icon-upload2">上传附件</el-button>
                            </el-upload>
                            <span v-if="createdData.certificatePath != ''" @click="lookFile(createdData.certificatePath)"
                                style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
                        </el-form-item>
                        <el-form-item label="回执单：" prop="roles">
                            <el-button icon="el-icon-search" size="small" type="primary" @click="lookReturn">查看装油回执单
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="right"></div>
            </div>
            <div class="operateBox">
                <div class="left">
                    <el-button icon="el-icon-download" size="mini" type="primary" @click="downMoudl">
                        <a href="./formwork/QFMB.xlsx" download style="color: #ffffff;">下载铅封模板</a>
                    </el-button>
                    <el-upload class="upload-demo" :action="'/api/several_quality/uploadInsertLeadsealing'"
                        :before-upload="beforeUpload2" :data="{ shipPlanId: rowData.shipPlanid }"
                        :headers="{ 'api-token': token }" :on-error="handleError2" :on-success="onsuccess2"
                        :show-file-list="false">
                        <el-button type="primary" style="margin: 8px 10px;" v-loading="logbtnLoading" size="mini"
                            icon="el-icon-upload2">上传铅封记录</el-button>
                    </el-upload>
                </div>
                <div class="right">
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto 25px;" header-row-class-name="header-table">
                <el-table-column align="center" type="index" width="55">
                </el-table-column>
                <el-table-column align="center" label="铅封位置" prop="abnormalSeat">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.abnormalSeat" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="进油管铅封" prop="oilinlet">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.oilinlet" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="出油管铅封" prop="outlettubecarseal">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.outlettubecarseal" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="仓阀铅封" prop="capsulevalvescarseal">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.capsulevalvescarseal" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="观察孔铅封" prop="observeholecarseal">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.observeholecarseal" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="计量孔铅封" prop="measureholecarseal">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.measureholecarseal" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="扫舱口铅封" prop="sweeppositions">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sweeppositions" clearable size="small"
                            @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="其他铅封" prop="rests">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.rests" clearable size="small" @blur="updatelead(scope.row)"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="operateBox">
                <div class="left">
                    <el-form ref="formAdd" :model="createdData" :rules="rules" label-width="130px" inline>
                        <el-form-item label="办事处负责人：" prop="memberid">
                            <el-select v-model="createdData.memberid" clearable placeholder="请选择">
                                <el-option v-for="(item, index) in member" :label="item.title" :value="item.id"
                                    :key="index">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="计量员：" prop="jlyname">
                            <el-input v-model="createdData.jlyname" clearable></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="right"></div>
            </div>
            <el-divider></el-divider>
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-button icon="el-icon-check" size="small" type="primary" @click="submit">提交
                    </el-button>
                    <el-button icon="el-icon-close" size="small" type="danger" @click="goBack">关闭
                    </el-button>
                </div>
            </div>
            <!-- 收油回执单页面 -->
            <el-dialog :close-on-click-modal="false" title="收油回执单页面" :visible.sync="dialogVisible" destroy-on-close
                top="15vh" width="65%">
                <div class="addoreidt" id="addoreidt">
                    <table class="tg">
                        <thead>
                            <tr>
                                <th class="tg-c3ow" colspan="8">华中分公司成品油水路运输回执单</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tg-0pky">制表单位：</td>
                                <td class="tg-0pky" colspan="4"></td>
                                <td class="tg-0pky">单位：吨</td>
                                <td class="tg-0pky" colspan="2">编号：HZSY</td>
                            </tr>
                            <tr>
                                <td class="tg-uzvj" rowspan="4">发运信息</td>
                                <td class="tg-uzvj" rowspan="2">船名</td>
                                <td class="tg-uzvj" rowspan="2">品名</td>
                                <td class="tg-uzvj" rowspan="2">发港</td>
                                <td class="tg-7btt" colspan="2">原到港油库</td>
                                <td class="tg-7btt" colspan="2">调整后的到港油库</td>
                            </tr>
                            <tr>
                                <td class="tg-7btt">到港</td>
                                <td class="tg-7btt">油库</td>
                                <td class="tg-7btt">到港</td>
                                <td class="tg-7btt">油库</td>
                            </tr>
                            <tr>
                                <td class="tg-c3ow" id="shiptitle">{{ returnCform.shiptitle || '' }}</td>
                                <td class="tg-c3ow" id="oilTypetitle">{{ returnCform.oilTypetitle || '' }}</td>
                                <td class="tg-c3ow" id="startPortName">{{ returnCform.startPortName || '' }}</td>
                                <td class="tg-c3ow" id="destPortName">{{ returnCform.destPortName || '' }}</td>
                                <td class="tg-c3ow" id="destoildepot">{{ returnCform.destoildepot || '' }}</td>
                                <td class="tg-c3ow" id="updatedestPortName">{{ returnCform.updatedestPortName || '' }}</td>
                                <td class="tg-c3ow" id="updatedestoildepot">{{ returnCform.updatedestoildepot || '' }}</td>
                            </tr>
                            <tr>
                                <td class="tg-7btt">发运量</td>
                                <td class="tg-c3ow" id="shipmentTon">{{ returnCform.shipmentTon || '' }}</td>
                                <td class="tg-7btt">出厂船板量</td>
                                <td class="tg-c3ow" colspan="2" id="plankingTon">{{ returnCform.plankingTon || '' }}</td>
                                <td class="tg-7btt">离港时间</td>
                                <td class="tg-c3ow" id="startTimedata">{{ returnCform.startTimedata || '' }}</td>
                            </tr>
                            <tr>
                                <td class="tg-uzvj" rowspan="2">收卸信息</td>
                                <td class="tg-7btt">到港时间</td>
                                <td class="tg-7btt">实收量</td>
                                <td class="tg-7btt">定损率(1/1000)</td>
                                <td class="tg-7btt">定额损耗量</td>
                                <td class="tg-7btt">超耗(+)溢(-)量</td>
                                <td class="tg-7btt">到库船板量</td>
                                <td class="tg-7btt">到港时间</td>
                            </tr>
                            <tr>
                                <td class="tg-c3ow" id="arrivalTimedata">{{ returnCform.arrivalTimedata || '' }}</td>
                                <td class="tg-c3ow" id="realTon">{{ returnCform.realTon || '' }}</td>
                                <td class="tg-c3ow" id="feerate">{{ returnCform.feerate || '' }}</td>
                                <td class="tg-c3ow" id="feeratemenber">{{ returnCform.feeratemenber || '' }}</td>
                                <td class="tg-c3ow" id="chyl">{{ (returnCform.shipmentTon - returnCform.realTon -
                                    returnCform.feeratemenber).toFixed(3) || '' }}</td>
                                <td class="tg-c3ow" id="oildischargeplankingTon">{{ returnCform.oildischargeplankingTon ||
                                    ''
                                }}</td>
                                <td class="tg-c3ow" id="updatestartTimedata">{{ returnCform.updatestartTimedata || '' }}
                                </td>
                            </tr>
                            <tr>
                                <td class="tg-uzvj">备注</td>
                                <td class="tg-c3ow" colspan="7"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="print">打印回执单</el-button>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "loadingOilEdit",
    data() {
        return {
            logbtnLoading: false,
            logbtnFile: "",
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            createdData: {
                id: '',
                memberid: '',
                jlyname: '',
                startTime: '',
                shipmentTon: '',
                plankingTon: '',
                certificatePath: '',
            },
            rules: {
                memberid: {
                    required: true, message: '请选择办事处负责人', trigger: 'blur'
                },
                jlyname: {
                    required: true, message: '请输入计量员', trigger: 'blur'
                },
                startTime: {
                    required: true, message: '请输入发运时间', trigger: 'blur'
                },
                plankingTon: {
                    required: true, message: '请输入船板量', trigger: 'blur'
                },
                certificatePath: {
                    required: true, message: '请上传合格证', trigger: 'blur'
                },
            },
            oilTypes: [],
            member: [],
            typeList: [],
            btnLoading: false,
            returnCform: {},
            token: ''
        }
    },
    created() {
        let vuex = localStorage.getItem('vuex')
        this.token = JSON.parse(vuex).token
        this.rowData = this.$route.params
        if (this.rowData?.shipPlanid) {
            this.getData()
            this.getmember()
        }

    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        lookReturn() {
            let data = {
                shipPlanId: this.rowData.shipPlanid
            }
            this.$Api.several_quality_getshipPlanReturnReceiptTwo(data).then(res => {
                if (res.code == 200) {
                    this.returnCform = res.data.data
                }
            })
            this.dialogVisible = true
        },
        print() {
            let printableContent = document.getElementById('addoreidt').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.tg {
            border-collapse: collapse;
            border-spacing: 0;
            margin: auto;
            margin-top: 30px;
            padding: 10px 10px 10px 10px;
            width: 100%;
        }

        .tg td {
            border-color: black;
            border-style: solid;
            border-width: 1px;
            font-family: Arial, sans-serif;
            font-size: 14px;
            overflow: hidden;
            padding: 10px 5px;
            word-break: normal;
        }

        .tg th {
            border-color: black;
            border-style: solid;
            border-width: 1px;
            font-family: Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            overflow: hidden;
            padding: 10px 5px;
            word-break: normal;
        }

        .tg .tg-c3ow {
            border-color: inherit;
            text-align: center;
            vertical-align: top
        }

        .tg .tg-0pky {
            border-color: inherit;
            text-align: left;
            vertical-align: top
        }

        .tg .tg-uzvj {
            border-color: inherit;
            font-weight: bold;
            text-align: center;
            vertical-align: middle
        }

        .tg .tg-7btt {
            border-color: inherit;
            font-weight: bold;
            text-align: center;
            vertical-align: top
        }
  </style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        updatelead(row) {
            console.log(row);
            let data = {
                id: row.id,
                abnormalSeat: row.abnormalSeat,
                shipplanid: row.shipplanid,
                oilinlet: row.oilinlet,
                outlettubecarseal: row.outlettubecarseal,
                capsulevalvescarseal: row.capsulevalvescarseal,
                observeholecarseal: row.observeholecarseal,
                measureholecarseal: row.measureholecarseal,
                sweeppositions: row.sweeppositions,
                rests: row.rests
            }
            this.$Api.several_quality_updateleadsealing(data).then(res => {
                if (res.code == 200) {
                    this.$message.success('操作成功')
                }
            })
        },
        downMoudl() {

        },
        updateMoudl() { },
        submit() {
            this.$refs['formAdd'].validate((valid) => {
                if (valid) {
                    let data = this.createdData
                    data.id = this.rowData.shipPlanid
                    this.$Api.several_quality_updateshipPlanByid(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getData()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        beforeUpload() {
            this.btnLoading = true
        },
        handleError() {
            this.$message.error('上传失败，请重新上传！')
            this.btnLoading = false
        },
        onsuccess(response) {
            if (response.code == 200) {
                this.createdData.certificatePath = response.data.save_path
            }
            this.btnLoading = false
        },
        beforeUpload2() {
            this.logbtnLoading = true
        },
        handleError2() {
            this.$message.error('上传失败，请重新上传！')
            this.logbtnLoading = false
        },
        onsuccess2(response) {
            if (response.code == 200) {
                this.$message({
                    type: 'success',
                    message: '操作成功'
                });
            }
            this.logbtnLoading = false
        },
        deleterow(row) {
            this.$confirm('确定删除该项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.carriers_deleteAssessItem2(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerow(row) {
            this.createdData = {
                id: row.id,
                title: row.title,
                assessId: row.assessId,
                roles: row.roles,
                points: row.points,
                itemtype: row.itemtype,
                rater: row.rater,
            }
            this.isAdd = false
            this.dialogVisible = true
        },
        addRole() {
            this.dialogVisible = true
            this.isAdd = true
            this.clearCreateData()
        },
        clearCreateData() {
            this.createdData = {
                id: '',
                title: '',
                assessId: '',
                roles: '',
                points: '',
                itemtype: '',
                rater: '',
            }
            this.$refs['form'].resetFields();
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/loadUnloadManage/loadingOilEdit')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getmember() {
            this.$Api.several_quality_getmemberinfo().then(res => {
                if (res.code == 200) {
                    this.member = res.data
                }
            })
        },
        getData() { // 获取数据
            let data = {
                id: this.rowData.shipPlanid,
            }
            this.tableLoading = true
            this.$Api.several_quality_getshipplanoildischargebyid(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.createdData.certificatePath = res.data.list[0].certificatePath
                    // this.createdData.memberid = ''
                    this.createdData.jlyname = res.data.list[0].quantitySurveyor
                    this.createdData.startTime = res.data.list[0].startTime
                    this.createdData.plankingTon = res.data.list[0].plankingTon
                }
            })
            let obj = {
                shipplanid: this.rowData.shipPlanid,
            }
            this.$Api.several_quality_getleadsealingByshipplanid(obj).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    console.log(res.data);
                    this.tableData = res.data.payments
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.loadingOilEdit {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    height: initial;

    .container {
        width: 100%;
        height: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .title {
            margin: 0px 0 12px;
            padding-left: 15px;
            font-size: 16px;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .upload-demo {
        display: inline-block;
    }

    .addoreidt {
        height: 50vh;
        overflow-y: scroll;

        /deep/.el-steps {
            height: auto !important;
        }

        .tg {
            border-collapse: collapse;
            border-spacing: 0;
            margin: auto;
            margin-top: 30px;
            padding: 10px 10px 10px 10px;
            width: 100%;
        }

        .tg td {
            border-color: black;
            border-style: solid;
            border-width: 1px;
            font-family: Arial, sans-serif;
            font-size: 14px;
            overflow: hidden;
            padding: 10px 5px;
            word-break: normal;
        }

        .tg th {
            border-color: black;
            border-style: solid;
            border-width: 1px;
            font-family: Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            overflow: hidden;
            padding: 10px 5px;
            word-break: normal;
        }

        .tg .tg-c3ow {
            border-color: inherit;
            text-align: center;
            vertical-align: top
        }

        .tg .tg-0pky {
            border-color: inherit;
            text-align: left;
            vertical-align: top
        }

        .tg .tg-uzvj {
            border-color: inherit;
            font-weight: bold;
            text-align: center;
            vertical-align: middle
        }

        .tg .tg-7btt {
            border-color: inherit;
            font-weight: bold;
            text-align: center;
            vertical-align: top
        }
    }
}</style>