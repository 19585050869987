<template>
  <div class="page AssessmentItems">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
            @click="searchTable('search')">下载导入模板
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-upload class="upload-demo" :action="'/api/carriers.CarriersAssess/assessUploadForSummary'"
            :before-upload="beforeUpload" :on-success="onsuccess" :show-file-list="false" :headers="headers">
            <el-button icon="el-icon-upload2" size="small" type="primary" v-loading="fileLoading">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="success"
            @click="addRole">新增考核项目</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-setting" size="small" type="success"
            @click="setAssessItem">考核项设置</el-button>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox label="序号"></el-checkbox>
                  <el-checkbox label="考核项目"></el-checkbox>
                  <el-checkbox label="类别"></el-checkbox>
                  <el-checkbox label="检查内容"></el-checkbox>
                  <el-checkbox label="分值"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table id="tabbox" v-if="ready" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
        tooltip-effect="dark" height="(100% - 104px)" row-key="id" default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" label="序号" width="55" prop="sort" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="考核项目" width="300" prop="title" v-if="checkList.indexOf('考核项目') != -1">
        </el-table-column>
        <el-table-column align="center" label="类别" width="200" prop="type.title" v-if="checkList.indexOf('类别') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查内容" prop="roles" v-if="checkList.indexOf('检查内容') != -1">
          <template slot-scope="scope">
            <el-popover placement="top-start" width="700" trigger="hover" :content="scope.row.roles">
              <div class="rolesBox" slot="reference">{{ scope.row.roles }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="分值" prop="score" width="55" v-if="checkList.indexOf('分值') != -1">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="10vh" width="750px">
        <div class="addoreidt">
          <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
            <el-form-item label="序号：" prop="sort">
              <el-input v-model="createdData.sort" type="number" clearable placeholder="请输入序号"></el-input>
            </el-form-item>
            <el-form-item label="考核项目：" prop="title">
              <el-input v-model="createdData.title" clearable placeholder="请输入考核项目"></el-input>
            </el-form-item>
            <el-form-item label="类别：" prop="title">
              <el-select v-model="createdData.typeId" clearable placeholder="请选择">
                <el-option v-for="item in typesList" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="检查内容：" prop="roles">
              <el-input v-model="createdData.roles" type="textarea" :rows="8" placeholder="请输入检查内容"></el-input>
            </el-form-item>
            <el-form-item label="分值：" prop="score">
              <el-input v-model="createdData.score" type="number" clearable placeholder="请输入分值"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑考核项 -->
      <el-dialog :close-on-click-modal="false" title="编辑考核项" :visible.sync="dialogVisible2" destroy-on-close top="30vh"
        width="500px">
        <div class="addoreidt">
          <el-alert title="关键因素分值 + 过程因素分值 = 100" type="error" style="margin-bottom: 15px;">
          </el-alert>
          <el-form ref="form" :model="setData" :rules="setrules" label-width="120px">
            <el-form-item label="关键因素：" prop="percent1">
              <el-input v-model="setData.percent1" type="number" clearable placeholder="请输入关键因素"></el-input>
            </el-form-item>
            <el-form-item label="过程因素：" prop="percent2">
              <el-input v-model="setData.percent2" type="number" clearable placeholder="请输入过程因素"></el-input>
            </el-form-item>
            <el-form-item label="认证检查：" prop="percent3">
              <el-input v-model="setData.percent3" type="number" clearable placeholder="请输入认证检查"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="setDataSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">考核项目</th>
              <th class="tg-0lax">类别</th>
              <th class="tg-0lax">检查内容</th>
              <th class="tg-0lax">分值</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.title }}</th>
            <th class="tg-0lax">{{ item.type?.title?item.type.title:'' }}</th>
            <th class="tg-0lax" v-html="item.roles"></th>
            <th class="tg-0lax">{{ item.score }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AssessmentItems",
  data() {
    return {
      headers: {},
      fileLoading: false,
      isAdd: false,
      ready: true,
      checkList: ['序号', '考核项目', '类别', '检查内容', '分值'],
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      createdData: {
        sort: '',
        title: '',
        roles: '',
        score: '',
        typeId: '',
        id: ""
      },
      rules: {
        sort: {
          required: true, message: '请输入序号', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入考核项目', trigger: 'blur'
        },
        roles: {
          required: true, message: '请输入检查内容', trigger: 'blur'
        },
        score: {
          required: true, message: '请输入分值', trigger: 'blur'
        },
        typeId: {
          required: true, message: '请选择类别', trigger: 'blur'
        }
      },
      setData: {
        percent1: '',
        percent2: '',
        percent3: ''
      },
      setrules: {
        percent1: {
          required: true, message: '请输入关键因素', trigger: 'blur'
        },
        percent2: {
          required: true, message: '请输入过程因素', trigger: 'blur'
        },
        percent3: {
          required: true, message: '请输入认证检查', trigger: 'blur'
        },
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      typesList: [],
      setType: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getTypes()
    this.getData()
    this.headers['api-token'] = localStorage.getItem('token')
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.tbyflg = (item.adjustPriceYUNJIA - item.originalPriceYUNJIA).toFixed(3)
            item.glztlg = item.sfglName == '未关联' ? '未关联' : '已关联'
            tableData.push(item)
          })
          let config = [
            { field: 'title', width: 80, align: 'center', title: '考核项目' },
            { field: 'type.title', width: 80, align: 'center', title: '类别' },
            { field: 'roles', width: 80, align: 'center', title: '检查内容' },
            { field: 'score', width: 150, align: 'center', title: '分值' },
          ]
          let obj = {
            name: '考核项模板维护',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    onsuccess(response, file, fileList) {
      if (response.code == 200) {
        console.log(response, file);
      }
      this.fileLoading = false
    },
    beforeUpload() {
      this.fileLoading = true
    },
    setDataSubmit() {
      if (this.setData.percent1 + this.setData.percent2 != 100) {
        this.$message.warning('关键因素分值加过程因素分值总和需为100！')
        return
      }
      this.$Api.carriers_updAssessItemTypes(this.setData).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.dialogVisible2 = false
          this.getTypes()
        }
      })
    },
    setAssessItem() {
      this.setData = {
        percent1: this.setType[0].percent,
        percent2: this.setType[1].percent,
        percent3: this.setType[2].percent
      }
      this.dialogVisible2 = true
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = this.createdData
          if (this.isAdd) {
            delete data.id
            this.$Api.carriers_addAssessSummary(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          } else {
            this.$Api.carriers_updateassessItem(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.isAdd = true
      this.dialogVisible = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createdData = {
        sort: '',
        title: '',
        roles: '',
        score: '',
        typeId: '',
        id: ""
      }
      this.$refs['form'].resetFields();
    },
    dropdownChange(e) {
      this.ready = false
      this.$nextTick(() => {
        this.ready = true
      })
    },
    editerow(row) {
      this.isAdd = false
      this.createdData = {
        sort: row.sort,
        title: row.title,
        roles: row.roles,
        score: row.score,
        typeId: row.type?.id?row.type.id:'',
        id: row.id
      }
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.carriers_deleteAssessItem(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData();
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getTypes() {
      this.$Api.carriers_getAssessItemTypes().then(res => {
        if (res.code == 200) {
          this.setType = res.data
          if (res.data.length > 0) {
            let arr = []
            res.data.forEach(element => {
              if (element.assessType == 0) {
                arr.push(element)
              }
            });
            this.typesList = arr
          }
        }
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      this.tableLoading = true
      this.$Api.carriers_getAssessSummary(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = res.data.total
          this.tableData = res.data.data
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.AssessmentItems {
  .searchBox::before {
    content: '操作面板';
    display: block;
    background-color: #FFF;
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #6b6b6b;
    left: 30px;
    z-index: 2;
  }

  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .rolesBox {
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    /deep/.el-dialog__body {
      padding-top: 10px;
    }
  }
}
</style>