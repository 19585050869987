<template>
    <div class="page CarrierInfo">
        <div class="container" v-if="carriersinfo?.id">
            <el-divider content-position="left">公司名称:{{ carriersinfo.title }}</el-divider>
            <!--列表-->
            <el-table v-if="showReady" ref="multipleTable" border :data="fileData" tooltip-effect="dark"
                style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="类型" prop="title">
                </el-table-column>
                <el-table-column align="center" label="编号" prop="no">
                    <template slot-scope="scope">
                        <el-input placeholder="请输入编号" v-model="scope.row.no" clearable></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="有效期" prop="date">
                    <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.date" type="date" value-format="yyyy-MM-dd" placeholder="请选择有效期">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="附件" prop="file">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" v-if="scope.row.file != ''" icon="el-icon-search"
                            @click="lookFile(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="上传">
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload2"
                                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                                <el-button size="mini" type="primary" style="margin: 8px 10px;" v-loading="fileLoading"
                                    @click="cxupload(scope.$index)" icon="el-icon-upload2">上传</el-button>
                            </el-upload>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="submit">
                <el-button type="primary" icon="el-icon-check" size="medium" @click="submit">提交审核</el-button>
                <el-button type="primary" icon="el-icon-finished" size="medium" @click="looklog">审核日志</el-button>
            </div>
            <!-- 日志 -->
            <el-dialog :close-on-click-modal="false" title="审核日志" :visible.sync="dialogVisible2" destroy-on-close
                top="15vh">
                <div class="dialogCont">
                    <el-steps direction="vertical" :active="1" :space="80">
                        <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
                            :description="item.username ? item.username+ '  ' + item.log_info : ''  + item.log_info"></el-step>
                    </el-steps>
                    <!-- 列表 -->
                    <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
                        tooltip-effect="dark" header-row-class-name="header-table">
                        <el-table-column align="center" label="审核级别" prop="aprv_level_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核人员" prop="username">
                        </el-table-column>
                        <el-table-column align="center" label="审核结果" prop="user_outcome_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核时间" prop="update_time">
                            <template slot-scope="scope">
                                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="power" v-else>
            <el-col :sm="12" :lg="6">
                <el-result icon="warning" title="提示" subTitle="你不是承运商用户，无权操作改页面">
                </el-result>
            </el-col>
        </div>
    </div>
</div></template>
    
<script>
import baseUrl from "@/api/config"
export default {
    name: "CarrierInfo",
    data() {
        return {
            fileLoading: false,
            updateUrl: "",
            fileList: [],
            cxIndex: "",
            showReady: true,
            member: [],
            dialogVisible2: false,
            logData: {},
            fileData: [{
                title: '营业执照',
                no: '',
                date: '',
                file: ''
            }, {
                title: '符合证明及年度签注页',
                no: '',
                date: '',
                file: ''
            }, {
                title: '水路运输许可证',
                no: '',
                date: '',
                file: ''
            }],
            carriersinfo: {},
        }
    },
    created() {
        this.updateUrl = baseUrl
        this.carriersinfo = this.$store.state.userInfo.carriersinfo
        if (this.carriersinfo?.id) {
            this.getData()
        }
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    mounted() {

    },
    methods: {
        looklog(row) {
            let data = {
                id: this.carriersinfo.id,
                type: 14
            }
            this.$Api.documentGary_LogList(data).then(res => {
                let level = ['未知', '一级', '二级', '三级'];
                if (res.data.logList.length != 0) {
                    res.data.logList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                    })
                }
                if (res.data.outcomeList.length != 0) {
                    res.data.outcomeList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                        if (this.myapprovalStatesList.length > 0) {
                            this.myapprovalStatesList.map(key => {
                                if (item.user_outcome == key.id) {
                                    item.user_outcome_text = key.title
                                }
                            })
                        }
                        item.aprv_level_text = level[item.aprv_level]
                    })
                }
                res.data.logList = res.data.logList.reverse()
                this.logData = res.data
                this.dialogVisible2 = true;
            })
        },
        submit() {
            for (let key in this.fileData[0]) {
                if (this.fileData[0][key] == '' || this.fileData[0][key] == null) {
                    switch (key) {
                        case 'no':
                            this.$message.warning('请上传营业执照编号')
                            break;
                        case 'date':
                            this.$message.warning('请上传营业执照有效期')
                            break;
                        case 'file':
                            this.$message.warning('请上传营业执照附近')
                            break;
                        default:
                            break;
                    }
                    return
                }
            }
            for (let key in this.fileData[1]) {
                if (this.fileData[1][key] == '' || this.fileData[1][key] == null) {
                    switch (key) {
                        case 'no':
                            this.$message.warning('请上传符合证明及年度签注页编号')
                            break;
                        case 'date':
                            this.$message.warning('请上传符合证明及年度签注页有效期')
                            break;
                        case 'file':
                            this.$message.warning('请上传符合证明及年度签注页附近')
                            break;
                        default:
                            break;
                    }
                    return
                }
            }
            for (let key in this.fileData[2]) {
                if (this.fileData[2][key] == '' || this.fileData[2][key] == null) {
                    switch (key) {
                        case 'no':
                            this.$message.warning('请上传水路运输许可证编号')
                            break;
                        case 'date':
                            this.$message.warning('请上传水路运输许可证有效期')
                            break;
                        case 'file':
                            this.$message.warning('请上传水路运输许可证附近')
                            break;

                        default:
                            break;
                    }
                    return
                }
            }
            let data = {
                id: this.carriersinfo.id,
                carriersId: this.carriersinfo.carriersId,
                yyzzCertNo: this.fileData[0].no,
                yyzzCertExp: this.fileData[0].date,
                yyzzCertFile: this.fileData[0].file,
                fhzmndqzCertNo: this.fileData[1].no,
                fhzmndqzCertExp: this.fileData[1].date,
                fhzmndqzCertFile: this.fileData[1].file,
                slysxkCertNo: this.fileData[2].no,
                slysxkCertExp: this.fileData[2].date,
                slysxkCertFile: this.fileData[2].file
            }
            this.$Api.carriers_saveCarrierInfo(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                }
            })
        },
        lookFile(row) {
            window.open(row.file, '_blank')
        },
        cxupload(index) {
            this.cxIndex = index
        },
        beforeUpload() {
            this.cxIndex = ''
            this.fileLoading = true
        },
        beforeUpload2() {
            this.fileLoading = true
            this.showReady = false
        },
        onsuccess(response, file, fileList) {
            if (response.code == 200) {
                this.fileData[this.cxIndex].file = file.response.data.save_path
                this.showReady = true
            }
            this.fileLoading = false
        },
        handleError(err, file, fileList) {
            this.$message.warning('上传失败请重新提交！')
        },
        // --------------------------公共方法--------------------------------------
        getData() { // 获取数据
            let data = {
                table_name: 'carriers',
                id: this.carriersinfo.id
            }
            this.$Api.bases_getOne(data).then(res => {
                if (res.code == 200) {
                    this.fileData[0].no = res.data.yyzzCertNo
                    this.fileData[0].date = res.data.yyzzCertExp
                    this.fileData[0].file = res.data.yyzzCertFile
                    this.fileData[1].no = res.data.fhzmndqzCertNo
                    this.fileData[1].date = res.data.fhzmndqzCertExp
                    this.fileData[1].file = res.data.fhzmndqzCertFile
                    this.fileData[2].no = res.data.slysxkCertNo
                    this.fileData[2].date = res.data.slysxkCertExp
                    this.fileData[2].file = res.data.slysxkCertFile
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.CarrierInfo {
    height: calc(100vh - 125px) !important;
    overflow-y: scroll;

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .formBox {
            width: 100%;
            padding: 0 10px;
            margin-top: 10px;

            .item {
                width: 100%;
                display: flex;
                margin-bottom: 15px;

                .itemHead {
                    width: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FBFBFB;
                    border: 1px solid #DCDFE6;
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    height: 40px;
                    line-height: 40px;
                }

                .itemContent {
                    width: 1;
                    flex: 1;

                    /deep/.el-input__inner {
                        width: 100%;
                        border-radius: 0 4px 4px 0;
                    }

                    /deep/.el-select {
                        width: 100%;
                    }

                    /deep/.el-cascader {
                        width: 100%;
                    }

                    .flowPath {
                        width: 100%;
                        padding: 0 15px;

                        .text {
                            line-height: 24px;
                            color: #808080;
                        }
                    }
                }
            }
        }

        .serverHead {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 15px;
            background-color: #FBFBFB;
            border: 1px solid #DCDFE6;
            border-radius: 4px 0 0 4px;
            height: 40px;
            line-height: 40px;
        }

        /deep/.el-divider__text.is-left {
            font-size: 20px;

        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .power {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>