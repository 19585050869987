<template>
  <div class="page Oildepot">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="油库名称：">
          <el-input v-model="searchForm.title" placeholder="请输入油库名称" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-delete" size="small" type="danger"
            @click="batchDelete">删除</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" @selection-change="handleSelectionChange"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="油库名称" prop="title">
        </el-table-column>
        <el-table-column align="center" label="省市公司" prop="group.title">
        </el-table-column>
        <el-table-column align="center" label="港口名称" prop="ship_port.title">
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookOil(scope.row)">油品</el-button>
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="20vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="名称：" prop="title">
            <el-input v-model="createdData.title" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="省市公司：" prop="parentId">
            <el-select v-model="createdData.parentId" placeholder="直接选择或搜索后选择" filterable style="width: 100%;">
              <el-option v-for="item in groupList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属港口：" prop="shipPortId">
            <el-select v-model="createdData.shipPortId" placeholder="直接选择或搜索后选择" filterable style="width: 100%;">
              <el-option v-for="item in shipPortList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Oildepot",
  data() {
    return {
      isAdd: false,
      multipleSelection: [],
      searchForm: {
        title: '',
      },
      tableLoading: false,
      dialogVisible: false,
      createdData: {
        title: '',
        parentId: '',
        groupId: '',
        shipPortId: '',
        alongSideTon: '',
        capacity: '',
        segment: '',
        orientation: '',
        voyage: '',
        leftCapacity: '',
        emptyCapacity: '',
        dailyCapacity: '',
        code: '',
        oilType: '',
        id: ''
      },
      rules: {
        parentId: {
          required: true, message: '请选择省市公司', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称', trigger: 'blur'
        },
        shipPortId: {
          required: true, message: '请选择所属港口', trigger: 'blur'
        },
      },
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 1,
        lastPage: 1,
      },
      tableData: [],
      shipPortList: [],
      groupList: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getDict()
  },
  activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
  methods: {
    lookOil(row){
      this.$router.push({name:'oildepotDetails', params:row})
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请勾选删除项')
        return
      }
      let ids = []
      this.multipleSelection.map(item => {
        ids.push(item.id)
      })
      let data = {
        id: ids,
      }
      this.$Api.oilWareHousedeleteById_delete(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          if (ids.length == this.tableData.length) this.page.currentPage = 1
          this.getData()
        }
      })
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {}
          if (!this.isAdd) {
            data = {
              title: this.createdData.title,
              parentId: this.createdData.parentId,
              shipPortId: this.createdData.shipPortId,
              alongSideTon: this.createdData.alongSideTon,
              capacity: this.createdData.capacity,
              segment: this.createdData.segment,
              orientation: this.createdData.orientation,
              voyage: this.createdData.voyage,
              leftCapacity: this.createdData.leftCapacity,
              emptyCapacity: this.createdData.emptyCapacity,
              dailyCapacity: this.createdData.dailyCapacity,
              code: '0',//暂时用 0 后面找到用途再替换
              oilType: 0,//暂时用 0 后面找到用途再替换
              id: this.createdData.id,
              groupId: this.createdData.groupId
            }
          } else {
            data = {
              title: this.createdData.title,
              parentId: this.createdData.parentId,
              shipPortId: this.createdData.shipPortId,
              alongSideTon: '0',
              capacity: '0',
              segment: '0',
              orientation: '0',
              voyage: '0',
              leftCapacity: '0',
              emptyCapacity: '0',
              dailyCapacity: '0',
              code: '0',//暂时用 0 后面找到用途再替换
              oilType: 0,//暂时用 0 后面找到用途再替换
              groupId: '0'
            }
          }
          this.$Api.insertOrUpdateOilWareHouse(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
      this.getDict()
    },
    clearCreateData() {
      this.createdData = {
        title: '',
        parentId: '',
        groupId: '',
        shipPortId: '',
        alongSideTon: '',
        capacity: '',
        segment: '',
        orientation: '',
        voyage: '',
        leftCapacity: '',
        emptyCapacity: '',
        dailyCapacity: '',
        code: '',
        oilType: '',
        id: ''
      }
      this.$refs['form'].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createdData = {
        title: row.title,
        parentId: row.parentId,
        shipPortId: row.shipPortId,
        alongSideTon: row.alongSideTon,
        capacity: row.capacity,
        segment: row.segment,
        orientation: row.orientation,
        voyage: row.voyage,
        leftCapacity: row.leftCapacity,
        emptyCapacity: row.emptyCapacity,
        dailyCapacity: row.dailyCapacity,
        code: row.code,//暂时用 0 后面找到用途再替换
        oilType: row.oilType,//暂时用 0 后面找到用途再替换
        id: row.id,
        groupId: row.groupId
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.oilWareHousedeleteById_delete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getDict() {
      this.$Api.oilWarehouseInfo_Dict().then(res => {
        this.shipPortList = res.data.shipPort
        this.groupList = res.data.groupList
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.searchForm.title,
      }
      this.tableLoading = true
      this.$Api.bases_getPageOilWarehouse(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          this.tableData = res.data.data
          if (res.data.total) this.page.total = res.data.total
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.Oildepot {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>