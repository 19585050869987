<template>
    <div class="page ChangeHarborDivideUnloadApply">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="月份：">
                    <el-date-picker size="small" v-model="searchForm.monthly" value-format="yyyy-MM" type="month"
                        placeholder="请选择月份">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="船名：">
                    <el-input size="small" placeholder="请输入船名" v-model="searchForm.shipName" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" @selection-change="handleSelectionChange"
                header-row-class-name="header-table">
                <el-table-column align="center" label="船名" prop="shipName" width="80">
                </el-table-column>
                <el-table-column align="center" label="发运时间" prop="startTime" width="110">
                    <template slot-scope="scope">
                        <div>{{ scope.row.startTime.split(' ')[0] || '' }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="companyIdName" width="110">
                </el-table-column>
                <el-table-column align="center" label="出发港" prop="startPortName" width="120">
                </el-table-column>
                <el-table-column align="center" label="到达港" prop="destPortName" width="120">
                </el-table-column>
                <el-table-column align="center" label="品号" prop="oilTypeIdName" width="200">
                </el-table-column>
                <el-table-column align="center" label="原发量(吨)" prop="shipErpAmount" width="120">
                </el-table-column>
                <el-table-column align="center" label="航次状态" prop="statesName" width="120">
                </el-table-column>
                <el-table-column align="center" label="航次类型" prop="planTypeName" width="120">
                </el-table-column>
                <el-table-column align="center" label="审核结果" prop="approveStatesTxt" width="140">
                </el-table-column>
                <el-table-column align="center" label="操作" width="400" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="goDetails(scope.row)">查看</el-button>
                        <el-button size="mini" @click="lookprocess(scope.row)"
                            v-if="scope.row.approveStates">查看申请</el-button>
                        <el-button type="primary" size="mini" @click="editerow(scope.row)"
                            v-if="!scope.row.approveStates || scope.row.approveStates == 5">编辑申请</el-button>
                        <el-button type="primary" size="mini" @click="subprocess(scope.row)"
                            v-if="!scope.row.approveStates || scope.row.approveStates == 5">提交审核</el-button>
                        <div v-else style="display: inline-block;margin-left: 4px;">
                            <el-button type="primary" size="mini" @click="looklog(scope.row)">审核日志</el-button>
                            <el-button type="success" size="mini" @click="processTab(scope.row)">审批表</el-button>
                            <el-button type="danger" size="mini" @click="revoke(scope.row)">撤销</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 编辑改港分卸申请 -->
            <el-dialog :close-on-click-modal="false" title="编辑改港分卸申请" :visible.sync="dialogVisible" destroy-on-close
                top="12vh" width="650px">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="改港" name="first"></el-tab-pane>
                    <el-tab-pane label="分卸" name="second"></el-tab-pane>
                </el-tabs>
                <div class="addBox">
                    <el-form v-if="activeName == 'first'" ref="formAdd1" :model="createData1" :rules="rules1"
                        label-width="130px">
                        <el-form-item label="原发量(吨)：" prop="oilTon">
                            <span>{{ createData1.oilTon }}</span>
                        </el-form-item>
                        <el-form-item label="属性：" prop="belongId">
                            <el-select v-model="createData1.belongId" placeholder="请搜索或选择内容" size="small">
                                <el-option v-for="(item, index) in dict.companyList" :key="item.id" :value="item.id"
                                    :label="item.subtitle"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="新到达港：" prop="destPort1">
                            <el-select v-model="createData1.destPort1" placeholder="请搜索或选择内容" size="small">
                                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                                    :label="item.title"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="原因：" prop="reason">
                            <el-input v-model="createData1.reason" type="textarea" placeholder="请输入内容" :rows="4"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-form v-if="activeName == 'second'" ref="formAdd2" :model="createData2" :rules="rules2"
                        label-width="130px">
                        <el-form-item label="原发量(吨)：" prop="oilTon">
                            <span>{{ createData2.oilTon }}</span>
                        </el-form-item>
                        <el-form-item label="新到达港1：" prop="destPort1">
                            <el-select v-model="createData2.destPort1" placeholder="请搜索或选择内容" size="small">
                                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                                    :label="item.title"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="分卸量1：" prop="oilTon1">
                            <el-input v-model="createData2.oilTon1" type="number" placeholder="请输入分卸量1"
                                size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="新到达港2：" prop="destPort2">
                            <el-select v-model="createData2.destPort2" placeholder="请搜索或选择内容" size="small">
                                <el-option v-for="(item, index) in dict.shipPortEnd" :key="item.id" :value="item.id"
                                    :label="item.title"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="分卸量2：" prop="oilTon2">
                            <el-input v-model="createData2.oilTon2" type="number" placeholder="请输入分卸量2"
                                size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="原因：" prop="reason">
                            <el-input v-model="createData2.reason" type="textarea" placeholder="请输入内容" :rows="4"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit">提交</el-button>
                </span>
            </el-dialog>
            <!-- 查看申请 -->
            <el-dialog :close-on-click-modal="false" title="查看申请" :visible.sync="dialogVisible2" destroy-on-close top="12vh"
                width="850px">
                <div class="addBox">
                    <el-form ref="formAddDetails" :model="createData3" label-width="130px">
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="月份:" prop="monthly">
                                    <el-input v-model="createData3.reason" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="承运商名称:" prop="companyId">
                                    <el-input v-model="createData3.companyId" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="船名:" prop="shipId">
                                    <el-input v-model="createData3.shipId" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="油品:" prop="oilTypeId">
                                    <el-input v-model="createData3.oilTypeId" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="出发港:" prop="startPort">
                                    <el-input v-model="createData3.startPort" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="原到达港:" prop="destPort">
                                    <el-input v-model="createData3.destPort" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="种类:" prop="oilType">
                                    <el-input v-model="createData3.oilType" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="原数量:" prop="oilTon">
                                    <el-input v-model="createData3.oilTon" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="原始属性:" prop="belongId">
                                    <el-input v-model="createData3.belongId" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="分卸属性:" prop="benlongId">
                                    <el-input v-model="createData3.benlongId" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="分卸港口1:" prop="destPort1">
                                    <el-input v-model="createData3.destPort1" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="分卸数量1:" prop="oilTon1">
                                    <el-input v-model="createData3.oilTon1" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="12">
                                <el-form-item label="分卸港口2:" prop="destPort2">
                                    <el-input v-model="createData3.destPort2" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="分卸数量2:" prop="oilTon2">
                                    <el-input v-model="createData3.oilTon2" size="small" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="原因：" prop="reason">
                            <el-input v-model="createData3.reason" type="textarea" disabled :rows="4"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">确定</el-button>
                </span>
            </el-dialog>
            <!-- 日志 -->
            <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
                <div class="addoreidt">
                    <el-steps direction="vertical" :active="1" :space="80">
                        <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
                            :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
                    </el-steps>
                    <!-- 列表 -->
                    <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
                        tooltip-effect="dark" header-row-class-name="header-table">
                        <el-table-column align="center" label="审核级别" prop="aprv_level_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核人员" prop="username">
                        </el-table-column>
                        <el-table-column align="center" label="审核结果" prop="user_outcome_text">
                        </el-table-column>
                        <el-table-column align="center" label="审核时间" prop="update_time">
                            <template slot-scope="scope">
                                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible3 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
                </span>
            </el-dialog>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">船名</th>
                            <th class="tg-0lax">发运时间</th>
                            <th class="tg-0lax">承运商</th>
                            <th class="tg-0lax">出发港</th>
                            <th class="tg-0lax">到达港</th>
                            <th class="tg-0lax">品号</th>
                            <th class="tg-0lax">原发量(吨)</th>
                            <th class="tg-0lax">航次状态</th>
                            <th class="tg-0lax">航次类型</th>
                            <th class="tg-0lax">审核结果</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.shipName }}</th>
                        <th class="tg-0lax">{{ item.startTime.split(' ')[0] || '' }}</th>
                        <th class="tg-0lax">{{ item.companyIdName }}</th>
                        <th class="tg-0lax">{{ item.startPortName }}</th>
                        <th class="tg-0lax">{{ item.destPortName }}</th>
                        <th class="tg-0lax">{{ item.oilTypeIdName }}</th>
                        <th class="tg-0lax">{{ item.shipErpAmount }}</th>
                        <th class="tg-0lax">{{ item.statesName }}</th>
                        <th class="tg-0lax">{{ item.planTypeName }}</th>
                        <th class="tg-0lax">{{ item.approveStatesTxt }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import staticData from "@/utils/static-data.js"
export default {
    name: "ChangeHarborDivideUnloadApply",
    data() {
        return {
            myapprovalStatesList: [],
            logData: {},
            dialogVisible3: false,
            dialogVisible: false,
            createData1: {
                destPort1: '',
                belongId: '',
                planId: '',
                reason: '',
                title: '',
                type: '',
                oilTon: ''
            },
            rules1: {
                belongId: { required: true, message: '必填项不能为空', trigger: 'blur' },
                destPort1: { required: true, message: '必填项不能为空', trigger: 'blur' },
            },
            createData2: {
                destPort1: '',
                destPort2: '',
                oilTon: '',
                oilTon1: '',
                oilTon2: '',
                belongId: '',
                planId: '',
                reason: '',
                title: '',
                type: '',
            },
            rules2: {
                destPort1: { required: true, message: '必填项不能为空', trigger: 'blur' },
                destPort2: { required: true, message: '必填项不能为空', trigger: 'blur' },
                oilTon1: { required: true, message: '必填项不能为空', trigger: 'blur' },
                oilTon2: { required: true, message: '必填项不能为空', trigger: 'blur' },
            },
            createData3: {
                monthly: '',
                companyId: '',
                shipId: '',
                oilTypeId: '',
                startPort: '',
                destPort: '',
                oilType: "",
                oilTon: '',
                benlongId: '',
                belongId: '',
                destPort1: '',
                destPort2: '',
                oilTon1: '',
                oilTon2: '',
                reason: ''
            },
            dialogVisible2: false,
            activeName: 'first',
            multipleSelection: [],
            searchForm: {
                monthly: '',
                shipName: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
            dict: {
                carrierList: [],
                companyList: [],
                monthlyList: [],
                oilList: [],
                shipList: [],
                shipPortBegin: [],
                shipPortEnd: [],
                shipPortList: [],
                shipPlanStates: []
            }
        }
    },
    created() {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if (month < 10) month = '0' + month;
        let date = now.getDate();
        if (date < 10) date = '0' + date;
        this.searchForm.monthly = year + '-' + month
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getStates()
        this.getDcit()
        this.getType()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let tableData = []
                    this.tableData.map(item => {
                        item.tbyflg = (item.adjustPriceYUNJIA - item.originalPriceYUNJIA).toFixed(3)
                        item.glztlg = item.sfglName == '未关联' ? '未关联' : '已关联'
                        tableData.push(item)
                    })
                    let config = [
                        { field: 'shipName', width: 80, align: 'center', title: '船名' },
                        { field: 'startTime', width: 80, align: 'center', title: '发运时间' },
                        { field: 'companyIdName', width: 80, align: 'center', title: '承运商' },
                        { field: 'startPortName', width: 150, align: 'center', title: '出发港' },
                        { field: 'destPortName', width: 150, align: 'center', title: '到达港' },
                        { field: 'oilTypeIdName', width: 150, align: 'center', title: '品号' },
                        { field: 'shipErpAmount', width: 80, align: 'center', title: '原发量(吨)' },
                        { field: 'statesName', width: 80, align: 'center', title: '航次状态' },
                        { field: 'planTypeName', width: 80, align: 'center', title: '航次类型' },
                        { field: 'approveStatesTxt', width: 80, align: 'center', title: '审核结果' },
                    ]
                    let obj = {
                        name: '改港分卸申请',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        looklog(row) {
            let data = {
                id: row.changeId,
                type: 8
            }
            this.$Api.MyAppRovalPublic_LogList(data).then(res => {
                let level = ['未知', '一级', '二级', '三级'];
                if (res.data.logList.length != 0) {
                    res.data.logList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                    })
                }
                if (res.data.outcomeList.length != 0) {
                    res.data.outcomeList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                        if (this.myapprovalStatesList.length > 0) {
                            this.myapprovalStatesList.map((key, kindex) => {
                                if (item.user_outcome == kindex) {
                                    item.user_outcome_text = key
                                }
                            })
                        }
                        item.aprv_level_text = level[item.aprv_level]
                    })
                }
                res.data.logList = res.data.logList
                this.logData = res.data
                this.dialogVisible3 = true;
            })
        },
        submit() {
            if (this.activeName == 'first') {
                this.$refs['formAdd1'].validate((valid) => {
                    if (valid) {
                        let data = this.createData1
                        this.$Api.shipPlanChange_Add(data).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功'
                                });
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    } else {
                        return false;
                    }
                });
            } else {
                this.$refs['formAdd2'].validate((valid) => {
                    if (valid) {
                        let data = this.createData2
                        if (Number(data.oilTon1) + Number(data.oilTon2) != data.oilTon) {
                            this.$message.warning('分卸后的总数量必须与原航次运输量相同!')
                            return
                        }
                        this.$Api.shipPlanChange_Add(data).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功'
                                });
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }
        },
        getType() {
            this.$Api.myAppRovalPublicDictState().then(res => {
                if (res.code == 200) {
                    this.myapprovalStatesList = res.data.myapprovalStatesList
                }
            })
        },
        goDetails(row) {
            this.$router.push({ name: 'changeHarborDivideUnloadApplyDateils', params: { id: row.id } })
        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        lookprocess(row) {
            let data = {
                id: row.changeId
            }
            this.$Api.shipPlanChange_View(data).then(res => {
                if (res.code == 200) {
                    let data = res.data
                    this.createData3 = {
                        monthly: data.monthly,
                        companyId: data.companyId,
                        shipId: data.shipId,
                        oilTypeId: data.oilType,
                        startPort: data.startPort,
                        destPort: data.destPort,
                        oilType: data.oilType,
                        oilTon: data.oilTon,
                        benlongId: data.benlongId,
                        belongId: data.belongId,
                        destPort1: data.destPort1,
                        destPort2: data.destPort2,
                        oilTon1: data.oilTon1,
                        oilTon2: data.oilTon2,
                        reason: data.reason
                    }
                }
            })
            this.dialogVisible2 = true
        },
        processTab(row) {

        },
        revoke(row) {
            this.$confirm('确定撤销此条数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.changeId
                }
                this.$Api.shipPlanChange_Reback(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        subprocess(row) {
            if (!row.changeId) {
                this.$alert('还未编辑改港分卸信息!', '信息', {
                    confirmButtonText: '确定'
                });
                return
            }
            this.$confirm('确定要提交此条数据进行审核吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.changeId
                }
                this.$Api.shipPlanChange_Push(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        editerow(row) {
            this.createData2 = {
                destPort1: '',
                destPort2: '',
                oilTon: row.shipErpAmount,
                oilTon1: '',
                oilTon2: '',
                belongId: '',
                planId: row.id,
                reason: '',
                title: "[" + row.monthly + "][" + this.dict.carrierList[row.companyId].title + "][" + row.shipName + "][原到达港:" + this.dict.shipPortList[row.destPort].title + "]" + staticData.shipPlanChageType[1],
                type: '1',
            }
            this.createData1 = {
                destPort1: '',
                belongId: '',
                planId: row.id,
                reason: '',
                title: "[" + row.monthly + "][" + this.dict.carrierList[row.companyId].title + "][" + row.shipName + "][原到达港:" + this.dict.shipPortList[row.destPort].title + "]" + staticData.shipPlanChageType[0],
                type: '0',
                oilTon: row.shipErpAmount
            }
            this.dialogVisible = true
        },
        deleterow() {

        },
        handleClick(tab) {
            switch (tab.name) {
                case 'first':
                    this.getData()
                    break;
                case 'second':

                    break;
                case 'third':

                    break;
                default:
                    break;
            }
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getStates() {
            this.$Api.publics_staicData().then(res => {
                if (res.code == 200) {
                    this.dict.shipPlanStates = res.data.shipPlanStates
                }
            })
        },
        async getDcit() {
            let res = await this.$Api.shipPlan_Dict()
            if (res.code == 200) {
                this.dict.carrierList = res.data.carrierList
                this.dict.companyList = res.data.companyList
                this.dict.companyList.unshift({ id: 0, subtitle: '不修改属性(无需修改选此项)' })
                this.dict.monthlyList = res.data.monthlyList
                this.dict.oilList = res.data.oilList
                this.dict.shipList = res.data.shipList
                this.dict.shipPortBegin = res.data.shipPortBegin
                this.dict.shipPortEnd = res.data.shipPortEnd
                this.dict.shipPortList = res.data.shipPortList
            }
            this.getData()
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                monthly: this.searchForm.monthly,
                shipName: this.searchForm.shipName
            }
            this.tableLoading = true
            this.$Api.shipPlanChange_List(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = Number(res.data.total);
                    if (res.data.list.length > 0 && this.dict.carrierList.length > 0) {
                        res.data.list.map(item => {
                            this.dict.carrierList.map(key => {
                                if (item.companyId == key.id) {
                                    item.companyIdName = key.subtitle
                                }
                            })
                        })
                    }
                    if (res.data.list.length > 0 && this.dict.shipPortList.length > 0) {
                        res.data.list.map(item => {
                            this.dict.shipPortList.map(key => {
                                if (item.startPort == key.id) {
                                    item.startPortName = key.title
                                }
                                if (item.destPort == key.id) {
                                    item.destPortName = key.title
                                }
                            })
                        })
                    }
                    if (res.data.list.length > 0 && this.dict.oilList.length > 0) {
                        res.data.list.map(item => {
                            this.dict.oilList.map(key => {
                                if (item.oilTypeId == key.id) {
                                    item.oilTypeIdName = key.title
                                }
                            })
                        })
                    }
                    if (res.data.list.length > 0 && this.dict.shipPlanStates.length > 0) {
                        res.data.list.map(item => {
                            item.statesName = this.dict.shipPlanStates[item.states]
                            item.planTypeName = staticData.shipPlanType[item.planType]
                            if (!item.approveStates && item.approveStates != 0) {
                                item.approveStatesTxt = "未编辑"
                            } else {
                                console.log(staticData, 1111);
                                item.approveStatesTxt = staticData.approveStates[item.approveStates] + "(" + staticData.shipPlanChageType[item.changeType] + ")"
                            }
                        })
                    }
                    this.tableData = res.data.list
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.ChangeHarborDivideUnloadApply {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form-item__label {
            width: 100px;
        }

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        /deep/.el-input__inner {
            width: 200px;
        }

        /deep/.el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 200px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度


        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .addBox {
            height: 50vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0 20px;

            .el-select {
                width: 100%;
            }
        }

        .addoreidt {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-steps {
                height: auto !important;
            }
        }

        /deep/.el-tabs__item {
            font-size: 18px;
        }
    }
}
</style>