<template>
  <div class="page ShipRectification">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="船舶名称：">
          <el-input v-model="searchForm.title" placeholder="请输入船舶名称" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox v-for="(item, index) in checkListData" :key="index" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" v-if="isready" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" type="index" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查类型" prop="checkType" v-if="checkList.indexOf('检查类型') != -1">
          <template slot-scope="scope">
            <span v-if="scope.row.checkType == 0">认证检查</span>
            <span v-else-if="scope.row.checkType == 1">日常检查</span>
            <span v-else-if="scope.row.checkType == 2">其他检查</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="待整改船舶" prop="title" v-if="checkList.indexOf('待整改船舶') != -1">
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="carrierstitle" v-if="checkList.indexOf('承运商') != -1">
        </el-table-column>
        <el-table-column align="center" label="发运港" prop="startPortName" v-if="checkList.indexOf('发运港') != -1">
          <template slot-scope="scope">
            <span>{{ scope.row.startPortName ? scope.row.startPortName : '无港口' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="到港" prop="destPortName" v-if="checkList.indexOf('到港') != -1">
          <template slot-scope="scope">
            <span>{{ scope.row.destPortName ? scope.row.destPortName : '无港口' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船舶检查状态" prop="book_title" v-if="checkList.indexOf('船舶检查状态') != -1">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.result == 1">检查通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.result == 2">检查不通过</el-tag>
            <el-tag type="info" v-else>待检查</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船舶检查时间" prop="createTime" v-if="checkList.indexOf('船舶检查时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <div v-if="scope.row.checkType == 0">
              <el-button type="primary" size="mini" @click="editerow(scope.row)">录入整改</el-button>
              <el-button type="success" size="mini" @click="editerow(scope.row)">查看结果</el-button>
            </div>
            <div v-else>
              <el-button type="primary" v-if="scope.row.result == 1" size="mini"
                @click="editerow(scope.row)">查看整改结果</el-button>
              <el-button type="primary" v-if="scope.row.result != 0 && scope.row.result != 1" size="mini"
                @click="editerow(scope.row)">录入整改结果</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">检查类型</th>
              <th class="tg-0lax">待整改船舶</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">发运港</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">船舶检查状态</th>
              <th class="tg-0lax">船舶检查时间</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.checkType==0?'认证检查':item.checkType==1?'日常检查':item.checkType==2?'其他检查':'无' }}</th>
            <th class="tg-0lax">{{ item.title }}</th>
            <th class="tg-0lax">{{ item.carrierstitle }}</th>
            <th class="tg-0lax">{{ item.startPortName }}</th>
            <th class="tg-0lax">{{ item.destPortName }}</th>
            <th class="tg-0lax">{{ item.result==1?'检查通过':item.result==2?'检查不通过':'待检查' }}</th>
            <th class="tg-0lax">{{ item.createTime }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ShipRectification",
  data() {
    return {
      isready: true,
      checkList: ['序号', '检查类型', '待整改船舶', '承运商', '发运港', '到港', '船舶检查状态', '船舶检查时间'],
      checkListData: ['序号', '检查类型', '待整改船舶', '承运商', '发运港', '到港', '船舶检查状态', '船舶检查时间'],
      multipleSelection: [],
      searchForm: {
        title: '',
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      shipProt: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getoilWarehouseInfo_Dict()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.jclxlg = item.checkType == 0 ? '认证检查' : item.checkType == 1 ? '日常检查' : item.checkType == 2 ? '其他检查' : '无'
            item.cbjcztlg = item.result == 1 ? '检查通过' : item.result == 2 ? '检查不通过' : '待检查'
            tableData.push(item)
          })
          let config = [
            { field: 'jclxlg', width: 80, align: 'center', title: '检查类型' },
            { field: 'title', width: 80, align: 'center', title: '待整改船舶' },
            { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
            { field: 'startPortName', width: 150, align: 'center', title: '发运港' },
            { field: 'destPortName', width: 150, align: 'center', title: '到港' },
            { field: 'cbjcztlg', width: 150, align: 'center', title: '船舶检查状态' },
            { field: 'createTime', width: 80, align: 'center', title: '船舶检查时间' },
          ]
          let obj = {
            name: '船舶整改',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    dropdownChange(e) {
      this.isready = false
      this.$nextTick(() => {
        this.isready = true
      })
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getoilWarehouseInfo_Dict() {
      this.$Api.oilWarehouseInfo_Dict().then(res => {
        this.shipProt = res.data.shipPort
        this.getData()
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        shipName: this.searchForm.title
      }
      this.tableLoading = true
      this.$Api.security_getcertifiedcheckship(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = Number(res.data.total[0].SUM);
          if (res.data.list.length > 0 && this.shipProt.length > 0) {
            res.data.list.map(item => {
              // item.startPortName = ''
              // item.destPortName = ''
              this.shipProt.map(key => {
                if (item.startPort == key.id) {
                  item.startPortName = key.title
                }
                if (item.destPort == key.id) {
                  item.destPortName = key.title
                }
              })
            })
          }
          this.tableData = res.data.list
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.ShipRectification {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>