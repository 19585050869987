<template>
  <div class="page Advent">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="证照状态：">
          <el-select clearable v-model="searchForm.type" placeholder="请选择" size="small">
            <el-option :value="30" label="已到期"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">

        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox label="船舶"></el-checkbox>
                  <el-checkbox label="承运商"></el-checkbox>
                  <el-checkbox label="MMIS号"></el-checkbox>
                  <el-checkbox label="内河船舶检验证"></el-checkbox>
                  <el-checkbox label="安全管理证书"></el-checkbox>
                  <el-checkbox label="船舶最低安全配员证"></el-checkbox>
                  <el-checkbox label="船舶营业运输证"></el-checkbox>
                  <el-checkbox label="内河船舶保险单(船舶险)"></el-checkbox>
                  <el-checkbox label="内河船舶保险单(油污险)"></el-checkbox>
                  <el-checkbox label="内河船舶保险单(船员险)"></el-checkbox>
                  <el-checkbox label="国家船舶舱容积计量站检定证"></el-checkbox>
                  <el-checkbox label="国内航行船舶入级证"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table v-if="ready" id="tabbox" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
        tooltip-effect="dark" height="(100% - 104px)" row-key="id" default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" label="序号" width="120" type="index" fixed="left">
        </el-table-column>
        <el-table-column align="center" label="船舶" width="120" prop="shiptitle" fixed="left"
          v-if="checkList.indexOf('船舶') != -1">
        </el-table-column>
        <el-table-column align="center" label="承运商" width="120" prop="carrierstitle" fixed="left"
          v-if="checkList.indexOf('承运商') != -1">
        </el-table-column>
        <el-table-column align="center" label="MMIS号" width="120" prop="ais" v-if="checkList.indexOf('MMIS号') != -1">
        </el-table-column>
        <el-table-column align="center" label="内河船舶检验证" width="200" prop="nhcbjyCertNoName"
          v-if="checkList.indexOf('内河船舶检验证') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.nhcbjyCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="安全管理证书" width="200" prop="aqglCertNoName"
          v-if="checkList.indexOf('安全管理证书') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.aqglCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船舶最低安全配员证" width="200" prop="cbzdaqypyCertNoName"
          v-if="checkList.indexOf('船舶最低安全配员证') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.cbzdaqypyCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船舶营业运输证" width="200" prop="cbyyyszjnshgzCertNoName"
          v-if="checkList.indexOf('船舶营业运输证') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.cbyyyszjnshgzCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="内河船舶保险单(船舶险)" width="200" prop="yhnhcbbxdCertNoName"
          v-if="checkList.indexOf('内河船舶保险单(船舶险)') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.yhnhcbbxdCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="内河船舶保险单(油污险)" width="200" prop="yhnhcbbxdCertNo1Name"
          v-if="checkList.indexOf('内河船舶保险单(油污险)') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.yhnhcbbxdCertNo1Name"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="内河船舶保险单(船员险)" width="200" prop="yhnhcbbxdCertNo2Name"
          v-if="checkList.indexOf('内河船舶保险单(船员险)') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.yhnhcbbxdCertNo2Name"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="国家船舶舱容积计量站检定证" width="200" prop="gjcbcrjjszjdCertNoName"
          v-if="checkList.indexOf('国家船舶舱容积计量站检定证') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.gjcbcrjjszjdCertNoName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="国内航行船舶入级证" width="200" prop="gnhxcbrjCertNoName"
          v-if="checkList.indexOf('国内航行船舶入级证') != -1">
          <template slot-scope="scope">
            <div v-html="scope.row.gnhxcbrjCertNoName"></div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">船舶</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">MMIS号</th>
              <th class="tg-0lax">内河船舶检验证</th>
              <th class="tg-0lax">安全管理证书</th>
              <th class="tg-0lax">船舶最低安全配员证</th>
              <th class="tg-0lax">船舶营业运输证</th>
              <th class="tg-0lax">内河船舶保险单(船舶险)</th>
              <th class="tg-0lax">内河船舶保险单(油污险)</th>
              <th class="tg-0lax">内河船舶保险单(船员险)</th>
              <th class="tg-0lax">国家船舶舱容积计量站检定证</th>
              <th class="tg-0lax">国内航行船舶入级证</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.shiptitle }}</th>
            <th class="tg-0lax">{{ item.carrierstitle }}</th>
            <th class="tg-0lax">{{ item.ais }}</th>
            <th class="tg-0lax" v-html="item.nhcbjyCertNoName"></th>
            <th class="tg-0lax" v-html="item.aqglCertNoName"></th>
            <th class="tg-0lax" v-html="item.cbzdaqypyCertNoName"></th>
            <th class="tg-0lax" v-html="item.cbyyyszjnshgzCertNoName"></th>
            <th class="tg-0lax" v-html="item.yhnhcbbxdCertNoName"></th>
            <th class="tg-0lax" v-html="item.yhnhcbbxdCertNo1Name"></th>
            <th class="tg-0lax" v-html="item.yhnhcbbxdCertNo2Name"></th>
            <th class="tg-0lax" v-html="item.gjcbcrjjszjdCertNoName"></th>
            <th class="tg-0lax" v-html="item.gnhxcbrjCertNoName"></th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Advent",
  data() {
    return {
      ready: true,
      checkList: ['序号', '船舶', '承运商', 'MMIS号', '内河船舶检验证', '安全管理证书', '船舶最低安全配员证', '船舶营业运输证', '内河船舶保险单(船舶险)', '内河船舶保险单(油污险)', '内河船舶保险单(船员险)', '国家船舶舱容积计量站检定证', '国内航行船舶入级证'],
      multipleSelection: [],
      searchForm: {
        type: ''
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.tbyflg = (item.adjustPriceYUNJIA - item.originalPriceYUNJIA).toFixed(3)
            item.glztlg = item.sfglName == '未关联' ? '未关联' : '已关联'
            tableData.push(item)
          })
          let config = [
            { field: 'shiptitle', width: 80, align: 'center', title: '船舶' },
            { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
            { field: 'ais', width: 80, align: 'center', title: 'MMIS号' },
            { field: 'nhcbjyCertNoName', width: 150, align: 'center', title: '内河船舶检验证' },
            { field: 'aqglCertNoName', width: 150, align: 'center', title: '安全管理证书' },
            { field: 'cbzdaqypyCertNoName', width: 150, align: 'center', title: '船舶最低安全配员证' },
            { field: 'cbyyyszjnshgzCertNoName', width: 80, align: 'center', title: '船舶营业运输证' },
            { field: 'yhnhcbbxdCertNoName', width: 80, align: 'center', title: '内河船舶保险单(船舶险)' },
            { field: 'yhnhcbbxdCertNo1Name', width: 80, align: 'center', title: '内河船舶保险单(油污险)' },
            { field: 'yhnhcbbxdCertNo2Name', width: 80, align: 'center', title: '内河船舶保险单(船员险)' },
            { field: 'gjcbcrjjszjdCertNoName', width: 100, align: 'center', title: '国家船舶舱容积计量站检定证' },
            { field: 'gnhxcbrjCertNoName', width: 80, align: 'center', title: '国内航行船舶入级证' },
          ]
          let obj = {
            name: '即将到期的证照',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    addRole() {
      console.log('添加');
    },
    dropdownChange(e) {
      this.ready = false
      this.$nextTick(() => {
        this.ready = true
      })
      console.log(e);
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        type: this.searchForm.type == '' ? 10 : this.searchForm.type
      }
      this.tableLoading = true
      this.$Api.security_getshipexpiredcertificate(data).then(res => {
        if (res.code == 200) {
          if (res.data[0].total) this.page.total = res.data[0].total
          this.tableData = res.data[0].data
          this.tableLoading = false
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.Advent {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }


    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>