<template>
    <div class="page boatPlan_Dateils" id="pageTop">
        <el-page-header @back="goBack" content="查看详情"></el-page-header>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                :row-class-name="tableRowClassName" :cell-class-name="tableCellClassName" 
                 style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="统计" prop="total">
                        <el-table-column align="center" label="名称" prop="name">
                            <template slot-scope="scope">
                                <div
                                    style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;flex-direction: column;">
                                    <div>{{ scope.row.name }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="小计" prop="total">
                            <template slot-scope="scope">
                                <span>{{ scope.row.total || 0 }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                <el-table-column align="center" label="进川">
                    <el-table-column align="center" label="预排" prop="wts" width="330">
                        <template slot-scope="scope">
                            <span>{{ scope.row.wts || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp2 == 'NaN%' ? '0.00%' : scope.row.gdp2 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="干线">
                    <el-table-column align="center" label="预排" prop="wts2" width="330">
                        <template slot-scope="scope">
                            <span>{{ scope.row.wts2 || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp3">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp3 == 'NaN%' ? '0.00%' : scope.row.gdp3 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="内河">
                    <el-table-column align="center" label="预排" prop="wts3" width="330">
                        <template slot-scope="scope">
                            <span>{{ scope.row.wts3 || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="占比" prop="gdp4">
                        <template slot-scope="scope">
                            <span>{{ scope.row.gdp4 == 'NaN%' ? '0.00%' : scope.row.gdp4 }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-p-t-15">
            </div>
        </div>

    </div>
    <!-- <el-empty description="暂无数据" v-else></el-empty> -->
</template>
    
<script>
export default {
    name: "boatPlan_Dateils",
    data() {
        return {
            tableData: [{
                name: "总计",
                total: 0,
                gdp: '100%',
                wts: 0,
                gdp2: '100%',
                wts2: 0,
                gdp3: '100%',
                wts3: 0,
                gdp4: '100%',
            }],
            tableLoading: false,
            monthly: ''
        }
    },
    created() {
        this.monthly = this.$route.query.monthly
        console.log(this.monthly, 'monthly');
        if (this.monthly) {
            this.getData()
        }
    },
    activated() {

    },
    mounted() {

    },
    methods: {
        lookFile(url) {
            window.open(url, '_blank');
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/planDispatch/walkthroughsPlanExamineDetails')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        tableCellClassName(cell) {
            if (cell.column.label == '占比') {
                return 'warning-row';
            }
            return '';
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 0) {
                return 'warning-row';
            }
            return '';
        },
        getData() {
            let data = {
                monthly: this.monthly,
            }
            this.tableLoading = true
            this.$Api.plan_approvePublic_view(data).then(res => {
                console.log(res);
                this.tableLoading = false
                if (res.code === 200) {
                    if (res.data.list.length > 0) {
                        res.data.list.map((item, index) => {
                            this.tableData.push({
                                name: item.title,
                                total: Number(item.num1) + Number(item.num2) + Number(item.num3) == 'NaN' ? 0 : Number(item.num1) + Number(item.num2) + Number(item.num3),
                                gdp: '0.00%',
                                wts: item.num1,
                                gdp2: '0.00%',
                                wts2: item.num2,
                                gdp3: '0.00%',
                                wts3: item.num3,
                                gdp4: '0.00%',
                            })
                            this.wtsInput(item.num1 ? item.num1 : 0, index + 1)
                            this.wtsInput2(item.num2 ? item.num2 : 0, index + 1)
                            this.wtsInput3(item.num3 ? item.num3 : 0, index + 1)
                        })
                    }
                }
            })
        },
        wtsInput3(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts3 = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts3 += Number(item.wts3)
                    }
                })
                this.tableData[0].wts3 = wts3
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        wtsInput2(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts2 = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts2 += Number(item.wts2)
                    }
                })
                this.tableData[0].wts2 = wts2
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        wtsInput(e, index) {
            if (e) {
                this.tableData[index].total = Number(this.tableData[index].wts) + Number(this.tableData[index].wts2) + Number(this.tableData[index].wts3)
                let wts = 0
                this.tableData.forEach((item, indexs) => {
                    if (indexs != 0) {
                        wts += Number(item.wts)
                    }
                })
                this.tableData[0].wts = wts
                this.tableData[0].total = Number(this.tableData[0].wts) + Number(this.tableData[0].wts2) + Number(this.tableData[0].wts3)
                this.tableData.forEach((item, indexx) => {
                    if (indexx != 0) {
                        this.tableData[indexx].gdp = (Number(this.tableData[indexx].total) / Number(this.tableData[0].total) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp2 = (Number(this.tableData[indexx].wts) / Number(this.tableData[0].wts) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp3 = (Number(this.tableData[indexx].wts2) / Number(this.tableData[0].wts2) * 100).toFixed(2) + '%'
                        this.tableData[indexx].gdp4 = (Number(this.tableData[indexx].wts3) / Number(this.tableData[0].wts3) * 100).toFixed(2) + '%'
                    }
                })
            }
        },
        // --------------------------公共方法--------------------------------------
    }
}
</script>
    
<style lang="scss" scoped>
.boatPlan_Dateils {
    height: initial;
    // display: flex;
    .el-page-header {
        line-height: 50px;
    }

    /deep/.el-input {
        width: 182px !important;
    }

    /deep/.el-divider__text.is-left {
        font-size: 20px;
        font-weight: 500;
    }

    .container {
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }



        /deep/.el-table .warning-row {
            background: #FFD700;
        }

        /deep/.warning-row:hover {
            background: #FFD700 !important;
        }

        /deep/.el-table .success-row {
            background: #f0f9eb;
        }
    }
}
</style>