<template>
  <div class="menu-page">
    <template v-for="item in list">
      <el-submenu v-if="item._child" :index="item.href">
        <template slot="title">
          <!-- <i :class="item.icon ? item.icon : 'el-icon-menu'" :style="{visibility: item.icon ? 'visible' : 'hidden'}"></i> -->
          <span slot="title">{{ item.title }}</span>
        </template>
        <menu-tree :list="item._child"></menu-tree>
      </el-submenu>
      <el-menu-item v-else :index="item.href">
        <!-- <i :class="item.icon ? item.icon : 'el-icon-menu'" :style="{visibility: item.icon ? 'visible' : 'hidden'}"></i> -->
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "menuTree",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep.menu-page{
  //.el-submenu__title{
  //  span,.el-submenu__icon-arrow{
  //    display: none;
  //  }
  //}
  .el-menu-item.is-active {
    background-color: #409EFF !important;
  }
  .el-menu-item:focus, .el-menu-item:hover{
  }
  .el-sub-menu {
    .el-menu-item {
      padding-left: 40px;
      background-color: #F9FAFB !important;
    }
  }
}

</style>