<template>
  <div class="tree-select" @click.stop>
    <el-input :value="select.name" size="small" clearable @clear="clear" @focus="show = true"></el-input>
    <div class="tree-box" v-show="show">
      <el-scrollbar class="u-row-nowrap" style="max-height: 240px">
        <el-tree :default-checked-keys="checkArr" node-key="id" :expand-on-click-node="false" :data="list"
                 :props="defaultProps" @node-click="selectTree"></el-tree>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "treeSelect",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      default: 0
    }
  },
  data() {
    return {
      show: false,
      select: {},
      defaultProps: {
        children: 'child',
        label: 'name',
        value: 'id'
      },
      checkArr: []
    }
  },
  created() {
    if (this.value !== '') {
      this.initSelect()
    }
  },
  watch:{
    value(val){
      if (val !== '') {
        this.initSelect()
      }
    }
  },
  mounted() {
    document.body.onclick = () => {
      this.show = false
    }
  },
  methods: {
    initSelect() {
      let arr = []
      let initArr = (list = this.list) => {
        list.map(item => {
          arr.push(item)
          if (item.child) {
            initArr(item.child)
          }
        })
      }
      initArr()
      arr.map(item => {
        if (item.id == this.value) {
          this.select = item
        }
      })
    },
    selectTree(e) {
      this.select = e
      this.$emit('input', e.id)
      this.show = false
    },
    clear() {
      this.select = {}
      this.$emit('input', '')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep.tree-select {
  position: relative;

  .tree-box {
    padding: 4px;
    position: absolute;
    background: #fff;
    z-index: 111;
    top: 32px;
    left: 0;
    width: 100%;
    border: 1px solid #DCDFE6;
    border-radius: 0 0 4px 4px;

    .el-tree {
      padding-bottom: 20px;
    }
    .el-scrollbar{
      overflow-y: auto;
    }
  }
}
</style>