<template>
    <div class="page ReportViewoilType">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="时间范围：">
                    <el-date-picker size="small" v-model="searchForm.dateResult" type="daterange" align="right"
                        unlink-panels value-format="yyyy-MM-dd" format="yyyy-MM-dd" range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
                        @change="dateChange">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" :label="item.sfmc" :prop="item.sfmc" v-for="(item, index) in tableHeader">
                    <template slot-scope="scope">
                        {{ scope.row[index][item.sfmc] }}
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div class="detailInfo" id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax" v-for="(item, index) in tableHeader" :key="index">{{ item.sfmc }}</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax" v-for="(items, indexs) in tableHeader">{{ item[indexs][items.sfmc] }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ReportViewoilType",
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            multipleSelection: [],
            searchForm: {
                dateResult: [],
                date: '',
                dateOver: '',
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            tableHeader: [],
            headerList: []
        }
    },
    created() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // 月份从0开始计数，因此要加1
        let day = date.getDate();
        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day
        this.searchForm.dateResult[0] = year + '-' + month + "-" + day
        this.searchForm.dateResult[1] = year + '-' + month + "-" + day
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.maxDate()
        this.maxDateend()
        this.getHeader()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        maxDate() {
            var now = new Date();
            this.searchForm.date = now.getFullYear() + "-" + (now.getMonth() + 1) + "-1";
        },
        maxDateend() {
            var now = new Date();
            this.searchForm.dateOver = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
        },
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                console.log('Excel');
                if (this.tableData.length > 0) {
                    let obj = {
                        name: '承运商完成量(分省市)',
                        excel_config: JSON.stringify(this.headerList),
                        data: JSON.stringify(this.tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

.detailInfo .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.detailInfo td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        dateChange(e) {
            if (e) {
                console.log(e, this.searchForm.dateResult);
                this.date = e[0]
                this.dateOver = e[1]
            } else {
                this.date = ''
                this.dateOver = ''
            }
        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        handleClick(tab) {
            switch (tab.name) {
                case 'first':
                    this.getData()
                    break;
                case 'second':

                    break;
                case 'third':

                    break;
                default:
                    break;
            }
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
                this.getHeader()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
                this.getHeader()
            }
        },
        getHeader() {
            let data = {
                date: this.searchForm.dateResult[0] ? this.searchForm.dateResult[0] : '',
                dateOver: this.searchForm.dateResult[1] ? this.searchForm.dateResult[1] : '',
            }
            this.tableLoading = true
            this.$Api.report_view_getsflist(data).then(res => {
                if (res.code == 200) {
                    this.tableHeader = res.data
                    //4.将数据转换为Object类型
                    var keys = Object.values(res.data);

                    //5.循环数据并追加到表头数组中
                    for (let i = 0; i < keys.length; i++) {
                        this.headerList.push({ field: keys[i].sfmc, align: 'center', title: keys[i].sfmc, width: 150 });
                    }
                    if (this.tableHeader.length > 0) {
                        this.getData()
                    } else {
                        this.tableLoading = false
                    }
                }
            })
        },
        getData() { // 获取数据
            let data = {
                date: this.searchForm.dateResult[0] ? this.searchForm.dateResult[0] : '',
                dateOver: this.searchForm.dateResult[1] ? this.searchForm.dateResult[1] : '',
                page: this.page.currentPage,
                limit: this.page.pageSize
            }
            this.tableLoading = true
            this.$Api.report_view_getsywcqk(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.tableData = res.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.ReportViewoilType {
    height: initial;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        .el-table {
            height: 800px;
        }

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }


    }
}
</style>