import http from '../utils/request'

const Index = {
	login: (data) => { //登录
		return http({
			url: '/api/auths/login',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	init: (data) => { //系统初始化
		return http({
			url: '/api/system/init',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	userInfo: (data) => { //个人资料
		return http({
			url: '/api/index/user_info',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	listByState: (data) => { //待审批
		return http({
			url: '/api/MyAppRovalPublic/ListByState',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	listByState_look: (data) => { //待审批-查看
		return http({
			url: '/api/ShipApproval/View',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	listByState_log: (data) => { //待审批-日志
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	listByState_appRova: (data) => { //待审批-通过
		return http({
			url: '/api/MyApprovalPublic/OkMyAppRovalList',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	listByState_refuse: (data) => { //待审批-拒绝
		return http({
			url: '/api/MyApprovalPublic/FailMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	menuList: (data) => { //菜单列表
		return http({
			url: '/api/bases/getList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	menuList_addorupdate: (data) => { //菜单列表-新增和编辑
		return http({
			url: '/api/bases/addorupdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	menuList_delete: (data) => { //菜单列表-删除
		return http({
			url: '/api/bases/delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	getLogData: (data) => { //日志管理
		return http({
			url: '/api/system/GetLogData',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getLogData_handle_type: (data) => { //日志管理-操作类型
		return http({
			url: '/api/publics/handle_type',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getLogData_data_type: (data) => { //日志管理-业务类型
		return http({
			url: '/api/publics/data_type',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getDict: (data) => { //基础信息-单位管理
		return http({
			url: '/api/member/Dict',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getListMyUnReadDocument: (data) => { //首页-未读公文
		return http({
			url: '/api/DocumentGary/ListMyUnReadDocument',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getListListShipPapers: (data) => { //首页-未读公文
		return http({
			url: '/api/DocumentGary/ListShipPapers',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getMemberAssessSocerList: (data) => { //首页-考核评分
		return http({
			url: '/api/Carriers/getMemberAssessSocerList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getListByState: (data) => { //待审批
		return http({
			url: '/api/MyAppRovalPublic/ListByState',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	myAppRovalPublicDictState: (data) => { //待审批 - 状态字典
		return http({
			url: '/api/MyAppRovalPublic/DictState',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	myAppRovalPublicLogList: (data) => { //待审批 - 日志
		return http({
			url: '/api/MyAppRovalPublic/LogList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	myAppRovalPublicOkMyAppRoval: (data) => { //待审批 - 审核通过
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	myAppRovalPublicFailMyAppRoval: (data) => { //待审批 - 审核拒绝
		return http({
			url: '/api/MyAppRovalPublic/FailMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	myAppRovalPublicOkMyAppRovalList: (data) => { //待审批 - 批量通过
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRovalList',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	myAppRovalPublicFailMyAppRovalList: (data) => { //待审批 - 批量拒绝
		return http({
			url: '/api/MyAppRovalPublic/FailMyAppRovalList',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carrierAddorupdate: (data) => { //基础信息 - 单位管理 - 承运商 - 承运商新增/编辑
		return http({
			url: '/api/bases/carrierAddorupdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriermemberGroupdelete: (data) => { //基础信息 - 单位管理 - 承运商 - 删除
		return http({
			url: '/api/bases/memberGroup_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriermemberGroupInfo: (data) => { //基础信息 - 单位管理 - 承运商 - 查看资质
		return http({
			url: '/api/CarriersGary/Info',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	provinceAddorupdate: (data) => { //基础信息 - 单位管理 - 省市公司 - 新增/编辑
		return http({
			url: '/api/bases/provinceAddorupdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	getListCrew: (data) => { //基础信息 - 单位管理 - 省市公司 - 关联油库
		return http({
			url: '/api/OilWarehouseInfo/getListCrew',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getOneByIdForYk: (data) => { //基础信息 - 单位管理 - 省市公司 - 油库详情
		return http({
			url: '/api/OilWarehouseInfo/GetOneByIdForYk',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	oilWarehouseInfo_delete: (data) => { //基础信息 - 单位管理 - 省市公司 - 删除
		return http({
			url: '/api/OilWarehouseInfo/delSSTKGK',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	memberGroupGary_ListByType: (data) => { //基础信息 - 单位管理 - 省市公司 - 省市公司列表
		return http({
			url: '/api/MemberGroupGary/ListByType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	publics_transportType: (data) => { //港口类型
		return http({
			url: '/api/publics/transportType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	oilWarehouseInfo_InsertOrUpdateOilWareHouseNew: (data) => { ///基础信息 - 单位管理 - 省市公司 - 新增/编辑油库
		return http({
			url: '/api/OilWarehouseInfo/InsertOrUpdateOilWareHouseNew',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	memberGroupGary_Addorupdate: (data) => { ///基础信息 - 单位管理 - 华中公司 - 新增/编辑
		return http({
			url: '/api/bases/centralAddorupdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	memberGroupGary_detele: (data) => { ///基础信息 - 单位管理 - 华中公司 - 删除
		return http({
			url: '/api/bases/central_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	memberGroupGary_lookdepartment: (data) => { ///基础信息 - 单位管理 - 华中公司 - 查看部门
		return http({
			url: '/api/MemberGroupGary/ListById',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	publics_shipPort: (data) => { // 港口
		return http({
			url: '/api/publics/shipPort',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	residentFactoryaddorupdate: (data) => { ///基础信息 - 其他信息 - 驻厂办事处 - 新增编辑
		return http({
			url: '/api/bases/residentFactoryaddorupdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	residentFactory_delete: (data) => { ///基础信息 - 其他信息 - 驻厂办事处 - 删除
		return http({
			url: '/api/bases/residentFactory_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	getPageShipPort: (data) => { ///基础信息 - 其他信息 - 港口 - 列表
		return http({
			url: '/api/bases/getPageShipPort',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	shipPort_addorupdateShipPort: (data) => { ///基础信息 - 其他信息 - 港口 - 新增/编辑
		return http({
			url: '/api/bases/addShipPort',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPort_deleteShipPort: (data) => { ///基础信息 - 其他信息 - 港口 - 删除
		return http({
			url: '/api/bases/deleteShipPort',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	oilWarehouseInfo_Dict: (data) => { ///基础信息 - 其他信息 - 港口 - 港口数据字典
		return http({
			url: '/api/OilWarehouseInfo/Dict',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	oilWarehouseInfo_Dict2: (data) => { ///基础信息 - 其他信息 - 港口 - 油库数据字典
		return http({
			url: '/api/OilWarehouseInfo/Dict2',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	publics_portType: (data) => { ///发/到站
		return http({
			url: '/api/publics/portType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	getPageOilType: (data) => { /// 油品列表
		return http({
			url: '/api/bases/getPageOilType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	oilType_addorupdate: (data) => { /// 油品列表 - 新增/编辑
		return http({
			url: '/api/bases/addOilType',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	oilType_delete: (data) => { /// 油品列表 - 删除
		return http({
			url: '/api/bases/deleteOilType',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	publics_oilType: (data) => { /// 油品类型
		return http({
			url: '/api/publics/oilType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	bases_addOilType: (data) => { /// 油品类型 - 新增/编辑
		return http({
			url: '/api/bases/addOilType',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	bases_deleteOilType: (data) => { /// 油品类型 - 删除
		return http({
			url: '/api/bases/deleteOilType',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	bases_getPageOilWarehouse: (data) => { ///基础信息 - 其他信息 - 油库 - 列表
		return http({
			url: '/api/bases/getPageOilWarehouse',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	insertOrUpdateOilWareHouse: (data) => { ///基础信息 - 其他信息 - 油库 -新增/编辑
		return http({
			url: '/api/OilWarehouseInfo/InsertOrUpdateOilWareHouse',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	oilWareHousedeleteById_delete: (data) => { ///基础信息 - 其他信息 - 油库 - 删除
		return http({
			url: '/api/OilWarehouseInfo/OilWareHousedeleteById',
			method: 'POST',
			data,
			// noHead:true
		})
	},

	oilWarehouseInfo_Lists: (data) => { ///基础信息 - 其他信息 - 油库 - 油品列表
		return http({
			url: '/api/OilWarehouseInfo/Lists',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	oilWarehouseInfooilType_InsertOrUpdate: (data) => { ///基础信息 - 其他信息 - 油品 - 新增/编辑
		return http({
			url: '/api/OilWarehouseInfo/InsertOrUpdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	oilWarehouseInfo_deleteById: (data) => { ///基础信息 - 其他信息 - 油品 - 删除
		return http({
			url: '/api/OilWarehouseInfo/deleteById',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_role_getList: (data) => { ///基础信息 - 用户管理 - 角色管理 - 列表
		return http({
			url: '/api/member_role/getList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	member_role_insertOrUpdate: (data) => { ///基础信息 - 用户管理 - 角色管理 - 新增/编辑
		return http({
			url: '/api/member_role/insertOrUpdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_role_setStates: (data) => { ///基础信息 - 用户管理 - 角色管理 - 状态启用/禁用
		return http({
			url: '/api/member_role/setStates',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_role_getRoleMenu: (data) => { ///基础信息 - 用户管理 - 角色管理 - 获取角色菜单
		return http({
			url: '/api/member_role/getRoleMenu',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	member_getMemberList: (data) => { ///基础信息 - 用户管理 - 用户列表 - 列表
		return http({
			url: '/api/member/getMemberList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	member_editUserMemberGroup: (data) => { ///基础信息 - 用户管理 - 用户列表 - 编辑用户分组
		return http({
			url: '/api/member/editUserMemberGroup',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_member_delete: (data) => { ///基础信息 - 用户管理 - 删除
		return http({
			url: '/api/bases/member_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_getUserContactList: (data) => { ///基础信息 - 用户管理 - 联系人 - 列表
		return http({
			url: '/api/member/getUserContactList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	member_editUserPhoneGroup: (data) => { ///基础信息 - 用户管理 - 联系人 - 新增编辑
		return http({
			url: '/api/member/editUserPhoneGroup',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_deleteUserPhoneGroup: (data) => { ///基础信息 - 用户管理 - 联系人 - 删除
		return http({
			url: '/api/member/deleteUserPhoneGroup',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_role_setUserRole: (data) => { ///基础信息 - 用户管理 - 公司用户 - 编辑角色
		return http({
			url: '/api/member_role/setUserRole',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_enable: (data) => { ///基础信息 - 用户管理 - 公司用户 - 用户启用
		return http({
			url: '/api/member/Enable',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_disable: (data) => { ///基础信息 - 用户管理 - 公司用户 - 用户禁用
		return http({
			url: '/api/member/Disable',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	company_detele: (data) => { ///基础信息 - 用户管理 - 公司用户 - 用户删除
		return http({
			url: '/api/bases/member_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	member_setDirector: (data) => { ///基础信息 - 用户管理 - 办事处用户 -  设为负责人
		return http({
			url: '/api/member/setDirector',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	bases_getOilWarehouse: (data) => { ///基础信息 - 用户管理 - 省市公司用户 - 用户关联油库
		return http({
			url: '/api/bases/getOilWarehouse',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	member_saveBindoilwarehouse: (data) => { ///基础信息 - 用户管理 - 省市公司 -  绑定油库
		return http({
			url: '/api/member/saveBindoilwarehouse',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	approval_Dict: (data) => { ///基础信息 - 流程管理 - 流程数据字典
		return http({
			url: '/api/ApprovalSet/Dict',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	approval_List: (data) => { ///基础信息 - 流程管理 - 流程列表
		return http({
			url: '/api/ApprovalSet/List',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	approval_Add: (data) => { ///基础信息 - 流程管理 - 新增
		return http({
			url: '/api/ApprovalSet/Create',
			method: 'POST',
			data,
			// noHead:true 
		})
	},
	approval_Edit: (data) => { ///基础信息 - 流程管理 - 修改
		return http({
			url: '/api/ApprovalSet/Update',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	approval_Delete: (data) => { ///基础信息 - 流程管理 - 删除
		return http({
			url: '/api/ApprovalSet/Delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	publicCharacter_Dict: (data) => { ///公文管理 - 公文数据字典 
		return http({
			url: '/api/DocumentGary/Dict',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	publicCharacter_Send: (data) => { //公文管理 - 创建公文
		return http({
			url: '/api/Document/send',
			method: 'POST',
			data,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
			// noHead:true
		})
	},
	public_approvalType: (data) => { ///数据枚举 - 审批类型
		return http({
			url: '/api/publics/approvalType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	public_assessType: (data) => { ///数据枚举 - 审批考核类型
		return http({
			url: '/api/publics/assessType',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_ListMyAppRoval: (data) => { ///公文管理 - 公文审批 - 列表
		return http({
			url: '/api/DocumentGary/ListMyAppRoval',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_DictMyAppRoval: (data) => { ///公文管理 - 公文审批 - 公文审批状态
		return http({
			url: '/api/DocumentGary/DictMyAppRoval',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_SetDocumentRead: (data) => { ///公文管理 - 公文审批 - 查看公文详情
		return http({
			url: '/api/DocumentGary/SetDocumentRead',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	documentGary_LogList: (data) => { ///公文管理 - 公文审批 - 日志
		return http({
			url: '/api/DocumentGary/LogList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_OkMyAppRoval: (data) => { ///公文管理 - 公文审批 - 通过
		return http({
			url: '/api/DocumentGary/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	documentGary_FailMyAppRoval: (data) => { ///公文管理 - 公文审批 - 不通过
		return http({
			url: '/api/DocumentGary/FailMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	bases_getOne: (data) => { ///获取详情，可通过传递不同表名查询
		return http({
			url: '/api/bases/getOne',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_ListMyDocument: (data) => { ///公文管理 - 我收到的公文
		return http({
			url: '/api/DocumentGary/ListMyDocument',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_ListMySendDocument: (data) => { ///公文管理 - 我发送的公文
		return http({
			url: '/api/DocumentGary/ListMySendDocument',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_DictMyDocument: (data) => { ///公文管理 - 我的文公字典
		return http({
			url: '/api/DocumentGary/DictMyDocument',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	documentGary_deleteDocument: (data) => { ///公文管理 - 我发送的公文 - 删除
		return http({
			url: '/api/DocumentGary/deleteDocument',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_getshipexpiredcertificate: (data) => { ///承运商管理 - 即将到期的证照 - 列表
		return http({
			url: '/api/Security/getshipexpiredcertificate',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_getAssessSummary: (data) => { ///承运商管理 - 考核项模板维护 - 列表
		return http({
			url: '/api/carriers.CarriersAssess/getAssessSummary',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_assessUploadForSummary: (data) => { ///承运商管理 - 考核项模板维护 - 导入
		return http({
			url: '/api/carriers.CarriersAssess/assessUploadForSummary',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_getAssessItemTypes: (data) => { ///承运商管理 - 考核项模板维护 - 考核项类型
		return http({
			url: '/api/carriers.CarriersAssess/getAssessItemTypes',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_updAssessItemTypes: (data) => { ///承运商管理 - 考核项模板维护 - 考核项类型设置
		return http({
			url: '/api/carriers.CarriersAssess/updAssessItemTypes',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_addAssessSummary: (data) => { ///承运商管理 - 考核项模板维护 - 新增考核项
		return http({
			url: '/api/carriers.CarriersAssess/addAssessSummary',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_updateassessItem: (data) => { ///承运商管理 - 考核项模板维护 - 修改考核项
		return http({
			url: '/api/carriers.CarriersAssess/updateassessItem',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_deleteAssessItem: (data) => { ///承运商管理 - 考核项模板维护 - 删除考核项
		return http({
			url: '/api/carriers.CarriersAssess/deleteAssessItem',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_getAssessList: (data) => { ///承运商管理 - 考核任务创建 - 列表
		return http({
			url: '/api/carriers.Assess/getAssessList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_getCarriersList: (data) => { ///承运商管理 - 考核任务创建 - 承运商列表
		return http({
			url: '/api/carriers.Assess/getCarriersList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_saveAssess: (data) => { ///承运商管理 - 考核任务创建 - 保存考核
		return http({
			url: '/api/carriers.Assess/saveAssess',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_assessSummaryTaskitemUpload: (data) => { ///承运商管理 - 考核任务创建 - 新增自动添加检查标准项
		return http({
			url: '/api/carriers.Assess/assessSummaryTaskitemUpload',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_updateStartScore: (data) => { ///承运商管理 - 考核任务创建 - 开始评分
		return http({
			url: '/api/carriers.Assess/updateStartScore',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_getAssessItemList: (data) => { ///承运商管理 - 考核任务创建 - 考核项列表
		return http({
			url: '/api/carriers.Assess/getAssessItemList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_saveAssessItem: (data) => { ///承运商管理 - 考核任务创建 - 新增编辑考核项
		return http({
			url: '/api/carriers.Assess/saveAssessItem',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_deleteAssessItem2: (data) => { ///承运商管理 - 考核任务创建 - 删除考核项
		return http({
			url: '/api/carriers.Assess/deleteAssessItem',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_deleteAssess: (data) => { ///承运商管理 - 考核任务创建 - 删除考核计划
		return http({
			url: '/api/carriers.Assess/deleteAssess',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriersResultList_getMemberAssessSocerResultList: (data) => { ///承运商管理 - 考核结果发布 - 列表
		return http({
			url: '/api/carriers.ResultList/getMemberAssessSocerResultList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriersResultList_itemlist: (data) => { ///承运商管理 - 考核结果发布 - 详情(按月)
		return http({
			url: '/api/carriers.ResultList/itemlist',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriersResultList_itemlistByQuarter: (data) => { ///承运商管理 - 考核结果发布 - 详情(按季)
		return http({
			url: '/api/carriers.ResultList/itemlistByQuarter',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriersResultList_itemlistByYear: (data) => { ///承运商管理 - 考核结果发布 - 详情(按年)
		return http({
			url: '/api/carriers.ResultList/itemlistByYear',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_saveCarrierInfo: (data) => { ///承运商管理 - 承运商信息维护 - 保存
		return http({
			url: '/api/Carriers/saveCarrierInfo',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_ScoreitemList: (data) => { ///承运商管理 - 评分 - 考核操作
		return http({
			url: '/api/carriers.Score/itemList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_assessCarriersItem: (data) => { ///承运商管理 - 评分 - 承运商考核项详情
		return http({
			url: '/api/carriers.Score/assessCarriersItem',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_saveAssessCarriersScore: (data) => { ///承运商管理 - 评分 - 提交评分
		return http({
			url: '/api/carriers.Score/saveAssessCarriersScore',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	carriers_ScoreGetScoreForChange: (data) => { ///承运商管理 - 考核结果发布 - 查询评分修改记录
		return http({
			url: '/api/carriers.Score/GetScoreForChange',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	carriers_ResultListpublishAssess: (data) => { ///承运商管理 - 考核结果发布 - 发布考核结果
		return http({
			url: '/api/carriers.ResultList/publishAssess',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	MyAppRovalPublic_List: (data) => { ///承运商管理 - 考核结果审核 - 列表
		return http({
			url: '/api/MyAppRovalPublic/List',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	MyAppRovalPublic_LogList: (data) => { ///承运商管理 - 考核结果审核 - 日志
		return http({
			url: '/api/MyAppRovalPublic/LogList',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	MyAppRovalPublic_FailMyAppRoval: (data) => { ///承运商管理 - 考核结果审核 - 审核拒绝
		return http({
			url: '/api/MyAppRovalPublic/FailMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	MyAppRovalPublic_OkMyAppRoval: (data) => { ///承运商管理 - 考核结果审核 - 审核通过
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_getPageShip: (data) => { ///承运商管理 - 船舶管理 - 列表
		return http({
			url: '/api/CarriersShip/getPageShip',
			method: 'GET',
			params: data,
			// noHead:true
		})
	},
	CarriersShip_saveShip: (data) => { ///承运商管理 - 船舶管理 - 新增和保存船舶
		return http({
			url: '/api/CarriersShip/saveShip',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_ship_delete: (data) => { ///承运商管理 - 船舶管理 - 新增和保存船舶
		return http({
			url: '/api/CarriersShip/ship_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_GetApproval: (data) => { ///承运商管理 - 船舶管理 - 获取证书审核
		return http({
			url: '/api/CarriersShip/GetApproval',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_getListCrewl: (data) => { ///承运商管理 - 船舶管理 - 船员列表
		return http({
			url: '/api/CarriersShip/getListCrew',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_crewJob: (data) => { ///承运商管理 - 船舶管理 - 船员职务列表
		return http({
			url: '/api/publics/crewJob',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_saveShipCrew: (data) => { ///承运商管理 - 船舶管理 - 船员保存
		return http({
			url: '/api/CarriersShip/saveShipCrew',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_shipCrew_delete: (data) => { ///承运商管理 - 船舶管理 - 船员删除
		return http({
			url: '/api/CarriersShip/shipCrew_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_getListWatch: (data) => { ///承运商管理 - 船舶管理 - 监控列表
		return http({
			url: '/api/CarriersShip/getListWatch',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_saveShipWatch: (data) => { ///承运商管理 - 船舶管理 - 监控保存
		return http({
			url: '/api/CarriersShip/saveShipWatch',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_shipWatch_delete: (data) => { ///承运商管理 - 船舶管理 - 监控删除
		return http({
			url: '/api/CarriersShip/shipWatch_delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	publics_shipOnlineApplyType: (data) => { ///承运商管理 - 船舶管理 - 船舶上线申请类型
		return http({
			url: '/api/publics/shipOnlineApplyType',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_addapplyView: (data) => { ///承运商管理 - 船舶管理 - 获取上线停复航申请
		return http({
			url: '/api/CarriersShip/addapplyView',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_CreateApproval: (data) => { ///承运商管理 - 船舶管理 - 提交上线停复航申请
		return http({
			url: '/api/ship.ShipApproval/CreateApproval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	publics_shipRepairApplyType: (data) => { ///承运商管理 - 船舶管理 - 船舶维修申请类型
		return http({
			url: '/api/publics/shipRepairApplyType',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_EditRepairApprovel: (data) => { ///承运商管理 - 船舶管理 - 提交船舶检修流程审批
		return http({
			url: '/api/ship.ShipApproval/EditRepairApprovel',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_ShipSiteTable: (data) => { ///承运商管理 - 船舶管理 - 获取船舶船位
		return http({
			url: '/api/ship.ShipSiteTable/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_ShipSiteTable_GetData: (data) => { ///承运商管理 - 船舶管理 - 更新船舶船位
		return http({
			url: '/api/ship.ShipSiteTable/GetData',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_ShipSiteTable_Save: (data) => { ///承运商管理 - 船舶管理 - 保存船舶船位
		return http({
			url: '/api/ship.ShipSiteTable/Save',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_ShipTonnageTable: (data) => { ///承运商管理 - 船舶管理 - 获取船舶吨位
		return http({
			url: '/api/ship.ShipTonnageTable/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	CarriersShip_ShipTonnageTable_IsUpdate: (data) => { ///承运商管理 - 船舶管理 - 获取是否可以修改
		return http({
			url: '/api/ship.ShipTonnageTable/IsUpdate',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_ShipTonnageTable_Save: (data) => { ///承运商管理 - 船舶管理 - 保存吨位
		return http({
			url: '/api/ship.ShipTonnageTable/Save',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_addEditTonApprovel: (data) => { ///承运商管理 - 船舶管理 - 提交船舶吨位流程审批
		return http({
			url: '/api/ship.ShipApproval/addEditTonApprovel',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	SCM_getshipCheckRule: (data) => { ///安检管理 - 日常检查标准维护 - 获取日常检查规则
		return http({
			url: '/api/security/getshipCheckRule',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	SCM_addshipCheckRule: (data) => { ///安检管理 - 日常检查标准维护 - 新增日常检查标准
		return http({
			url: '/api/security/addshipCheckRule',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	SCM_updateshipCheckRule: (data) => { ///安检管理 - 日常检查标准维护 - 修改日常检查标准
		return http({
			url: '/api/security/updateshipCheckRule',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	SCM_deleteshipCheckRule: (data) => { ///安检管理 - 日常检查标准维护 - 删除日常检查标准
		return http({
			url: '/api/security/deleteshipCheckRule',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	SCM_uploadshipCheckRule: (data) => { ///安检管理 - 日常检查标准维护 - 导入日常检查标准
		return http({
			url: '/api/security/safetyitemUpload',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	ShipRepair_View: (data) => { ///承运商管理 - 船舶检修审批 - 查看
		return http({
			url: '/api/ship.ShipRepair/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	ShipRepair_OkMyAppRoval: (data) => { ///承运商管理 - 船舶检修审批 - 查看
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	CarriersShip_updateShipStates: (data) => { ///承运商管理 - 船舶管理 - 修改状态- 立即生效
		return http({
			url: '/api/ship.ShipApproval/updateShipStates',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	ShipApproval_view11ship:(data) => { ///承运商管理 - 船舶资质审批 - 查看
		return http({
			url: '/api/ship.ShipApproval/view11ship',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	ShipApproval_View:(data) => { ///承运商管理 - 船舶上线复航审批 - 查看
		return http({
			url: '/api/ship.ShipApproval/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_getcertifiedcheckrest:(data) => { ///安检管理 - 其他检查 - 列表
		return http({
			url: '/api/security/getcertifiedcheckrest',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_getshipRectifyData:(data) => { ///安检管理 - 其他检查 - 查看检查结果
		return http({
			url: '/api/security/getshipRectifyData',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_getshiplist:(data) => { ///安检管理 - 其他检查 - 船舶列表
		return http({
			url: '/api/publics/getshiplist',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_updateshipCheckTaskbyid:(data) => { ///安检管理 - 其他检查 - 通过
		return http({
			url: '/api/security/updateshipCheckTaskbyid',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_addshipRectif:(data) => { ///安检管理 - 其他检查 - 不通过
		return http({
			url: '/api/security/addshipRectif',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_insertshipCheckTask:(data) => { ///安检管理 - 其他检查 - 新增检查
		return http({
			url: '/api/several_quality/insertshipCheckTask',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_getcertifiedcheckship:(data) => { ///安检管理 - 船舶整改 - 列表
		return http({
			url: '/api/security/getcertifiedcheckship',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_updateshipRectifybyid:(data) => { ///安检管理 - 船舶整改 - 列表
		return http({
			url: '/api/security/updateshipRectifybyid',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_getcertifiedcheck:(data) => { ///安检管理 - 认证检查 - 列表
		return http({
			url: '/api/security/getcertifiedcheck',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_deleteShipCheck:(data)=>{ ///安检管理 - 认证检查 - 删除
		return http({
			url: '/api/several_quality/deleteShipCheck',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	security_getcertifiedcheckday:(data) => { ///安检管理 - 日常检查 - 列表
		return http({
			url: '/api/security/getcertifiedcheckday',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	security_getshipCheckRuleHistory:(data) => { ///安检管理 - 日常检查 - 根据条件搜索最新检查类型历史归档数据
		return http({
			url: '/api/security/getshipCheckRuleHistory',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthlyDict:(data) => { ///计划调度 -	月度计划 -  月度计划数据字典
		return http({
			url: '/api/plan.plan_monthly/Dict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_List:(data) => { ///计划调度 -	月度计划 -  月度计划列表
		return http({
			url: '/api/plan.plan_monthly/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_GetOilByPortId:(data) => { ///计划调度 -	月度计划 -  根据港口ID获取油库
		return http({
			url: '/api/ship.ShipPlan/GetOilByPortId',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_GetTonInfo:(data) => { ///计划调度 -	月度计划 -  获取吃水量
		return http({
			url: '/api/ship.ShipPlan/GetTonInfo',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_getShipState:(data) => { ///计划调度 -	月度计划 -  获取船舶状态
		return http({
			url: '/api/ship.ShipPlan/getShipState',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_shipAdd:(data) => { ///计划调度 -	月度计划 -  下达航次计划
		return http({
			url: '/api/ship.ShipPlan/Add',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	plan_monthly_monthLogDetail:(data) => { ///计划调度 -	月度计划 -  完成数记录
		return http({
			url: '/api/plan.PlanMonthly/monthLogDetail',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_ShipPlanOther:(data) => { ///计划调度 -	月度计划 -  新增完成数列表
		return http({
			url: '/api/plan.ShipPlanOther/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_monthly_AddOtherPlanToMonthPlan:(data) => { ///计划调度 -	月度计划 -  确认完成数
		return http({
			url: '/api/plan.ShipPlanOther/AddOtherPlanToMonthPlan',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	plan_approvePublic_getList:(data) => { ///计划调度 - 预排计划审批 -  预排计划审批列表
		return http({
			url: '/api/MyAppRovalPublic/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_approvePublic_view:(data) => { ///计划调度 - 预排计划审批 -  查看
		return http({
			url: '/api/plan.PlanWalkthroughs/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_approvePublic_getLogList:(data) => { ///计划调度 - 预排计划审批 -  日志
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_approvePublic_OkMyAppRoval:(data) => { ///计划调度 - 预排计划审批 -  审核通过
		return http({
			url: '/api/MyApprovalPublic/OkMyAppRoval',
			method: 'POST',
			data:data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_List:(data) => { ///计划调度 - 预排计划 -  预排计划列表
		return http({
			url: '/api/plan.PlanWalkthroughs/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_LogList:(data) => { ///计划调度 - 预排计划 -  审核日志
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_remarks:(data) => { ///计划调度 - 预排计划 -  填写备注
		return http({
			url: '/api/plan.PlanWalkthroughs/remarks',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_PushNotice:(data) => { ///计划调度 - 预排计划 -  发送预排通知
		return http({
			url: '/api/plan.PlanWalkthroughs/PushNotice',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_Reedit:(data) => { ///计划调度 - 预排计划 -  重新编辑
		return http({
			url: '/api/plan.PlanWalkthroughs/Reedit',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	plan_PlanWalkthroughs_PushApprove:(data) => { ///计划调度 - 预排计划 -  提交审批
		return http({
			url: '/api/plan.PlanWalkthroughs/PushApprove',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_Dict:(data) => { ///计划调度 - 航次计划 -  航次查询数据字典
		return http({
			url: '/api/ship.ShipPlan/Dict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_List:(data) => { ///计划调度 - 航次计划 -  航次计划列表
		return http({
			url: '/api/ship.ShipPlan/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_Add:(data) => { ///计划调度 - 航次计划 -  增加航次计划
		return http({
			url: '/api/ship.ShipPlan/Add',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_Update:(data) => { ///计划调度 - 航次计划 -  编辑计划
		return http({
			url: '/api/ship.ShipPlan/Update',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_Push:(data) => { ///计划调度 - 航次计划 -  提交审核
		return http({
			url: '/api/ship.ShipPlan/Push',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_PushList:(data) => { ///计划调度 - 航次计划 -  批量提交审核
		return http({
			url: '/api/ship.ShipPlan/PushList',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_shipPlanDetail:(data) => { ///计划调度 - 航次计划 -  航次计划详情
		return http({
			url: '/api/ship.ShipPlan/shipPlanDetail',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_GetFileInfo:(data) => { ///计划调度 - 航次计划 -  获取文件信息
		return http({
			url: '/api/ship.ShipPlan/GetFileInfo',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_LogList:(data) => { ///计划调度 - 航次计划 -  日志
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_GetErpVbelnList:(data) => { ///计划调度 - 航次计划 -  完成数列表
		return http({
			url: '/api/ship.ShipPlan/GetErpVbelnList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	publics_staicData:(data) => { // 获取状态
		return http({
			url: '/api/Publics/staicData',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlan_Cancel:(data) => { ///计划调度 - 航次计划 -  取消航次计划
		return http({
			url: '/api/ship.ShipPlan/Cancel',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_Delete:(data) => { ///计划调度 - 航次计划 -  删除航次
		return http({
			url: '/api/ship.ShipPlan/Delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_updateshipplan:(data) => { ///计划调度 - 航次计划 -  确认回执单
		return http({
			url: '/api/ship.ShipPlan/updateshipplan',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_updateFinishAmount:(data) => { ///计划调度 - 航次计划 -  更新完成数
		return http({
			url: '/api/ship.ShipPlan/updateFinishAmount',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_UpdateForLX:(data) => { ///计划调度 - 航次计划 -  更新流向
		return http({
			url: '/api/ship.ShipPlan/UpdateForLX',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlan_UpdateAmount:(data) => { ///计划调度 - 航次计划 -  更新数量
		return http({
			url: '/api/ship.ShipPlan/UpdateAmount',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanOutside_Dict:(data) => { // 计划调度 - 外采计划 - 数据字典
		return http({
			url: '/api/plan.ShipPlanOutside/Dict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	ShipPlanOutside_Dict:(data) => { // 计划调度 - 外采计划 - 数据字典
		return http({
			url: '/api/plan.ShipPlanOutside/Dict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOutside_List:(data) => { // 计划调度 - 外采计划 - 外采计划列表
		return http({
			url: '/api/plan.ShipPlanOutside/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOutside_Create:(data) => { // 计划调度 - 外采计划 - 新增外采计划
		return http({
			url: '/api/plan.ShipPlanOutside/Create',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanOutside_Update:(data) => { // 计划调度 - 外采计划 - 编辑外采计划
		return http({
			url: '/api/plan.ShipPlanOutside/Update',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanOutside_Delete:(data) => { // 计划调度 - 外采计划 - 删除外采计划
		return http({
			url: '/api/plan.ShipPlanOutside/Delete',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanOutside_View:(data) => { // 计划调度 - 外采计划 - 查看外采计划
		return http({
			url: '/api/plan.ShipPlanOutside/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOutside_getStaList:(data) => { // 计划调度 - 外采计划 - 小计列表
		return http({
			url: '/api/plan.ShipPlanOutside/getStaList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOther_GetList:(data) => { // 计划调度 - 其他水运计划列表 - 列表
		return http({
			url: '/api/plan.ShipPlanOther/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOther_Create:(data) => { // 计划调度 - 其他水运计划列表 - 新增
		return http({
			url: '/api/plan.ShipPlanOther/Create',
			method: 'POST',
			data:data,
			// noHead:true
		})
	},
	shipPlanOther_Update:(data) => { // 计划调度 - 其他水运计划列表 - 编辑
		return http({
			url: '/api/plan.ShipPlanOther/Update',
			method: 'POST',
			data:data,
			// noHead:true
		})
	},
	shipPlanOther_View:(data) => { // 计划调度 - 其他水运计划列表 - 查看
		return http({
			url: '/api/plan.ShipPlanOther/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanOther_Delete:(data) => { // 计划调度 - 其他水运计划列表 - 删除
		return http({
			url: '/api/plan.ShipPlanOther/Delete',
			method: 'POST',
			data:data,
			// noHead:true
		})
	},
	several_quality_getshipPlantolist:(data) => { // 计划调度 - 航次计划 - 航次查询
		return http({
			url: '/api/several_quality/getshipPlantolist',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipPlanReturnReceipt:(data) => { // 计划调度 - 航次计划 - 根据航次搜索回执单
		return http({
			url: '/api/several_quality/getshipPlanReturnReceipt',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_updateshipplan:(data) => { // 计划调度 - 航次计划 - 确认收油
		return http({
			url: '/api/several_quality/updateshipplan',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	myAppRovalPublic_Dict9:(data) => { // 计划调度 - 船舶外出计划审批 - 船舶外出数据字典
		return http({
			url: '/api/MyAppRovalPublic/Dict9',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	myAppRovalPublic_List9:(data) => { // 计划调度 - 船舶外出计划审批 - 列表
		return http({
			url: '/api/MyAppRovalPublic/List9',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	myAppRovalPublic_ShipPlanChangeList:(data) => { // 计划调度 - 改港分卸审批 - 改港分卸审批列表
		return http({
			url: '/api/MyAppRovalPublic/ShipPlanChangeList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	myAppRovalPublic_ShipPlanDict:(data) => { // 计划调度 - 改港分卸审批 - 改港分卸审批字典
		return http({
			url: '/api/MyAppRovalPublic/ShipPlanDict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	myAppRovalPublic_OkMyAppRoval:(data) => { // 计划调度 - 改港分卸审批 - 审核通过
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanChange_Dict:(data) => { // 计划调度 - 改港分卸申请 - 改港分卸申请字典
		return http({
			url: '/api/plan.ShipPlanChange/Dict',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_List:(data) => { // 计划调度 - 改港分卸申请 - 改港分卸列表
		return http({
			url: '/api/plan.ShipPlanChange/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_GetChangeData:(data) => { // 计划调度 - 改港分卸申请 - 获取改港分卸申请数据
		return http({
			url: '/api/plan.ShipPlanChange/GetChangeData',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_View:(data) => { // 计划调度 - 改港分卸申请 - 查看申请
		return http({
			url: '/api/plan.ShipPlanChange/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_LogList:(data) => { // 计划调度 - 改港分卸申请 - 审核日志
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_GetItemDatataForView:(data) => { // 计划调度 - 改港分卸申请 - 审核表显示
		return http({
			url: '/api/plan.ShipPlanChange/GetItemDatataForView',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_Add:(data) => { // 计划调度 - 改港分卸申请 - 新增改港分卸
		return http({
			url: '/api/plan.ShipPlanChange/Add',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanChange_Edit:(data) => { // 计划调度 - 改港分卸申请 - 编辑改港分卸
		return http({
			url: '/api/plan.ShipPlanChange/Edit',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanChange_Push:(data) => { // 计划调度 - 改港分卸申请 - 改港分卸申请提交
		return http({
			url: '/api/plan.ShipPlanChange/Push',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	shipPlanChange_Reback:(data) => { // 计划调度 - 改港分卸申请 - 改港分卸申请取消
		return http({
			url: '/api/plan.ShipPlanChange/Reback',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	myAppRovalPublic_ShipPlanList:(data) => { // 计划调度 - 计划审批 - 列表
		return http({
			url: '/api/MyAppRovalPublic/ShipPlanList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	shipPlanChange_OkMyAppRoval:(data) => { // 计划调度 - 计划审批 - 审核通过
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	ShipPlan_View:(data) => { // 计划调度 - 改港分卸申请 - 查看
		return http({
			url: '/api/ship.ShipPlan/View',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	severalQuality_getcompensateinvoices:(data) => { // 数质量管理 - 索赔通知单 - 索赔通知单列表
		return http({
			url: '/api/several_quality/getcompensateinvoices',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	severalQuality_sentInformCarrier:(data) => { // 数质量管理 - 索赔通知单 - 发送赔付通知单
		return http({
			url: '/api/several_quality/sentInformCarrier',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	severalQuality_updatecompensateinvoices:(data) => { // 数质量管理 - 索赔通知单 - 撤回索赔通知单
		return http({
			url: '/api/several_quality/updatecompensateinvoices',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	
	ShipPlan_ListData:(data) => { // 数质量管理 - 航次数据 - 列表
		return http({
			url: '/api/ship.ShipPlan/ListData',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	ShipPlan_UpdateAmountData:(data) => { // 数质量管理 - 航次数据 - 编辑
		return http({
			url: '/api/ship.ShipPlan/UpdateAmount',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_getshipPlan:(data) => { // 数质量管理 - 超耗索赔 - 列表
		return http({
			url: '/api/several_quality/getshipPlan',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getsys_LogDataByid:(data) => { // 数质量管理 - 超耗索赔 - 查看进度
		return http({
			url: '/api/several_quality/getsys_LogDataByid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_insertClaimCompensationInfo:(data) => { // 数质量管理 - 超耗索赔 - 新增
		return http({
			url: '/api/ClaimCompensationInfo/insertClaimCompensationInfo',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_updateclaimcompensationInfo:(data) => { // 数质量管理 - 超耗索赔 - 承运商审批
		return http({
			url: '/api/ClaimCompensationInfo/updateclaimcompensationInfo',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_getshipPlanGroupBycarrierstitle:(data) => { // 数质量管理 - 超耗索赔 - 发起承运商赔付单
		return http({
			url: '/api/several_quality/getshipPlanGroupBycarrierstitle',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	report_view_getcarriersdata:(data) => { // 报表管理 - 承运商资料 - 承运商资料列表
		return http({
			url: '/api/report_view/getcarriersdata',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getTableHeaderOilType:(data) => { // 报表管理 - 水运完成量（分油品） - 分油品统计运输量
		return http({
			url: '/api/report_view/getTableHeaderOilType',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getwcl_oilType:(data) => { // 报表管理 - 水运完成量（分油品） - XXXX水运完成计量明细(内向)-华中承运
		return http({
			url: '/api/report_view/getwcl_oilType',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getassessyearMonth:(data) => { // 报表管理 - 水运完成量（分油品） - 承运商月度考核情况
		return http({
			url: '/api/report_view/getassessyearMonth',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getcarriers:(data) => { // 报表管理 - 在线船舶基础资料 - 获取承运商列表
		return http({
			url: '/api/report_view/getcarriers',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getshipdata:(data) => { // 报表管理 - 在线船舶基础资料 - 船舶基础资料列表
		return http({
			url: '/api/report_view/getshipdata',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getshipdataupload:(data) => { // 报表管理 - 在线船舶基础资料 - 船舶基础资料下载
		return http({
			url: '/api/report_view/getshipdataupload',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getshipCYdataupload:(data) => { // 报表管理 - 在线船舶基础资料 - 船舶船员资料下载
		return http({
			url: '/api/report_view/getshipCYdataupload',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getshipCheckTask1:(data) => { // 报表管理 - 船舶日常检查汇总表 - 船舶日常检查汇总表
		return http({
			url: '/api/report_view/getshipCheckTask1',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getshipCheckTask0:(data) => { // 报表管理 - 船舶日常检查汇总表 - 船舶认证检查汇总表(初稿）
		return http({
			url: '/api/report_view/getshipCheckTask0',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_ndhzsywcqk:(data) => { // 报表管理 - 船舶日常检查汇总表 - 年度华中水运完成情况
		return http({
			url: '/api/report_view/ndhzsywcqk',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getsflist:(data) => { // 报表管理 - 船舶日常检查汇总表 - 分省市完成量统计表头
		return http({
			url: '/api/report_view/getsflist',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getsywcqk:(data) => { // 报表管理 - 船舶日常检查汇总表 - 承运商完成量
		return http({
			url: '/api/report_view/getsywcqk',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	report_view_getShipSiteTable:(data) => { // 报表管理 - 船舶日常检查汇总表 - 船舶吨位统计
		return http({
			url: '/api/CarriersShip/getShipSiteTable',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getcarriersSelect:(data) => { // 装卸管理 - 装油 - 承运商名称列表
		return http({
			url: '/api/several_quality/getcarriersSelect',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipplancharge:(data) => { // 装卸管理 - 装油 - 装油列表
		return http({
			url: '/api/several_quality/getshipplancharge',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipplanoildischargebyid:(data) => { // 装卸管理 - 装油 - 根据状态搜索航次计划列表数据
		return http({
			url: '/api/several_quality/getshipplanoildischargebyid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getleadsealingByshipplanid:(data) => { // 装卸管理 - 装油 - 根据航次id搜索封铅记录
		return http({
			url: '/api/several_quality/getleadsealingByshipplanid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipPlanReturnReceiptTwo:(data) => { // 装卸管理 - 装油 - 获取回执单
		return http({
			url: '/api/several_quality/getshipPlanReturnReceipt',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipplanoildischargeSB:(data) => { // 装卸管理 - 装油 - 
		return http({
			url: '/api/several_quality/getshipplanoildischargeSB',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getmemberinfo:(data) => { // 装卸管理 - 装油 - 获取所有办事处员工信息
		return http({
			url: '/api/several_quality/getmemberinfo',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_uploadInsertLeadsealing:(data) => { // 装卸管理 - 装油 - 批量上传铅封
		return http({
			url: '/api/several_quality/uploadInsertLeadsealing',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_insertleadsealing:(data) => { // 装卸管理 - 装油 - 新增航次封铅记录
		return http({
			url: '/api/several_quality/insertleadsealing',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_updateleadsealing:(data) => { // 装卸管理 - 装油 - 更新航次封铅记录
		return http({
			url: '/api/several_quality/updateleadsealing',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_updateshipPlanByid:(data) => { // 装卸管理 - 装油 - 提交发运申请
		return http({
			url: '/api/several_quality/updateshipPlanByid',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_addStartPortEditApprovel:(data) => { // 装卸管理 - 装油 - 办事处信息修改-编辑申请
		return http({
			url: '/api/ship.ShipPlan/addStartPortEditApprovel',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	execl_out:(data) => { // 导出execl
		return http({
			url: '/api/export/execl_out',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	report_view_getsyypwcqk:(data) => { // 报表管理  - 预排计划完成情况
		return http({
			url: '/api/report_view/getsyypwcqk',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	MyAppRovalPublic_List:(data) => { // 数质量管理 - 超耗索赔 - 承运商审批
		return http({
			url: '/api/MyAppRovalPublic/List',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	MyAppRovalPublic_getClaimCompensationInfoByid:(data) => { // 数质量管理 - 超耗索赔 - 承运商审批
		return http({
			url: '/api/ship.ShipPlan/getClaimCompensationInfoByid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	MyAppRovalPublic_LogList:(data) => { // 数质量管理 - 超耗索赔 - 承运商审批
		return http({
			url: '/api/MyApprovalPublic/LogList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	MyAppRovalPublic_OkMyAppRoval:(data) => { // 数质量管理 - 超耗索赔 - 承运商审批
		return http({
			url: '/api/MyAppRovalPublic/OkMyAppRoval',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_getmemberGroup:(data) => { // 装卸管理 - 卸油 - 省市公司列表
		return http({
			url: '/api/several_quality/getmemberGroup',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getmemberGroupAll:(data) => { // 装卸管理 - 卸油 - 承运商列表
		return http({
			url: '/api/several_quality/getmemberGroupAll',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getshipplanoildischarge:(data) => { // 装卸管理 - 卸油 - 卸油管理列表
		return http({
			url: '/api/several_quality/getshipplanoildischarge',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_getupload:(data) => { // 装卸管理 - 卸油 - 获取该航次铅封数据
		return http({
			url: '/api/several_quality/getupload',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	several_quality_updateshipPlanByidoilTon:(data) => { // 装卸管理 - 卸油 - 根据id编辑航次计划到库船板量、实收量
		return http({
			url: '/api/several_quality/updateshipPlanByidoilTon',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	several_quality_updateshipPlanByidoilTonBJ:(data) => { // 装卸管理 - 卸油 -省市公司编辑 根据id编辑航次计划到库船板量、实收量
		return http({
			url: '/api/several_quality/updateshipPlanByidoilTonBJ',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_getshipplanparent:(data) => { // 结算管理 - 改港分卸 - 列表
		return http({
			url: '/api/settle/getshipplanparent',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getsettleinfoListPid:(data) => { // 结算管理 - 改港分卸 - 改港分卸查看详情
		return http({
			url: '/api/settle/getsettleinfoListPid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getshipplanYunJiaTiao:(data) => { // 结算管理 - 运价调整 - 运价调整列表(新)
		return http({
			url: '/api/settle/getshipplanYunJiaTiao',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getsettleinfoListPid_yjtz:(data) => { // 结算管理 - 运价调整 - 根据航次id查询子航次信息
		return http({
			url: '/api/settle/getsettleinfoListPid',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getsettleinfoList:(data) => { // 结算管理 - 运杂费结算（财务） - 列表
		return http({
			url: '/api/settle/getsettleinfoList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getCarrierAssociationShipPlan:(data) => { // 结算管理 - 运杂费结算（财务） - 根据承运商查询关联下的航次计划
		return http({
			url: '/api/settle/getCarrierAssociationShipPlan',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getinvoiceInfoByVBELN:(data) => { // 结算管理 - 运杂费结算（财务） - 查看发票
		return http({
			url: '/api/settle/getinvoiceInfoByVBELN',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_geterpVbelnRveShipplanByVBELN:(data) => { // 结算管理 - 运杂费结算（财务） - 查看明细
		return http({
			url: '/api/settle/geterpVbelnRveShipplanByVBELN',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_adderpVbelnRveShipplan:(data) => { // 结算管理 - 运杂费结算（财务） - 关联航次
		return http({
			url: '/api/settle/adderpVbelnRveShipplan',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_deleteErpVbelnRveShipplan:(data) => { // 结算管理 - 运杂费结算（财务） - 删除已关联航次
		return http({
			url: '/api/settle/deleteErpVbelnRveShipplan',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_deleteinvoice:(data) => { // 结算管理 - 运杂费结算（财务） - 根据id删除发票信息
		return http({
			url: '/api/settle/deleteinvoice',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_updateState:(data) => { // 结算管理 - 运杂费结算（财务） - 撤回通知
		return http({
			url: '/api/settle/updateState',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_updateErpVbelnShareno:(data) => { // 结算管理 - 运杂费结算（财务） - 根据交货单号修改财务共享号
		return http({
			url: '/api/settle/updateErpVbelnShareno',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_BatchUpdateerpVbelnSettle:(data) => { // 结算管理 - 运杂费结算（财务） - 批量结算
		return http({
			url: '/api/settle/BatchUpdateerpVbelnSettle',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_getTariffDefend:(data)=>{ // 结算管理 -运价维护 - 列表
		return http({
			url: '/api/settle/getTariffDefend',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getsettleinfoListCys:(data)=>{ // 结算管理 - 运杂费结算（承运商） - 列表
		return http({
			url: '/api/settle/getsettleinfoList',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getgetinvoiceInfoBymemberId:(data)=>{ // 结算管理 - 运杂费结算（承运商） - 最新发票消息
		return http({
			url: '/api/settle/getgetinvoiceInfoBymemberId',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_getCarrierAccessoryGXZJ:(data)=>{ // 结算管理 - 运杂费结算（承运商） - 承运商下载自己的发票
		return http({
			url: '/api/settle/getCarrierAccessoryGXZJ',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_uploadinvoice:(data)=>{ // 结算管理 - 运杂费结算（承运商） - 上传发票
		return http({
			url: '/api/settle/uploadinvoice',
			method: 'POST',
			data,
			// noHead:true
		})
	},
	settle_largescreenDataBindDs:(data)=>{ // 大屏
		return http({
			url: '/api/largescreen.index/DataBindDs',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenDatagetPlans:(data)=>{ // 大屏
		return http({
			url: '/api/largescreen.plan/getPlans',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenDataGetWaterLevels:(data)=>{ // 大屏
		return http({
			url: '/api/largescreen.water_level/getWaterLevels',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenDatayouku:(data)=>{ // 大屏 —— 添加油库
		return http({
			url: '/api/largescreen.ship/youku',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenDataoperation:(data)=>{ // 大屏 —— 添加油库
		return http({
			url: '/api/largescreen.ship/operation',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenDatashowshiptdtjiukouxin:(data)=>{ // 大屏 —— 添加油库
		return http({
			url: '/api/largescreen.ship/showshiptdtjiukouxin',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenShipPlanindex:(data)=>{ // 大屏 —— 获取船舶信息
		return http({
			url: '/api/largescreen.ShipPlan/index',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenShipIndex:(data)=>{ // 大屏 —— 首页数据
		return http({
			url: '/api/largescreen.ship/index',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenShipStatusindex:(data)=>{ // 大屏 —— 船舶状态
		return http({
			url: '/api/largescreen.ShipStatus/index',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	settle_largescreenShowshipsfanwei:(data)=>{ // 大屏 —— 范围
		return http({
			url: '/api/largescreen.ship/showshipsfanwei',
			method: 'GET',
			params:data,
			// noHead:true
		})
	},
	
}

export default Index