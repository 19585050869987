<template>
    <div class="page boatPlan_Dateils" id="pageTop" v-if="infoData?.id">
        <el-page-header @back="goBack" content="查看详情"></el-page-header>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="下达日期：">
                <el-input v-model="infoData.createTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="承运商名称：">
                <el-input v-model="infoData.carrier_name.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="船名：">
                <el-input v-model="infoData.ship_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="出发港：">
                <el-input v-model="infoData.ship_port_begin.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="到达港：">
                <el-input v-model="infoData.ship_port_end.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="调整后港口：" v-if="infoData.changfeDestStr">
                <el-input v-model="infoData.changfeDestStr" disabled></el-input>
            </el-form-item>
            <el-form-item label="油品：">
                <el-input v-model="infoData.oil_type_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="数量(吨)：">
                <el-input v-model="infoData.oilTon" disabled></el-input>
            </el-form-item>
            <el-form-item label="流向：">
                <el-input v-model="infoData.company_name.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="属性：">
                <el-input v-model="infoData.KUNTP.subtitle" disabled></el-input>
            </el-form-item>
            <el-form-item label="计划状态：">
                <el-input v-model="infoData.statesName" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">发运信息</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="炼厂办事处：">
                <el-input v-model="infoData.office_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="发运时间：">
                <el-input v-model="infoData.startTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="船板量(吨)：">
                <el-input v-model="infoData.plankingTon" disabled></el-input>
            </el-form-item>
            <el-form-item label="完成数(吨)：">
                <el-input v-model="infoData.shipErpAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="计量员：">
                <el-input v-model="infoData.quantitySurveyor" disabled></el-input>
            </el-form-item>
            <el-form-item label="合格证">
                <el-button v-if="infoData.certificatePath != null || infoData.certificatePath != ''" type="primary"
                    icon="el-icon-view" size="mini" @click="lookFile(infoData.certificatePath)">查看</el-button>
                <span v-else>暂无</span>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">卸油信息</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="收卸油库：">
                <el-input v-model="infoData.wareHouse_name.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="收卸时间：">
                <el-input v-model="infoData.arrivalTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="船板量：">
                <el-input v-model="infoData.oildischargeplankingTon" disabled></el-input>
            </el-form-item>
            <el-form-item label="卸油量：">
                <el-input v-model="infoData.realTon" disabled></el-input>
            </el-form-item>
            <el-form-item label="备注：" v-if="infoData.oilDesc">
                <el-input v-model="infoData.oilDesc" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">损耗信息</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="损溢量(吨)：">
                <el-input v-model="infoData.totalSum" disabled></el-input>
            </el-form-item>
            <el-form-item label="定损量(吨)：">
                <el-input v-model="infoData.confirmSum" disabled></el-input>
            </el-form-item>
            <el-form-item label="超耗量(吨)：">
                <el-input v-model="infoData.overclaimSum" disabled></el-input>
            </el-form-item>
            <el-form-item label="索赔量(吨)：" v-if="infoData.claimcompensationInfoOut">
                <el-input v-model="infoData.claimcompensationInfoOut.claimSum" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider content-position="left">回执单确认</el-divider>
        <el-form ref="formShipRole" :model="infoData" inline label-width="110px">
            <el-form-item label="回执单：">
                <div v-if="infoData.tonConfirmStatus == 0">
                    <div v-html="infoData.tonConfirmStatusTxt"></div>
                </div>
                <div v-else>
                    <div style="color: green;">{{ infoData.tonConfirmStatus == 0 ? "未确认" : "已确认"  }}</div>
                    <div v-if="infoData.tonConfirmerUser != null">确认人：{{ infoData.tonConfirmerUser.title }}</div>
                    <div v-if="infoData.tonConfirmTime">确认时间：{{ infoData.tonConfirmTime }}</div>
                </div>
                <!-- 列表 -->
                <el-table ref="multipleTable" border :data="infoFiel" style="width: 100vh;margin:0 auto;"
                    tooltip-effect="dark" header-row-class-name="header-table">
                    <el-table-column  label="文件名" prop="fileName">
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button  type="primary" icon="el-icon-view" size="mini" @click="lookFile(scope.row.fileUrl)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
</template>
    
<script>
export default {
    name: "boatPlan_Dateils",
    data() {
        return {
            cxIndex: "",
            showReady: true,
            infoData: {
                title: '',
                birthday: '',
                job: '',

            },
            shipJob: [],
            infoData: {},
            infoFiel: [],
            dict: {
                staicData: []
            }
        }
    },
    created() {
        let id = this.$route.params.id
        this.getstaicData()
        if (id) {
            let data = {
                id: id,
            }
            this.$Api.shipPlan_shipPlanDetail(data).then(res => {
                if (res.code == 200) {
                    this.infoData = res.data
                    if (this.dict.staicData.length > 0) {
                        this.infoData.statesName = this.dict.staicData[this.infoData.states]
                    }
                    if(this.infoData.tonConfirmStatus == 0){
                        let msg = "<div style='color: red;'>未确认</div>"
                        if(this.infoData.oildischargeStatus == 2){
                            msg = `<div style='color: green;'>已驳回${this.infoData.tonConfirmDesc}</div>`
                        }
                        if(this.infoData.tonConfirmStatus == 0){
                            this.infoData.tonConfirmStatusTxt = msg
                        }else{
                            this.infoData.tonConfirmStatusTxt = "<div style='color: red;'>已确认</div>"
                        }
                    }
                }
            })
            this.$Api.shipPlan_GetFileInfo(data).then(res => {
                if (res.code == 200) {
                    this.infoFiel[0] = res.data.ChangeList
                }
            })
        }
    },
    activated() {

    },
    mounted() {

    },
    methods: {
        getstaicData() {
            this.$Api.publics_staicData().then(res => {
                if (res.code == 200) {
                    this.dict.staicData = res.data.shipPlanStates
                }
            })
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/planDispatch/boatPlanDateils')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        // --------------------------公共方法--------------------------------------
    }
}
</script>
    
<style lang="scss" scoped>
.boatPlan_Dateils {
    height: initial;

    .el-page-header {
        line-height: 50px;
    }

    /deep/.el-input {
        width: 182px !important;
    }

    /deep/.el-divider__text.is-left {
        font-size: 20px;
        font-weight: 500;
    }
}
</style>