<!-- <template>
  <div>
    <education v-if="userType == 'system'"></education>
    <school v-if="userType == 'school'"></school>
    <bookstore v-if="userType == 'bstore'"></bookstore>
  </div>
</template>

<script>
import education from "@/components/userInfo/education"
import school from "@/components/userInfo/school"
import bookstore from "@/components/userInfo/bookstore"
export default {
  name: "userInfo",
  computed: {
    userType() {
      return this.$store.state.userInfo.type || ''
    }
  },
  components: {
    education,
    school,
    bookstore
  }
}
</script>

<style scoped></style> -->
<template>
  <div class="page">
    <div class="header u-flex u-row-between u-col-center">
      <span class="title">个人资料</span>
    </div>
    <div class="content">
      <div class="info">
        <div class="">
          <!-- <el-avatar :size="80" :src="userInfo.avatar" @error="errorHandler">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
          </el-avatar> -->
          <el-tooltip content="点击更换头像" placement="top">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar">
              <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" class="el-icon-plus avatar-uploader-icon">
            </el-upload>
          </el-tooltip>
          
        </div>
        <div class="right">
          <div>
              <el-tooltip content="点击修改昵称" placement="top">
                <span v-if="isclickName" class="name" @click="clickName">{{ userInfo.nickname || '暂无昵称' }}</span>
                <el-input
                v-else
                placeholder="请输入内容"
                v-model="userInfo.nickname"
                style="width: 100px;"
                @blur="editName"
                >
              </el-input>
            </el-tooltip>
            <span class="identity">{{ userInfo.real_name || '' }}</span>
          </div>
          <div class="phone">{{ userInfo.mobile || '' }}</div>
        </div>
      </div>
      <div class="edit">
        <div class="title">修改密码</div>
        <el-form ref="createForm" :inline="true" :model="form" class="demo-form-inline" :rules="createRules">
          <!-- <el-form-item label="原密码" prop="user_name">
            <el-input v-model="form.user_name" placeholder="原密码"></el-input>
          </el-form-item> -->
          <el-form-item label="新密码" prop="password">
            <el-input v-model="form.password" placeholder="新密码" show-password></el-input>
          </el-form-item>
          <el-form-item label="重复新密码" prop="password2">
            <el-input v-model="form.password2" placeholder="重复新密码" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userInfo",
  data() {
    return {
      userInfo: {},
      role: '',
      form: {
        user_name: '',
        password: '',
        password2: ""
      },
      createRules: {
        // user_name: {
        //   required: true,
        //   message: '请输入原密码',
        //   trigger: ['blur', 'change']
        // },
        password: {
          required: true,
          message: '请输入新密码',
          trigger: ['blur', 'change']
        },
        password2: {
          required: true,
          message: '请重复新密码',
          trigger: ['blur', 'change']
        },
      },
      uploadUrl:'',
      isclickName:true
    }
  },
  created() {
    this.uploadUrl = this.$http.defaults.baseURL + '/api/upload'
    // this.userInfo = JSON.parse(localStorage.getItem('vuex')).userInfo
    this.getUserInfo()
  },
  methods: {
    editName(){
      let data = {
        nickname:this.userInfo.nickname
      }
      this.$http.post('/backend/user/nickname',data).then(res=>{
        if(res.code==1){
          this.$message.success('昵称修改成功')
          this.isclickName = true
          this.$store.dispatch('updateUserInfo')
        }
      })
      
    },
    clickName(){
      this.isclickName = false
    },
    handleAvatarSuccess(e, file) {
      let data = {
        avatar:e.data.full_path
      }
      this.$http.post('/backend/user/avatar',data).then(res=>{
        if(res.code==1){
          this.$message.success('头像修改成功')
          this.userInfo.avatar = e.data.full_path
          this.$store.dispatch('updateUserInfo')
        }
      })
    },
    errorHandler() {
      return true
    },
    getUserInfo(){
      this.$http.get('/backend/user').then(res=>{
          this.userInfo = res.data.info
      })
    },
    onSubmit() {
      this.$refs.createForm.validate(b => {
        if (b) {
          if (this.form.password != this.form.password2) {
            this.$message({
              message: '新密码两次填写不一致！',
              type: 'warning'
            });
            return
          }
          let data = {
            id:this.userInfo.id,
            type:this.userInfo.type,
            user_name:this.userInfo.user_name,
            struct_id:this.userInfo.struct.id,
            role_ids:this.userInfo.role[0].id,
            password:this.form.password2
          }
          this.$http.post('/backend/role_user/update', data).then(res => {
            if (res.code == 1) {
              this.$message({
                message: '操作成功,请重新登录',
                type: 'success'
              });
              this.$store.commit('logout')
              setTimeout(() => {
                this.$router.replace('/login')
                location.reload()
              }, 800)
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner{
  height: 38px;
  line-height: 38px;
}
.page {
  padding: 24px 32px;
  height: 100%;
}

.header {
  padding-bottom: 24px;
  border-bottom: solid 1px #DCDFE6;

  .title {
    font-size: 20px;
  }

  .create {
    color: #409EFF;
    font-size: 16px;
    cursor: pointer;
  }
}

.content {
  padding: 65px 60px;
  background-color: #fff;
  padding-bottom: 420px;

  .info {
    display: flex;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 1px solid #ECEDEE;

    .right {
      margin-left: 25px;

      .name {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: 700;
        color: #1A1A1A;
        cursor: pointer;
      }

      .identity {
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: 700;
        color: #1B6CFC;
        margin-left: 12px;
      }

      .phone {
        margin-top: 8px;
      }
    }
  }
}

.edit {
  padding-top: 48px;

  .title {
    font-weight: 700;
    color: #1A1A1A;
    margin-bottom: 50px;
  }
}
.avatar-uploader .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%;
  }
</style>
