import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

/*------------公共页面------------------------ */
import Login from "@/views/login";
import Home from "@/views/home";
import Examine from "@/views/examine";
import UserInfo from "@/views/userInfo";

import details from "@/views/home/details";

/* 系统管理 */
import Role from "@/views/systems/systemManagement/role";
import Menu from "@/views/systems/systemManagement/menu";
import Config from "@/views/systems/systemManagement/config";
import Configuration from "@/views/systems/systemManagement/configuration";
// import Dept from "@/views/systems/systemManagement/dept";
import User from "@/views/systems/systemManagement/user";
import DataDictionary from "@/views/systems/systemManagement/dataDictionary";
import InterfaceLog from "@/views/systems/systemManagement/interfaceLog";
import AbnormalLog from "@/views/systems/systemManagement/abnormalLog";
import Maintain from "@/views/systems/systemManagement/maintain";

/*------------------基础信息------------------*/
// 单位管理
import Central from "@/views/info/unit/central";
import Carrier from "@/views/info/unit/carrier"
import Province from "@/views/info/unit/province"
// 用户管理
import UserAll from "@/views/info/user/all"
import UserCompany from "@/views/info/user/company"
import UserContacts from "@/views/info/user/contacts"
import UserCarrier from "@/views/info/user/carrier"
import UserOffice from "@/views/info/user/office"
import UserEconomize from "@/views/info/user/economize"
import UserRole  from "@/views/info/user/role"
//流程管理
import CourseWork from "@/views/info/course/index15"
import Coursequalifications from "@/views/info/course/index14"
import Coursedocument from "@/views/info/course/index1"
import Courseaptitude from "@/views/info/course/index2"
import CourseshipUp from "@/views/info/course/index3"
import CourseshipOff from "@/views/info/course/index4"
import Courseexamine from "@/views/info/course/index5"
import Courseboat from "@/views/info/course/index6"
import Coursewalkthroughs from "@/views/info/course/index7"
import Courseharbor from "@/views/info/course/index8"
import Courseout from "@/views/info/course/index9"
import Courseoverhaul from "@/views/info/course/index11"
import Courseclaimant from "@/views/info/course/index12"
//其他信息
import ResidentFactory from "@/views/info/other/residentFactory"
import oildepot from "@/views/info/other/oildepot"
import oildepotDetails from "@/views/info/other/oildepot_details"
import Oils from "@/views/info/other/oils"
import OtherPort from "@/views/info/other/port"

/*------------------公文管理------------------*/
import publicCharacterCreate from "@/views/publicCharacter/create"
import publicCharacterApprova from "@/views/publicCharacter/approva"
import Receive from "@/views/publicCharacter/receive"
import Sending from "@/views/publicCharacter/sending"

/*------------------承运商管理------------------*/
import Advent from "@/views/carrier/advent"
import CarrierInfo from "@/views/carrier/info"
import AssessmentItems from "@/views/carrier/assessmentItems"
import AssessmentTasks from "@/views/carrier/assessmentTasks"
import AssessmentTasksDetails from "@/views/carrier/assessmentTasks_Details"
import Score from "@/views/carrier/score"
import scoreDetails from "@/views/carrier/score_Details"
import AssessmentResultsExamine from "@/views/carrier/assessmentResultsExamine"
import assessmentResultsExamineDetails from "@/views/carrier/assessmentResultsExamine_Details"
import AssessmentResultsRelease from "@/views/carrier/assessmentResultsRelease"
import AssessmentResultsReleaseDetails from "@/views/carrier/assessmentResultsRelease_Details"
import ShipManage from "@/views/carrier/shipManage"
import shipManageDetails from "@/views/carrier/shipManage_Details"
import tonnage from "@/views/carrier/tonnage"
import ShipOverhaul from "@/views/carrier/shipOverhaul"
import ShipQualifications from "@/views/carrier/shipQualifications"
import ShipBelow from "@/views/carrier/shipBelow"
import Shipup from "@/views/carrier/shipup"
import Overhaul from "@/views/carrier/overhaul"


/*------------------安检管理------------------*/
import ShipRectification from "@/views/securityCheck/shipRectification"
import DailyInspect from "@/views/securityCheck/dailyInspect"
import AuthenticationStandardMaintenance from "@/views/securityCheck/authenticationStandardMaintenance"
import DailyStandardMaintenance from "@/views/securityCheck/dailyStandardMaintenance"
import CertificationInspection from "@/views/securityCheck/certificationInspection"
import OtherInspection from "@/views/securityCheck/otherInspection"

/*------------------计划调度------------------*/
import MonthlyPlan from "@/views/planDispatch/monthlyPlan"
import MonthlyPlanRecord from "@/views/planDispatch/monthlyPlan_record"
import monthlyPlanAddFinish from "@/views/planDispatch/monthlyPlan_AddFinish"
import BoatPlan from "@/views/planDispatch/boatPlan"
import boatPlanDateils from "@/views/planDispatch/boatPlan_Dateils"
import boatPlanFinish from "@/views/planDispatch/boatPlan_finish"

import WalkthroughsPlan from "@/views/planDispatch/walkthroughsPlan"
import WalkthroughsPlanExamine from "@/views/planDispatch/walkthroughsPlanExamine"
import PlanExamine from "@/views/planDispatch/planExamine"
import ChangeHarborDivideUnloadApply from "@/views/planDispatch/changeHarborDivideUnloadApply"
import changeHarborDivideUnloadApplyDateils from "@/views/planDispatch/changeHarborDivideUnloadApply_Dateils"
import ChangeHarborDivideUnloadExamine from "@/views/planDispatch/changeHarborDivideUnloadExamine"
import OtherShipPlan from "@/views/planDispatch/otherShipPlan"
import OutsourcingPlan from "@/views/planDispatch/outsourcingPlan"
import BoatQuery from "@/views/planDispatch/boatQuery"
import ShipOutExamine from "@/views/planDispatch/shipOutExamine"
import WalkthroughsPlanExamineDetails from "@/views/planDispatch/walkthroughsPlanExamineDetails"

/*------------------数质量管理------------------*/
import ExceedClaimant from "@/views/numQualityManage/exceedClaimant"
import ClaimantNotice from "@/views/numQualityManage/claimantNotice"
import Claimantexamine from "@/views/numQualityManage/claimantexamine"
import BoatData from "@/views/numQualityManage/boatData"
import boatDataEdit from "@/views/numQualityManage/boatData_edit"
import LossArateService from "@/views/numQualityManage/lossArateService"
import OilPriceService from "@/views/numQualityManage/oilPriceService"

/*------------------装卸管理------------------*/
import CompletedExamine from "@/views/loadUnloadManage/completedExamine"
import LoadingOil from "@/views/loadUnloadManage/loadingOil"
import loadingOilDetails from "@/views/loadUnloadManage/loadingOilDetails"
import loadingOilEdit from "@/views/loadUnloadManage/loadingOilEdit"
import UnloadOil from "@/views/loadUnloadManage/unloadOil"
import unloadOilEdit from "@/views/loadUnloadManage/unloadOilEdit"



/*------------------装卸管理------------------*/
import ChangeHarborDivideUnload from "@/views/settlementManage/changeHarborDivideUnload"
import FreightrateAdjust from "@/views/settlementManage/freightrateAdjust"
import TranspotationByFinance from "@/views/settlementManage/transpotationByFinance"
import TranspotationByCarrier from "@/views/settlementManage/transpotationByCarrier"
import PaymentDetailedList from "@/views/settlementManage/paymentDetailedList"
import TariffDefend from "@/views/settlementManage/tariffDefend"

/*------------------船舶监控------------------*/
import Positionship from "@/views/vesseMonitor/positionship"
import VideoMonitoring from "@/views/vesseMonitor/videoMonitoring"
import jihuas from "@/views/vesseMonitor/jihuas"
import huoqujihua from "@/views/vesseMonitor/huoqujihua"
import huoquxxsw from "@/views/vesseMonitor/huoquxxsw"


/*------------------报表管理------------------*/
import ReportViewoilType from "@/views/reportManager/reportViewoilType"
import MonthlyAssessmentOfCarriers from "@/views/reportManager/monthlyAssessmentOfCarriers"
import Carrierinfo from "@/views/reportManager/carrierinfo"
import ShipCertificationSummary from "@/views/reportManager/shipCertificationSummary"
import ShipDailySummary from "@/views/reportManager/shipDailySummary"
import OnlineShipInfo from "@/views/reportManager/onlineShipInfo"
import ReportViewfinishAmount from "@/views/reportManager/reportViewfinishAmount"
import Walkthroughsfinish from "@/views/reportManager/walkthroughsfinish"
import ShipPositionCount from "@/views/reportManager/shipPositionCount"
import CarrierFinish from "@/views/reportManager/carrierFinish"

/*------------------日志管理------------------*/
import LogManage from "@/views/logManage"




const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location,onResolve,onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      name: "首页",
      keepAlive: false,
    },
  },
  {
    path: "/details",
    name: "details",
    component: details,
    meta: {
      name: "详情",
      keepAlive: false,
    },
  },
  
  {
    path: "/examine",
    name: "Examine",
    component: Examine,
    meta: {
      name: "待审批",
      keepAlive: false,
    },
  },
  
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      name: "登录",
      keepAlive: false,
    },
  },
  {
    path: "/userInfo",
    name: "UserInfo",
    component: UserInfo,
    meta: {
      name: "个人资料",
      keepAlive: false,
    },
  },
 
  {
    path: "/maintain",
    name: "Maintain",
    component: Maintain,
    meta: {
      name: "服务器维护",
      keepAlive: true,
    },
  },
  
  //系统管理
  {
    path: "/system/user",
    name: "User",
    component: User,
    meta: {
      name: "系统管理,用户管理",
      keepAlive: false,
      isUseCache:false
    },
  },
  {
    path: "/system/role",
    name: "Role",
    component: Role,
    meta: {
      name: "系统管理,角色管理",
      keepAlive: true,
    },
  },
  {
    path: "/system/menu",
    name: "Menu",
    component: Menu,
    meta: {
      name: "系统管理,菜单管理",
      keepAlive: true,
    },
  },
  {
    path: "/system/config",
    name: "Config",
    component: Config,
    meta: {
      name: "系统管理,网站配置",
      keepAlive: true,
    },
  },
  {
    path: "/system/configuration",
    name: "Configuration",
    component: Configuration,
    meta: {
      name: "系统管理,配置管理",
      keepAlive: true,
    },
  },
  // {
  //   path: "/system/dept",
  //   name: "Dept",
  //   component: Dept,
  //   meta: {
  //     name: "系统管理,组织架构",
  //     keepAlive: true,
  //   },
  // },
  {
    path: "/system/dataDictionary",
    name: "DataDictionary",
    component: DataDictionary,
    meta: {
      name: "系统管理,数据字典",
      keepAlive: true,
    },
  },
  {
    path: "/system/interfaceLog",
    name: "InterfaceLog",
    component: InterfaceLog,
    meta: {
      name: "系统管理,系统日志",
      keepAlive: true,
    },
  },
  {
    path: "/system/abnormalLog",
    name: "AbnormalLog",
    component: AbnormalLog,
    meta: {
      name: "系统管理,异常日志",
      keepAlive: true,
    },
  },
  // 基础信息
  // 单位管理
  {
    path: "/info/unit/central",
    name: "Central",
    component: Central,
    meta: {
      name: "基础信息,单位管理,华中公司",
      keepAlive: true,
    },
  },
  {
    path: "/info/unit/carrier",
    name: "Carrier",
    component: Carrier,
    meta: {
      name: "基础信息,单位管理,承运商",
      keepAlive: true,
    },
  },
  {
    path: "/info/unit/province",
    name: "Province",
    component: Province,
    meta: {
      name: "基础信息,单位管理,省市公司",
      keepAlive: true,
    },
  },
  // 用户管理
  
  {
    path: "/info/uesr/all",
    name: "UserAll",
    component: UserAll,
    meta: {
      name: "基础信息,用户管理,所有用户",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/company",
    name: "UserCompany",
    component: UserCompany,
    meta: {
      name: "基础信息,用户管理,公司用户",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/contacts",
    name: "UserContacts",
    component: UserContacts,
    meta: {
      name: "基础信息,用户管理,联系人管理",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/carrier",
    name: "UserCarrier",
    component: UserCarrier,
    meta: {
      name: "基础信息,用户管理,承运商用户",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/office",
    name: "UserOffice",
    component: UserOffice,
    meta: {
      name: "基础信息,用户管理,办事处用户",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/economize",
    name: "UserEconomize",
    component: UserEconomize,
    meta: {
      name: "基础信息,用户管理,省市公司用户",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/role",
    name: "UserRole",
    component: UserRole,
    meta: {
      name: "基础信息,用户管理,角色管理",
      keepAlive: true,
    },
  },
  //流程管理
  {
    path: "/info/uesr/coursework", 
    name: "CourseWork",
    component: CourseWork,
    meta: {
      name: "基础信息,流程管理,办事处修改审批",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Coursequalifications", 
    name: "Coursequalifications",
    component: Coursequalifications,
    meta: {
      name: "基础信息,流程管理,承运商资质审批流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Coursedocument", 
    name: "Coursedocument",
    component: Coursedocument,
    meta: {
      name: "基础信息,流程管理,公文流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseaptitude", 
    name: "Courseaptitude",
    component: Courseaptitude,
    meta: {
      name: "基础信息,流程管理,资质审批流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/CourseshipUp", 
    name: "CourseshipUp",
    component: CourseshipUp,
    meta: {
      name: "基础信息,流程管理,船舶上线/复航流程",
      keepAlive: true,
    },
  },

  {
    path: "/info/uesr/CourseshipOff", 
    name: "CourseshipOff",
    component: CourseshipOff,
    meta: {
      name: "基础信息,流程管理,船舶下线/停航流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseboat", 
    name: "Courseboat",
    component: Courseboat,
    meta: {
      name: "基础信息,流程管理,航次计划流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseexamine", 
    name: "Courseexamine",
    component: Courseexamine,
    meta: {
      name: "基础信息,流程管理,承运商考核流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Coursewalkthroughs", 
    name: "Coursewalkthroughs",
    component: Coursewalkthroughs,
    meta: {
      name: "基础信息,流程管理,预排计划审批",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseharbor", 
    name: "Courseharbor",
    component: Courseharbor,
    meta: {
      name: "基础信息,流程管理,改港分卸审批",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseout", 
    name: "Courseout",
    component: Courseout,
    meta: {
      name: "基础信息,流程管理,外出计划审批流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseoverhaul", 
    name: "Courseoverhaul",
    component: Courseoverhaul,
    meta: {
      name: "基础信息,流程管理,船舶检修流程",
      keepAlive: true,
    },
  },
  {
    path: "/info/uesr/Courseclaimant", 
    name: "Courseclaimant",
    component: Courseclaimant,
    meta: {
      name: "基础信息,流程管理,超耗索赔流程",
      keepAlive: true,
    },
  },
  // 其他信息
  {
    path: "/info/other/ResidentFactory", 
    name: "ResidentFactory",
    component: ResidentFactory,
    meta: {
      name: "基础信息,其他信息,驻厂办事处",
      keepAlive: true,
    },
  },
  {
    path: "/info/other/oildepot", 
    name: "oildepot",
    component: oildepot,
    meta: {
      name: "基础信息,其他信息,油库",
      keepAlive: true,
    },
  },
  {
    path: "/info/other/oildepotDetails", 
    name: "oildepotDetails",
    component: oildepotDetails,
    meta: {
      name: "基础信息,其他信息,油品",
      keepAlive: true,
    },
  },
  {
    path: "/info/other/oils", 
    name: "Oils",
    component: Oils,
    meta: {
      name: "基础信息,其他信息,油品",
      keepAlive: true,
    },
  },
  {
    path: "/info/other/port", 
    name: "OtherPort",
    component: OtherPort,
    meta: {
      name: "基础信息,其他信息,港口",
      keepAlive: true,
    },
  },
  // 公文管理
  {
    path: "/info/publicCharacter/create", 
    name: "Create",
    component: publicCharacterCreate,
    meta: {
      name: "公文管理,公文创建",
      keepAlive: true,
    },
  },
  {
    path: "/info/publicCharacter/approva", 
    name: "Approva",
    component: publicCharacterApprova,
    meta: {
      name: "公文管理,公文审批",
      keepAlive: true,
    },
  },
  {
    path: "/info/publicCharacter/receive", 
    name: "Receive",
    component: Receive,
    meta: {
      name: "公文管理,我收到的公文",
      keepAlive: true,
    },
  },
  {
    path: "/info/publicCharacter/receive", 
    name: "Receive",
    component: Receive,
    meta: {
      name: "公文管理,我收到的公文",
      keepAlive: true,
    },
  },
  {
    path: "/info/publicCharacter/sending", 
    name: "Sending",
    component: Sending,
    meta: {
      name: "公文管理,我发送的公文",
      keepAlive: true,
    },
  },
  //承运商管理
  {
    path: "/carrier/advent", 
    name: "Advent",
    component: Advent,
    meta: {
      name: "承运商管理,即将到期的证照",
      keepAlive: true,
    },
  },
  {
    path: "/carriers/info", 
    name: "CarrierInfo",
    component: CarrierInfo,
    meta: {
      name: "承运商管理,承运商信息维护",
      keepAlive: true,
    },
  },
  
  {
    path: "/carrier/assessmentItems", 
    name: "AssessmentItems",
    component: AssessmentItems,
    meta: {
      name: "承运商管理,考核项模板维护",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/assessmentTasks", 
    name: "AssessmentTasks",
    component: AssessmentTasks,
    meta: {
      name: "承运商管理,考核任务创建",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/assessmentTasks_Details", 
    name: "AssessmentTasksDetails",
    component: AssessmentTasksDetails,
    meta: {
      name: "承运商管理,考核项",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/score", 
    name: "Score",
    component: Score,
    meta: {
      name: "承运商管理,评分",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/scoreDetails", 
    name: "scoreDetails",
    component: scoreDetails,
    meta: {
      name: "承运商管理,打分",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/assessmentResultsExamine", 
    name: "AssessmentResultsExamine",
    component: AssessmentResultsExamine,
    meta: {
      name: "承运商管理,考核结果审核",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/assessmentResultsExamineDetails", 
    name: "assessmentResultsExamineDetails",
    component: assessmentResultsExamineDetails,
    meta: {
      name: "承运商管理,查看",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/assessmentResultsRelease", 
    name: "AssessmentResultsRelease",
    component: AssessmentResultsRelease,
    meta: {
      name: "承运商管理,考核结果发布",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/AssessmentResultsReleaseDetails", 
    name: "AssessmentResultsReleaseDetails",
    component: AssessmentResultsReleaseDetails,
    meta: {
      name: "承运商管理,考核结果查看",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipManage", 
    name: "ShipManage",
    component: ShipManage,
    meta: {
      name: "承运商管理,船舶管理",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipManageDetails", 
    name: "shipManageDetails",
    component: shipManageDetails,
    meta: {
      name: "承运商管理,船舶详情",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/tonnage", 
    name: "tonnage",
    component: tonnage,
    meta: {
      name: "承运商管理,吨位编辑",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipOverhaul", 
    name: "ShipOverhaul",
    component: ShipOverhaul,
    meta: {
      name: "承运商管理,船舶检修审批",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipQualifications", 
    name: "ShipQualifications",
    component: ShipQualifications,
    meta: {
      name: "承运商管理,船舶资质审批",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipBelow", 
    name: "ShipBelow",
    component: ShipBelow,
    meta: {
      name: "承运商管理,船舶下线/停航审批",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/shipup", 
    name: "Shipup",
    component: Shipup,
    meta: {
      name: "承运商管理,船舶上线/复航审批",
      keepAlive: true,
    },
  },
  {
    path: "/carrier/overhaul", 
    name: "Overhaul",
    component: Overhaul,
    meta: {
      name: "承运商管理,船舶检修方案申请",
      keepAlive: true,
    },
  },
  
  //安检管理
  {
    path: "/securityCheck/ShipRectification", 
    name: "ShipRectification",
    component: ShipRectification,
    meta: {
      name: "安检管理,船舶整改",
      keepAlive: true,
    },
  },
  {
    path: "/securityCheck/dailyInspect", 
    name: "DailyInspect",
    component: DailyInspect,
    meta: {
      name: "安检管理,日常检查",
      keepAlive: true,
    },
  },
  {
    path: "/securityCheck/authenticationStandardMaintenance", 
    name: "AuthenticationStandardMaintenance",
    component: AuthenticationStandardMaintenance,
    meta: {
      name: "安检管理,认证检查标准维护",
      keepAlive: true,
    },
  },
  {
    path: "/securityCheck/dailyStandardMaintenance", 
    name: "DailyStandardMaintenance",
    component: DailyStandardMaintenance,
    meta: {
      name: "安检管理,日常检查标准维护",
      keepAlive: true,
    },
  },
  {
    path: "/securityCheck/certificationInspection", 
    name: "CertificationInspection",
    component: CertificationInspection,
    meta: {
      name: "安检管理,认证检查",
      keepAlive: true,
    },
  },
  {
    path: "/securityCheck/otherInspection", 
    name: "OtherInspection",
    component: OtherInspection,
    meta: {
      name: "安检管理,其他检查",
      keepAlive: true,
    },
  },
  //数质量管理
  {
    path: "/numQualityManage/exceedClaimant", 
    name: "ExceedClaimant",
    component: ExceedClaimant,
    meta: {
      name: "数质量管理,超耗索赔",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/claimantNotice", 
    name: "ClaimantNotice",
    component: ClaimantNotice,
    meta: {
      name: "数质量管理,索赔通知单",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/claimantexamine", 
    name: "Claimantexamine",
    component: Claimantexamine,
    meta: {
      name: "数质量管理,超耗索赔审批",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/boatData", 
    name: "BoatData",
    component: BoatData,
    meta: {
      name: "数质量管理,航次数据",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/boatDataEdit", 
    name: "boatDataEdit",
    component: boatDataEdit,
    meta: {
      name: "数质量管理,编辑",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/lossArateService", 
    name: "LossArateService",
    component: LossArateService,
    meta: {
      name: "数质量管理,定损率维护",
      keepAlive: true,
    },
  },
  {
    path: "/numQualityManage/oilPriceService", 
    name: "OilPriceService",
    component: OilPriceService,
    meta: {
      name: "数质量管理,油价维护",
      keepAlive: true,
    },
  },
  //计划调度
  {
    path: "/planDispatch/MonthlyPlan", 
    name: "MonthlyPlan",
    component: MonthlyPlan,
    meta: {
      name: "计划调度,月度计划",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/MonthlyPlan_record", 
    name: "MonthlyPlanRecord",
    component: MonthlyPlanRecord,
    meta: {
      name: "计划调度,完成数记录",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/monthlyPlanAddFinish", 
    name: "monthlyPlanAddFinish",
    component: monthlyPlanAddFinish,
    meta: {
      name: "计划调度,新增完成数",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/boatPlan", 
    name: "BoatPlan",
    component: BoatPlan,
    meta: {
      name: "计划调度,航次计划",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/boatPlanDateils", 
    name: "boatPlanDateils",
    component: boatPlanDateils,
    meta: {
      name: "计划调度,查看详情",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/boatPlanFinish", 
    name: "boatPlanFinish",
    component: boatPlanFinish,
    meta: {
      name: "计划调度,完成数",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/walkthroughsPlan", 
    name: "WalkthroughsPlan",
    component: WalkthroughsPlan,
    meta: {
      name: "计划调度,预排计划",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/walkthroughsPlanExamine", 
    name: "WalkthroughsPlanExamine",
    component: WalkthroughsPlanExamine,
    meta: {
      name: "计划调度,预排计划审批",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/walkthroughsPlanExamineDetails", 
    name: "WalkthroughsPlanExamineDetails",
    component: WalkthroughsPlanExamineDetails,
    meta: {
      name: "计划调度,预排计划审批详情",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/planExamine", 
    name: "PlanExamine",
    component: PlanExamine,
    meta: {
      name: "计划调度,计划审批",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/changeHarborDivideUnloadApply", 
    name: "ChangeHarborDivideUnloadApply",
    component: ChangeHarborDivideUnloadApply,
    meta: {
      name: "计划调度,改港分卸申请",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/changeHarborDivideUnloadApplyDateils", 
    name: "changeHarborDivideUnloadApplyDateils",
    component: changeHarborDivideUnloadApplyDateils,
    meta: {
      name: "计划调度,查看详情",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/changeHarborDivideUnloadExamine", 
    name: "ChangeHarborDivideUnloadExamine",
    component: ChangeHarborDivideUnloadExamine,
    meta: {
      name: "计划调度,改港分卸审批",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/otherShipPlan", 
    name: "OtherShipPlan",
    component: OtherShipPlan,
    meta: {
      name: "计划调度,其它水运计划",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/outsourcingPlan", 
    name: "OutsourcingPlan",
    component: OutsourcingPlan,
    meta: {
      name: "计划调度,外采计划",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/boatQuery", 
    name: "BoatQuery",
    component: BoatQuery,
    meta: {
      name: "计划调度,航次查询",
      keepAlive: true,
    },
  },
  {
    path: "/planDispatch/shipOutExamine", 
    name: "ShipOutExamine",
    component: ShipOutExamine,
    meta: {
      name: "计划调度,船舶外出审批",
      keepAlive: true,
    },
  },
  //装卸管理
  {
    path: "/loadUnloadManage/completedExamine", 
    name: "CompletedExamine",
    component: CompletedExamine,
    meta: {
      name: "装卸管理,完成数审批",
      keepAlive: true,
    },
  },
  {
    path: "/loadUnloadManage/loadingOil", 
    name: "LoadingOil",
    component: LoadingOil,
    meta: {
      name: "装卸管理,装油",
      keepAlive: true,
    },
  },
  {
    path: "/loadUnloadManage/loadingOilDetails", 
    name: "loadingOilDetails",
    component: loadingOilDetails,
    meta: {
      name: "装卸管理,详情",
      keepAlive: true,
    },
  },
  {
    path: "/loadUnloadManage/loadingOilEdit", 
    name: "loadingOilEdit",
    component: loadingOilEdit,
    meta: {
      name: "装卸管理,编辑",
      keepAlive: true,
    },
  },
  {
    path: "/loadUnloadManage/unloadOil", 
    name: "UnloadOil",
    component: UnloadOil,
    meta: {
      name: "装卸管理,卸油",
      keepAlive: true,
    },
  },
  {
    path: "/loadUnloadManage/unloadOilEdit", 
    name: "unloadOilEdit",
    component: unloadOilEdit,
    meta: {
      name: "装卸管理,编辑",
      keepAlive: true,
    },
  },
  //结算管理
  {
    path: "/settlementManage/changeHarborDivideUnload", 
    name: "ChangeHarborDivideUnload",
    component: ChangeHarborDivideUnload,
    meta: {
      name: "结算管理,改港分卸",
      keepAlive: true,
    },
  },
  {
    path: "/settlementManage/freightrateAdjust", 
    name: "FreightrateAdjust",
    component: FreightrateAdjust,
    meta: {
      name: "结算管理,运价调整",
      keepAlive: true,
    },
  },
  {
    path: "/settlementManage/transpotationByFinance", 
    name: "TranspotationByFinance",
    component: TranspotationByFinance,
    meta: {
      name: "结算管理,运杂费结算(财务)",
      keepAlive: true,
    },
  },
  {
    path: "/settlementManage/transpotationByCarrier", 
    name: "TranspotationByCarrier",
    component: TranspotationByCarrier,
    meta: {
      name: "结算管理,运杂费结算(承运商)",
      keepAlive: true,
    },
  },
  {
    path: "/settlementManage/paymentDetailedList", 
    name: "PaymentDetailedList",
    component: PaymentDetailedList,
    meta: {
      name: "结算管理,下载付款清单",
      keepAlive: true,
    },
  },
  {
    path: "/settlementManage/tariffDefend", 
    name: "TariffDefend",
    component: TariffDefend,
    meta: {
      name: "结算管理,运价维护",
      keepAlive: true,
    },
  },
  //船舶监控
  {
    path: "/vesseMonitor/VideoMonitoring", 
    name: "VideoMonitoring",
    component: VideoMonitoring,
    meta: {
      name: "船舶监控,视频监控",
      keepAlive: true,
    },
  },
  {
    path: "/vesseMonitor/Positionship", 
    name: "Positionship",
    component: Positionship,
    meta: {
      name: "船舶监控,船舶位置",
      keepAlive: true,
    },
  },
  {
    path: "/vesseMonitor/jihuas", 
    name: "jihuas",
    component: jihuas,
    meta: {
      name: "船舶监控,船舶位置,船舶计划",
      keepAlive: true,
    },
  },
  {
    path: "/vesseMonitor/huoqujihua", 
    name: "huoqujihua",
    component: huoqujihua,
    meta: {
      name: "船舶监控,船舶位置,调度计划",
      keepAlive: true,
    },
  },
  {
    path: "/vesseMonitor/huoquxxsw", 
    name: "huoquxxsw",
    component: huoquxxsw,
    meta: {
      name: "船舶监控,船舶位置,水位信息",
      keepAlive: true,
    },
  },
  //报表管理
  {
    path: "/reportManager/reportViewoilType", 
    name: "ReportViewoilType",
    component: ReportViewoilType,
    meta: {
      name: "报表管理,水运完成量(分油品)",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/monthlyAssessmentOfCarriers", 
    name: "MonthlyAssessmentOfCarriers",
    component: MonthlyAssessmentOfCarriers,
    meta: {
      name: "报表管理,承运商月度考核情况",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/carrierinfo", 
    name: "Carrierinfo",
    component: Carrierinfo,
    meta: {
      name: "报表管理,承运商资料",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/shipCertificationSummary", 
    name: "ShipCertificationSummary",
    component: ShipCertificationSummary,
    meta: {
      name: "报表管理,船舶认证检查汇总表",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/shipDailySummary", 
    name: "ShipDailySummary",
    component: ShipDailySummary,
    meta: {
      name: "报表管理,船舶日常检查汇总表",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/onlineShipInfo", 
    name: "OnlineShipInfo",
    component: OnlineShipInfo,
    meta: {
      name: "报表管理,在线船舶基础资料",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/reportViewfinishAmount", 
    name: "ReportViewfinishAmount",
    component: ReportViewfinishAmount,
    meta: {
      name: "报表管理,承运量完成情况",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/walkthroughsfinish", 
    name: "Walkthroughsfinish",
    component: Walkthroughsfinish,
    meta: {
      name: "报表管理,预排计划完成情况",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/shipPositionCount", 
    name: "ShipPositionCount",
    component: ShipPositionCount,
    meta: {
      name: "报表管理,船舶船位统计",
      keepAlive: true,
    },
  },
  {
    path: "/reportManager/carrierFinish", 
    name: "CarrierFinish",
    component: CarrierFinish,
    meta: {
      name: "报表管理,承运商完成量(分省市)",
      keepAlive: true,
    },
  },
  //日志管理
  {
    path: "/LogManage", 
    name: "LogManage",
    component: LogManage,
    meta: {
      name: "日志管理",
      keepAlive: true,
    },
  },
  
];

const router = new VueRouter({
  routes,
  scrollBehavior: function (to, from, savedPosition) {},
});
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
});

const whiteList = ["/vesseMonitor/positionship","/login", "/maintain"];
// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {
  let list = JSON.parse(localStorage.getItem("$navbar")) || [];
  let data = list.find((item) => item.path === to.path);
  to.meta.keepAlive = !(
    data === undefined ||
    to.path === "/" ||
    to.path === "/home"
  );
  NProgress.start();
  if (localStorage.getItem("token")) {
    // 判断是否有token
    if (to.path === "/login") {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login"); // 否则全部重定向到登录页
    }
  }
});
router.afterEach((to, from, next) => {
  NProgress.done();
});
export default router;
