<template>
    <div class="page Create">
        <div class="container">
            <el-divider content-position="left">船舶检修方案申请</el-divider>
            <div class="formBox">
                <div class="item">
                    <div class="itemHead">公文类型</div>
                    <div class="itemContent">
                        <el-select v-model="createdData.docType" placeholder="请选择">
                            <el-option label="通知" value="0"></el-option>
                            <el-option label="通报" value="1"></el-option>
                            <el-option label="外部来文" value="2"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item" v-show="createdData.docType != 2">
                    <div class="itemHead">审批类型</div>
                    <div class="itemContent">
                        <el-select v-model="createdData.approveId" placeholder="请选择" @change="approvaChange">
                            <el-option v-for="(item, index) in approvalSet" :label="item.title"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item" v-show="createdData.docType != 2">
                    <div class="itemHead" style="border-right: 1px solid #DCDFE6;">审核流程</div>
                    <div class="itemContent">
                        <div class="flowPath" v-show="createdData.approveId != ''">
                            <div class="text">一级审核：{{ flowPathContent.content1 }}</div>
                            <div class="text">二级审核：{{ flowPathContent.content2 }}</div>
                            <div class="text">三级审核：{{ flowPathContent.content3 }}</div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="itemHead">主题</div>
                    <div class="itemContent">
                        <el-input placeholder="请输入内容" v-model="createdData.title" clearable></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="itemHead">接收人</div>
                    <div class="itemContent">
                        <el-cascader placeholder="选择或搜索后选择" v-model="createdData.receiver" :options="selectMember"
                            filterable :props="{ multiple: true, label: 'title', value: 'id' }"></el-cascader>
                    </div>
                </div>
                <div class="item">
                    <div class="itemHead" style="border-right: 1px solid #DCDFE6;">是否提醒</div>
                    <div class="itemContent">
                        <div class="flowPath" style="height: 40px;line-height: 40px;">
                            <el-switch v-model="createdData.isSendSms" active-color="#13ce66" inactive-color="#ff4949"
                                active-value="1" inactive-value="0"></el-switch>
                        </div>
                    </div>
                </div>
                <div class="item" v-show="createdData.isSendSms == 1">
                    <div class="itemHead">短信接收人</div>
                    <div class="itemContent">
                        <el-cascader placeholder="选择或搜索后选择" v-model="createdData.smsUser" :options="selectMember" filterable
                            :props="{ multiple: true, label: 'title', value: 'id' }"></el-cascader>
                    </div>
                </div>
                <div class="serverHead">
                    正文
                </div>
                <el-input type="textarea" :rows="10" placeholder="请输入正文" v-model="createdData.content">
                </el-input>
                <div class="serverHead" style="margin-top: 15px;">
                    附件
                </div>
                <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
                    :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                    <el-button icon="el-icon-upload2" size="small" type="primary" style="margin: 8px 0;"
                        v-loading="fileLoading">点击上传</el-button>
                </el-upload>
                <!--列表-->
                <el-table v-if="showReady" ref="multipleTable" border :data="fileList" tooltip-effect="dark"
                    style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                    <el-table-column align="center" label="文件名" prop="name">
                    </el-table-column>
                    <el-table-column align="center" label="状态" prop="status">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.status == 'success'">上传成功</el-tag>
                            <el-tag type="danger" v-else>上传失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div style="display: flex;align-items: center;justify-content: center;">
                                <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload2"
                                    :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                                    <el-button size="mini" v-if="scope.row.status != 'success'" type="primary"
                                        style="margin: 8px 10px;" v-loading="fileLoading" @click="cxupload(scope.$index)"
                                        icon="el-icon-upload2">重新上传</el-button>
                                </el-upload>
                                <el-button type="danger" size="mini"
                                    @click="deleterow(scope.row, scope.$index)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="submit"><el-button type="primary" icon="el-icon-check" size="medium" @click="submit">提交</el-button>
            </div>
        </div>
    </div>
</template>
    
<script>
import baseUrl from "@/api/config"
export default {
    name: "Create",
    data() {
        return {
            fileLoading: false,
            flowPathContent: {},
            createdData: {
                approveId: "",
                content: '',
                fileList: [],
                isSendSms: '',
                publicScope: '',
                receiver: [],
                smsUser: [],
                title: '',
                docType: '0'
            },
            updateUrl: "",
            fileList: [],
            cxIndex: "",
            showReady: true,
            rules: {
                title: {
                    required: true, message: '请输入标题', trigger: 'blur'
                }
            },
            member: [],
            memberGroup: [],
            approvalSet: [],
            selectMember: [],
            fileData: [],
        }
    },
    created() {
        this.updateUrl = baseUrl
        this.getDict()
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    mounted() {

    },
    methods: {
        submit() {
            if (this.createdData.title == '') {
                this.$message.warning('请输入主题')
                return
            }
            if (this.createdData.receiver.length == 0) {
                this.$message.warning('请选择接收人')
                return
            }
            if (this.createdData.content == '') {
                this.$message.warning('请输入正文')
                return
            }
            if (this.createdData.isSendSms == 1) {
                if (this.createdData.smsUser.length == 0) {
                    this.$message.warning('请选择短信接收人')
                    return
                }
            }
            let data = {
                approveId: this.createdData.approveId,
                content: this.createdData.content,
                isSendSms: this.createdData.isSendSms,
                publicScope: '',
                title: this.createdData.title,
                docType: this.createdData.docType,
                smsUser: this.createdData.smsUser
            }
            let r = []
            this.createdData.receiver.map(item => {
                r.push(item[1])
            })
            data.receiver = r.join(',')
            if (this.createdData.isSendSms == 1) {
                if (this.createdData.smsUser.length > 0) {
                    let s = []
                    this.createdData.smsUser.map(item => {
                        s.push(item[1])
                    })
                    data.smsUser = s.join(',')
                }
            }
            if (this.fileList.length > 0) {
                let f = []
                this.fileList.map(item => {
                    f.push({
                        title: item.name,
                        file: item.response.data.save_path
                    })
                })
                data.fileList = JSON.stringify(f)
            }
            this.$Api.publicCharacter_Send(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.createdData = {
                        approveId: "",
                        content: '',
                        fileList: [],
                        isSendSms: '',
                        publicScope: '',
                        receiver: [],
                        smsUser: [],
                        title: '',
                        docType: '0'
                    }
                    this.fileList = []
                }
            })
        },
        cxupload(index) {
            this.cxIndex = index
        },
        beforeUpload() {
            this.cxIndex = ''
            this.fileLoading = true
        },
        beforeUpload2() {
            this.fileLoading = true
            this.showReady = false
        },
        onsuccess(response, file, fileList) {
            if (response.code == 200) {
                if (this.cxIndex === '') {
                    this.fileList.push(file);
                } else {
                    this.fileList[this.cxIndex] = file
                    this.showReady = true
                }
            } else {
                file.status = 'faile'
                if (this.cxIndex === '') {
                    this.fileList.push(file);
                } else {
                    this.fileList[this.cxIndex] = file
                    this.showReady = true
                }
            }
            this.fileLoading = false
        },
        handleError(err, file, fileList) {
            let errItem = {
                name: file.name,
                status: 'faile'
            }
            if (this.cxIndex === '') {
                this.fileList.push(errItem);
            } else {
                this.fileList[this.cxIndex] = errItem
                this.showReady = true
            }
            this.fileLoading = false
        },
        approvaChange(e) {
            this.approvalSet.map(item => {
                if (e == item.id) {
                    this.flowPathContent = item
                }
            })
        },
        clearCreateData() {
            this.createdData = {
                id: "",
                title: '',
                type: 15,
                user1: [],
                user2: [],
                user3: []
            }
            this.$refs['form'].resetFields();
        },
        deleterow(row, index) {
            this.$confirm('是否确定删除该文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fileList.splice(index, 1);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        setNameInfo(list) {
            let arr = []
            list.map((key, kindex) => {
                if (this.member.length > 0) {
                    this.member.map(row => {
                        if (key == row.id) {
                            arr[kindex] = { name: row.title }
                            if (this.memberGroup.length > 0) {
                                this.memberGroup.map(h => {
                                    if (row.groupId == h.id) {
                                        arr[kindex].groupName = h.title
                                    }
                                })
                            }
                        }
                    })
                }
            })
            return arr
        },
        getDict() {
            this.$Api.publicCharacter_Dict().then(res => {
                this.member = res.data.member
                this.memberGroup = res.data.memberGroup
                if (res.data.approvalSet.length > 0) {
                    res.data.approvalSet.map(item => {
                        if (item.user1 != 0) {
                            let userArr1 = item.user1.split(',')
                            if (userArr1.length > 0) {
                                let arr = this.setNameInfo(userArr1)
                                item.userArr1 = arr
                                if (arr.length > 0) {
                                    let content = ''
                                    arr.map(item => {
                                        content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                                    })
                                    item.content1 = content
                                } else {
                                    item.content1 = '无'
                                }
                            } else {
                                item.content1 = '无'
                            }
                        } else {
                            item.content1 = '无'
                        }
                        if (item.user2 != 0) {
                            let userArr2 = item.user2.split(',')
                            if (userArr2.length > 0) {
                                let arr = this.setNameInfo(userArr2)
                                item.userArr2 = arr
                                if (arr.length > 0) {
                                    let content = ''
                                    arr.map(item => {
                                        content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                                    })
                                    item.content2 = content
                                } else {
                                    item.content2 = '无'
                                }
                            } else {
                                item.content2 = '无'
                            }
                        } else {
                            item.content2 = '无'
                        }
                        if (item.user3 != 0) {
                            let userArr3 = item.user3.split(',')
                            if (userArr3.length > 0) {
                                let arr = this.setNameInfo(userArr3)
                                item.userArr3 = arr
                                if (arr.length > 0) {
                                    let content = ''
                                    arr.map(item => {
                                        content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                                    })
                                    item.content3 = content
                                } else {
                                    item.content3 = '无'
                                }
                            } else {
                                item.content3 = '无'
                            }
                        } else {
                            item.content3 = '无'
                        }

                    })
                }
                this.approvalSet = res.data.approvalSet
                let selectMember = JSON.parse(JSON.stringify(res.data.memberGroup))
                if (selectMember.length > 0) {
                    selectMember.map(item => {
                        let children = []
                        if (res.data.member.length > 0) {
                            res.data.member.map(key => {
                                if (item.id == key.groupId) {
                                    children.push({
                                        title: key.title,
                                        id: key.id
                                    })
                                }
                            })
                            item.children = children
                        }

                    })
                }
                this.selectMember = selectMember
            })
        },
        // --------------------------公共方法--------------------------------------


        getData() { // 获取数据
            let data = {
                type: 1,
                page: this.page.currentPage,
                limit: this.page.pageSize
            }

        },
    }
}
</script>
    
<style lang="scss" scoped>
.Create {
    height: calc(100vh - 125px) !important;
    overflow-y: scroll;

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .formBox {
            width: 100%;
            padding: 0 10px;
            margin-top: 10px;

            .item {
                width: 100%;
                display: flex;
                margin-bottom: 15px;

                .itemHead {
                    width: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FBFBFB;
                    border: 1px solid #DCDFE6;
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    height: 40px;
                    line-height: 40px;
                }

                .itemContent {
                    width: 1;
                    flex: 1;

                    /deep/.el-input__inner {
                        width: 100%;
                        border-radius: 0 4px 4px 0;
                    }

                    /deep/.el-select {
                        width: 100%;
                    }

                    /deep/.el-cascader {
                        width: 100%;
                    }

                    .flowPath {
                        width: 100%;
                        padding: 0 15px;

                        .text {
                            line-height: 24px;
                            color: #808080;
                        }
                    }
                }
            }
        }

        .serverHead {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 15px;
            background-color: #FBFBFB;
            border: 1px solid #DCDFE6;
            border-radius: 4px 0 0 4px;
            height: 40px;
            line-height: 40px;
        }

        /deep/.el-divider__text.is-left {
            font-size: 20px;

        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
    }

}
</style>