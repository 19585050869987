<template>
    <div class="page u-flex">
        <div class="left">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-form :model="searchForm1" class="search-form" inline>
                        <el-form-item label="搜索：">
                            <el-input v-model="searchForm1.title" placeholder="请输入关键字" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="状态：">
                            <el-select clearable v-model="searchForm1.is_hid" placeholder="状态" size="small">
                                <el-option :value="0" label="启用"></el-option>
                                <el-option :value="1" label="禁用"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                                @click="searchTable1('search')">搜索
                            </el-button>
                            <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
                                type="primary" @click="searchTable1('reset')">重置
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
                                @click="create1">新增
                            </el-button>
                            <el-button class="u-custom-btn-primary" icon="el-icon-edit" size="small" type="primary"
                                @click="edit1">编辑
                            </el-button>
                            <el-button class="u-custom-btn-primary" icon="el-icon-delete" size="small" type="danger"
                                @click="remove1">删除
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-for="o in treeList" :key="o.id" class="item" @click="handClick(o)"
                    :class="[id == o.id ? 'active' : '']">
                    {{ o.name }}({{ o.code }})
                </div>
            </el-card>
        </div>
        <el-scrollbar class="u-row-nowrap" style="height: calc(100vh - 50px); width:100%">
            <div class="right">
                <el-form :model="searchForm" class="search-form" inline>
                    <el-form-item label="标签名称：">
                        <el-input v-model="searchForm.label" placeholder="请输入标签名称" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-select clearable v-model="searchForm.is_hid" placeholder="状态" size="small">
                            <el-option :value="0" label="启用"></el-option>
                            <el-option :value="1" label="禁用"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                            @click="searchTable('search')">搜索
                        </el-button>
                        <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small"
                            type="primary" @click="searchTable('reset')">重置
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="operate-box u-flex u-col-center">
                    <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
                        @click="create">新增
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-setting" size="small" type="primary"
                        v-show="id == 35 || id == 36" @click="synchronization">同步
                    </el-button>
                </div>
                <el-table v-loading="loading" :data="tableData"
                    :tree-props="{ children: 'child', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
                    header-row-class-name="header-table">
                    <el-table-column label="标签" prop="label" align="center">
                    </el-table-column>
                    <el-table-column label="键值" prop="value" align="center">
                    </el-table-column>
                    <el-table-column label="常量标识" prop="code" align="center">
                    </el-table-column>
                    <el-table-column label="创建时间" prop="add_time" align="center">
                    </el-table-column>
                    <el-table-column align="center" label="状态" prop="is_hid" width="80">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.is_hid === 0 ? true : false" :disabled="scope.row.loading"
                                active-color="#13ce66" inactive-color="#ff4949"
                                @change="statusChange(scope.row, scope.$index)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right" align="center">
                        <template slot-scope="scope">
                            <span class="o-btn" @click="edit(scope.row)">
                                <el-icon class="el-icon-edit"></el-icon>
                                编辑
                            </span>
                            <span v-if="scope.row.id !== $store.state.userInfo.id" class="o-btn o-btn-red"
                                @click="remove(scope.row.id)">
                                <el-icon class="el-icon-delete"></el-icon>
                                删除
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="u-flex u-row-end u-p-t-24 u-p-b-24">
                    <el-pagination :current-page="page" :page-size="size" :total="total"
                        layout="total,prev, pager, next, jumper" @current-change="changePage">
                    </el-pagination>
                </div>
            </div>
        </el-scrollbar>
        <el-dialog :close-on-click-modal="false" :title="mode1 === 'add' ? '添加' : '编辑'" :visible.sync="dialogVisible1"
            destroy-on-close width="600px" @closed="resetCreateData1">
            <div>
                <el-form ref="createForm1" :model="createData1" :rules="createRules1" class="create-form"
                    label-width="80px">
                    <el-row>
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="createData1.name" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="标识" prop="code">
                            <el-input v-model="createData1.code" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-radio-group v-model="createData1.is_hid">
                                    <el-radio v-for="item in menuRoots" :label="item.id">{{ item.title }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="createData1.remark" type="textarea" :rows="3" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="u-custom-btn-primary" type="primary" @click="createMenu1">确 定</el-button>
                <el-button @click="dialogVisible1 = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :title="mode === 'add' ? '添加' : '编辑'" :visible.sync="dialogVisible"
            destroy-on-close width="600px" @closed="resetCreateData">
            <div>
                <el-form ref="createForm" :model="createData" :rules="createRules" class="create-form" label-width="80px">
                    <el-row>
                        <el-form-item label="名称" prop="label">
                            <el-input v-model="createData.label" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="标识" prop="code">
                            <el-input v-model="createData.code" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="键值" prop="value">
                            <el-input v-model="createData.value" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-radio-group v-model="createData.is_hid">
                                    <el-radio v-for="item in menuRoots" :label="item.id">{{ item.title }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="createData.remark" type="textarea" :rows="3" size="small"></el-input>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="u-custom-btn-primary" type="primary" @click="createMenu">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DataDictionary",
    data() {
        return {
            page: 1,
            size: 20,
            total: 0,
            mode: 'add',
            mode1: 'add',
            loading: false,
            dialogVisible: false,
            dialogVisible1: false,
            id: '',
            treeList: [],
            createData: {
                id: '',
                data_dict_id: '',
                label: '',
                code: '',
                value: '',
                remark: '',
                is_hid: 0
            },
            createData1: {
                id: '',
                name: '',
                code: '',
                remark: '',
                is_hid: 0
            },
            createRules: {
                label: {
                    required: true,
                    message: '请输入标签',
                    trigger: ['blur', 'change']
                },
                code: {
                    required: true,
                    message: '请输入标识',
                    trigger: ['blur', 'change']
                },
                value: {
                    required: true,
                    message: '请输入键值',
                    trigger: ['blur', 'change']
                },
            },
            createRules1: {
                name: {
                    required: true,
                    message: '请输入名称',
                    trigger: ['blur', 'change']
                },
                code: {
                    required: true,
                    message: '请输入标识',
                    trigger: ['blur', 'change']
                },
            },
            defaultProps: {
                children: 'child',
                label: 'name',
                value: 'id'
            },
            menuRoots: [{
                title: '正常',
                id: 0
            }, {
                title: '禁用',
                id: 1
            }],
            searchForm: {
                data_dict_id: '',
                label: '',
                is_hid: '',
            },
            searchForm1: {
                title: '',
                is_hid: ''
            },
            tableData: [],
        }
    },
    created() {
        // this.getData()
        this.getInfo()
    },
    methods: {
        synchronization() {
            let url = ''
            if (this.id == 35) {
                url = '/backend/data_dict_options/synchronous_school'
            } else if (this.id == 36) {
                url = '/backend/data_dict_options/synchronous_supplier'
            }
            this.$http.get(url).then(res => {
                if (res.code === 1) {
                    this.$message.success('同步成功')
                    this.getData()

                }
            })
        },
        statusChange(e, index) {
            let obj = {
                id: e.id,
                is_hid: e.is_hid === 0 ? 1 : 0
            }
            this.tableData[index].loading = true
            this.$http.post('/backend/data_dict_options/status', obj).then(res => {
                if (res.code == 1) {
                    this.tableData[index].is_hid = obj.is_hid
                }
                this.tableData[index].loading = false
            })
        },
        getInfo() {
            this.$http.get('/backend/data_dict/list', { params: this.searchForm1 }).then(res => {
                if (res.code === 1) {
                    this.treeList = res.data.list.data
                    // this.resetCreateData()
                }
            })
        },
        handClick(e) {
            this.page = 1
            this.searchForm.data_dict_id = e.id
            this.createData.data_dict_id = e.id
            this.id = e.id
            this.getData()
        },
        searchTable(t) {
            this.page = 1
            if (t === 'search') {
                this.getData()
            } else {
                this.searchForm = {
                    data_dict_id: '',
                    label: '',
                    is_hid: '',
                }
                this.getData()
            }
        },
        searchTable1(t) {
            this.page = 1
            if (t === 'search') {
                this.getInfo()
            } else {
                this.searchForm1 = {
                    title: '',
                    is_hid: ''
                }
                this.getInfo()
            }
        },
        edit(e) {
            this.mode = 'edit'
            console.log(e);
            this.createData = e
            this.dialogVisible = true
        },
        edit1() {
            if (!this.searchForm.data_dict_id) return this.$message.error('请选择要编辑的数据')
            this.treeList.forEach(item => {
                if (item.id === this.searchForm.data_dict_id) {
                    this.createData1 = item
                }
            })
            this.mode1 = 'edit'
            this.dialogVisible1 = true
        },
        create() {
            this.mode = 'add'
            this.createData.data_dict_id = this.id
            this.dialogVisible = true
        },
        create1() {
            this.mode = 'add'
            this.dialogVisible1 = true
        },
        remove(id) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id
                }
                this.$http.post('/backend/data_dict_options/delete', data).then(res => {
                    if (res.code === 1) {
                        this.$message.success('删除成功')
                        this.getData()
                    }
                })
            })
        },
        remove1() {
            if (!this.searchForm.data_dict_id) return this.$message.error('请选择要删除的数据')
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: this.searchForm.data_dict_id
                }
                this.$http.post('/backend/data_dict/delete', data).then(res => {
                    if (res.code === 1) {
                        this.getInfo()
                        this.searchForm.data_dict_id = ''
                        this.id = ''
                        this.$nextTick(() => {
                            this.tableData = []
                        })
                        this.$message.success('删除成功')
                    }
                })
            })

        },
        resetCreateData() {
            this.createData = {
                id: '',
                data_dict_id: '',
                label: '',
                code: '',
                value: '',
                remark: '',
                is_hid: 0
            }
            this.$refs['createForm'].resetFields()
        },
        resetCreateData1() {
            this.createData1 = {
                name: '',
                code: '',
                remark: '',
                is_hid: 0
            }
            this.$refs['createForm1'].resetFields()
        },
        createMenu() {
            if (this.mode === 'add') {
                this.$refs['createForm'].validate(valid => {
                    if (valid) {
                        this.$http.post('/backend/data_dict_options/create', this.createData).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    }
                })
            } else {
                this.$refs['createForm'].validate(valid => {
                    if (valid) {
                        this.$http.post('/backend/data_dict_options/update', this.createData).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    }
                })
            }

        },
        createMenu1() {
            if (this.mode1 === 'add') {
                this.$refs['createForm1'].validate(valid => {
                    if (valid) {
                        this.$http.post('/backend/data_dict/create', this.createData1).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible1 = false
                                this.searchForm.data_dict_id = res.data.info.id
                                this.createData.data_dict_id = res.data.info.id
                                this.getInfo()
                                // this.getData()
                            }
                        })
                    }
                })
            } else {
                this.$refs['createForm1'].validate(valid => {
                    if (valid) {
                        this.$http.post('/backend/data_dict/update', this.createData1).then(res => {
                            if (res.code === 1) {
                                this.dialogVisible1 = false
                                this.getData()
                            }
                        })
                    }
                })
            }

        },
        getData() {
            this.loading = true
            this.searchForm.page = this.page
            this.$http.get('/backend/data_dict_options/list', {
                params: this.searchForm
            }).then(res => {
                if (res.code === 1) {
                    let {
                        data: list,
                        total,
                        per_page: size
                    } = res.data.list
                    this.tableData = list
                    this.total = total
                    this.size = size
                }
                this.loading = false
            })
        },
        changePage(e) {
            this.page = e
            this.getData()
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: calc(100vh - 50px);
    overflow: hidden;
}

.left {
    min-height: calc(100vh - 50px);
    width: 480px;
    background: #fff;
    padding: 10px;

    .el-card {
        width: 100%;
        padding-bottom: 20px;
    }

    .item {
        margin-bottom: 10px;

        &.active {
            color: #409EFF;
        }
    }

    .item:hover {
        cursor: pointer;
        color: #409EFF;
    }
}

.right {
    min-height: calc(100vh - 50px);
    padding: 20px;
    flex: 1;
    overflow: hidden;
}

.el-table {
    margin-top: 24px;

    .o-btn {
        color: #409EFF;
        cursor: pointer;

        ~.o-btn {
            margin-left: 12px;
        }
    }
}

.create-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
</style>
