<template>
    <div class="page boatPlan_finish" id="pageTop">
        <el-page-header @back="goBack" content="航次信息"></el-page-header>
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="已选中(吨)：">
                    <el-input v-model="searchForm.number" disabled size="small"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-circle-check" size="small" type="primary" @click="save">保存
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="getData">刷新
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 列表 -->
        <el-table ref="multipleTable" border :data="tabData" tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="流向" prop="belongIdName">
            </el-table-column>
            <el-table-column align="center" label="发港" prop="startPortName">
            </el-table-column>
            <el-table-column align="center" label="到港" prop="destPortName">
            </el-table-column>
            <el-table-column align="center" label="属性" prop="KUNTPNAME">
            </el-table-column>
            <el-table-column align="center" label="油号" prop="oilTypeIdName">
            </el-table-column>
            <el-table-column align="center" label="船舶" prop="shipName">
            </el-table-column>
            <el-table-column align="center" label="承运商" prop="subtitleName">
            </el-table-column>
            <el-table-column align="center" label="发运时间" prop="startTime">
            </el-table-column>
            <el-table-column align="center" label="完成量" prop="shipErpAmount">
            </el-table-column>
            <el-table-column align="center" label="计划量" prop="oilTon">
            </el-table-column>
        </el-table>
        <!-- 列表 -->
        <el-table ref="multipleTables" border v-loading="tabLoading" :data="tabData2" tooltip-effect="dark"
            header-row-class-name="header-table" @selection-change="handleSelectionChange" style="margin-top: 15px;">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column align="center" label="交货单号" prop="VBELN">
            </el-table-column>
            <el-table-column align="center" label="发港" prop="start_port_name">
            </el-table-column>
            <el-table-column align="center" label="到港" prop="dest_port_name">
            </el-table-column>
            <el-table-column align="center" label="属性" prop="AULWE_NAME">
            </el-table-column>
            <el-table-column align="center" label="油号" prop="oil_type_name">
            </el-table-column>
            <el-table-column align="center" label="船舶" prop="shipName">
            </el-table-column>
            <el-table-column align="center" label="承运商" prop="carrierTtitle">
            </el-table-column>
            <el-table-column align="center" label="录入时间" prop="WADAT_IST">
            </el-table-column>
            <el-table-column align="center" label="数量" prop="LFIMG">
            </el-table-column>
            <el-table-column align="center" label="处理" prop="CHECKED_STATUS">
                <template slot-scope="scope">
                    <div v-html="scope.row.CHECKED_STATUS"></div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
            <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                :page-size="page.pageSize" :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
                layout="total,sizes ,prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "boatPlan_finish",
    data() {
        return {
            searchForm: {
                number: ""
            },
            infoData: {
                title: '',
                birthday: '',
                job: '',
            },
            tabData: [],
            tabData2: [],
            shipJob: [],
            infoData: {},
            infoFiel: [],
            dict: {
                staicData: []
            },
            tabLoading: false,
            multipleSelection: [],
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
        }
    },
    created() {
        this.tabData[0] = this.$route.params
        if (this.$route.params?.id) {
            this.getData()
        }

    },
    activated() {

    },
    mounted() {

    },
    methods: {
        save() {
            this.$confirm(`是否确认提交吗？，已选中${this.multipleSelection.length}单，共${this.searchForm.number}吨`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let arr = []
                if (this.multipleSelection.length > 0) {
                    this.multipleSelection.map(item => {
                        arr.push(item.VBELN)
                    })
                }
                let data = {
                    planId: this.$route.params.id,
                    vbList: arr.join(',')
                }
                this.$Api.shipPlan_updateFinishAmount(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '保存成功'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        toggleSelection(rows) {
            console.log(rows, 'rows');
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTables.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTables.clearSelection();
            }
        },
        getData() {
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                planId: this.$route.params.id
            }
            this.tabLoading = true
            this.$Api.shipPlan_GetErpVbelnList(data).then(res => {
                this.tabLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total
                    let arr = []
                    this.tabData2 = res.data.data
                    this.$nextTick(() => {
                        if (res.data.data.length > 0) {
                            res.data.data.map((item, index) => {
                                if (item.LAY_CHECKED == true) {
                                    arr.push(item)
                                }
                            })
                            this.toggleSelection(arr)
                            if (arr.length > 0) {
                                let total = 0
                                arr.forEach((item) => {
                                    total = Number(total) + Number(item.LFIMG)
                                })
                                this.searchForm.number = (total).toFixed(2)
                            }
                        }
                    })
                }
            })
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            if (this.multipleSelection.length > 0) {
                let total = 0
                this.multipleSelection.forEach((item) => {
                    total = Number(total) + Number(item.LFIMG)
                })
                this.searchForm.number = (total).toFixed(2)
            }
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/planDispatch/boatPlanFinish')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        // --------------------------公共方法--------------------------------------
    }
}
</script>
    
<style lang="scss" scoped>
.boatPlan_finish {
    height: initial;

    .searchBox::before {
        content: '航次信息';
        display: block;
        background-color: #FFF;
        position: absolute;
        top: -10px;
        font-size: 14px;
        color: #6b6b6b;
        left: 30px;
        z-index: 2;
    }

    .container {
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }


        .addoreidt {
            height: 50vh;
            overflow-y: scroll;

            /deep/.el-steps {
                height: auto !important;
            }
        }
    }

    .el-page-header {
        line-height: 50px;
    }


    /deep/.el-divider__text.is-left {
        font-size: 20px;
        font-weight: 500;
    }
}
</style>