<template>
    <div class="page shipManage_Details" id="pageTop">
        <el-page-header @back="goBack" content="船舶详情">
        </el-page-header>
        <div class="container">
            <el-form ref="formShip" :model="rowData" :rules="rules" label-width="120px" inline>
                <el-form-item label="船舶名称：" prop="title">
                    <el-input v-model="rowData.title" clearable placeholder="请输入船舶名称" :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="MMIS号：" prop="ais">
                    <el-input v-model="rowData.ais" clearable placeholder="请输入MMIS号" :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="船舶编码：" prop="acode">
                    <el-input v-model="rowData.acode" clearable placeholder="请输入船舶编码" :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="满载吃水(m)：" prop="maxWater">
                    <el-input v-model="rowData.maxWater" clearable placeholder="请输入满载吃水(m)"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="装载量(吨)：" prop="maxCapacity">
                    <el-input v-model="rowData.maxCapacity" clearable placeholder="请输入装载量(吨)"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="过坝运营：" prop="canCrossBam">
                    <el-select v-model="rowData.canCrossBam" placeholder="请选择过坝运营" :disabled="isEdite">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="船舶类型：" prop="shipType">
                    <el-select v-model="rowData.shipType" placeholder="请选择船舶类型" :disabled="isEdite">
                        <el-option label="油船" :value="1"></el-option>
                        <el-option label="化学品/油船" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="长度(m)：" prop="shipsize">
                    <el-input v-model="rowData.shipsize" clearable placeholder="请输入长度(m)"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="最大宽度(m)：" prop="maxWidth">
                    <el-input v-model="rowData.maxWidth" clearable placeholder="请输入最大宽度(m)"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="最大高度(m)：" prop="maxHeight">
                    <el-input v-model="rowData.maxHeight" clearable placeholder="请输入最大高度(m)"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="装载容积：" prop="capacity">
                    <el-input v-model="rowData.capacity" clearable placeholder="请输入装载容积" :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="建造日期：" prop="shipBuildDate">
                    <el-date-picker v-model="rowData.shipBuildDate" type="date" placeholder="请选择建造日期"
                        value-format="yyyy-MM-dd" :disabled="isEdite">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="船籍港：" prop="ShipHomePort">
                    <el-input v-model="rowData.ShipHomePort" clearable placeholder="请输入船籍港"
                        :disabled="isEdite"></el-input>
                </el-form-item>
                <el-form-item label="船检登记号：" prop="ShipInspectionNO">
                    <el-input v-model="rowData.ShipInspectionNO" clearable placeholder="请输入船检登记号"
                        :disabled="isEdite"></el-input>
                </el-form-item>
            </el-form>
            <!--列表-->
            <el-table v-if="showReady" ref="multipleTable" border :data="fileList" tooltip-effect="dark"
                style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="类型" prop="title">
                </el-table-column>
                <el-table-column align="center" label="编号" prop="no">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.no" clearable placeholder="请输入编号" :disabled="isEdite"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="有效期" prop="date" width="280">
                    <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.date" type="date" placeholder="请选择有效期" :disabled="isEdite"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="状态" prop="states" width="100">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.states == 1">有效</el-tag>
                        <div v-else>
                            <el-button type="primary" icon="el-icon-s-operation" size="mini" v-if="scope.row.isLog"
                                @click="looklog(scope.row)">审核日志</el-button>
                            <el-tag type="danger" v-else>
                                无效
                            </el-tag>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column align="center" label="附件" prop="file" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="lookFile(scope.row.file)" icon="el-icon-view">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="上传" prop="name" width="100" v-if="!isEdite">
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload2"
                                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                                <el-button size="mini" type="primary" style="margin: 8px 10px;" v-loading="fileLoading"
                                    @click="cxupload(scope.$index)" icon="el-icon-upload2">上传</el-button>
                            </el-upload>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="备注" prop="Intro">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.Intro" clearable placeholder="请输入备注"
                            :disabled="isEdite"></el-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="submit" v-if="!isEdite" style="margin-top: 15px;"><el-button type="primary" icon="el-icon-check"
                size="medium" @click="submit">提交</el-button>
        </div>
        <el-divider content-position="left">船员</el-divider>
        <div class="container">
            <div class="operateBox" v-if="!isEdite">
                <div class="left">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="addRoleShip">新增船员</el-button>
                    <el-button icon="el-icon-refresh-right" size="small" @click="getListCrewl">刷新</el-button>
                </div>
                <div class="right"></div>
            </div>
            <!--船员列表-->
            <el-table ref="multipleTable" v-loading="shipRoleLoading" :data="shipTabData" tooltip-effect="dark"
                style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="船员名称" prop="title">
                </el-table-column>
                <el-table-column align="center" label="出生年月" prop="birthday">
                </el-table-column>
                <el-table-column align="center" label="职务" prop="job">
                </el-table-column>
                <el-table-column align="center" label="适任证书附件" prop="competentCertFile">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-view" size="mini"
                            @click="lookFile(scope.row.competentCertFile)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="证书编号" prop="competentCertNo">
                </el-table-column>
                <el-table-column align="center" label="有效期" prop="competentCertExp">
                </el-table-column>
                <el-table-column align="center" label="油操证书附件" prop="oilCertFile">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-view" size="mini"
                            @click="lookFile(scope.row.oilCertFile)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="证书编号" prop="oilCertNo">
                </el-table-column>
                <el-table-column align="center" label="有效期" prop="oilCertExp">
                </el-table-column>
                <el-table-column align="center" label="操作" width="200" fixed="right" v-if="!isEdite">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerowShip(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleterowShip(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加船船员 -->
            <el-dialog :close-on-click-modal="false" :title="isAddShip ? '添加' : '编辑'" :visible.sync="dialogVisible"
                destroy-on-close top="20vh" width="550px">
                <div class="dialogCont">
                    <el-form ref="formShipRole" :model="createDataShipRole" :rules="rulesShipRole" label-width="140px">
                        <el-form-item label="船员姓名：" prop="title">
                            <el-input v-model="createDataShipRole.title" clearable placeholder="请输入船员姓名"
                                style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="出生年月：" prop="birthday">
                            <el-date-picker v-model="createDataShipRole.birthday" type="date" placeholder="请选择出生年月"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="职务：" prop="job">
                            <el-select v-model="createDataShipRole.job" placeholder="请选择职务">
                                <el-option v-for="(item, index) in shipJob" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="适任证书编号：" prop="competentCertNo">
                            <el-input v-model="createDataShipRole.competentCertNo" clearable style="width: 220px;"
                                placeholder="请输入适任证书编号"></el-input>
                        </el-form-item>
                        <el-form-item label="适任证书有效期：" prop="competentCertExp">
                            <el-date-picker v-model="createDataShipRole.competentCertExp" type="date"
                                placeholder="请选择适任证书有效期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="适任证书附件：" prop="competentCertFile">
                            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload3"
                                :on-error="handleError" :on-success="onsuccessShiprole1" :show-file-list="false">
                                <el-button type="primary" style="margin: 8px 10px;" v-loading="shipRoleFileLoading1"
                                    icon="el-icon-upload2">上传</el-button>
                            </el-upload>
                            <el-button v-if="createDataShipRole.competentCertFile != ''" icon="el-icon-view" size="mini"
                                @click="lookFile(createDataShipRole.competentCertFile)">查看</el-button>
                        </el-form-item>
                        <el-form-item label="油操证书编号：" prop="oilCertNo">
                            <el-input v-model="createDataShipRole.oilCertNo" clearable style="width: 220px;"
                                placeholder="请输入油操证书编号"></el-input>
                        </el-form-item>
                        <el-form-item label="油操证书有效期：" prop="oilCertExp">
                            <el-date-picker v-model="createDataShipRole.oilCertExp" type="date" placeholder="请选择油操证书有效期"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="油操证书文件：" prop="oilCertFile">
                            <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload4"
                                :on-error="handleError" :on-success="onsuccessShiprole2" :show-file-list="false">
                                <el-button type="primary" style="margin: 8px 10px;" v-loading="shipRoleFileLoading2"
                                    icon="el-icon-upload2">上传</el-button>
                            </el-upload>
                            <el-button v-if="createDataShipRole.oilCertFile != ''" icon="el-icon-view" size="mini"
                                @click="lookFile(createDataShipRole.oilCertFile)">查看</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuShipRole">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <el-divider content-position="left">监控信息</el-divider>
        <div class="container">
            <div class="operateBox" v-if="!isEdite">
                <div class="left">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="addRoleMonitor">新增监控</el-button>
                </div>
                <div class="right"></div>
            </div>
            <!--监控信息-->
            <el-table ref="multipleTable" v-loading="monitorLoading" :data="monitorTabData" tooltip-effect="dark"
                style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="监控描述" prop="title">
                </el-table-column>
                <el-table-column align="center" label="ip地址" prop="ipaddr">
                </el-table-column>
                <el-table-column align="center" label="端口号" prop="ipport">
                </el-table-column>
                <el-table-column align="center" label="操作" width="200" fixed="right" v-if="!isEdite">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerowMonitor(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleterowMonitorp(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加船船员 -->
            <el-dialog :close-on-click-modal="false" :title="isAddmonitor ? '添加' : '编辑'" :visible.sync="dialogVisible2"
                destroy-on-close top="20vh" width="500px">
                <!-- <div class="dialogCont"> -->
                <el-form ref="formmonitor" :model="createDataMonitor" :rules="rulesMonitor" label-width="140px">
                    <el-form-item label="描述：" prop="title">
                        <el-input v-model="createDataMonitor.title" clearable placeholder="请输入描述"
                            style="width: 220px;"></el-input>
                    </el-form-item>
                    <el-form-item label="IP地址：" prop="ipaddr">
                        <el-input v-model="createDataMonitor.ipaddr" clearable placeholder="请输入IP地址"
                            style="width: 220px;"></el-input>
                    </el-form-item>
                    <el-form-item label="端口号：" prop="ipport">
                        <el-input v-model="createDataMonitor.ipport" clearable placeholder="请输入端口号"
                            style="width: 220px;"></el-input>
                    </el-form-item>
                </el-form>
                <!-- </div> -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuMonitor">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 日志 -->
        <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
            <div class="dialogCont">
                <el-steps direction="vertical" :active="1" :space="80">
                    <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
                        :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
                </el-steps>
                <!-- 列表 -->
                <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
                    tooltip-effect="dark" header-row-class-name="header-table">
                    <el-table-column align="center" label="审核级别" prop="aprv_level_text">
                    </el-table-column>
                    <el-table-column align="center" label="审核人员" prop="username">
                    </el-table-column>
                    <el-table-column align="center" label="审核结果" prop="user_outcome_text">
                    </el-table-column>
                    <el-table-column align="center" label="审核时间" prop="update_time">
                        <template slot-scope="scope">
                            <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</div></template>

<script>
import baseUrl from "@/api/config"
export default {
    name: "shipManage_Details",
    data() {
        return {
            createDataMonitor: {
                id: '',
                title: '',
                shipId: '',
                ipport: '',
                ipaddr: '',
            },
            rulesMonitor: {
                ipaddr: {
                    required: true, message: '请输入IP地址', trigger: 'blur'
                },
                ipport: {
                    required: true, message: '请输入端口号', trigger: 'blur'
                },
                title: {
                    required: true, message: '请输入描述', trigger: 'blur'
                },
            },
            dialogVisible2: false,
            isAddmonitor: false,
            monitorLoading: false,
            monitorTabData: [],
            createDataShipRole: {
                birthday: '',
                competentCertExp: '',
                competentCertFile: '',
                competentCertNo: '',
                job: '',
                oilCertExp: '',
                oilCertFile: '',
                oilCertNo: '',
                shipId: '',
                title: ''
            },
            rulesShipRole: {
                birthday: {
                    required: true, message: '请选择出生年月', trigger: 'blur'
                },
                competentCertExp: {
                    required: true, message: '请选择适任证书有效期', trigger: 'blur'
                },
                competentCertFile: {
                    required: true, message: '请上传适任证书附件', trigger: 'blur'
                },
                competentCertNo: {
                    required: true, message: '请输入适任证书编号', trigger: 'blur'
                },
                job: {
                    required: true, message: '请选择职务', trigger: 'blur'
                },
                oilCertExp: {
                    required: true, message: '请选择油操证书有效期', trigger: 'blur'
                },
                oilCertFile: {
                    required: true, message: '请上传油操证书附件', trigger: 'blur'
                },
                oilCertNo: {
                    required: true, message: '请输入油操证书编号', trigger: 'blur'
                },
                title: {
                    required: true, message: '请输入船员姓名', trigger: 'blur'
                },
            },
            shipRoleFileLoading1: false,
            shipRoleFileLoading2: false,
            isAddShip: false,
            shipJob: [],
            shipTabData: [],
            shipRoleLoading: false,
            logData: {},
            dialogVisible: false,
            dialogVisible3: false,
            isEdite: false,
            fileLoading: false,
            flowPathContent: {},
            createdData: {
                title: "",
                ais: '',
                acode: '',
                maxWater: '',
                maxCapacity: '',
                shipsize: '',
                maxWidth: '',
                maxHeight: '',
                capacity: '',
            },

            updateUrl: "",
            fileList: [{
                title: '内河检验证书（安全环保证书)',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'nhcbjyCertStates'
            }, {
                title: '船舶所有权登记证书',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'cbsyqCertStates'
            }, {
                title: '安全管理证书',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'aqglCertStates'
            }, {
                title: '船舶最低安全配员证书',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'cbzdaqypyCertStates'
            }, {
                title: '船舶营业运输证',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'cbyyyszjnshgzCertStates'
            }, {
                title: '内河船舶保险单(船舶险)',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'yhnhcbbxdCertStates'
            }, {
                title: '内河船舶保险单(油污险)',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'yhnhcbbxdCertStates1'
            }, {
                title: '内河船舶保险单(船员险)',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'yhnhcbbxdCertStates2'
            }, {
                title: '国家船舶舱容积计量站检定证书',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                isLog: false,
                stetesName: 'gjcbcrjjszjdCertStates'
            }, {
                title: '国内航行船舶入级证书',
                no: '',
                date: '',
                states: '',
                file: '',
                Intro: '',
                stetesName: 'gnhxcbrjCertStates',
                isLog: false
            }],
            cxIndex: "",
            showReady: true,
            rules: {
                title: {
                    required: true, message: '请输入船舶名称', trigger: 'blur'
                },
                ais: {
                    required: true, message: '请输入MMIS号', trigger: 'blur'
                },
                acode: {
                    required: true, message: '请输入船舶编码', trigger: 'blur'
                },
                maxWater: {
                    required: true, message: '请输入满载吃水(m)', trigger: 'blur'
                },
                maxCapacity: {
                    required: true, message: '请输入装载量(吨)', trigger: 'blur'
                },
                canCrossBam: {
                    required: true, message: '请选择过坝运营', trigger: 'blur'
                },
                shipType: {
                    required: true, message: '请选择船舶类型', trigger: 'blur'
                },
                shipsize: {
                    required: true, message: '请输入长度(m)', trigger: 'blur'
                },
                maxWidth: {
                    required: true, message: '请输入最大宽度(m)', trigger: 'blur'
                },
                maxHeight: {
                    required: true, message: '请输入最大高度(m)', trigger: 'blur'
                },
                capacity: {
                    required: true, message: '请输入装载容积', trigger: 'blur'
                },
                shipBuildDate: {
                    required: true, message: '请输入建造日期', trigger: 'blur'
                },
                ShipHomePort: {
                    required: true, message: '请输入船籍港', trigger: 'blur'
                },
                ShipInspectionNO: {
                    required: true, message: '请输入船检登记号', trigger: 'blur'
                },

            },
            member: [],
            memberGroup: [],
            approvalSet: [],
            selectMember: [],
            fileData: [],
            rowData: {},
            approval: []
        }
    },
    created() {
        this.updateUrl = baseUrl
        this.rowData = this.$route.params
        this.rowData.isEidte == 0 ? this.isEdite = true : this.isEdite = false
        this.fileList[0].no = this.rowData.nhcbjyCertNo
        this.fileList[0].date = this.rowData.nhcbjyCertExp
        this.fileList[0].states = this.rowData.nhcbjyCertStates
        this.fileList[0].file = this.rowData.nhcbjyCertFile
        this.fileList[0].Intro = this.rowData.nhcbjyCertIntro
        this.fileList[1].no = this.rowData.cbsyqCertNo
        this.fileList[1].date = this.rowData.cbsyqCertExp
        this.fileList[1].states = this.rowData.cbsyqCertStates
        this.fileList[1].file = this.rowData.cbsyqCertFile
        this.fileList[1].Intro = this.rowData.cbsyqCertIntro
        this.fileList[2].no = this.rowData.aqglCertNo
        this.fileList[2].date = this.rowData.aqglCertExp
        this.fileList[2].states = this.rowData.aqglCertStates
        this.fileList[2].file = this.rowData.aqglCertFile
        this.fileList[2].Intro = this.rowData.aqglCertIntro
        this.fileList[3].no = this.rowData.cbzdaqypyCertNo
        this.fileList[3].date = this.rowData.cbzdaqypyCertExp
        this.fileList[3].states = this.rowData.cbzdaqypyCertStates
        this.fileList[3].file = this.rowData.cbzdaqypyCertFile
        this.fileList[3].Intro = this.rowData.cbzdaqypyCertIntro
        this.fileList[4].no = this.rowData.cbyyyszjnshgzCertNo
        this.fileList[4].date = this.rowData.cbyyyszjnshgzCertExp
        this.fileList[4].states = this.rowData.cbyyyszjnshgzCertStates
        this.fileList[4].file = this.rowData.cbyyyszjnshgzCertFile
        this.fileList[4].Intro = this.rowData.cbyyyszjnshgzCertIntro
        this.fileList[5].no = this.rowData.yhnhcbbxdCertNo
        this.fileList[5].date = this.rowData.yhnhcbbxdCertExp
        this.fileList[5].states = this.rowData.yhnhcbbxdCertStates
        this.fileList[5].file = this.rowData.yhnhcbbxdCertFile
        this.fileList[5].Intro = this.rowData.yhnhcbbxdCertIntro
        this.fileList[6].no = this.rowData.yhnhcbbxdCertNo1
        this.fileList[6].date = this.rowData.yhnhcbbxdCertExp1
        this.fileList[6].states = this.rowData.yhnhcbbxdCertStates1
        this.fileList[6].file = this.rowData.yhnhcbbxdCertFile1
        this.fileList[6].Intro = this.rowData.yhnhcbbxdCertIntro1
        this.fileList[7].no = this.rowData.yhnhcbbxdCertNo2
        this.fileList[7].date = this.rowData.yhnhcbbxdCertExp2
        this.fileList[7].states = this.rowData.yhnhcbbxdCertStates2
        this.fileList[7].file = this.rowData.yhnhcbbxdCertFile2
        this.fileList[7].Intro = this.rowData.yhnhcbbxdCertIntro2
        this.fileList[8].no = this.rowData.gjcbcrjjszjdCertNo
        this.fileList[8].date = this.rowData.gjcbcrjjszjdCertExp
        this.fileList[8].states = this.rowData.gjcbcrjjszjdCertStates
        this.fileList[8].file = this.rowData.gjcbcrjjszjdCertFile
        this.fileList[8].Intro = this.rowData.gjcbcrjjszjdCertIntro
        this.fileList[9].no = this.rowData.gnhxcbrjCertNo
        this.fileList[9].date = this.rowData.gnhxcbrjCertExp
        this.fileList[9].states = this.rowData.gnhxcbrjCertStates
        this.fileList[9].file = this.rowData.gnhxcbrjCertFile
        this.fileList[9].Intro = this.rowData.gnhxcbrjCertIntro
        if (this.rowData?.id) {
            this.getApproval()
            this.getListCrewl()
            this.getListWatch()
            this.getType()
            this.getShipJob()
        }
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    mounted() {

    },
    methods: {
        deleterowMonitorp(row) {
            this.$confirm('确定删除该监控吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.CarriersShip_shipWatch_delete(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getListWatch()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerowMonitor(row) {
            this.isAddmonitor = false
            this.createDataMonitor = {
                id: row.id,
                title: row.title,
                ipport: row.ipport,
                ipaddr: row.ipaddr,
            }
            this.dialogVisible2 = true
        },
        subRefuMonitor() {
            this.$refs['formmonitor'].validate((valid) => {
                if (valid) {
                    let data = this.createDataMonitor
                    if (this.isAddmonitor) delete data.id
                    data.shipId = this.rowData.id
                    this.$Api.CarriersShip_saveShipWatch(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.dialogVisible2 = false
                            this.getListWatch()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        addRoleMonitor() {
            this.isAddmonitor = true
            this.createDataMonitor = {
                id: '',
                title: '',
                shipId: '',
                ipport: '',
                ipaddr: '',
            }
            this.dialogVisible2 = true
        },
        subRefuShipRole() {
            this.$refs['formShipRole'].validate((valid) => {
                if (valid) {
                    let data = this.createDataShipRole
                    data.shipId = this.rowData.id
                    this.$Api.CarriersShip_saveShipCrew(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.dialogVisible = false
                            this.getListCrewl()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        addRoleShip() {
            this.isAddShip = true
            this.createDataShipRole = {
                birthday: '',
                competentCertExp: '',
                competentCertFile: '',
                competentCertNo: '',
                job: '',
                oilCertExp: '',
                oilCertFile: '',
                oilCertNo: '',
                shipId: '',
                title: ''
            }
            this.dialogVisible = true
        },
        editerowShip(row) {
            this.isAddShip = false
            this.createDataShipRole = {
                birthday: row.birthday ? row.birthday.substring(0, 10) : '',
                competentCertExp: row.competentCertExp ? row.competentCertExp.substring(0, 10) : '',
                competentCertFile: row.competentCertFile,
                competentCertNo: row.competentCertNo,
                oilCertExp: row.oilCertExp ? row.oilCertExp.substring(0, 10) : '',
                oilCertFile: row.oilCertFile,
                oilCertNo: row.oilCertNo,
                title: row.title,
                job: '',
                id: row.id
            }
            if (this.shipJob.length > 0) {
                this.shipJob.map(item => {
                    if (item.name == row.job) {
                        this.createDataShipRole.job = item.id
                    }
                })
            }
            this.dialogVisible = true
        },
        deleterowShip(row) {
            this.$confirm('确定删除该船员吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.CarriersShip_shipCrew_delete(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getListCrewl()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        looklog(row) {
            let data = {
                id: row.id,
                type: 2
            }
            this.$Api.MyAppRovalPublic_LogList(data).then(res => {
                let level = ['未知', '一级', '二级', '三级'];
                if (res.data.logList.length != 0) {
                    res.data.logList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                    })
                }
                if (res.data.outcomeList.length != 0) {
                    res.data.outcomeList.map(item => {
                        res.data.userList.map(key => {
                            if (item.user_id == key.id) {
                                item.username = key.title
                            }
                        })
                        if (this.myapprovalStatesList.length > 0) {
                            this.myapprovalStatesList.map((key, kindex) => {
                                if (item.user_outcome == kindex) {
                                    item.user_outcome_text = key
                                }
                            })
                        }
                        item.aprv_level_text = level[item.aprv_level]
                    })
                }
                res.data.logList = res.data.logList
                this.logData = res.data
                this.dialogVisible3 = true;
            })
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/carrier/shipManageDetails')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        submit() {
            this.$refs['formShip'].validate((valid) => {
                if (valid) {
                    let data = {
                        id: this.rowData.id,
                        ShipHomePort: this.rowData.ShipHomePort,
                        ShipInspectionNO: this.rowData.ShipInspectionNO,
                        acode: this.rowData.acode,
                        ais: this.rowData.ais,
                        aqglCertExp: this.fileList[2].date,
                        aqglCertFile: this.fileList[2].file,
                        aqglCertIntro: this.fileList[2].Intro,
                        aqglCertNo: this.fileList[2].no,
                        canCrossBam: this.rowData.canCrossBam,
                        capacity: this.rowData.capacity,
                        cbsyqCertExp: this.fileList[1].date,
                        cbsyqCertFile: this.fileList[1].file,
                        cbsyqCertIntro: this.fileList[1].Intro,
                        cbsyqCertNo: this.fileList[1].no,
                        cbyyyszjnshgzCertExp: this.fileList[4].date,
                        cbyyyszjnshgzCertFile: this.fileList[4].file,
                        cbyyyszjnshgzCertIntro: this.fileList[4].Intro,
                        cbyyyszjnshgzCertNo: this.fileList[4].no,
                        cbzdaqypyCertExp: this.fileList[3].date,
                        cbzdaqypyCertFile: this.fileList[3].file,
                        cbzdaqypyCertIntro: this.fileList[3].Intro,
                        cbzdaqypyCertNo: this.fileList[3].no,
                        gjcbcrjjszjdCertExp: this.fileList[8].date,
                        gjcbcrjjszjdCertFile: this.fileList[8].file,
                        gjcbcrjjszjdCertIntro: this.fileList[8].Intro,
                        gjcbcrjjszjdCertNo: this.fileList[8].no,
                        gnhxcbrjCertExp: this.fileList[9].date,
                        gnhxcbrjCertFile: this.fileList[9].file,
                        gnhxcbrjCertIntro: this.fileList[9].Intro,
                        gnhxcbrjCertNo: this.fileList[9].no,
                        maxCapacity: this.rowData.maxCapacity,
                        maxHeight: this.rowData.maxHeight,
                        maxWater: this.rowData.maxWater,
                        maxWidth: this.rowData.maxWidth,
                        nhcbjyCertExp: this.fileList[0].date,
                        nhcbjyCertFile: this.fileList[0].file,
                        nhcbjyCertIntro: this.fileList[0].Intro,
                        nhcbjyCertNo: this.fileList[0].no,
                        shipBuildDate: this.rowData.shipBuildDate,
                        shipType: this.rowData.shipType,
                        shipsize: this.rowData.shipsize,
                        title: this.rowData.title,
                        yhnhcbbxdCertExp: this.fileList[5].date,
                        yhnhcbbxdCertExp1: this.fileList[6].date,
                        yhnhcbbxdCertExp2: this.fileList[7].date,
                        yhnhcbbxdCertFile: this.fileList[5].file,
                        yhnhcbbxdCertFile1: this.fileList[6].file,
                        yhnhcbbxdCertFile2: this.fileList[7].file,
                        yhnhcbbxdCertIntro: this.fileList[5].Intro,
                        yhnhcbbxdCertIntro1: this.fileList[6].Intro,
                        yhnhcbbxdCertIntro2: this.fileList[7].Intro,
                        yhnhcbbxdCertNo: this.fileList[5].no,
                        yhnhcbbxdCertNo1: this.fileList[6].no,
                        yhnhcbbxdCertNo2: this.fileList[7].no,
                        carriersId: this.rowData.carriers.id,
                        groupId: this.rowData.groupId
                    }
                    this.$Api.CarriersShip_saveShip(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            // this.dialogVisible = false
                            // this.getData()
                        }
                    })
                } else {
                    this.$message.warning('存在必填项未填写！')
                    return false;
                }
            });
        },
        cxupload(index) {
            this.cxIndex = index
        },
        beforeUpload() {
            this.cxIndex = ''
            this.fileLoading = true
        },
        beforeUpload2() {
            this.fileLoading = true
            // this.showReady = false
        },
        beforeUpload3() {
            this.shipRoleFileLoading1 = true
        },
        beforeUpload4() {
            this.shipRoleFileLoading2 = true
        },
        onsuccessShiprole1(response) {
            if (response.code == 200) {
                this.createDataShipRole.competentCertFile = response.data.save_path
                this.showReady = true
            }
            this.shipRoleFileLoading1 = false
        },
        onsuccessShiprole2(response) {
            if (response.code == 200) {
                this.createDataShipRole.oilCertFile = response.data.save_path
                this.showReady = true
            }
            this.shipRoleFileLoading2 = false
        },
        onsuccess(response, file, fileList) {
            if (response.code == 200) {
                this.fileList[this.cxIndex].file = response.data.save_path
                this.showReady = true
            }
            this.fileLoading = false
        },
        handleError(err, file, fileList) {
            this.$message.error('上传失败，请重新上传！')
            this.fileLoading = false
            this.showReady = true
        },
        approvaChange(e) {
            this.approvalSet.map(item => {
                if (e == item.id) {
                    this.flowPathContent = item
                }
            })
        },
        clearCreateData() {
            this.createdData = {
                id: "",
                title: '',
                type: 15,
                user1: [],
                user2: [],
                user3: []
            }
            this.$refs['form'].resetFields();
        },
        deleterow(row, index) {
            this.$confirm('是否确定删除该文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fileList.splice(index, 1);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // --------------------------公共方法--------------------------------------
        getApproval() {
            let data = {
                shipId: this.rowData.id
            }
            this.$Api.CarriersShip_GetApproval(data).then(res => {
                this.approval = res.data
                if (res.data.length > 0) {
                    res.data.map(item => {
                        this.fileList.map(key => {
                            if (item.fieldName == key.stetesName) {
                                key.isLog = true
                            }
                        })
                    })
                }
            })
        },
        getType() {
            this.$Api.myAppRovalPublicDictState().then(res => {
                if (res.code == 200) {
                    this.myapprovalStatesList = res.data.myapprovalStatesList
                }
            })
        },
        getListCrewl() {
            let data = {
                shipId: this.rowData.id,
                states: 1,
                limit: 999
            }
            this.shipRoleLoading = true
            this.$Api.CarriersShip_getListCrewl(data).then(res => {
                this.shipRoleLoading = false
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.map(item => {
                            item.birthday = item.birthday ? item.birthday.substring(0, 10) : ''
                            item.competentCertExp = item.competentCertExp ? item.competentCertExp.substring(0, 10) : ''
                            item.oilCertExp = item.oilCertExp ? item.oilCertExp.substring(0, 10) : ''
                        })
                        this.shipTabData = res.data.data
                    }

                }
            })
        },
        getShipJob() {
            this.$Api.CarriersShip_crewJob().then(res => {
                if (res.code == 200) {
                    let jobs = []
                    if (Object.keys(res.data).length > 0) {
                        for (let key in res.data) {
                            jobs.push(res.data[key])
                        }
                    }
                    this.shipJob = jobs
                }
            })
        },
        getListWatch() {
            let data = {
                shipId: this.rowData.id,
                states: 1,
                limit: 999
            }
            this.monitorLoading = true
            this.$Api.CarriersShip_getListWatch(data).then(res => {
                this.monitorLoading = false
                if (res.code == 200) {
                    this.monitorTabData = res.data.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.shipManage_Details {
    height: initial;

    .el-page-header {
        line-height: 50px;
    }

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .formBox {
            width: 100%;
            padding: 0 10px;
            margin-top: 10px;

            .item {
                width: 100%;
                display: flex;
                margin-bottom: 15px;

                .itemHead {
                    width: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FBFBFB;
                    border: 1px solid #DCDFE6;
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    height: 40px;
                    line-height: 40px;
                }

                .itemContent {
                    width: 1;
                    flex: 1;

                    /deep/.el-input__inner {
                        width: 100%;
                        border-radius: 0 4px 4px 0;
                    }

                    /deep/.el-select {
                        width: 100%;
                    }

                    /deep/.el-cascader {
                        width: 100%;
                    }

                    .flowPath {
                        width: 100%;
                        padding: 0 15px;

                        .text {
                            line-height: 24px;
                            color: #808080;
                        }
                    }
                }
            }
        }

        .serverHead {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 15px;
            background-color: #FBFBFB;
            border: 1px solid #DCDFE6;
            border-radius: 4px 0 0 4px;
            height: 40px;
            line-height: 40px;
        }

        /deep/.el-divider__text.is-left {
            font-size: 20px;

        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }




    }

    .dialogCont {
        height: 50vh;
        overflow-y: scroll;

        /deep/.el-steps--vertical {
            height: initial;
        }
    }

    /deep/.el-divider__text.is-left {
        font-size: 20px;
        font-weight: 500;
    }
}
</style>