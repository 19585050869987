<template>
    <div class="page PaymentDetailedList">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form">
                <el-row >
                    <el-col :span="7">
                        <el-form-item label="时间范围：">
                            <el-date-picker v-model="searchForm.dateResult" type="daterange" size="small" range-separator="-"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="打印状态：">
                            <el-select v-model="searchForm.is_hid" placeholder="请选择打印状态" clearable size="small">
                                <el-option label="已处理" value="1" key="1"></el-option>
                                <el-option label="已财务制证" value="2" key="2"></el-option>
                                <el-option label="已办结" value="3" key="3"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                                @click="searchTable('search')">搜索
                            </el-button>
                            <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                                @click="searchTable('reset')">重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-printer" size="small" type="primary" @click="addRole">打印付款清单</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-back" size="small" type="primary" @click="addRole">回退付款清单</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-s-grid" size="small" type="primary" @click="addRole">历史下载记录</el-button>
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                @selection-change="handleSelectionChange" height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column
                align="center"
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column align="center" label="清单号" prop="id" fixed="left" width="150">
                </el-table-column>
                <el-table-column align="center" label="序号" prop="add_time">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="发货日期" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="承运商" prop="book_title" width="200">
                </el-table-column>
                <el-table-column align="center" label="船名" prop="book_title" width="200">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="book_title" width="150">
                </el-table-column>
                <el-table-column align="center" label="发站" prop="book_title" width="250">
                </el-table-column>
                <el-table-column align="center" label="到站" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="原价" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="当前单价" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="book_title" width="100">
                </el-table-column>
                <el-table-column align="center" label="运杂费" prop="book_title" width="200">
                </el-table-column>
                <el-table-column align="center" label="回执单数" prop="book_title" width="200">
                </el-table-column>
                
                <el-table-column align="center" label="运费状态" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="处理时间" prop="book_title">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">查看明细</el-button>
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">查看发票</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PaymentDetailedList",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                name: '',
                is_hid: [],
                dateResult: '',
                startTime: '',
                endtime: '',
                month: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    methods: {
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        handleClick(tab) {
            switch (tab.name) {
                case 'first':
                    this.getData()
                    break;
                case 'second':

                    break;
                case 'third':

                    break;
                default:
                    break;
            }
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            console.log(`每页 ${val} 条`);
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                branch_office_id: 10,
                book_title: '',
                book_zddm: '',
                supplier_id: '',
                head_office_apply_status: '',
                page: 1
            }
            this.tableLoading = true
            this.$http.get('/backend/struct_order/list', {
                params: data
            }).then(res => {
                this.tableLoading = false
                if (res.code == 1) {
                    if (res.data.list.total) this.page.total = res.data.list.total;
                    if (res.data.list.last_page) this.page.lastPage = res.data.list.last_page
                    this.tableData = res.data.list.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.PaymentDetailedList {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        // height: 800px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }
        // .el-table{
        //     height: 800px;
        // }

    }
}
</style>