<template>
    <div class="page CompletedExamine">
       <div class="container">
        <div class="operateBox">
          <div class="left">
            <el-button class="u-custom-btn-primary" icon="el-icon-check" size="small" type="primary" @click="addRole">批量通过</el-button>
            <el-button class="u-custom-btn-primary" icon="el-icon-close" size="small" type="danger" @click="addRole">批量拒绝</el-button>
          </div>
          <div class="right">
          </div>
        </div>
          <el-table  ref="multipleTable" border v-loading="tableLoading" :data="tableData"  tooltip-effect="dark"
             height="(100%-250px)" max-height="(100%-250px)"
             @selection-change="handleSelectionChange"
             style="width: 98%;margin:0 auto;" 
              header-row-class-name="header-table" >
              <el-table-column
                align="center"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column align="center" label="类型" prop="id">
              </el-table-column>
              <el-table-column align="center" label="标题" prop="id">
              </el-table-column>
              <el-table-column align="center" label="审核状态" prop="book_title" >
              </el-table-column>
              <el-table-column align="center" label="我的审核" prop="book_title" >
              </el-table-column>
              <el-table-column align="center" label="创建时间" prop="add_time" >
              </el-table-column>
              <el-table-column align="center" label="操作" width="180" fixed="right">
                  <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
                      <el-button type="danger" size="mini"  @click="deleterow(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
         <!-- 分页 -->
          <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
              <el-pagination 
                  :page-sizes="[10, 15, 20, 25,50,100]"
                  :current-page="page.currentPage" 
                  :page-size="page.pageSize" 
                  :total="page.total"
                  @current-change="changePage"
                  @size-change="handleSizeChange"
                  layout="total,sizes ,prev, pager, next, jumper" >
              </el-pagination>
          </div>
       </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CompletedExamine",
    data() {
      return {
        multipleSelection:[],
        searchForm: {
          name: '',
          is_hid: [],
        },
        tableLoading:false,
        page: {
            currentPage: 1,
            pageSize: 15,
            total: 0,
            lastPage: 1,
        },
        tableData:[],
        activeName: 'fourth'
      }
    },
    created(){
      let that = this
      document.onkeydown = function(e) {
        var key = e.keyCode;
        if (key == 13) {
          that.searchTable('search')
        }
      }
    },
    activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
      addRole(){
        console.log('添加');
      },
      handleSelectionChange(){
        this.multipleSelection = val;
      },
      editerow(){
        console.log('editerow');
      },
      deleterow(){
  
      },
      handleClick(tab) {
        switch (tab.name) {
          case 'first':
            this.getData()
            break;
          case 'second':
            
            break;
          case 'third':
            
            break;
          default:
            break;
        }
      },
      // ----------------------------------------------------------------
      handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
      changePage(val) { //选择第几页
          this.page.currentPage = val;
          this.getData()
      },
      searchTable(t) { // 搜索/重置
        if (t === 'search') {
          this.page.currentPage = 1
          this.getData()
        } else {
          for (const key in this.searchForm) {
            if(this.searchForm[key] instanceof Array){
              this.searchForm[key] = []
            }else{
              this.searchForm[key] = ''
            }
          }
          this.getData()
        }
      },
      getData(){ // 获取数据
          let data = {
            branch_office_id:  10,
            book_title: '',
            book_zddm: '',
            supplier_id: '',
            head_office_apply_status: '',
            page:  1
          }
          this.tableLoading = true
          this.$http.get('/backend/struct_order/list',{
              params:data
          }).then(res=>{
              this.tableLoading = false
              if (res.code == 1) {
                  if (res.data.list.total) this.page.total = res.data.list.total;
                  if(res.data.list.last_page) this.page.lastPage = res.data.list.last_page
                  this.tableData = res.data.list.data
              }
          })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .CompletedExamine{
      height: inherit;
    .searchBox {
        /deep/.el-table .el-table__cell{
            padding: 4px 0;
        }
    }
      .container{
        width: 100%;
        // height: 800px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        ::-webkit-scrollbar {  
            width: 6px;  
        }
        ::-webkit-scrollbar-track {  
            background: #f1f1f1; 
            width: 6px; 
        }
        ::-webkit-scrollbar-thumb {  
            background: rgb(214,216,219);
            border-radius: 4px;  
        }
          // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }
        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }
       .operateBox{
        margin: 0px auto 10px;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
        }
        .right{
          display: flex;
          align-items: center;
          .item{
            margin-left: 10px;
            /deep/.el-checkbox-group{
                display: flex;
                flex-direction: column;
                padding: 5px 10px;
            }
            }
        }
       }
        .tip{
          width: 100%;
          height: 42px;
          line-height: 42px;
          padding: 0 15px;
          border-bottom: 1px solid #f6f6f6;
          color: #333;
          border-radius: 2px 2px 0 0;
          font-size: 14px;
          display: flex;
          align-items: center;
          i{
            color: #1e9fff;
            font-size: 18px;
            margin-right: 6px;
          }
        }
        
      }
      .tabsBox{
        width: 98%;
        margin: 10px auto;
      }
    }
  </style>