<template>
	<div class="page">
		<div class="big-box">
			<div class="login-box">
				<div class="login-top">
					<div class="title">用户登录</div>
					<div class="bg1"></div>
					<div class="bg2"></div>
				</div>
				<div class="login-form">
					<div class="login-warp">
						<div class="input-box">
							<span class="icon icon-2"></span>
							<input autocomplete="new-password" placeholder="请输入用户名" v-on:keyup.enter="sub" type="text"
								v-model="form.user_name">
						</div>
						<div class="input-box">
							<span class="icon icon-3"></span>
							<input :type="t" autocomplete="new-password" placeholder="请输入密码" v-on:keyup.enter="sub"
								v-model="form.password">
							<img alt="" class="eye" src="@/assets/img/eye.png" @click="showPwd" v-show="t == 'text'">
							<img alt="" class="eye" src="@/assets/img/eye1.png" @click="showPwd" v-show="t == 'password'">
						</div>
						<div class="input-box" style="border: none;">
							<el-checkbox v-model="isRemenber" size="medium ">记住密码</el-checkbox>
						</div>
						<el-button :loading="loading" class="u-custom-btn-primary u-m-t-40" type="primary"
							v-on:keyup.enter="sub" @click="sub">立即登录</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="footerBox">
			©版权所有 2023 <span class="padding-5">|</span><a target="_blank" href="https://beian.miit.gov.cn">粤ICP备XXXXXX号</a>
		</div> -->
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
export default {
	name: "login",
	data() {
		let checkMobile = function (rule, value, callback) {
			let reg = /^((\+|00)86)?1\d{10}$/
			if (!value) return callback(new Error('手机号不能为空'))
			if (!reg.test(value)) return callback(new Error('手机号格式不符合规范'))
			callback()
		}
		let checkPwd = function (rule, value, callback) {
			if (!value) return callback(new Error('密码不能为空'))
			if (value.length < 6) return callback(new Error('密码长度不能低于6位数字'))
			callback()
		}
		let checkRePwd = (rule, value, callback) => {
			if (!value) return callback(new Error('确认密码不能为空'))
			if (value.length < 6) return callback(new Error('确认密码长度不能低于6位数字'))
			if (value !== this.ruleForm.password) return callback(new Error('确认密码与密码不一致'))
			callback()
		}

		return {
			t: 'password',
			show: false,
			isRemenber: false,
			form: {
				user_name: '',
				password: '',
				verify: '',
				unique_id: '',
				province_id: '',
				city_id: '',
				area_id: '',
			},
			ruleForm: {
				mobile: '',
				code: '',
				password: '',
				rePassword: ''
			},
			rules: {
				mobile: [{
					required: true,
					validator: checkMobile,
					trigger: ['blur', 'change']
				}],
				code: [{
					required: true,
					message: '验证码不能为空',
					trigger: ['blur', 'change']
				}],
				password: [{
					required: true,
					validator: checkPwd,
					trigger: ['blur', 'change']
				}],
				rePassword: [{
					required: true,
					validator: checkRePwd,
					trigger: ['blur', 'change']
				}]
			},
			loading: false,

			imgInfo: {
				image: '',
				unique_id: ''
			},
			code: '',
			options: [],
			area: [],
			props: {
				value: "id",
				label: "title", // 这里的label表示这是Cascader的默认label属性
				children: "child"
			},
			redirect_uri: '',
			appid: 'dingmucgebyrq9bykr0v',
		}
	},
	created() {
		let url = new URL(window.document.URL); // 获取路径
		this.redirect_uri = encodeURIComponent(url.origin)
		if (url.search) {
			this.code = url.search.split('authCode=')[1].split('&')[0]
			console.log(this.code);
		}
		

		const user_name = Cookies.get('user_name') ? Cookies.get('user_name') : '';
		const enPassword = Cookies.get('password') ? Cookies.get('password') : '';
		if (enPassword) {
			// 对密码进行解密
			this.form.user_name = user_name;
			this.form.password = CryptoJS.AES.decrypt(enPassword, 'user123!').toString(CryptoJS.enc.Utf8);
			// 将是否记住密码置为true
			this.isRemenber = true;
		} else {
			this.form.password = '';
		}
	},
	methods: {
		sub() {
			if (!this.form.user_name) {
				this.$message.error('用户名不能为空')
				return
			}
			if (!this.form.password) {
				this.$message.error('密码不能为空')
				return
			}
			this.loading = true
			let data = {
				account:this.form.user_name,
				pwd:this.form.password
			}
			this.$Api.login(data).then(res=>{
				if (res.code === 200) {
					this.$message.success('登录成功')
					this.$store.commit('setToken', res.data.token.token)
					this.$store.dispatch('updateUserInfo')
					this.$store.dispatch('initMenu').then(()=>{ //获取菜单
						// setTimeout(() => {
							if (this.$route.query.redirect) {
								this.$router.replace(this.$route.query.redirect)
							} else {
								this.$router.replace({path:'/home'})
							}
						// }, 500)
					})
					// 登录成功后 判断是否选择了勾选密码
					if (this.isRemenber) {
						//添加cookie
						Cookies.set('user_name', this.form.user_name, {
							expires: 30
						})
						//使用crypto-js进行加密（需要npm加载后引入） 并存储到cookie中 此处user123! 为秘钥 
						Cookies.set('password', CryptoJS.AES.encrypt(this.form.password, 'user123!'), {
							expires: 30 // 存储30天
						})
					} else {
						// 删除cookie
						Cookies.remove('user_name')
						Cookies.remove('password')
					}
					
				}else{
					this.loading = false
				}
				if (res.code === 0) {
					this.form.verify = ''
				}
				this.loading = false
			}).catch(err=>{
				this.loading = false
			})
		},
		
		changePassword() {
			this.$refs.ruleForm.validate(b => {
				if (b) {

				}
			})
		},
		reset() {
			this.$refs.ruleForm.resetFields()
		},
		showPwd() {
			this.t = this.t === 'password' ? 'text' : 'password'
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	height: 100vh;
	background: url("../../assets/img/loginbg.png") no-repeat center/cover;
	position: relative;
	/deep/.el-button--primary{
		background-color: #409EFF !important;
		border-color: #409EFF !important;
	}
	/deep/.el-button--primary:hover {
		background-color: #409EFF;
		border-color: #409EFF;
		opacity: 0.8;
		color: #FFF;
	}
}
.input-backup{
  position: absolute;
  left: 0;
  pointer-events: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  outline: 0;
  padding: 0 15px;
  font-family: monospace;
  font-size: 16px;
}
.input-original{
	height: 60px;
	::v-deep .el-input__inner{
		height:60px;
	}
	::v-deep .el-input__inner::placeholder{
		font-family: Helvetica Neue;
	}
	::v-deep .el-input__inner::selection{
    color: white;
  }
}
.input-original{
  height: 60px !important;
  color: white;
  caret-color: #606266;
  font-family: monospace;
  &::placeholder {
    font-family: Helvetica Neue;
  }
  &::selection{
    color: white;
  }
}
.w350{
  width: 350px;
}

.big-box {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	overflow: hidden;
	.img-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.img-title {
			width: 130px;
			height: 36px;
			background-image: url(../../assets/img/login_bg.png);
			background-size: cover;
			background-repeat: no-repeat;
			color: #F15353;
			line-height: 36px;
			text-align: center;
		}

		.icon-login {
			width: 46px;
			height: 46px;
			cursor: pointer;
		}
	}

	.login-box {
		width: 428px;
		position: relative;
		.login-top{
			height: 117px;
			background-color: #148be4;
			border-radius: 12px 12px 0 0;
			font-family: SourceHanSansCN-Regular;
			font-size: 30px;
			font-weight: 400;
			font-stretch: normal;
			letter-spacing: 0;
			color: #fff;
			line-height: 117px;
			text-align: center;
			overflow: hidden;
		}
		.bg1{
			display: inline-block;
			width: 74px;
			height: 74px;
			background: #fff;
			opacity: .1;
			border-radius: 0 74px 0 0;
			position: absolute;
			left: 0;
			top: 43px;
		}
		.bg2{
			display: inline-block;
			width: 94px;
			height: 94px;
			background: #fff;
			opacity: .1;
			border-radius: 50%;
			position: absolute;
			right: -16px;
			top: -16px;
		}
		.login-form{
			width: 428px;
			background: #fff;
			border-radius: 0 0 12px 12px;
			padding-bottom: 10px;
		}

		.login-header {}

		.login-warp {
			width: 288px;
			margin: 0 auto;
			padding-top: 40px;
			padding-bottom: 15px;
			position: relative;
			.input-box {
				width: 288px;
				height: 35px;
				border-bottom: 2px solid #dae1e6;
				margin-bottom: 35px;
				position: relative;
				/deep/.el-cascader {
					width: 100%;
					height: 60px;

					.el-input {
						width: 100%;
						height: 22px;

						.el-input__inner {
							height: 100%;
							line-height: 22px;
							height: 22px;
						}
					}
				}

				~.input-box {
					margin-top: 24px;
				}

				input {
					display: inline-block;
					width: 227px;
					height: 22px;
					padding: 0;
					position: absolute;
					border: 0;
					outline: 0;
					font-size: 14px;
					letter-spacing: 0;
				}

				input::placeholder {
					color: #b3b3b3;
				}



				.pre-icon {
					position: absolute;
					left: 12px;
					top: 50%;
					transform: translateY(-50%);
					width: 30px;
					height: 30px;
				}

				.eye {
					position: absolute;
					right: 14px;
					top: 2
					px;
					cursor: pointer;
				}

				.code-img {
					position: absolute;
					right: 12px;
					top: 8px;
					cursor: pointer;
					width: 180px;
					height: 44px;
					background-color: #f7f7f7;
					line-height: 44px;
					text-align: center;
				}
				.icon{
					display: inline-block;
					width: 33px;
					height: 22px;
				}
				.icon-2{
					background: url(../../assets/img/icon-login.png) no-repeat -54px 0;
				}
				.icon-3{
					background: url(../../assets/img/icon-login.png) no-repeat -106px 0;
				}
			}

			.forget {
				color: #409EFF;
				margin-top: 12px;
				cursor: pointer;
			}

			.el-button {
				width: 288px;
				height: 48px;
				margin: 16px auto;
				display: block;
			}
		}
	}
	
}
.footerBox{
		left: 0;
		bottom: 0;
		color: #fff;
		width: 100%;
		position: absolute;
		text-align: center;
		line-height: 30px;
		padding-bottom: 10px;
		text-shadow: #000 0.1em 0.1em 0.1em;
		font-size: 14px;
		span{
			color: #FFF;
		}
		.padding-5{
			padding: 5px !important;
		}
		a{
			color: #FFF;
		}
	}
</style>
