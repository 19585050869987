import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import T from "./utils/Tmapapi"
// Vue.use(T)
Vue.use(ElementUI)
import mixin from './utils/mixin'
Vue.mixin(mixin)
import axios from "./utils/request"
Vue.prototype.$http = axios
import api from "./api/api"
Vue.prototype.$Api = api

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );
import * as echarts from "echarts"
Vue.prototype.$echarts = echarts

import treeSelect from "@/components/treeSelect"
Vue.component('treeSelect',treeSelect)

Vue.directive('permission', {  
  inserted: function (el, binding) {  
    // 检查权限  
    const hasPermission = checkPermission(binding.value);  
  
    // 根据权限控制按钮的显示或隐藏  
    if (!hasPermission) {  
      el.style.display = 'none';  
    }  
  }  
});
var currRoutePath = ''
function mapMnuList(list){
  let reuslt = null
  list.map(item=>{
    if(item.href == currRoutePath){
      reuslt = item.rbutton
    }else{
      
    }
  })
  return reuslt
}
// 假设的权限检查函数  
function checkPermission(permission) {
  let vuex = localStorage.getItem('vuex')
  let menuList = JSON.parse(vuex).menuList
  console.log(router,'routerpermission',permission,menuList);
  currRoutePath = router.app._route.path
  if(menuList.length > 0){
    let rbuttons = mapMnuList(menuList)
    console.log(rbuttons,'rbuttons');
  }
  return true; // 或者 false，根据你的权限检查逻辑而定  
}

import qs from 'qs'
Vue.prototype.$qs = qs
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
