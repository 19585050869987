<template>
    <div class="page ShipPositionCount">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="承运商：">
                    <el-select clearable v-model="searchForm.carrierList_new" placeholder="请选择承运商" size="small">
                        <el-option v-for="item in dict.carrierList" :value="item.id" :label="item.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="船舶：">
                    <el-select clearable v-model="searchForm.shipList_new" placeholder="请选择船舶" size="small">
                        <el-option v-for="item in dict.shipList" :value="item.id" :label="item.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">查询
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="船名" prop="title">
                </el-table-column>
                <el-table-column align="center" label="吨位" prop="gjys">
                    <el-table-column align="center" label="汽油" prop="tonnage1">
                    </el-table-column>
                    <el-table-column align="center" label="柴油" prop="tonnage2">
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="船位" prop="site">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="state">
                </el-table-column>
                <el-table-column align="center" label="本载计划" prop="planNowLine" width="200">
                    <el-table-column align="center" label="航线" prop="planNowLine">
                    </el-table-column>
                    <el-table-column align="center" label="装运品种" prop="planNowOilType">
                    </el-table-column>
                    <el-table-column align="center" label="计划属性" prop="planNowType">
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="上航次计划" prop="bz" width="200">
                    <el-table-column align="center" label="航线" prop="planPrevLine">
                    </el-table-column>
                    <el-table-column align="center" label="装运品种" prop="planPrevOilType">
                    </el-table-column>
                    <el-table-column align="center" label="计划属性" prop="planPrevType">
                    </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="是否有检修计划" prop="repairInfo">
                </el-table-column>
                <el-table-column align="center" label="是否有外出安排" prop="goOutInfo">
                </el-table-column>
                <el-table-column align="center" label="备注" prop="remarks">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "ShipPositionCount",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                carrierList_new: '',
                shipList_new: ''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            dict:{
                shipList:[],
                carrierList:[]
            }
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getDict()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        getDict() {
            this.$Api.shipPlan_Dict().then(res => {
                if (res.code == 200) {
                    this.dict.carrierList = res.data.carrierList
                    this.dict.shipList = res.data.shipList
                }
                this.getData()
            })
        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange() {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        handleClick(tab) {
            switch (tab.name) {
                case 'first':
                    this.getData()
                    break;
                case 'second':

                    break;
                case 'third':

                    break;
                default:
                    break;
            }
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getData() { // 获取数据
            let data = {
                carrierList_new: this.searchForm.carrierList_new,
                shipList_new: this.searchForm.shipList_new,
            }
            this.tableLoading = true
            this.$Api.report_view_getShipSiteTable(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.tableData = res.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.ShipPositionCount {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .searchBox::before {
        content: '操作面板';
        display: block;
        background-color: #FFF;
        position: absolute;
        top: -10px;
        font-size: 14px;
        color: #6b6b6b;
        left: 30px;
        z-index: 2;
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }
    }
}
</style>