<template>
	<div class="header">
		<div class="navbar u-flex u-col-center">
			<div class=" u-flex u-col-center">
				<div class="collapse" @click="changeNav">
					<el-icon v-if="collapse" class="el-icon-s-unfold"></el-icon>
					<el-icon v-else class="el-icon-s-fold"></el-icon>
				</div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in list">{{ item }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class=" u-flex u-col-center serverBox">
				<el-tooltip class="item" effect="dark" content="刷新页面" placement="bottom">
					<img src="../assets/img/refus.png" class="avatar" @click="refreshFn" :class="refresh ? 'animate' : ''">
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="全屏" placement="bottom" v-if="!fullscreen">
					<img src="../assets/img/exitfullScreen.png" class="serverItem" @click="fullScreen"
						:class="refresh ? 'animate' : ''">
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="退出全屏" placement="bottom" v-else>
					<img src="../assets/img/fullScreen.png" class="serverItem" @click="exitFullscreen"
						:class="refresh ? 'animate' : ''">
				</el-tooltip>
				<el-dropdown placement="bottom" @command="commandClick">
					<div class="user-box u-flex u-col-center">
						<span>{{"【"+user.groupinfo.title+"】"}}{{"【"+user.roleInfo.roleName+"】"}}{{user.usubname }}</span>
						<img src="@/assets/img/down.png">
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="user">个人资料</el-dropdown-item>
							<el-dropdown-item command="logout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</div>
				</el-dropdown>
			</div>
		</div>
		<div class="tags-view">
			<el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container"
				@wheel.native.prevent="handleScroll">
				<div class="tags-view-wrap u-flex u-col-center">
					<el-tabs v-model="editableTabsValue" type="card" @tab-click="tabsChoose">
						<el-tab-pane :key="item.path" v-for="(item, index) in home" :label="item.title" :name="item.path">
						</el-tab-pane>
					</el-tabs>
					<el-tabs v-model="editableTabsValue" type="card" closable @tab-click="tabsChoose"
						@edit="handleTabsEdit">
						<el-tab-pane :key="item.path" v-for="(item, index) in navbar" :label="item.title" :name="item.path">
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-scrollbar>
			<el-dropdown placement="bottom" @command="tabsClick">
				<el-button class="alltabs"><img src="@/assets/img/down.png"></el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="curr">关闭当前</el-dropdown-item>
					<el-dropdown-item command="other">关闭其他</el-dropdown-item>
					<el-dropdown-item command="all">关闭全部</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
import ThemePicker from '@/components/themePicker'
export default {
	name: "uHeader",
	components: {
		ThemePicker: ThemePicker
	},
	data() {
		return {
			list: [],
			uploadUrl: '',
			rolekey: '',
			notice: [],//入库未处理消息列表
			testRole: [],
			testToken: '',
			home: [{ title: '首页', path: '/home' }],
			editableTabsValue: '',
			refresh: false,
			fullscreen: false
		}
	},
	computed: {
		collapse() {
			return this.$store.state.collapse
		},
		navbar() {
			return this.$store.state.$navbar || []
		},
		unReadNum() {
			return this.$store.state.unReadNum || 0
		},
		user() {
			return this.$store.state.userInfo || {}
		},
		scrollWrapper() {
			return this.$refs.scrollContainer.$refs.wrap
		}
	},
	watch: {
		'$route'(val) {
			this.list = this.$route.meta.name ? this.$route.meta.name.split(',') : []
			this.editableTabsValue = this.$route.path
		}
	},
	created() {
		this.uploadUrl = this.$http.defaults.baseURL + '/backend/upload'
		this.list = this.$route.meta.name ? this.$route.meta.name.split(',') : []
		let vuex = localStorage.getItem('vuex')
		this.editableTabsValue = this.$route.path
		let that = this
		window.onresize = function () {
			if (!that.checkFull()) {
				// 退出全屏后要执行的动作
				that.exitFullscreen()
			}
		}
	},
	mounted() {

	},
	methods: {
		fullScreen() {
			//全屏
			var full = document.documentElement;
			this.launchIntoFullscreen(full)
			this.fullscreen = true
		},
		//退出全屏封装
		exitFullscreen() {
			if (document.exitFullscreen)
				document.exitFullscreen()
			else if (document.mozCancelFullScreen)
				document.mozCancelFullScreen()
			else if (document.webkitExitFullscreen)
				document.webkitExitFullscreen()
			this.fullscreen = false
		},
		//全屏封装
		launchIntoFullscreen(element) {
			if (element.requestFullscreen)
				element.requestFullscreen()
			else if (element.mozRequestFullScreen)
				element.mozRequestFullScreen()
			else if (element.webkitRequestFullscreen)
				element.webkitRequestFullscreen()
			else if (element.msRequestFullscreen)
				element.msRequestFullscreen()
		},
		checkFull() {
			//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
			//火狐浏览器//谷歌浏览器及Webkit内核浏览器
			var isFull = document.mozFullScreen || document.fullScreen || document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled
			if (isFull === undefined)
				isFull = false
			return isFull
		},
		tabsChoose() {
			setTimeout(() => {
				this.openPage(this.editableTabsValue)
			}, 200)
		},
		refreshFn() {
			if (!this.refresh) {
				this.refresh = true
				this.$router.go(0) //刷新
				setTimeout(() => {
					this.refresh = false
				}, 300)
			}
		},
		handleScroll(e) {
			const eventDelta = e.wheelDelta || -e.deltaY * 40
			const $scrollWrapper = this.scrollWrapper
			$scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
		},
		handleTabsEdit(targetName, action) {
			if (action === 'remove') {
				this.$store.commit('removeNavBar', targetName)
				if (this.$route.path == targetName) {
					let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
						.$navbar.length - 1].path
					this.editableTabsValue = url
					this.openPage(url)
				}
			}
		},
		commandClick(e) {
			if (e === 'logout') {
				this.$store.commit('logout')
				setTimeout(() => {
					this.$router.replace('/login')
					location.reload()
				}, 800)
			}
			if (e === 'user') {
				this.$router.replace('/userInfo')
			}
		},
		tabsClick(e) {
			switch (e) {
				case 'curr':
					this.removeNavBar(this.$route.path)
					break;
				case 'other':
					if (this.navbar.length > 1) {
						this.$store.commit('setNavBar', [{
							title: this.$route.meta.name.split(',').length != 1 ? this.$route.meta.name.split(',')[this.$route.meta.name.split(',').length - 1] : this.$route.meta.name,
							path: this.$route.path
						}])
					}
					break;
				case 'all':
					this.$store.commit('setNavBar', [])
					this.editableTabsValue = '/'
					this.tabsChoose()
					break;
				default:
					break;
			}
		},
		changeNav() {
			this.$store.commit('setCollapse', !this.$store.state.collapse)
		},
		removeNavBar(path) {
			this.$store.commit('removeNavBar', path)
			if (this.$route.path == path) {
				let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
					.$navbar.length - 1].path
				this.openPage(url)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	background-color: #fff;
	box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.08);
	position: relative;
	z-index: 1;

	/deep/.el-badge__content {
		line-height: 16px;
	}

	.navbar {
		height: 50px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.notice_message {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20%;
			height: 38px;
			transform: translate(-50%, -50%);
			border-radius: 10px;
			overflow: hidden;

			.notice_message_item {
				width: 100%;
				height: 100%;
				background-color: #FFF8ED;
				display: flex;
				align-items: center;
				padding: 0 30px;
				cursor: pointer;

				.icon_img {
					width: 25px;
					height: 25px;
					margin-right: 30px;
				}

				.message_txt {
					flex: 1;
					width: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					font-weight: bold;
					color: #FF7C1E;
				}
			}
		}

		.collapse {
			padding: 0 15px;
			cursor: pointer;

			i {
				vertical-align: sub;
				font-size: 20px;
			}
		}

		.el-breadcrumb {

			.el-breadcrumb__separator,
			.el-breadcrumb__inner.is-link {
				font-weight: normal;
			}
		}
	}

	.serverBox {
		.item {
			margin: 0 15px;
		}
	}

	.tags-view {
		background-color: #fff;
		display: flex;
		align-items: center;

		.scroll-container {
			width: 1px;
			flex: 1;
		}

		.alltabs {
			margin-bottom: 20px;
			margin-left: 10px;
			margin-right: 10px;
		}

		.tags-view-wrap {
			padding-bottom: 6px;
			margin: 0 20px;

			.tags-item {
				flex-shrink: 0;
				height: 28px;
				border: 1px solid #d8dce5;
				color: #495060;
				background: #fff;
				padding: 0 8px;
				font-size: 12px;
				margin: 4px 0 0 4px;
				cursor: pointer;

				&.current {
					background-color: #409eff;
					color: #fff;
					padding-left: 20px;
					position: relative;

					&:after {
						content: '';
						background: #fff;
						position: absolute;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						top: 50%;
						margin-top: -4px;
						left: 8px;
					}
				}

				.close-icon {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					line-height: 16px;
					text-align: center;
					margin-left: 4px;

					&:hover {
						background-color: #b4bccc;
						color: #fff;
					}

					.el-icon-close {
						font-size: 12px;
						transform-origin: center;
						transform: scale(0.8);

						:before {
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}

.role {
	margin-right: 10px;
	color: #409eff;
}

.notice-box {
	margin-left: auto;
	position: relative;
	cursor: pointer;

	span {
		width: 14px;
		height: 14px;
		background: #FF4949;
		position: absolute;
		color: #fff;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		border-radius: 50%;
		top: -4px;
		right: -4px;
	}
}

.avatar {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-top: 3px;
	cursor: pointer;
	transition: all 0.3s;
}

.serverItem {
	width: 16px;
	height: 16px;
	margin-top: 3px;
	cursor: pointer;
}
.animate {
	transform: rotate(360deg);
}

.user-box {
	margin: 0 24px 0 10px;
	cursor: pointer;

	span {
		margin: 0 8px 0 12px;
	}

}
</style>
