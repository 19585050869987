<template>
  <div class="page central">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="名称：">
          <el-input v-model="searchForm.title" placeholder="请输入名称" size="small"></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态：">
            <el-select clearable v-model="searchForm.is_hid"  placeholder="角色状态"  size="small">
              <el-option :value="0" label="启用"></el-option>
              <el-option :value="1" label="禁用"></el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-delete" size="small" type="danger"
            @click="batchDelete">删除</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" @selection-change="handleSelectionChange"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="名称" prop="title">
        </el-table-column>
        <el-table-column align="center" label="简称" prop="subtitle">
        </el-table-column>
        <el-table-column align="center" label="编码" prop="groupCode">
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookdepartment(scope.row)"
              v-if="scope.row.groupType != 4">查看部门</el-button>
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible" destroy-on-close
        top="30vh" width="500px">
        <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
          <el-form-item label="名称/全称：" prop="title">
            <el-input v-model="createdData.title" placeholder="请输入名称/全称"></el-input>
          </el-form-item>
          <el-form-item label="简称：" prop="subtitle">
            <el-input v-model="createdData.subtitle" placeholder="请输入简称"></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="groupCode">
            <el-input v-model="createdData.groupCode" placeholder="请输入编码"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查看部门 -->
      <el-dialog :close-on-click-modal="false" title="查看部门" :visible.sync="dialogVisible2" destroy-on-close top="15vh"
        width="70%">
        <div class="dialogCont">
          <el-button icon="el-icon-sort" plain size="small" type="info" @click="expandTable"
            style="margin-bottom: 10px;">展开/折叠</el-button>
          <el-table v-if="ready" v-loading="loading2" border :data="oilTableData" :default-expand-all="is_expand"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id" style="width: 100%;"
            header-row-class-name="header-table">
            <el-table-column align="center" label="名称" prop="title">
            </el-table-column>
            <el-table-column align="center" label="简称" prop="subtitle">
            </el-table-column>
            <el-table-column align="center" label="编码" prop="groupCode">
            </el-table-column>
            <el-table-column align="center" label="id" prop="id">
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime">
            </el-table-column>
            <el-table-column align="center" label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="childerAdd(scope.row)">添加子部门</el-button>
                <el-button type="primary" size="mini" @click="childerediterow(scope.row)">编辑</el-button>
                <el-button type="danger" size="mini" @click="childerdeleterow(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-dialog width="500px" top="25vh"
          :title="oilCreateData.mode == 1 ? '添加' + '子部门' + ' - ' + oilCreateData.pname : '编辑' + '子部门' + ' - ' + oilCreateData.pname"
          :visible.sync="innerVisible" append-to-body>
          <div class="oildialog">
            <el-form ref="oilform" :model="oilCreateData" :rules="oildrules" label-width="100px">
              <el-form-item label="名称：" prop="title">
                <el-input v-model="oilCreateData.title"></el-input>
              </el-form-item>
              <el-form-item label="简称：" prop="subtitle">
                <el-input v-model="oilCreateData.subtitle"></el-input>
              </el-form-item>
              <el-form-item label="编码：" prop="groupCode">
                <el-input v-model="oilCreateData.groupCode"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="oilsubRefuse">确 定</el-button>
          </span>
        </el-dialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "Central",
  data() {
    return {
      ready: true,
      is_expand: true,
      isAdd: false,
      multipleSelection: [],
      createdData: {
        id: '',
        groupCode: '',
        groupType: 1,
        pid: 0,
        subtitle: '',
        title: ''
      },
      oilCreateData: {
        title: '',
        subtitle: '',
        groupCode: '',
        pid: 0,
        id: '',
        groupType: 1,
        mode: 1, //1:添加 2:编辑
        pname: '',
      },
      rules: {
        groupCode: {
          required: true, message: '请输入编码', trigger: 'blur'
        },
        subtitle: {
          required: true, message: '请输入简称', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称/全称', trigger: 'blur'
        },
      },
      oildrules: {
        groupCode: {
          required: true, message: '请输入编码', trigger: 'blur'
        },
        subtitle: {
          required: true, message: '请输入简称', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入名称/全称', trigger: 'blur'
        },
      },
      searchForm: {
        title: '',
      },
      tableLoading: false,
      loading2: false,
      dialogVisible: false,
      dialogVisible2: false,
      innerVisible: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 1,
        lastPage: 1,
      },
      tableData: [],
      oilTableData: [],
      lookdepartmentTable: {}
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()

  },
  activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
  methods: {
    oilsubRefuse() {
      this.$refs['oilform'].validate((valid) => {
        if (valid) {
          let data = {
            groupCode: this.oilCreateData.groupCode,
            groupType: 1,
            pid: this.oilCreateData.pid,
            subtitle: this.oilCreateData.subtitle,
            title: this.oilCreateData.title
          }
          if (this.oilCreateData.mode == 2) data.id = this.oilCreateData.id
          this.$Api.memberGroupGary_Addorupdate(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.innerVisible = false
              this.lookdepartment(this.lookdepartmentTable)
            }
          })
        } else {
          return false;
        }
      });
    },
    childerAdd(row) {
      this.oilCreateData.mode = 1
      this.oilCreateData.pname = row.title
      this.oilCreateData.pid = row.id
      this.oilCreateData.id = ''
      this.oilCreateData.groupCode = ''
      this.oilCreateData.subtitle = ''
      this.oilCreateData.title = ''
      this.innerVisible = true
    },
    childerediterow(row) {
      this.oilCreateData.mode = 2
      this.oilCreateData.pname = row.title
      this.oilCreateData.pid = row.pid
      this.oilCreateData.id = row.id
      this.oilCreateData.groupCode = row.groupCode
      this.oilCreateData.subtitle = row.subtitle
      this.oilCreateData.title = row.title
      this.innerVisible = true
    },
    childerdeleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.memberGroupGary_detele(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.lookdepartment(this.lookdepartmentTable)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    lookdepartment(row) {
      this.lookdepartmentTable = row
      this.loading2 = true
      this.dialogVisible2 = true
      let data = {
        id: row.id,
        groupType: 1
      }
      this.$Api.memberGroupGary_lookdepartment(data).then(res => {
        this.loading2 = false;
        this.oilTableData = res.data
      })
    },
    expandTable() {
      this.ready = false
      this.is_expand = !this.is_expand
      this.$nextTick(() => {
        this.ready = true
      })
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请勾选删除项')
        return
      }
      let ids = []
      this.multipleSelection.map(item => {
        ids.push(item.id)
      })
      let data = {
        id: ids,
      }
      this.$Api.memberGroupGary_detele(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          if (ids.length == this.tableData.length) this.page.currentPage = 1
          this.getData()
        }
      })
    },
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            groupCode: this.createdData.groupCode,
            groupType: 1,
            pid: this.createdData.pid,
            subtitle: this.createdData.subtitle,
            title: this.createdData.title
          }
          if (!this.isAdd) data.id = this.createdData.id
          this.$Api.memberGroupGary_Addorupdate(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.dialogVisible = false
              this.clearCreateData()
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    addRole() {
      this.dialogVisible = true
      this.isAdd = true
      this.clearCreateData()
    },
    clearCreateData() {
      this.createdData = {
        id: '',
        groupCode: '',
        groupType: 1,
        pid: 0,
        subtitle: '',
        title: ''
      }
      this.$refs['form'].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createdData = {
        id: row.id,
        groupCode: row.groupCode,
        groupType: 1,
        pid: row.pid,
        subtitle: row.subtitle,
        title: row.title
      }
      this.isAdd = false
      this.dialogVisible = true
    },
    deleterow(row) {
      this.$confirm('确定删除该项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.memberGroupGary_detele(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        curpage: this.page.currentPage,
        pagesize: this.page.pageSize,
        title: this.searchForm.title,
        groupType: 1,
        pid: 0,
        states: 1,
        show_type: 2
      }
      this.tableLoading = true
      this.$Api.getDict(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = res.data.total;
        this.tableData = res.data.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.central {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .dialogCont {
      height: 50vh;
      overflow-y: scroll;
    }
  }
}</style>