<template>
    <div class="page OtherShipPlan">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="月份：">
                    <el-date-picker size="small" v-model="searchForm.monthly" value-format="yyyy-MM" type="month"
                        placeholder="请选择月份">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
                        @click="addRole">增加其他计划</el-button>
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="标题" prop="title">
                </el-table-column>
                <el-table-column align="center" label="承运公司" prop="carrier" width="150">
                </el-table-column>
                <el-table-column align="center" label="船舶" prop="ship">
                </el-table-column>
                <el-table-column align="center" label="起始油厂" prop="start_port_name">
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPort">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="oilTypeId" width="200">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="num">
                </el-table-column>
                <el-table-column align="center" label="创建人" prop="member.title">
                </el-table-column>
                <el-table-column align="center" label="操作" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="dateilsrow(scope.row)">详情</el-button>
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 新增/编辑 -->
            <el-dialog :close-on-click-modal="false" :title="isAdd ? '增加其他计划' : '编辑其他计划'" :visible.sync="dialogVisible"
                destroy-on-close top="15vh" width="650px">
                <div class="addBox">
                    <el-form ref="formAdd" :model="createData" :rules="rules" label-width="130px">
                        <el-form-item label="输入标题：" prop="title">
                            <el-input v-model="createData.title" placeholder="请输入标题" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="输入承运商：" prop="carrier">
                            <el-input v-model="createData.carrier" placeholder="请输入承运商" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="输入船舶：" prop="ship">
                            <el-input v-model="createData.ship" placeholder="请输入船" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="输入起始炼厂：" prop="oilWarehouse">
                            <el-select v-model="createData.oilWarehouse" placeholder="请搜索或选择内容" size="small">
                                <el-option v-for="(item, index) in dict.shipPortBegin" :key="item.id" :value="item.id"
                                    :label="item.title"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="输入到达港：" prop="destPort">
                            <el-input v-model="createData.destPort" placeholder="请输入到达港" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="油品：" prop="oilTypeId">
                            <el-input v-model="createData.oilTypeId" placeholder="请输入油品" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="输入数量：" prop="num">
                            <el-input v-model="createData.num" type="number" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="记录单：" prop="photo">
                            <el-upload v-if="!isdetails" class="upload-demo" :action="'/api/upload/file'"
                                :before-upload="beforeUpload" :on-error="handleError" :on-success="onsuccess"
                                :show-file-list="false">
                                <el-button type="primary" style="margin: 8px 0" v-loading="btnLoading" size="mini"
                                    icon="el-icon-upload2">上传附件</el-button>
                            </el-upload>
                            <span v-if="createData.photo != ''" @click="lookFile(createData.photo)"
                                style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit">确 定</el-button>
                </span>
            </el-dialog>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">标题</th>
                            <th class="tg-0lax">承运公司</th>
                            <th class="tg-0lax">船舶</th>
                            <th class="tg-0lax">起始油厂</th>
                            <th class="tg-0lax">到港</th>
                            <th class="tg-0lax">油品</th>
                            <th class="tg-0lax">数量</th>
                            <th class="tg-0lax">创建人</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.title }}</th>
                        <th class="tg-0lax">{{ item.carrier }}</th>
                        <th class="tg-0lax">{{ item.ship }}</th>
                        <th class="tg-0lax">{{ item.start_port_name }}</th>
                        <th class="tg-0lax">{{ item.destPort }}</th>
                        <th class="tg-0lax">{{ item.oilTypeId }}</th>
                        <th class="tg-0lax">{{ item.num }}</th>
                        <th class="tg-0lax">{{ item.member.title }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { TimeSelect } from 'element-ui';

export default {
    name: "OtherShipPlan",
    data() {
        return {
            isdetails: false,
            isAdd: false,
            createData: {
                title: '',
                carrier: '',
                ship: '',
                oilWarehouse: '',
                destPort: '',
                oilTypeId: '',
                num: '',
                monthly: '',
                photo: ''
            },
            rules: {
                title: { required: true, message: '必填项不能为空', trigger: 'blur' },
                carrier: { required: true, message: '必填项不能为空', trigger: 'blur' },
                ship: { required: true, message: '必填项不能为空', trigger: 'blur' },
                oilWarehouse: { required: true, message: '必填项不能为空', trigger: 'blur' },
                destPort: { required: true, message: '必填项不能为空', trigger: 'blur' },
                oilTypeId: { required: true, message: '必填项不能为空', trigger: 'blur' },
                num: { required: true, message: '必填项不能为空', trigger: 'blur' },
                monthly: { required: true, message: '必填项不能为空', trigger: 'blur' },
                photo: { required: true, message: '必填项不能为空', trigger: 'blur' },
            },
            multipleSelection: [],
            searchForm: {
                monthly: ''
            },
            tableLoading: false,
            dialogVisible: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 1,
                lastPage: 1,
            },
            tableData: [],
            dict: {
                shipPortBegin: []
            },
            btnLoading: false
        }
    },
    created() {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        if (month < 10) month = '0' + month;
        let date = now.getDate();
        if (date < 10) date = '0' + date;
        this.searchForm.monthly = year + '-' + month
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getData()
        this.getDict()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        commandClick(e) {
            console.log(111);
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let config = [
                        { field: 'title', width: 80, align: 'center', title: '标题' },
                        { field: 'carrier', width: 80, align: 'center', title: '承运公司' },
                        { field: 'ship', width: 80, align: 'center', title: '船舶' },
                        { field: 'start_port_name', width: 150, align: 'center', title: '起始油厂' },
                        { field: 'destPort', width: 150, align: 'center', title: '到港' },
                        { field: 'oilTypeId', width: 150, align: 'center', title: '油品' },
                        { field: 'num', width: 80, align: 'center', title: '数量' },
                        { field: 'member.title', width: 80, align: 'center', title: '创建人' }
                    ]
                    let obj = {
                        name: '其他水运计划',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(this.tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        beforeUpload() {
            this.btnLoading = true
        },
        handleError: function () {
            this.$message.error('上传失败，请重新上传！')
            this.btnLoading = false
        },
        onsuccess: function (response) {
            if (response.code == 200) {
                this.createData.photo = response.data.save_path
            }
            this.btnLoading = false
        },
        lookFile(url) {
            window.open(url, '_blank');
        },
        submit() {
            this.$refs['formAdd'].validate((valid) => {
                if (valid) {
                    if (this.isAdd) {
                        let data = this.createData
                        this.$Api.shipPlanOther_Create(data).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功'
                                });
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    } else {
                        let data = this.createData
                        this.$Api.shipPlanOther_Update(data).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功'
                                });
                                this.dialogVisible = false
                                this.getData()
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        addRole() {
            this.isdetails = false
            this.isAdd = true
            this.createData = {
                title: '',
                carrier: '',
                ship: '',
                oilWarehouse: '',
                destPort: '',
                oilTypeId: '',
                num: '',
                monthly: this.searchForm.monthly,
                photo: ''
            }
            this.dialogVisible = true
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        dateilsrow(row) {
            this.isdetails = true
            this.isAdd = false
            this.createData = {
                title: row.title,
                carrier: row.carrier,
                ship: row.ship,
                oilWarehouse: Number(row.oilWarehouse),
                destPort: row.destPort,
                oilTypeId: row.oilTypeId,
                num: row.num,
                monthly: row.monthly,
                photo: row.photo,
                id: row.id,
            }
            this.dialogVisible = true
        },
        editerow(row) {
            this.isdetails = false
            this.isAdd = false
            this.createData = {
                title: row.title,
                carrier: row.carrier,
                ship: row.ship,
                oilWarehouse: Number(row.oilWarehouse),
                destPort: row.destPort,
                oilTypeId: row.oilTypeId,
                num: row.num,
                monthly: row.monthly,
                photo: row.photo,
                id: row.id,
            }
            this.dialogVisible = true
        },
        deleterow(row) {
            this.$confirm(`确定要删除此条数据吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.shipPlanOther_Delete(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSizes = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getDict() {
            this.$Api.shipPlan_Dict().then(res => {
                if (res.code == 200) {
                    this.dict.shipPortBegin = res.data.shipPortBegin
                }
                this.getData()
            })
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                monthly: this.searchForm.monthly,
                planType: '0'
            }
            this.tableLoading = true
            this.$Api.shipPlanOther_GetList(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total;
                    this.tableData = res.data.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.OtherShipPlan {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form-item__label {
            width: 100px;
        }

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        /deep/.el-input__inner {
            width: 200px;
        }

        /deep/.el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 200px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .addBox {
            height: 50vh;
            overflow-y: scroll;
            overflow-x: hidden;

            .el-select {
                width: 100%;
            }
        }
    }
}
</style>