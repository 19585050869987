<template>
  <div id="app">
    <div  class="u-flex">
      <aside-nav v-if="!hideNav"></aside-nav>
      <div class="content">
        <u-header v-if="!hideNav"></u-header>
        <div :class="hideNav?'isheight':'noheight'" class="main">
          <el-scrollbar class="u-row-nowrap" style="height: 100%">
            <transition mode="out-in" name="fade-transform">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive" :key="key"  />
              </keep-alive>
            </transition>
            <router-view v-if="!$route.meta.keepAlive"/>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import asideNav from "@/components/asideNav"
import uHeader from '@/components/uHeader'
export default {
  components: {
    asideNav,
    uHeader
  },
  created() {
    // const web_config = sessionStorage.getItem('web_config') ? sessionStorage.getItem('web_config') : '';
    // if (web_config) {
    //   document.title = JSON.parse(web_config).title
    // } else {
    //   this.$http.get('/backend/web_config').then(res => {
    //     if(res.code == 1){
    //       document.title = res.data.title
    //       let data = JSON.stringify(res.data)
    //       sessionStorage.setItem('web_config', data)
    //     }
    //   })
    // }
  },
  data() {
    return {
      keepAliveArr: [],
      pageTabList:[],
      refreshControl: true //刷新页面
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.$navbar
    },
    key() {
      return this.$route.path
    },
    hideNav() {
      let list = ['/login', '/maintain', '/dataVisualization',"/wxApplet"]
      return list.indexOf(this.$route.path) !== -1
    },
    collapse() {
			return this.$store.state.collapse
      
		}
  },
  methods:{
    refreshChild () {
      // 先移除，再加载，强制刷新子页面
      const name = this.$refs.child.$options.name
      this.keepAliveArr.splice(this.keepAliveArr.indexOf(name), 1)
      this.refreshControl = false
      this.$nextTick(() => this.refreshControl = true)
    },
    afterRouterChange () {
      const childName = this.$refs.child.$options.name
      if (!this.keepAliveArr.includes(childName)) {
        this.keepAliveArr.push(childName)
      }
    },
  }
}
</script>
<style lang="scss">
@import '@/assets/css/common.scss';
</style>
<style lang="scss" scoped>
.content {
  flex: 1;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.isheight{
  height: 100vh;
}
.noheight{
  height: 1px;
  flex: 1;
  background-color: #f2f2f2;
  padding: 10px;
}

/deep/.el-button--mini, .el-button--mini.is-round{
  padding: 6px 7px;
}
/deep/.el-button--small{
  padding: 9px;
}
/deep/.el-dialog{
     border-radius: 8px;
    padding: 10px 10px 0;
}
/deep/.el-scrollbar__view{
 height: inherit;
}
/deep/.el-table{
  font-size: 12px !important;
  transform: scale(1);
}
  /deep/.el-button--primary{
    background-color: #009688 !important;
    border-color: #009688 !important;
  }
  /deep/.el-button--primary:hover {
    background-color: #009688;
    border-color: #009688;
    opacity: 0.8;
    color: #FFF;
}
/deep/.el-button--primary.is-plain{
  color: #409EFF !important;
  background: #ecf5ff !important;
  border-color: #b3d8ff !important;
}
/deep/.el-button--primary.is-plain:hover{
    background: #409EFF !important;
    border-color: #409EFF !important;
    color: #FFF !important;
}
/deep/.page{
  height: inherit;
  background-color: #fff;
  border-radius: 10px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  .searchBox{
    width: 100%;
    padding: 30px 20px 10px;
    border: 1px solid #e6e6e6;
    position: relative;
    margin-top: 8px;
    z-index: 1;
    margin-bottom: 15px;
  }
  .searchBox::before{
    content: '搜索信息';
    display: block;
    background-color: #FFF;
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #6b6b6b;
    left: 30px;
    z-index: 2;
  }
  
}

.footer{
  width: 100%;
  height: 68px;
  color: #999;
  font-size: 12px;
  display: flex;
  align-items: center;
  p{
    margin-bottom: 10px;
  }
  .footer_left{
    background-color: #293449;
    height: 100%;
  }
  .footer_right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style>
.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
</style>
