<template>
    <div class="page assessmentResultsRelease_Details">
        <el-page-header @back="goBack" content="查看">
        </el-page-header>
        <div class="container">
            <el-divider content-position="left">{{ rowData.title }}情况汇总表</el-divider>
            <span class="tip" v-if="Types.length > 0">总分=关键因素*{{ Number(Types[0].percent) / 100 }}+过程因素*{{
                Number(Types[1].percent) / 100 }}
                (点击分数即可修改)</span>
            <!--列表-->
            <el-table v-if="isread" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
                tooltip-effect="dark" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="承运商名称" prop="title" width="350">
                </el-table-column>
                <el-table-column align="center" label="总分" prop="scoreSum">
                </el-table-column>
                <el-table-column align="center" label="排名" type="index" width="55">
                </el-table-column>
                <el-table-column align="center" :prop="'' + item.title" :label="item.title"
                    v-for="(item, index) in assessItem" :key="index">
                    <template slot-scope="scope">
                        <div class="editScore">{{ scope.row[scope.column.label] }}</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-divider content-position="left">扣分项</el-divider>
            <!--列表-->
            <el-table v-if="isread" ref="multipleTable" border v-loading="tableLoading" :data="ReasonForAssess"
                tooltip-effect="dark" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="考核项" prop="title" width="150">
                </el-table-column>
                <el-table-column label="扣分详情" prop="strReasons">
                    <template slot-scope="scope">
                        <div>{{ scope.row.strReasons || '暂无' }}</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="submit">
                <el-button type="success" icon="el-icon-check" size="medium" :disabled="IsTiJiao != 0"
                    @click="submit">发布结果</el-button>
                <el-button icon="el-icon-printer" size="medium">打印</el-button>
                <el-button icon="el-icon-download" size="medium">下载</el-button>
            </div>
            <!-- 添加/编辑 -->
            <el-dialog :close-on-click-modal="false" title="改分" :visible.sync="dialogVisible" destroy-on-close top="10vh"
                width="700px">
                <div class="addoreidt">
                    <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
                        <el-form-item label="原始分：" prop="oldpoints">
                            <el-input v-model="createdData.oldpoints" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="修改分：" prop="finalpoints">
                            <el-input v-model="createdData.finalpoints" type="number" clearable
                                placeholder="请输入修改分"></el-input>
                        </el-form-item>
                        <el-form-item label="备注：" prop="intro">
                            <el-input v-model="createdData.intro" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-form>
                    <!--列表-->
                    <el-table ref="multipleTablehistory" border :data="history" tooltip-effect="dark"
                        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                        <el-table-column align="center" label="原分数" prop="XScore" width="55">
                        </el-table-column>
                        <el-table-column align="center" label="修改分数" prop="YScore" width="55">
                        </el-table-column>
                        <el-table-column align="center" label="修改人" prop="uSubName">
                        </el-table-column>
                        <el-table-column align="center" label="修改时间" prop="CreateTime">
                        </el-table-column>
                        <el-table-column align="center" label="备注" prop="Log">
                            <template slot-scope="scope">
                                <el-popover placement="top-start" width="700" trigger="hover" :content="scope.row.Log">
                                    <div class="rolesBox" slot="reference">{{ scope.row.Log }}</div>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuse">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "assessmentResultsRelease_Details",
    data() {
        return {
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            createdData: {
                oldpoints: '',
                points: '',
                intro: '',
                title: '',
                assessId: '',
                assessItemId: '',
                carriersId: '',
                rater: '',
                finalpoints: '',
                id: ""
            },
            rules: {
                finalpoints: {
                    required: true, message: '请输入修改分', trigger: 'blur'
                },
                intro: {
                    required: true, message: '请输入备注', trigger: 'blur'
                },
            },
            Types: [],
            assessItem: [],
            ReasonForAssess: [],
            userInfo: {},
            editID: '',
            history: [],
            IsTiJiao: 0
        }
    },
    created() {
        this.rowData = this.$route.params
        this.userInfo = this.$store.state.userInfo
        this.getData()
        this.getTypes()
    },
    activated() {
        this.rowData = this.$route.params
        this.userInfo = this.$store.state.userInfo
        this.getData()
        this.getTypes()
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        submit() {
            if (this.IsTiJiao != 0) {
                this.$confirm('确定发布考核结果吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        id: this.rowData.id
                    }
                    this.$Api.carriers_ResultListpublishAssess(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '发布成功'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            } else {
                this.$message.warning('当前考核还有未完成项目 , 无法发布')
            }
        },
        editScore(scope, item) {
            console.log(scope, item);
            this.createdData = {
                oldpoints: scope.row[scope.column.label],
                points: item.points,
                intro: '',
                title: item.title,
                assessId: item.assessId,
                assessItemId: item.id,
                carriersId: scope.row.carriersId,
                rater: this.userInfo.id,
                finalpoints: '',
                id: scope.row[item.id]
            },
                this.editID = scope.row[item.id]
            let data = {
                carriersId: scope.row.carriersId,
                assessItemId: item.id,
            }
            this.$Api.carriers_ScoreGetScoreForChange(data).then(res => {
                if (res.code == 200) {
                    this.history = res.data
                }
            })
            this.dialogVisible = true
        },
        subRefuse() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = this.createdData
                    data.points = data.finalpoints
                    delete data.oldpoints
                    this.$Api.carriers_saveAssessCarriersScore(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.dialogVisible = false
                            this.getData()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/carrier/assessmentResultsExamineDetails')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getTypes() {
            this.$Api.carriers_getAssessItemTypes().then(res => {
                if (res.code == 200) {
                    this.Types = res.data
                }
            })
        },
        getData() { // 获取数据
            if (this.rowData.assessType == 1) { //(月份)
                let data = {
                    assessId: this.rowData.id,
                }
                this.tableLoading = true
                this.isread = false
                this.$Api.carriersResultList_itemlist(data).then(res => {
                    this.tableLoading = false
                    if (res.code == 200) {
                        if (res.data.total) this.page.total = res.data.total
                        this.assessItem = res.data.MonthTables.dt2
                        this.tableData = res.data.MonthTables.table
                        this.ReasonForAssess = res.data.ReasonForAssess
                        this.IsTiJiao = res.data.MonthTables.IsTiJiao
                    }
                })
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.isread = true
                    })
                })
            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
.assessmentResultsRelease_Details {
    padding-top: 20px;
    padding-left: 20px;
    height: initial;

    .container {
        width: 100%;
        height: auto;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .el-divider__text.is-left {
            font-size: 16px;
            font-weight: 600;
        }

        .tip {
            font-size: 14px;
            color: red;
        }

        .submit {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }

        .editScore {
            cursor: pointer;
        }

        .addoreidt {
            width: 100%;
            height: 50vh;
            overflow-y: scroll;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}
</style>