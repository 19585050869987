<template>
  <div class="page Course">
    <div class="container">
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="类型" prop="title">
        </el-table-column>
        <el-table-column align="center" label="一级审批" prop="content1">
          <template slot-scope="scope">
            <el-popover width="600" trigger="hover" :content="scope.row.content1">
              <div class="userBox" slot="reference">
                {{ scope.row.content1 }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="二级审批" prop="content2">
          <template slot-scope="scope">
            <el-popover width="600" trigger="hover" :content="scope.row.content2">
              <div class="userBox" slot="reference">
                {{ scope.row.content2 }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="三级审批" prop="content3">
          <template slot-scope="scope">
            <el-popover width="600" trigger="hover" :content="scope.row.content3">
              <div class="userBox" slot="reference">
                {{ scope.row.content3 }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加/编辑 -->
      <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加审批流程' : '编辑审批流程'" :visible.sync="dialogVisible"
        destroy-on-close top="15vh" width="600px">
        <div class="addoreidt">
          <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
            <el-form-item label="标题：" prop="title">
              <el-input v-model="createdData.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="一级审核：" prop="user1">
              <el-cascader placeholder="选择或搜索后选择" v-model="createdData.user1" :options="selectMember" filterable
                :props="{ multiple: true, label: 'title', value: 'id' }"></el-cascader>
            </el-form-item>
            <el-form-item label="二级审核：" prop="user2">
              <el-cascader placeholder="选择或搜索后选择" v-model="createdData.user2" :options="selectMember" filterable
                :props="{ multiple: true, label: 'title', value: 'id' }"></el-cascader>
            </el-form-item>
            <el-form-item label="三级审核：" prop="user3">
              <el-cascader placeholder="选择或搜索后选择" v-model="createdData.user3" :options="selectMember" filterable
                :props="{ multiple: true, label: 'title', value: 'id' }"></el-cascader>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  name: "Course",
  data() {
    return {
      isAdd: false,
      searchForm: {
        name: '',
        is_hid: [],
      },
      createdData: {
        id: "",
        title: '',
        type: 9,
        user1: [],
        user2: [],
        user3: []
      },
      rules: {
        title: {
          required: true, message: '请输入标题', trigger: 'blur'
        }
      },
      tableLoading: false,
      dialogVisible: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      member: [],
      memberGroup: [],
      selectMember: []
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getDict()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted() {

  },
  methods: {
    subRefuse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            title: this.createdData.title,
            type: this.createdData.type
          }
          if(this.createdData.user1.length > 0) {
            let user1 = []
            this.createdData.user1.map(item=>{
              user1.push(item[1])
            })
            data.user1 = user1.join(',')
          }else{
            data.user1 = '0' 
          }
          if(this.createdData.user2.length > 0) {
            let user2 = []
            this.createdData.user2.map(item=>{
              user2.push(item[1])
            })
            data.user2 = user2.join(',')
          }else{
            data.user2 = '0' 
          }
          if(this.createdData.user3.length > 0) {
            let user3 = []
            this.createdData.user3.map(item=>{
              user3.push(item[1])
            })
            data.user3 = user3.join(',')
          }else{
            data.user3 = '0'
          }
          if (!this.isAdd) { //编辑
            data.id = this.createdData.id
            this.$Api.approval_Edit(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          } else {

          }
        } else {
          return false;
        }
      });
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow(row) {
      this.createdData.title = row.title;
      this.createdData.id = row.id;
      this.isAdd = false
      if (row.user1 != 0 && row.user1 != null) {
        let ueser1 = row.user1.split(',');
        if (ueser1.length > 0 && this.member.length > 0) {
          ueser1.map(item => {
            this.member.map(key => {
              if (item == key.id) {
                this.createdData.user1.push([key.groupId, key.id])
              }
            })
          })
        } else {
          this.createdData.user1 = []
        }
      } else {
        this.createdData.user1 = []
      }
      if (row.user2 != 0 && row.user2 != null) {
        let ueser1 = row.user2.split(',');
        if (ueser1.length > 0 && this.member.length > 0) {
          ueser1.map(item => {
            this.member.map(key => {
              if (item == key.id) {
                this.createdData.user2.push([key.groupId, key.id])
              }
            })
          })
        } else {
          this.createdData.user2 = []
        }
      } else {
        this.createdData.user2 = []
      }
      if (row.user3 != 0 && row.user3 != null) {
        let ueser1 = row.user3.split(',');
        if (ueser1.length > 0 && this.member.length > 0) {
          ueser1.map(item => {
            this.member.map(key => {
              if (item == key.id) {
                this.createdData.user3.push([key.groupId, key.id])
              }
            })
          })
        } else {
          this.createdData.user3 = []
        }
      } else {
        this.createdData.user3 = []
      }
      this.dialogVisible = true
    },
    clearCreateData() {
      this.createdData = {
        id: "",
        title: '',
        type: 15,
        user1: [],
        user2: [],
        user3: []
      }
      this.$refs['form'].resetFields();
    },
    deleterow() {

    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getData()
          break;
        case 'second':

          break;
        case 'third':

          break;
        default:
          break;
      }
    },
    // --------------------------公共方法--------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getDict() {
      this.$Api.approval_Dict().then(res => {
        this.memberGroup = res.data.memberGroup
        this.member = res.data.member
        let selectMember = JSON.parse(JSON.stringify(res.data.memberGroup))
        if (selectMember.length > 0) {
          selectMember.map(item => {
            let children = []
            if (res.data.member.length > 0) {
              res.data.member.map(key => {
                if (item.id == key.groupId) {
                  children.push({
                    title: key.title,
                    id: key.id
                  })
                }
              })
              item.children = children
            }

          })
        }
        this.selectMember = selectMember
        this.getData()
      })
      
    },
    setNameInfo(list) {
      let arr = []
      list.map((key, kindex) => {
        if (this.member.length > 0) {
          this.member.map(row => {
            if (key == row.id) {
              arr[kindex] = { name: row.title }
              if (this.memberGroup.length > 0) {
                try {
                  this.memberGroup.map(h => {
                    if (row.groupId == h.id) {
                      arr[kindex].groupName = h.title
                      throw new Error()
                    } else {
                      arr[kindex].groupName = ''
                      throw new Error()
                    }
                  })
                } catch { }

              }
            }
          })
        }
      })
      return arr
    },
    getData() { // 获取数据
      let data = {
        type: 9,
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      this.$Api.approval_List(data).then(res => {
        if (res.data.total) this.page.total = res.data.total;
        if (res.data.data.length != 0) {
          res.data.data.map(item => {
            if (item.user1 != 0) {
              let userArr1 = item.user1.split(',')
              if (userArr1.length > 0) {
                let arr = this.setNameInfo(userArr1)
                item.userArr1 = arr
                if (arr.length > 0) {
                  let content = ''
                  arr.map(item => {
                    content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                  })
                  item.content1 = content
                } else {
                  item.content1 = '无'
                }
              } else {
                item.content1 = '无'
              }
            } else {
              item.content1 = '无'
            }
            if (item.user2 != 0) {
              let userArr2 = item.user2.split(',')
              if (userArr2.length > 0) {
                let arr = this.setNameInfo(userArr2)
                item.userArr2 = arr
                if (arr.length > 0) {
                  let content = ''
                  arr.map(item => {
                    content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                  })
                  item.content2 = content
                } else {
                  item.content2 = '无'
                }
              } else {
                item.content2 = '无'
              }
            } else {
              item.content2 = '无'
            }
            if (item.user3 != 0) {
              let userArr3 = item.user3.split(',')
              if (userArr3.length > 0) {
                let arr = this.setNameInfo(userArr3)
                item.userArr3 = arr
                if (arr.length > 0) {
                  let content = ''
                  arr.map(item => {
                    content = content + '【' + item.groupName + '】' + ' ' + item.name + ','
                  })
                  item.content3 = content
                } else {
                  item.content3 = '无'
                }
              } else {
                item.content3 = '无'
              }
            } else {
              item.content3 = '无'
            }

          })
        }
        this.tableData = res.data.data
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.Course {
  height: calc(100vh - 125px) !important;
  overflow-y: scroll;

  .container {
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .menuBox {}

    .userBox {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .addoreidt {
      height: 50vh;
      overflow-y: scroll;

      /deep/.el-cascader {
        width: 100% !important;
      }
    }
  }

}
</style>