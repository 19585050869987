<template>
  <div class="page ShipManage">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="船名：">
          <el-input v-model="searchForm.title" placeholder="请输入船名" size="small"></el-input>
        </el-form-item>
        <el-form-item label="承运商：">
          <el-select clearable v-model="searchForm.carriersId" placeholder="请选择承运商" size="small">
            <el-option v-for="(item, index) in carriers" :key="index" :value="item.id"
              :label="item.subtitle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
            @click="addRole">添加船舶</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small"
            @click="addRole">导出船位表</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small"
            @click="addRole">导出吨位表</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small"
            @click="addRole">导出航次计划数</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small"
            @click="addRole">同步船位信息</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small"
            @click="addRole">导出船舶信息</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" @click="addRole">导出船员</el-button>
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;font-size: 12px;"
        header-row-class-name="header-table">
        <el-table-column align="center" label="船舶名称" prop="title" width="115" fixed="left">
        </el-table-column>
        <el-table-column align="center" label="MMIS" prop="ais" width="115">
        </el-table-column>
        <el-table-column align="center" label="船舶编码" prop="acode" width="115">
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="carriers.subtitle" width="115">
        </el-table-column>
        <el-table-column align="center" label="认证状态" prop="verifyDtatus" width="115">
          <template slot-scope="scope">
            <div v-html="scope.row.verifyDtatus"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="认证时间" prop="verifyTime" width="115">
        </el-table-column>
        <el-table-column align="center" label="上线时间" prop="onlineTime" width="115">
        </el-table-column>
        <el-table-column align="center" label="当前计划" prop="planStatus" width="115">
        </el-table-column>
        <el-table-column align="center" label="上线状态" prop="ship_states_text" width="115">
        </el-table-column>
        <el-table-column align="center" label="证书状态" prop="certificateStates" width="115">

          <template slot-scope="scope">
            <div v-html="scope.row.certificateStates"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="船员证书" prop="expiredStatus" width="115">

          <template slot-scope="scope">
            <div v-html="scope.row.expiredStatus"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="检修状态" prop="repairState" width="115">
        </el-table-column>
        <el-table-column align="center" label="载重状态" prop="site.state" width="115">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="approveStatus" width="115">
        </el-table-column>
        <el-table-column align="center" label="操作" width="460" fixed="right">

          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row, 0)"
              v-if="scope.row.button_detail == 1">详情</el-button>
            <el-button type="primary" size="mini" @click="editerow(scope.row, 1)"
              v-if="scope.row.button_detail == 1">编辑</el-button>
            <el-button type="primary" size="mini" @click="onlineEdite(scope.row, 0)"
              v-if="scope.row.button_online == 1">上下线</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row)" v-if="scope.row.button_log == 1">审核日志
            </el-button>
            <el-button type="primary" size="mini" @click="overhaul(scope.row)"
              v-if="scope.row.button_repair == 1">检修</el-button>
            <el-button type="primary" size="mini" @click="position(scope.row)"
              v-if="scope.row.button_position == 1">船位</el-button>
            <el-button type="primary" size="mini" @click="tonnage(scope.row)"
              v-if="scope.row.button_ton == 1">吨位</el-button>
            <el-button type="primary" size="mini" @click="onlineEdite(scope.row, 1)">修改状态</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
          :page-size="page.pageSize" :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 添加船舶 -->
      <el-dialog :close-on-click-modal="false" title="添加" :visible.sync="dialogVisible" destroy-on-close top="20vh"
        width="450px">
        <div class="addoreidt">
          <el-form ref="formShip" :model="createData" :rules="rules" label-width="130px">
            <el-form-item label="船舶名称：" prop="title">
              <el-input v-model="createData.title" clearable placeholder="请输入船舶名称"></el-input>
            </el-form-item>
            <el-form-item label="MMIS号：" prop="ais">
              <el-input v-model="createData.ais" clearable placeholder="请输入MMIS号"></el-input>
            </el-form-item>
            <el-form-item label="船舶编码：" prop="acode">
              <el-input v-model="createData.acode" clearable placeholder="请输入船舶编码"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuShip">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 上下线 -->
      <el-dialog :close-on-click-modal="false" title="上线停复航申请" :visible.sync="onlineData.dialogVisible" destroy-on-close
        top="20vh" width="650px">
        <div class="addoreidt">
          <el-form ref="formShipOnline" :model="onlineData.createData" :rules="onlineData.rules" label-width="130px">
            <el-form-item label="承运公司：" prop="carriersName">
              <span>{{ onlineData.createData.carriersName || '' }}</span>
            </el-form-item>
            <el-form-item label="船舶：" prop="shipName">
              <span>{{ onlineData.createData.shipName || '' }}</span>
            </el-form-item>
            <el-form-item label="类型：" prop="approveType">
              <el-select v-model="onlineData.createData.approveType" placeholder="请选择类型">
                <el-option v-for="(item, index) in onlineData.type" :key="index" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="情况说明：" prop="intro">
              <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="onlineData.createData.intro">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onlineData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" v-if="immediately" @click="immediatelyShipOnline">立即生效</el-button>
          <el-button type="primary" @click="subRefuShipOnline">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 检修 -->
      <el-dialog :close-on-click-modal="false" title="申请检修" :visible.sync="overhaulData.dialogVisible" destroy-on-close
        top="20vh" width="650px">
        <div class="addoreidt">
          <el-form ref="formShipoverhaul" :model="overhaulData.createData" :rules="overhaulData.rules"
            label-width="130px">
            <el-form-item label="承运公司：" prop="carriersName">
              <span>{{ overhaulData.createData.carriersName || '' }}</span>
            </el-form-item>
            <el-form-item label="船舶：" prop="shipName">
              <span>{{ overhaulData.createData.shipName || '' }}</span>
            </el-form-item>
            <el-form-item label="检修状态：" prop="repairState">
              <el-select v-model="overhaulData.createData.repairState" placeholder="请选择检修状态"
                @change="repairStatechange">
                <el-option v-for="(item, index) in overhaulData.type" :key="index" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="检修开始时间：" prop="repairStartTime">
              <el-date-picker v-model="overhaulData.createData.repairStartTime" type="date" placeholder="请选择检修开始时间"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="检修结束时间：" prop="repairEndTime">
              <el-date-picker v-model="overhaulData.createData.repairEndTime" type="date" placeholder="请选择检修结束时间"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="检修原因：" prop="repairReason">
              <el-input type="textarea" :rows="6" placeholder="请输入检修原因" v-model="overhaulData.createData.repairReason">
              </el-input>
            </el-form-item>
            <div v-if="overhaulData.createData.repairState == 4">
              <div class="serverHead" style="margin-top: 15px;">检修方案</div>
              <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload"
                :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                <el-button icon="el-icon-upload2" size="small" type="primary" style="margin: 8px 0;"
                  v-loading="fileLoading">点击上传</el-button>
              </el-upload>
              <!--列表-->
              <el-table v-if="showReady" ref="multipleTable" border :data="fileList" tooltip-effect="dark"
                style="width: 100%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="文件名" prop="name">
                </el-table-column>
                <el-table-column align="center" label="状态" prop="status">

                  <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.status == 'success'">上传成功</el-tag>
                    <el-tag type="danger" v-else>上传失败</el-tag>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div style="display: flex;align-items: center;justify-content: center;">
                      <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUpload2"
                        :on-error="handleError" :on-success="onsuccess" :show-file-list="false">
                        <el-button size="mini" v-if="scope.row.status != 'success'" type="primary"
                          style="margin: 8px 10px;" v-loading="fileLoading" @click="cxupload(scope.$index)"
                          icon="el-icon-upload2">重新上传</el-button>
                      </el-upload>
                      <el-button type="danger" size="mini"
                        @click="deleterowFile(scope.row, scope.$index)">删除</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="overhaulData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subRefuShipoverhaul">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 船位 -->
      <el-dialog :close-on-click-modal="false" title="船位编辑" :visible.sync="shipPostionData.dialogVisible"
        destroy-on-close top="12vh" width="750px">
        <div class="addoreidt">
          <el-form ref="formShipPostion" :model="shipPostionData.createData" :rules="shipPostionData.rules"
            label-width="130px">
            <el-form-item label="承运公司：" prop="carriersName">
              <span>{{ shipPostionData.createData.carriersName || '' }}</span>
            </el-form-item>
            <el-form-item label="船舶：" prop="shipName">
              <span>{{ shipPostionData.createData.shipName || '' }}</span>
            </el-form-item>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="吨位-柴油：" prop="tonnage1">
                  <el-input placeholder="请输入吨位-柴油" v-model="shipPostionData.createData.tonnage1" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="吨位-汽油：" prop="tonnage2">
                    <el-input placeholder="请输入吨位-汽油" v-model="shipPostionData.createData.tonnage2" clearable>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="状态：" prop="state">
                  <el-select v-model="shipPostionData.createData.state" placeholder="请选择状态">
                    <el-option v-for="(item, index) in shipPostionData.states" :key="index" :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="船位：" prop="site">
                    <el-input placeholder="请输入船位" v-model="shipPostionData.createData.site" clearable>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-form-item label="本载-航线：" prop="planNowLine">
              <el-input placeholder="请输入本载-航线" v-model="shipPostionData.createData.planNowLine" clearable>
              </el-input>
            </el-form-item>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="本载-油品：" prop="planNowOilType">
                  <el-select v-model="shipPostionData.createData.planNowOilType" placeholder="请选择本载-油品">
                    <el-option v-for="(item, index) in shipPostionData.oilType" :key="index" :label="item.title"
                      :value="item.title"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="本载-计划属性：" prop="planNowType">
                    <el-input placeholder="请输入本载-计划属性" v-model="shipPostionData.createData.planNowType" clearable>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-form-item label="上次-航线：" prop="planPrevLine">
              <el-input placeholder="请输入上次-航线" v-model="shipPostionData.createData.planPrevLine" clearable>
              </el-input>
            </el-form-item>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="上次-油品：" prop="planPrevOilType">
                  <el-select v-model="shipPostionData.createData.planPrevOilType" placeholder="请选择上次-油品">
                    <el-option v-for="(item, index) in shipPostionData.oilType" :key="index" :label="item.title"
                      :value="item.title"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="上次-计划属性：" prop="planPrevType">
                    <el-input placeholder="请输入上次-计划属性" v-model="shipPostionData.createData.planPrevType" clearable>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-form-item label="检修计划：" prop="repairInfo">
              <el-input placeholder="请输入检修计划" v-model="shipPostionData.createData.repairInfo" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="是否有外出安排：" prop="goOutInfo">
              <el-input placeholder="请输入是否有外出安排" v-model="shipPostionData.createData.goOutInfo" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="remarks">
              <el-input type="textarea" :rows="4" placeholder="请输入备注内容" v-model="shipPostionData.createData.remarks">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="shipPostionData.dialogVisible = false">取 消</el-button>
          <el-button @click="subRefuShipoPostionRefus">更 新</el-button>
          <el-button type="primary" @click="subRefuShipoPostion">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible3" destroy-on-close top="15vh">
        <div class="addoreidt">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level_text">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="user_outcome_text">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="update_time">

              <template slot-scope="scope">
                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import mapConfig from '@/utils/mapConfig'
export default {
  name: "ShipManage",
  data() {
    return {
      immediately: false,
      shipPostionData: {
        dialogVisible: false,
        createData: {
          id: '',
          tonnage1: '',
          tonnage2: '',
          state: '',
          site: '',
          planNowLine: '',
          planNowOilType: '',
          planNowType: '',
          planPrevLine: '',
          planPrevOilType: '',
          planPrevType: '',
          repairInfo: '',
          remarks: '',
          shipName: "",
          carriersName: '',
          goOutInfo: ''
        },
        rules: {
          tonnage1: { required: true, message: '请输入吨位-柴油', trigger: 'blur' },
          tonnage2: { required: true, message: '请输入吨位-汽油', trigger: 'blur' },
          state: { required: true, message: '请选择状态', trigger: 'blur' },
          site: { required: true, message: '请输入船位', trigger: 'blur' },
          repairInfo: { required: true, message: '请输入检修计划', trigger: 'blur' },
          goOutInfo: { required: true, message: '请输入是否有外出安排', trigger: 'blur' }
        },
        oilType: [],
        states: ['重载上行', '重载下行', '重载待卸', '重载等闸', '重载过闸', '重载抛锚', '空载上行', '空载下行', '空载待装', '空载等闸', '空载过闸', '空载抛锚', '正在卸油', '正在装油', '检修', '年检']
      },
      overhaulData: {
        dialogVisible: false,
        createData: {
          id: '',
          repairEndTime: '',
          repairReason: '',
          repairStartTime: '',
          repairState: '',
          carriersName: '',
          shipName: ''
        },
        rules: {
          repairEndTime: { required: true, message: '请选择检修结束时间', trigger: 'blur' },
          repairReason: { required: true, message: '请输入检修原因', trigger: 'blur' },
          repairStartTime: { required: true, message: '请选择检修开始时间', trigger: 'blur' },
          repairState: { required: true, message: '请选择检修状态', trigger: 'blur' },
        },
        type: []
      },
      onlineData: {
        dialogVisible: false,
        createData: {
          carriersName: '',
          shipName: "",
          approveType: '',
          intro: '',
          shipId: '',
          applyType: ''
        },
        rules: {
          intro: { required: true, message: '请输入情况说明', trigger: 'blur' },
          approveType: { required: true, message: '请选择类型', trigger: 'blur' }
        },
        type: []
      },
      multipleSelection: [],
      searchForm: {
        title: '',
        carriersId: ''
      },
      createData: {
        acode: '',
        title: "",
        ais: '',
        carriersId: '',
        groupId: ''
      },
      rules: {
        acode: {
          required: true, message: '请输入船舶编码', trigger: 'blur'
        },
        title: {
          required: true, message: '请输入船舶名称', trigger: 'blur'
        },
        ais: {
          required: true, message: '请输入MMIS号', trigger: 'blur'
        },
      },
      tableLoading: false,
      dialogVisible: false,
      dialogVisible3: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      carriers: [],
      userInfo: {},
      logData: {},
      myapprovalStatesList: [],
      fileList: [],
      cxIndex: "",
      fileLoading: false,
      showReady: true
    }
  },
  created() {
    let that = this
    that.userInfo = that.$store.state.userInfo
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getCarriers()
    that.getType()
    that.getShipOnlineType()
    that.getshipRepairApplyType()
    that.getOilType()
  },

  activated() {
    let that = this
    that.userInfo = that.$store.state.userInfo
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getCarriers()
    that.getType()
    that.getShipOnlineType()
    that.getshipRepairApplyType()
    that.getOilType()
    that.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    repairStatechange(e) {
      if (e == 4) {
        this.$message.warning('可在下方上传检修方案')
      }
    },
    cxupload(index) {
      this.cxIndex = index
    },
    deleterowFile(row, index) {
      this.$confirm('是否确定删除该文件', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    beforeUpload() {
      this.cxIndex = ''
      this.fileLoading = true
    },
    beforeUpload2() {
      this.fileLoading = true
      this.showReady = false
    },
    onsuccess(response, file, fileList) {
      if (response.code == 200) {
        if (this.cxIndex === '') {
          this.fileList.push(file);
        } else {
          this.fileList[this.cxIndex] = file
          this.showReady = true
        }
      } else {
        file.status = 'faile'
        if (this.cxIndex === '') {
          this.fileList.push(file);
        } else {
          this.fileList[this.cxIndex] = file
          this.showReady = true
        }
      }
      this.fileLoading = false
    },
    handleError(err, file, fileList) {
      let errItem = {
        name: file.name,
        status: 'faile'
      }
      if (this.cxIndex === '') {
        this.fileList.push(errItem);
      } else {
        this.fileList[this.cxIndex] = errItem
        this.showReady = true
      }
      this.fileLoading = false
    },
    tonnage(row) {
      this.$router.push({ name: 'tonnage', params: row })
    },
    subRefuShipoPostionRefus() {
      let data = {
        id: this.shipPostionData.createData.id
      }
      this.$Api.CarriersShip_ShipSiteTable_GetData(data).then(res => {
        if (res.code == 200) {
          let newShipData = res.data.shipData;
          if (newShipData.isRepair == 1) {
            this.shipPostionData.createData.repairInfo = newShipData.repair_info
            this.shipPostionData.createData.remarks = newShipData.remarks
          } else {
            this.shipPostionData.createData.repairInfo = '无'
          }
          if (newShipData.shipStates == 2) {
            this.shipPostionData.createData.goOutInfo = newShipData.go_out_info
          } else {
            this.shipPostionData.createData.goOutInfo = '无'
          }
          if (res.data.currRow) {
            this.shipPostionData.createData.planNowLine = res.data.currRow.line
            this.shipPostionData.createData.planNowOilType = res.data.currRow.oilTypeTitle
            this.shipPostionData.createData.planNowType = res.data.currRow.companyName
          } else {
            this.shipPostionData.createData.planNowLine = '无'
            this.shipPostionData.createData.planNowOilType = '无'
            this.shipPostionData.createData.planNowType = '无'
          }
          if (res.data.prevRow) {
            this.shipPostionData.createData.planPrevLine = res.data.prevRow.line
            this.shipPostionData.createData.planPrevOilType = res.data.prevRow.oilTypeTitle
            this.shipPostionData.createData.planPrevType = res.data.prevRow.companyName
          } else {
            this.shipPostionData.createData.planPrevLine = '无'
            this.shipPostionData.createData.planPrevOilType = '无'
            this.shipPostionData.createData.planPrevType = '无'
          }
          //下面这里还要通过经纬度来获取船位地址信息
          let lng = this.shipPostionData.createData.longitude
          let lat = this.shipPostionData.createData.latitude
          if (lng != null && lat != null) { //调起第三方接口获取船位信息
            axios.get(mapConfig.url, {
              params: {
                postStr: { 'lon': lng, 'lat': lat, 'ver': 1 },
                type: 'geocode',
                tk: mapConfig.key
              }
            }).then(res => {
              this.shipPostionData.createData.site = res.result.addressComponent.city
            })
          }
        }
      })
    },
    position(row) {
      let data = {
        id: row.id
      }
      this.$Api.CarriersShip_ShipSiteTable(data).then(res => {
        if (res.code == 200) {
          if (res.data.tableData?.id) {
            this.shipPostionData.createData = {
              id: row.id,
              tonnage1: res.data.tableData.tonnage1,
              tonnage2: res.data.tableData.tonnage2,
              state: res.data.tableData.state,
              site: res.data.tableData.site,
              planNowLine: res.data.tableData.planNowLine,
              planNowOilType: res.data.tableData.planNowOilType,
              planNowType: res.data.tableData.planNowType,
              planPrevLine: res.data.tableData.planPrevLine,
              planPrevOilType: res.data.tableData.planPrevOilType,
              planPrevType: res.data.tableData.planPrevType,
              repairInfo: res.data.tableData.repairInfo,
              remarks: res.data.tableData.remarks,
              goOutInfo: res.data.tableData.goOutInfo,
              carriersName: row.carriers.title,
              shipName: row.title,
              latitude: res.data.tableData.latitude,
              longitude: res.data.tableData.longitude
            }
          } else {
            this.shipPostionData.createData = {
              id: row.id,
              tonnage1: '',
              tonnage2: '',
              state: '',
              site: '',
              planNowLine: '',
              planNowOilType: '',
              planNowType: '',
              planPrevLine: '',
              planPrevOilType: '',
              planPrevType: '',
              repairInfo: '',
              goOutInfo: '',
              remarks: '',
              carriersName: row.carriers.title,
              shipName: row.title
            }
          }
          this.shipPostionData.oilType = res.data.oilList
          this.shipPostionData.dialogVisible = true
        }
      })
    },
    subRefuShipoPostion() {
      this.$refs['formShipPostion'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.shipPostionData.createData.id,
            tonnage1: this.shipPostionData.createData.tonnage1,
            tonnage2: this.shipPostionData.createData.tonnage2,
            state: this.shipPostionData.createData.state,
            site: this.shipPostionData.createData.site,
            planNowLine: this.shipPostionData.createData.planNowLine,
            planNowOilType: this.shipPostionData.createData.planNowOilType,
            planNowType: this.shipPostionData.createData.planNowType,
            planPrevLine: this.shipPostionData.createData.planPrevLine,
            planPrevOilType: this.shipPostionData.createData.planPrevOilType,
            planPrevType: this.shipPostionData.createData.planPrevType,
            repairInfo: this.shipPostionData.createData.repairInfo,
            remarks: this.shipPostionData.createData.remarks,
            goOutInfo: this.shipPostionData.createData.goOutInfo
          }
          this.$Api.CarriersShip_ShipSiteTable_Save(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.shipPostionData.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    subRefuShipoverhaul() {
      this.$refs['formShipoverhaul'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.overhaulData.createData.id,
            repairEndTime: this.overhaulData.createData.repairEndTime,
            repairReason: this.overhaulData.createData.repairReason,
            repairStartTime: this.overhaulData.createData.repairStartTime,
            repairState: this.overhaulData.createData.repairState
          }
          if(this.overhaulData.createData.repairState == 4 && this.fileList.length > 0){
            let arr = []
            this.fileList.map(item=>{
              arr.push(item.response.data.save_path)
            })
            data.repairFile = arr.join(',')
          }
          this.$Api.CarriersShip_EditRepairApprovel(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.overhaulData.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    immediatelyShipOnline() {
      this.$refs['formShipOnline'].validate((valid) => {
        if (valid) {
          let data = {
            approveType: this.onlineData.createData.approveType,
            intro: this.onlineData.createData.intro,
            shipId: this.onlineData.createData.shipId,
          }
          this.$Api.CarriersShip_updateShipStates(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.onlineData.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    subRefuShipOnline() {
      this.$refs['formShipOnline'].validate((valid) => {
        if (valid) {
          let data = {
            applyType: 0,
            approveType: this.onlineData.createData.approveType,
            intro: this.onlineData.createData.intro,
            shipId: this.onlineData.createData.shipId,
          }
          this.$Api.CarriersShip_CreateApproval(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
              this.onlineData.dialogVisible = false
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },
    getType() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.myapprovalStatesList = res.data.myapprovalStatesList
        }
      })
    },
    looklog(row) {
      let data = {
        id: row.id,
        type: 3
      }
      this.$Api.MyAppRovalPublic_LogList(data).then(res => {
        let level = ['未知', '一级', '二级', '三级'];
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
            if (this.myapprovalStatesList.length > 0) {
              this.myapprovalStatesList.map((key, kindex) => {
                if (item.user_outcome == kindex) {
                  item.user_outcome_text = key
                }
              })
            }
            item.aprv_level_text = level[item.aprv_level]
          })
        }
        res.data.logList = res.data.logList
        this.logData = res.data
        this.dialogVisible3 = true;
      })
    },
    overhaul(row) {
      this.overhaulData.createData = {
        id: row.id,
        repairEndTime: '',
        repairReason: '',
        repairStartTime: '',
        repairState: '',
        carriersName: row.carriers.title,
        shipName: row.title
      }
      this.fileList = []
      this.overhaulData.dialogVisible = true
    },
    onlineEdite(row, id) {
      this.onlineData.createData = {
        approveType: '',
        intro: '',
        shipId: row.id,
        applyType: '',
        carriersName: row.carriers.title,
        shipName: row.title
      }
      this.onlineData.dialogVisible = true
      if (id == 1) {
        this.immediately = true
      } else {
        this.immediately = false
      }
    },
    subRefuShip() {
      if (this.userInfo.carriersinfo?.carriersId) {
        this.$refs['formShip'].validate((valid) => {
          if (valid) {
            let data = {
              acode: this.createData.acode,
              title: this.createData.title,
              ais: this.createData.ais,
              carriersId: this.userInfo.carriersinfo.carriersId,
              groupId: this.userInfo.groupid
            }
            this.$Api.CarriersShip_saveShip(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.dialogVisible = false
                this.getData()
              }
            })
          } else {
            return false;
          }
        });
      } else {
        this.$message.warning('非承运商用户，无法添加船舶！')
      }
    },
    addRole() {
      this.createData = {
        acode: '',
        title: "",
        ais: '',
        carriersId: '',
        groupId: ''
      }
      this.dialogVisible = true
    },
    editerow(row, id) {
      let data = row
      data.isEidte = id
      this.$router.push({ name: 'shipManageDetails', params: data })
    },
    deleterow(row) {
      this.$confirm('确定删除该船舶吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        this.$Api.CarriersShip_ship_delete(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            if (this.tableData.length == 1) this.page.currentPage = 1
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getOilType() {
      this.$Api.publics_oilType().then(res => {

      })
    },
    getshipRepairApplyType() {
      this.$Api.publics_shipRepairApplyType().then(res => {
        if (res.code == 200) {
          let jobs = []
          if (Object.keys(res.data).length > 0) {
            for (let key in res.data) {
              jobs.push(res.data[key])
            }
          }
          this.overhaulData.type = jobs
        }
      })
    },
    getShipOnlineType() {
      this.$Api.publics_shipOnlineApplyType().then(res => {
        if (res.code == 200) {
          let jobs = []
          if (Object.keys(res.data).length > 0) {
            for (let key in res.data) {
              jobs.push(res.data[key])
            }
          }
          this.onlineData.type = jobs
        }
      })
    },
    getCarriers() {
      let data = {
        groupType: 2,
        pid: 0,
        states: 1,
        show_type: 2,
        limit: 999
      }
      this.$Api.getDict(data).then(res => {
        if (res.code == 200) {
          this.carriers = res.data.data
        }
      })
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        title: this.searchForm.title,
        states: 1,
        carriersId: this.searchForm.carriersId
      }
      this.tableLoading = true
      this.$Api.CarriersShip_getPageShip(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = res.data.total;
          this.tableData = res.data.data
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.ShipManage {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 12px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    /deep/.el-table .cell button {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .addoreidt {
      height: 50vh;
      overflow-y: scroll;

      /deep/.el-steps {
        height: auto !important;
      }
    }

    .serverHead {
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #FBFBFB;
      border: 1px solid #DCDFE6;
      border-radius: 4px 0 0 4px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>