<template>
    <div class="page oildepot_details">
        <el-page-header @back="goBack" content="油品">
        </el-page-header>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-plus" size="small" type="primary"
                        @click="addRole">添加油品</el-button>
                </div>
                <div class="right"></div>
            </div>
            <!--列表-->
            <el-table v-if="isread" ref="multipleTable" border v-loading="tableLoading" :data="tableData"
                tooltip-effect="dark" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="ID" prop="id">
                </el-table-column>
                <el-table-column align="center" label="油品" prop="ailTitle">
                </el-table-column>
                <el-table-column align="center" label="录入日期" prop="dateYmd">
                </el-table-column>
                <el-table-column align="center" label="最大罐容" prop="capacity">
                </el-table-column>
                <el-table-column align="center" label="今日可发库存" prop="leftCapacity">
                </el-table-column>
                <el-table-column align="center" label="罐底库存" prop="emptyCapacity">
                </el-table-column>
                <el-table-column align="center" label="今日库存" prop="dailyCapacity">
                </el-table-column>
                <el-table-column align="center" label="今日库容空容" prop="todayEmptyCapacity">
                </el-table-column>
                <el-table-column align="center" label="今日在途" prop="todayOnline">
                </el-table-column>
                <el-table-column align="center" label="安全罐容" prop="safeCapacity">
                </el-table-column>
                <el-table-column align="center" label="预计可支撑天数" prop="leftDays">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加/编辑 -->
            <el-dialog :close-on-click-modal="false" :title="isAdd ? '添加' : '编辑'" :visible.sync="dialogVisible"
                destroy-on-close top="10vh" width="500px">
                <el-form ref="form" :model="createdData" :rules="rules" label-width="120px">
                    <el-form-item label="油品：" prop="oilTypeId">
                        <el-select v-model="createdData.oilTypeId" placeholder="直接选择或搜索后选择" filterable style="width: 100%;">
                            <el-option v-for="item in oilTypes" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="最大罐容：" prop="capacity">
                        <el-input v-model="createdData.capacity" type="number" placeholder="请输入油罐容量"></el-input>
                    </el-form-item>
                    <el-form-item label="罐底库存：" prop="emptyCapacity">
                        <el-input v-model="createdData.emptyCapacity" type="number" placeholder="请输入罐底库存"></el-input>
                    </el-form-item>
                    <el-form-item label="安全罐容：" prop="safeCapacity">
                        <el-input v-model="createdData.safeCapacity" type="number" placeholder="请输入安全罐容"></el-input>
                    </el-form-item>
                    <el-form-item label="预计支撑天数：" prop="leftDays">
                        <el-input v-model="createdData.leftDays" type="number" placeholder="请输入预计支撑天数"></el-input>
                    </el-form-item>
                    <el-form-item label="今日库存：" prop="dailyCapacity">
                        <el-input v-model="createdData.dailyCapacity" type="number" placeholder="请输入今日库存"></el-input>
                    </el-form-item>
                    <el-form-item label="今日可发库存：" prop="leftCapacity">
                        <el-input v-model="createdData.leftCapacity" type="number" placeholder="请输入今日可发库存"></el-input>
                    </el-form-item>
                    <el-form-item label="今日在途：" prop="todayOnline">
                        <el-input v-model="createdData.todayOnline" type="number" placeholder="请输入今日在途"></el-input>
                    </el-form-item>
                    <el-form-item label="今日库容空容：" prop="todayEmptyCapacity">
                        <el-input v-model="createdData.todayEmptyCapacity" type="number" placeholder="请输入今日库容空容"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="subRefuse">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "oildepot_details",
    data() {
        return {
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            createdData: {
                id: '',
                oilWarehouseId: '',
                oilTypeId: '',
                capacity: '',
                emptyCapacity: "",
                safeCapacity: "",
                leftDays: "",
                dailyCapacity: '',
                leftCapacity: '',
                todayOnline: '',
                todayEmptyCapacity: ''
            },
            rules: {
                oilTypeId: {
                    required: true, message: '请选择油品', trigger: 'blur'
                },
            },
            oilTypes: []
        }
    },
    created() {
        this.rowData = this.$route.params
        this.getData()
        this.getpublics_oilType()
    },
    activated() {
        this.rowData = this.$route.params
        this.getData()
        this.getpublics_oilType()
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        deleterow(row) {
            this.$confirm('确定删除该项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.$Api.oilWarehouseInfo_deleteById(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editerow(row) {
            this.createdData = {
                id: row.id,
                oilWarehouseId: this.rowData.id,
                oilTypeId: row.oilTypeId,
                capacity: row.capacity,
                emptyCapacity: row.emptyCapacity,
                safeCapacity: row.safeCapacity,
                leftDays: row.leftDays,
                dailyCapacity: row.dailyCapacity,
                leftCapacity: row.leftCapacity,
                todayOnline: row.todayOnline,
                todayEmptyCapacity: row.todayEmptyCapacity,
            }
            this.isAdd = false
            this.dialogVisible = true
        },
        subRefuse() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = {
                        oilWarehouseId: this.rowData.id,
                        oilTypeId: this.createdData.oilTypeId,
                        capacity: this.createdData.capacity == '' ? 0 : this.createdData.capacity,
                        emptyCapacity: this.createdData.emptyCapacity == '' ? 0 : this.createdData.emptyCapacity,
                        safeCapacity: this.createdData.safeCapacity == '' ? 0 : this.createdData.safeCapacity,
                        leftDays: this.createdData.leftDays == '' ? 0 : this.createdData.leftDays,
                        dailyCapacity: this.createdData.dailyCapacity == '' ? 0 : this.createdData.dailyCapacity,
                        leftCapacity: this.createdData.leftCapacity == '' ? 0 : this.createdData.leftCapacity,
                        todayOnline: this.createdData.todayOnline == '' ? 0 : this.createdData.todayOnline,
                        todayEmptyCapacity: this.createdData.todayEmptyCapacity == '' ? 0 : this.createdData.todayEmptyCapacity
                    }
                    if (!this.isAdd) data.id = this.createdData.id
                    this.$Api.oilWarehouseInfooilType_InsertOrUpdate(data).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.dialogVisible = false
                            this.clearCreateData()
                            this.getData()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        addRole() {
            this.dialogVisible = true
            this.isAdd = true
            this.clearCreateData()
        },
        clearCreateData() {
            this.createdData = {
                id: '',
                oilWarehouseId: '',
                oilTypeId: '',
                capacity: '',
                emptyCapacity: "",
                safeCapacity: "",
                leftDays: "",
                dailyCapacity: '',
                leftCapacity: '',
                todayOnline: '',
                todayEmptyCapacity: ''
            }
            this.$refs['form'].resetFields();
        },
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/info/other/oildepotDetails')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        getpublics_oilType() {
            let data = {
                page: 1,
                limit: 500
            }
            this.$Api.getPageOilType(data).then(res => {
                this.oilTypes = res.data.data
            })
        },
        getData() { // 获取数据
            let data = {
                oilWarehouseId: this.rowData.id,
            }
            this.tableLoading = true
            this.isread = false
            this.$Api.oilWarehouseInfo_Lists(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    this.tableData = res.data.list
                }
            })
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isread = true
                })
            })

        },
    }
}
</script>
  
<style lang="scss" scoped>
.oildepot_details {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    .container {
        width: 100%;
        height: 1px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }
    }
}
</style>