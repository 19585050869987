<template>
    <div class="page monthlyPlan_record">
        <el-page-header @back="goBack" content="完成数记录">
        </el-page-header>
        <div class="container">
            <!--列表-->
            <el-table ref="multipleTable" show-summary border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" label="标题" prop="title" >
                </el-table-column>
                <el-table-column align="center" label="计划类型" prop="planType">
                </el-table-column>
                <el-table-column align="center" label="月度" prop="monthly">
                </el-table-column>
                <el-table-column align="center" label="承运公司" prop="carrieName">
                </el-table-column>
                <el-table-column align="center" label="流向" prop="companyName">
                </el-table-column>
                <el-table-column align="center" label="船舶" prop="shipName" >
                </el-table-column>
                <el-table-column align="center" label="起始油厂" prop="startPort" >
                </el-table-column>
                <el-table-column align="center" label="到港" prop="destPort" >
                </el-table-column>
                <el-table-column align="center" label="油品" prop="oilType">
                </el-table-column>
                <el-table-column align="center" label="数量" prop="num">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "monthlyPlan_record",
    data() {
        return {
            isread: true,
            rowData: {},
            tableLoading: false,
            dialogVisible: false,
            tableData: [],
            isAdd: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            oilTypes: [],
            member: [],
            typeList: []
        }
    },
    created() {
        this.rowData = this.$route.params
        if (this.rowData?.id) {
            this.getData()
        }

    },
    activated() {
        this.rowData = this.$route.params
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        goBack() {
            this.$router.go(-1);
            this.removeNavBar('/planDispatch/MonthlyPlan_record')
        },
        removeNavBar(path) {
            this.$store.commit('removeNavBar', path)
            if (this.$route.path == path) {
                let url = this.$store.state.$navbar.length === 0 ? '/' : this.$store.state.$navbar[this.$store.state
                    .$navbar.length - 1].path
                this.openPage(url)
            }
        },
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData();
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        getData() { // 获取数据
            let data = {
                id: this.rowData.id,
                page: this.page.currentPage,
                limit: this.page.pageSize
            }
            this.tableLoading = true
            this.$Api.plan_monthly_monthLogDetail(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total
                    this.tableData = res.data.data
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.monthlyPlan_record {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    height: initial;

    .container {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 12px;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .title {
            margin: 0px 0 12px;
            padding-left: 15px;
            font-size: 16px;
        }

        .rolesBox {
            width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
</style>