<template>
  <div class="page ShipRectification">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="船舶名称：">
          <el-input v-model="searchForm.shipName" placeholder="请输入船舶名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="时间范围：">
          <el-date-picker size="small" v-model="searchForm.date" type="daterange" align="right" unlink-panels
            format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" value-format="yyyy-MM-dd"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary" 
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="筛选列" placement="top">
            <el-dropdown trigger="click" size="small">
              <el-button icon="el-icon-menu" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="checkList" @change="dropdownChange"
                  style="display: flex;flex-direction: column; padding: 5px 10px;">
                  <el-checkbox v-for="(item, index) in checkListData" :key="index" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
            <el-dropdown trigger="click" size="small" @command="commandClick">
              <el-button icon="el-icon-document-copy" size="mini"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="打印" placement="top">
            <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        height="520px" max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" type="index" width="60" v-if="checkList.indexOf('序号') != -1">
        </el-table-column>
        <el-table-column align="center" label="船舶" prop="title" v-if="checkList.indexOf('船舶') != -1">
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="carrierstitle" v-if="checkList.indexOf('承运商') != -1">
        </el-table-column>
        <el-table-column align="center" label="发运港" prop="getshipPortstartPortName"
          v-if="checkList.indexOf('发运港') != -1">
        </el-table-column>
        <el-table-column align="center" label="到港" prop="getshipPortdestPortName" v-if="checkList.indexOf('到港') != -1">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oilTypetitle" v-if="checkList.indexOf('品号') != -1">
        </el-table-column>
        <el-table-column align="center" label="数量" prop="oilTon" v-if="checkList.indexOf('数量') != -1">
        </el-table-column>
        <el-table-column align="center" label="当前位置" prop="site" v-if="checkList.indexOf('当前位置') != -1">
        </el-table-column>
        <el-table-column align="center" label="下达时间" prop="plancreateTime" v-if="checkList.indexOf('下达时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="检查录入时间" prop="createTime" v-if="checkList.indexOf('检查录入时间') != -1">
        </el-table-column>
        <el-table-column align="center" label="船舶检查状态" prop="book_title" v-if="checkList.indexOf('船舶检查状态') != -1">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.result == 1">检查通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.result == 2">检查不通过</el-tag>
            <el-tag type="info" v-else>待检查</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="得分" prop="grossscore" width="60" v-if="checkList.indexOf('得分') != -1">
        </el-table-column>
        <el-table-column align="center" label="操作" width="280" fixed="right">

          <template slot-scope="scope">
            <div v-if="scope.row.result == 0">
              <div v-if="scope.row.staskId > 0">
                <el-button type="primary" size="mini" @click="enterRow(scope.row)">录入结果</el-button>
                <el-button type="success" size="mini" @click="lookhistoryRow(scope.row)">查看历史结果</el-button>
              </div>
              <div v-else>
                <el-button type="primary" size="mini" @click="enterRow(scope.row)">录入结果</el-button>
              </div>
            </div>
            <div v-else>
              <div v-if="scope.row.states < 6">
                <el-button type="primary" size="mini" @click="lookRow(scope.row)">查看结果</el-button>
                <el-button type="primary" size="mini" @click="enterRow(scope.row)">重新录入</el-button>
              </div>
              <div v-else>
                <div v-if="scope.row.startPortModify == 1">
                  <el-button type="primary" size="mini" @click="lookRow(scope.row)">查看结果</el-button>
                  <el-button type="primary" size="mini" @click="enterRow(scope.row)">重新录入</el-button>
                </div>
                <el-button type="primary" size="mini" @click="lookRow(scope.row)">查看结果</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
          :page-size="page.pageSize" :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 录入检查结果 -->
      <el-dialog :close-on-click-modal="false" title="录入日常检查结果" :visible.sync="dialogVisible2" destroy-on-close
        top="20vh" width="650px">
        <div class="addoreidt" style="overflow-y: scroll;">
          <el-form ref="formTask" label-width="50px">
            <el-form-item label="附件:">
              <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="beforeUploadenter"
                :on-error="handleErrorenter" :on-success="onsuccessenter" :show-file-list="false">
                <el-button type="primary" style="margin: 8px 10px;" v-loading="btnLoadingenter"
                  icon="el-icon-upload2">上传检查单原件</el-button>
              </el-upload>
              <span v-if="enterData.originalPath != ''" @click="lookFile(enterData.originalPath)"
                style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
            </el-form-item>
            <el-form-item label="得分:">
              <el-input placeholder="请输入巡检得分" v-model="enterData.grossscore" clearable style="width: 180px;">
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" icon="el-icon-circle-plus" style="width: 150px;margin-bottom: 20px;"
            @click="addtaskItem">新增不合格项</el-button>
          <div class="addoreidtContent" v-if="task.length > 0">
            <el-form ref="formTask" label-width="130px">
              <div class="item" v-for="(item, index) in task" :key="index">
                <el-form-item :label="'检查标准' + (index + 1) + ':'">
                  <el-select v-model="item.shipCheckRuleItem" filterable placeholder="请选择" style="width: 100%;">
                    <el-option v-for="item in tasktype" :key="item.id" :label="item.checkContent" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="'问题描述' + (index + 1) + ':'">
                  <el-input placeholder="请输入内容" v-model="item.description" clearable>
                  </el-input>
                </el-form-item>
                <div class="itemFile">
                  <el-upload class="upload-demo" :action="'/api/upload/file'" :before-upload="item.beforeUpload"
                    :on-error="item.handleError" :on-success="item.onsuccess" :show-file-list="false">
                    <el-button type="primary" style="margin: 8px 10px;" v-loading="item.btnLoading" size="mini"
                      icon="el-icon-upload2" @click="setCurrindex(index)">上传附件</el-button>
                  </el-upload>
                  <span v-if="item.filePath != ''" @click="lookFile(item.filePath)"
                    style="margin: 0 25px 0 0px;cursor: pointer;">查看附件</span>
                  <el-button type="danger" icon="el-icon-delete" size="mini"
                    @click="delteTaskItem(index)">删除该项</el-button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitPassenter">通过</el-button>
          <el-button type="danger" @click="submitNoPassenter(2)">不通过</el-button>
          <el-button @click="submitNoPassenter(3)">下航次整改</el-button>
          <el-button @click="submitNoPassenter(4)">本航次整改</el-button>
          <el-button @click="dialogVisible2 = false">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 查看检查结果 -->
      <el-dialog :close-on-click-modal="false" title="问题与整改" :visible.sync="dialogVisible3" destroy-on-close top="20vh"
        width="550px">
        <div class="addoreidt" style="overflow-y: scroll;">
          <div class="addoreidtContent">
            <el-form ref="formTaskRsult" label-width="130px">
              <div class="item">
                <el-form-item label="附件：">
                  <el-button icon="el-icon-view" size="small" type="primary" @click="searchTable('search')">查看检查单原件
                  </el-button>
                  <el-button icon="el-icon-view" size="small" type="primary" @click="searchTable('search')">查看整改附件
                  </el-button>
                </el-form-item>
              </div>
              <div class="item" v-for="(item, index) in taskRsults" :key="index">
                <el-form-item :label="'问题' + (index + 1) + ':检查时间'">
                  <el-input v-model="item.createTime" disabled>
                  </el-input>
                </el-form-item>
                <el-form-item label="检查结果">
                  <el-input v-model="item.description" disabled>
                  </el-input>
                </el-form-item>
                <div class="itemFile" style="margin: 10px 20px 10px 0;">
                  <el-button v-if="item.filePath" type="primary" icon="el-icon-view" size="mini"
                    @click="lookFile(item.filePath)">查看结果附件</el-button>
                  <span v-else>未上传</span>
                </div>
                <el-form-item label="原因分析">
                  <el-input v-model="item.reason" disabled>
                  </el-input>
                </el-form-item>
                <el-form-item label="整改措施">
                  <el-input v-model="item.rectifyDesc" disabled>
                  </el-input>
                </el-form-item>
                <el-form-item label="预防措施">
                  <el-input v-model="item.preventMeasure" disabled>
                  </el-input>
                </el-form-item>
                <el-form-item label="整改附件">
                  <el-button v-if="item.originalcopyPath != null" type="primary" icon="el-icon-view" size="mini"
                    @click="lookFile(item.originalcopyPath)">查看</el-button>
                  <span v-else>未上传</span>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">关闭</el-button>
        </span>
      </el-dialog>
      <div id="detailInfo" v-show="false">
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">船舶</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">发运港</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">品号</th>
              <th class="tg-0lax">数量</th>
              <th class="tg-0lax">当前位置</th>
              <th class="tg-0lax">下达时间</th>
              <th class="tg-0lax">检查录入时间</th>
              <th class="tg-0lax">船舶检查状态</th>
              <th class="tg-0lax">得分</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in tableData">
            <th class="tg-0lax">{{ item.title }}</th>
            <th class="tg-0lax">{{ item.carrierstitle }}</th>
            <th class="tg-0lax">{{ item.getshipPortstartPortName }}</th>
            <th class="tg-0lax">{{ item.getshipPortdestPortName }}</th>
            <th class="tg-0lax">{{ item.oilTypetitle }}</th>
            <th class="tg-0lax">{{ item.oilTon }}</th>
            <th class="tg-0lax">{{ item.site }}</th>
            <th class="tg-0lax">{{ item.plancreateTime }}</th>
            <th class="tg-0lax">{{ item.createTime }}</th>
            <th class="tg-0lax">{{ item.result == 1 ? '检查通过' : item.result == 2 ? '检查不通过' : '待检查' }}</th>
            <th class="tg-0lax">{{ item.grossscore }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShipRectification",
  data() {
    return {
      task: [],
      tasktype: [],
      taskCurrIndex: 0,
      dialogVisible2: false,
      taskRsults: [],
      dialogVisible3: false,
      checkList: ['序号', '船舶', '承运商', '发运港', '到港', '品号', '数量', '当前位置', '下达时间', '检查录入时间', '船舶检查状态', '得分'],
      checkListData: ['序号', '船舶', '承运商', '发运港', '到港', '品号', '数量', '当前位置', '下达时间', '检查录入时间', '船舶检查状态', '得分'],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      multipleSelection: [],
      searchForm: {
        shipName: '',
        date: [],
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      isready: true,
      createdData: {},
      enterData: {
        id: '',
        taskId: '',
        shipId: '',
        result: '',
        grossscore: '',
        shipPlanId: '',
        originalPath: ''
      },
      btnLoadingenter: false,

    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getData()
    this.gettasktype()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    submitPassenter() {
      this.$confirm('确认要通过本次检查吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.enterData.originalPath == '') {
          this.$message.warning('请上传检查单原件后提交！')
          return
        }
        if (this.enterData.grossscore == '') {
          this.$message.warning('请输入巡检得分！')
          return
        }
        let data = {
          id: this.enterData.id,
          result: 1,
          grossscore: this.enterData.grossscore,
          shipId: this.enterData.shipId,
          shipPlanId: this.enterData.shipPlanId,
          originalPath: this.enterData.originalPath
        }
        this.$Api.security_updateshipCheckTaskbyid(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getData()
            this.dialogVisible2 = false
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    submitNoPassenter(index) {
      let tip = ''
      if (index == 2) {
        tip = '确认不通过本次检查吗？'
      } else if (index == 3) {
        tip = '确认提交本次检查为下航次整改吗？'
      } else {
        tip = '确认提交本次检查为本航次整改吗？'
      }
      this.$confirm(`${tip}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.enterData.originalPath == '' && index == 2) {
          this.$message.warning('请上传检查单原件后提交！')
          return
        }
        if (this.enterData.grossscore == '') {
          this.$message.warning('请输入巡检得分！')
          return
        }
        if (this.task.length == 0) {
          this.$message.warning('请录入不合格项后再提交整改！')
          return
        }
        let filereason = []
        this.task.map(item=>{
          filereason.push({
            filePath:item.filePath,
            description:item.description,
            shipCheckRuleItem:item.shipCheckRuleItem
          })
        })
        let data = {
          taskId: this.enterData.id,//列表id
          result: index,// 2:不通过  3:下航次整改 4:本航次整改
          grossscore: this.enterData.grossscore, //得分
          shipId: this.enterData.shipId,//船舶id
          shipPlanId: this.enterData.shipPlanId,//船舶航次id
          originalPath: this.enterData.originalPath,//检查单原件
          filereason:filereason//不合格项
        }
        this.$Api.security_addshipRectif(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getData()
            this.dialogVisible2 = false
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    gettasktype() {
      this.$Api.security_getshipCheckRuleHistory({ checkType: 1 }).then(res => {
        if (res.code == 200) {
          this.tasktype = res.data
        }
      })
    },
    enterRow(row) {
      this.enterData = {
        id: row.id,
        taskId: row.id,
        shipId: row.shipId,
        shipPlanId: row.shipPlanId,
        result: '',
        grossscore: '',
        originalPath: ''
      }
      this.task = []
      this.dialogVisible2 = true
    },
    delteTaskItem(index) {
      this.task.splice(index, 1)
    },
    lookFile(url) {
      window.open(url, '_blank');
    },
    commandClick(e) {
      if (e === 'Csv') {
        console.log('Csv');
      }
      if (e === 'Excel') {
        if (this.tableData.length > 0) {
          let tableData = []
          this.tableData.map(item => {
            item.cbjcztlg = item.result == 1 ? '检查通过' : item.result == 2 ? '检查不通过' : '待检查'
            tableData.push(item)
          })
          let config = [
            { field: 'title', width: 80, align: 'center', title: '船舶' },
            { field: 'carrierstitle', width: 80, align: 'center', title: '承运商' },
            { field: 'getshipPortstartPortName', width: 80, align: 'center', title: '发运港' },
            { field: 'getshipPortdestPortName', width: 150, align: 'center', title: '到港' },
            { field: 'oilTypetitle', width: 150, align: 'center', title: '品号' },
            { field: 'oilTon', width: 150, align: 'center', title: '数量' },
            { field: 'site', width: 80, align: 'center', title: '当前位置' },
            { field: 'plancreateTime', width: 80, align: 'center', title: '下达时间' },
            { field: 'createTime', width: 80, align: 'center', title: '检查录入时间' },
            { field: 'cbjcztlg', width: 80, align: 'center', title: '船舶检查状态' },
            { field: 'grossscore', width: 100, align: 'center', title: '得分' },
          ]
          let obj = {
            name: '日常检查',
            excel_config: JSON.stringify(config),
            data: JSON.stringify(tableData),
          }
          this.$Api.execl_out(obj).then(res => {
            if (res.code === 200) {
              var a = document.createElement('a') // 创建一个<a></a>标签
              a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
              a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
              a.style.display = 'none'           // 隐藏a标签
              document.body.appendChild(a)       // 将a标签追加到文档对象中
              a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
              a.remove()                        // 一次性的，用完就删除a标签
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '暂无数据'
          });
        }
      }
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)
    },
    addtaskItem() {
      let that = this
      this.task.push({
        that: this,
        description: '',
        filePath: '',
        shipCheckRuleItem: '',
        btnLoading: false,
        beforeUpload: function () {
          that.task[that.taskCurrIndex].btnLoading = true
        },
        handleError: function () {
          that.$message.error('上传失败，请重新上传！')
          that.task[that.taskCurrIndex].btnLoading = false
        },
        onsuccess: function (response) {
          if (response.code == 200) {
            that.task[that.taskCurrIndex].filePath = response.data.save_path
          }
          that.task[that.taskCurrIndex].btnLoading = false
        }
      })
    },
    setCurrindex(index) {
      this.taskCurrIndex = index
    },
    beforeUploadenter() {
      this.btnLoadingenter = true
    },
    handleErrorenter() {
      this.$message.error('上传失败，请重新上传！')
      this.btnLoadingenter = false
    },
    onsuccessenter(response) {
      if (response.code == 200) {
        this.enterData.originalPath = response.data.save_path
      }
      this.btnLoadingenter = false
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    dropdownChange(e) {
      this.isready = false
      this.$nextTick(() => {
        this.isready = true
      })
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        checkType: 1,
        shipName: this.searchForm.shipName,
        createTimeStart: this.searchForm.date[0] ? this.searchForm.date[0] : '',
        createTimeOver: this.searchForm.date[1] ? this.searchForm.date[1] : '',
      }
      this.tableLoading = true
      this.$Api.security_getcertifiedcheckday(data).then(res => {
        this.tableLoading = false
        if (res.data.total) this.page.total = Number(res.data.total[0].SUM);
        this.tableData = res.data.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.ShipRectification {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .addoreidt {
      width: 100%;
      height: 50vh;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .addoreidtContent {
        width: 100%;
        height: 1px;
        flex: 1;

        .item {
          padding: 12px 0;
          border-top: 1px solid #f6f6f6;
          border-bottom: 1px solid #f6f6f6;

          .itemFile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
</style>