<template>
  <div class="page planExamine">
    <div class="tabsBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="航次计划审批" name="first"></el-tab-pane>
        <el-tab-pane label="预排计划审批" name="second"></el-tab-pane>
        <el-tab-pane label="改港计划审批" name="third"></el-tab-pane>
        <el-tab-pane label="船舶外出计划审批" name="fourth"></el-tab-pane>
        <el-tab-pane label="发运信息更改审批" name="send"></el-tab-pane>
        <el-tab-pane label="船舶吨位变更审批" name="tonnage"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
          <el-button class="u-custom-btn-primary" icon="el-icon-check" size="small" type="primary"
            @click="batchpass">批量通过</el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-close" size="small" type="danger"
            @click="batchrefuse">批量拒绝</el-button>
        </div>
        <div class="right">
        </div>
      </div>
      <!--列表 航次计划审批-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'first'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="shipIdName">
        </el-table-column>
        <el-table-column align="center" label="承运公司" prop="companyIdName">
        </el-table-column>
        <el-table-column align="center" label="计划下达时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="出发港" prop="startPortName">
        </el-table-column>
        <el-table-column align="center" label="到达港" prop="destPortName">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oilTypeIdName">
        </el-table-column>
        <el-table-column align="center" label="数量" prop="oilTon" width="110">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="states_text">
          <template slot-scope="scope">
            <div>{{ dict.approvalStatesList[scope.row.states] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="user_outcome_states_text">
          <template slot-scope="scope">
            <span v-if="scope.row.myapproval">{{ scope.row.myapproval.user_outcome_states_text }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookData(scope.row, 'first')">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row, 'first')">日志</el-button>
            <el-button type="success" size="mini" @click="pass(scope.row, 2, 'first')"
              v-if="scope.row.myapproval.user_outcome == 3 || (scope.row.approveStates == scope.row.myapproval.aprv_level && scope.row.myapproval.user_outcome == 1) && scope.row.approveStates != 6">通过</el-button>
            <el-button type="danger" size="mini" @click="pass(scope.row, 3, 'first')"
              v-if="scope.row.approveStates == scope.row.myapproval.aprv_level">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'first'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 查看申请 -->
      <el-dialog :close-on-click-modal="false" title="查看" :visible.sync="createdData.dialogVisible" destroy-on-close
        top="12vh" width="850px">
        <div class="addBox">
          <el-form ref="formAddDetails" :model="createdData.data" label-width="130px">
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="月份:" prop="monthly">
                  <el-input v-model="createdData.data.monthly" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="属性:" prop="benlongId">
                  <el-input v-model="createdData.data.benlongId" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="承运商名称:" prop="companyId">
                  <el-input v-model="createdData.data.companyId" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="船名:" prop="shipId">
                  <el-input v-model="createdData.data.shipId" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="出发港:" prop="startPort">
                  <el-input v-model="createdData.data.startPort" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="办事处:" prop="banshichu">
                  <el-input v-model="createdData.data.banshichu" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="到达港:" prop="destPort">
                  <el-input v-model="createdData.data.destPort" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="油库:" prop="oilWaireHouse">
                  <el-input v-model="createdData.data.oilWaireHouse" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="12">
                <el-form-item label="油品:" prop="oilTypeId">
                  <el-input v-model="createdData.data.oilTypeId" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="数量:" prop="oilTon">
                  <el-input v-model="createdData.data.oilTon" size="small" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createdData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="createdData.dialogVisible = false">确定</el-button>
        </span>
      </el-dialog>
      <!-- 预排计划审批 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'second'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="states_text">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="myapproval.user_outcome_states_text">
          <template slot-scope="scope">
            <span v-if="scope.row.myapproval">{{ scope.row.myapproval.user_outcome_states_text }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="viewRow(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row, 'second')">日志</el-button>
            <el-button
              v-if="scope.row.myapproval.user_outcome == 3 || (scope.row.myapproval.user_outcome == 1 && scope.row.approveStates == scope.row.myapproval.aprv_level)"
              type="success" size="mini" @click="pass(scope.row, 2, 'second')">通过</el-button>
            <el-button v-if="scope.row.approveStates == scope.row.myapproval.aprv_level" type="danger" size="mini"
              @click="pass(scope.row, 3, 'second')">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'second'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page2.currentPage"
          :page-size="page2.pageSize" :total="page2.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 改港计划审批 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'third'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="发港" prop="startPortName">
        </el-table-column>
        <el-table-column align="center" label="运输方式" prop="destPortName">
        </el-table-column>
        <el-table-column align="center" label="流向" prop="belongIdName">
        </el-table-column>
        <el-table-column align="center" label="到达港" prop="destPortName">
        </el-table-column>
        <el-table-column align="center" label="品号" prop="oilTypeIdName">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="shipIdName">
        </el-table-column>
        <el-table-column align="center" label="承运公司" prop="companyIdName">
        </el-table-column>
        <el-table-column align="center" label="原发量" prop="shipErpAmount">
        </el-table-column>
        <el-table-column align="center" label="调整后到港" prop="destPort1OrdestPort2">
        </el-table-column>
        <el-table-column align="center" label="改港原因" prop="reason">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="states">
          <template slot-scope="scope">
            <div>{{ dict.approvalStatesList[scope.row.states] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="myapproval">
          <template slot-scope="scope">
            <span v-if="scope.row.myapproval">{{ scope.row.myapproval.user_outcome_states_text }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookData(scope.row, 'third')">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row, 'third')">日志</el-button>
            <el-button type="success" size="mini" @click="pass(scope.row, 2, 'third')"
              v-if="scope.row.myapproval.user_outcome == 3 || (scope.row.approveStates == scope.row.myapproval.aprv_level && scope.row.myapproval.user_outcome == 1) && scope.row.approveStates != 6">通过</el-button>
            <el-button type="danger" size="mini" @click="pass(scope.row, 3, 'third')"
              v-if="scope.row.approveStates == scope.row.myapproval.aprv_level">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'third'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page3.currentPage"
          :page-size="page3.pageSize" :total="page3.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 船舶外出计划审批 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'fourth'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="船名" prop="shipIdName">
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="companyIdName">
        </el-table-column>
        <el-table-column align="center" label="起始炼厂" prop="oilWarehouse">
        </el-table-column>
        <el-table-column align="center" label="到港" prop="destPort">
        </el-table-column>
        <el-table-column align="center" label="油品" prop="oilTypeIdName">
        </el-table-column>
        <el-table-column align="center" label="预计返回时间" prop="expectBackTime">
        </el-table-column>
        <el-table-column align="center" label="船舶状态" prop="shipStatesName">
        </el-table-column>
        <el-table-column align="center" label="计划状态" prop="shipOutStatesName">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="approveStatesName">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="myapproval.user_outcome_states_text">
        </el-table-column>
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookData(scope.row, 'fourth')">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row, 'fourth')">日志</el-button>
            <el-button type="success" size="mini" @click="pass(scope.row, 2, 'fourth')"
              v-if="scope.row.myapproval.user_outcome == 3 || (scope.row.approveStates == scope.row.myapproval.aprv_level && scope.row.myapproval.user_outcome == 1) && scope.row.approveStates != 6">通过</el-button>
            <el-button type="danger" size="mini" @click="pass(scope.row, 3, 'fourth')"
              v-if="scope.row.approveStates == scope.row.myapproval.aprv_level">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'fourth'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page4.currentPage"
          :page-size="page4.pageSize" :total="page4.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 查看 -->
      <el-dialog :close-on-click-modal="false" title="查看" :visible.sync="createdData.dialogVisible4" destroy-on-close
        top="15vh" width="650px">
        <div class="addBox">
          <el-form ref="formAdd" :model="createdData.data4" label-width="130px">
            <el-form-item label="船舶：" prop="shipIdName">
              <el-input v-model="createdData.data4.shipIdName" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="外出属性：" prop="goOutStates">
              <el-input v-model="createdData.data4.goOutStates" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="流向：" prop="ssgsLx">
              <el-input v-model="createdData.data4.ssgsLx" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="输入起始炼厂：" prop="oilWarehouse">
              <el-input v-model="createdData.data4.oilWarehouse" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="到达港：" prop="destPort">
              <el-input v-model="createdData.data4.destPort" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="油品：" prop="oilTypeIdName">
              <el-input v-model="createdData.data4.oilTypeIdName" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="预计返回时间：" prop="expectBackTime">
              <el-input v-model="createdData.data4.expectBackTime" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="船舶状态：" prop="shipStatesName">
              <el-input v-model="createdData.data4.shipStatesName" disabled size="small"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createdData.dialogVisible4 = false">取 消</el-button>
          <el-button type="primary" @click="createdData.dialogVisible4 = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 发运信息更改审批 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'send'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="标题" prop="id">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="book_title">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="book_title">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="add_time">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'send'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page5.currentPage"
          :page-size="page5.pageSize" :total="page5.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 船舶吨位变更审批 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
        v-if="activeName == 'tonnage'" @selection-change="handleSelectionChange" style="width: 98%;margin:0 auto;"
        header-row-class-name="header-table">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="标题" prop="id">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="book_title">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="book_title">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="add_time">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editerow(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleterow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;" v-if="activeName == 'tonnage'">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page6.currentPage"
          :page-size="page6.pageSize" :total="page6.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible" destroy-on-close top="15vh">
        <div class="addoreidt">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level_text">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="user_outcome_text">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="update_time">
              <template slot-scope="scope">
                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
import staticData from "@/utils/static-data.js"
export default {
  name: "planExamine",
  data() {
    return {

      staticData: {},
      logData: {},
      dialogVisible: false,
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page2: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page3: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page4: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page5: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      page6: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      activeName: 'third',
      dict: {
        carrierList: [],
        companyList: [],
        monthlyList: [],
        oilList: [],
        shipList: [],
        shipPortBegin: [],
        shipPortEnd: [],
        shipPortList: [],
        shipPlanStates: [],
        approvalStatesList: []
      },
      createdData: {
        data: {
          monthly: '',
          companyId: '',
          shipId: '',
          startPort: '',
          destPort: '',
          oilTypeId: '',
          benlongId: '',
          banshichu: '',
          oilWaireHouse: '',
          oilType: '',
          oilTon: ''
        },
        dialogVisible: false,
        myapprovalStatesList: [],
        data4: {
          shipIdName: "",
          goOutStates: '',
          ssgsLx: '',
          oilWarehouse: '',
          destPort: '',
          oilTypeIdName: '',
          expectBackTime: '',
          shipStatesName: ''
        },
        dialogVisible4: false,
      },
    }
  },
  created() {
    this.staticData = staticData
    this.getapprovalStatesList()
    this.getDcit().then(() => {
      this.handleClick({ name: this.activeName })
    })
    this.getType()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    batchpass() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选选项')
        return
      }
      let arr = []
      this.multipleSelection.map(item => {
        arr.push(item.id)
      })
      let data = {
        msg: '',
        ids: arr.join(',')
      }
      switch (this.activeName) {
        case 'first':
          data.type = 6
          break;
        case 'second':
          data.type = 7
          break;
        case 'third':
          data.type = 8
          break;
        case 'fourth':
          data.type = 9
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
      this.$Api.myAppRovalPublicOkMyAppRovalList(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
          this.getData()
        }
      })
    },
    batchrefuse() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选选项')
        return
      }
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputValidator: this.validator,
        // inputErrorMessage: '拒绝原因不能为空'
      }).then(({ value }) => {
        if (value == '' || value == null) {
          this.$message.warning('请输入拒绝原因')
          return
        }
        let arr = []
        this.multipleSelection.map(item => {
          arr.push(item.id)
        })
        let data = {
          msg: '',
          ids: arr.join(',')
        }
        switch (this.activeName) {
          case 'first':
            data.type = 6
            break;
          case 'second':
            data.type = 7
            break;
          case 'third':
            data.type = 8
            break;
          case 'fourth':
            data.type = 9
            break;
          case 'send':

            break;
          case 'tonnage':

            break;

          default:
            break;
        }
        this.$Api.myAppRovalPublicFailMyAppRovalList(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    pass(row, id, name) {
      let data = {}
      switch (name) {
        case 'first':
          data.type = 6
          break;
        case 'second':
          data.type = 7
          break;
        case 'third':
          data.type = 8
          break;
        case 'fourth':
          data.type = 9
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
      if (id == 2) {
        this.$confirm('确定是否通过?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          data.table_id = row.id
          data.user_outcome = id
          this.$Api.plan_approvePublic_OkMyAppRoval(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.getData()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        this.$prompt('请输入拒绝原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // inputValidator: this.validator,
          // inputErrorMessage: '拒绝原因不能为空'
        }).then(({ value }) => {
          if (value == '' || value == null) {
            this.$message.warning('请输入拒绝原因')
            return
          }
          data.table_id = row.id
          data.user_outcome = 3
          data.msg = value
          this.$Api.plan_approvePublic_OkMyAppRoval(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.getData()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    getType() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.myapprovalStatesList = res.data.myapprovalStatesList
        }
      })
    },
    viewRow(row) {
      console.log(row);
      this.$router.push({
        path: '/planDispatch/walkthroughsPlanExamineDetails',
        query: {
          monthly: row.monthly,
        }
      })
    },
    looklog(row, name) {
      let data = {
        id: row.id
      }
      switch (name) {
        case 'first':
          data.type = 6
          break;
        case 'second':
          data.type = 7
          break;
        case 'third':
          data.type = 8
          break;
        case 'fourth':
          data.type = 9
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
      this.$Api.MyAppRovalPublic_LogList(data).then(res => {
        let level = ['未知', '一级', '二级', '三级'];
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
            if (this.myapprovalStatesList.length > 0) {
              this.myapprovalStatesList.map((key, kindex) => {
                if (item.user_outcome == kindex) {
                  item.user_outcome_text = key
                }
              })
            }
            item.aprv_level_text = level[item.aprv_level]
          })
        }
        res.data.logList = res.data.logList
        this.logData = res.data
        this.dialogVisible = true;
      })
    },
    lookData(row, name) {
      switch (name) {
        case 'first':
          let data = {
            id: row.id
          }
          this.$Api.ShipPlan_View(data).then(res => {
            if (res.code == 200) {
              let data = res.data
              this.createdData.data = {
                monthly: data.monthly,
                companyId: data.companyId,
                shipId: data.shipId,
                startPort: data.startPort,
                destPort: data.destPort,
                oilTypeId: data.oilTypeId,
                benlongId: data.benlongId,
                banshichu: data.benlongId,
                oilWaireHouse: data.oilWaireHouse,
                oilType: data.oilType,
                oilTon: data.oilTon
              }
              this.createdData.dialogVisible = true
            }
          })
          break;
        case 'second':

          break;
        case 'third':

          break;
        case 'fourth':
          this.createdData.data4 = {
            shipIdName: row.shipIdName,
            goOutStates: row.goOutStates,
            ssgsLx: row.ssgsLx,
            oilWarehouse: row.oilWarehouse,
            destPort: row.destPort,
            oilTypeIdName: row.oilTypeIdName,
            expectBackTime: row.expectBackTime,
            shipStatesName: row.shipStatesName
          }
          this.createdData.dialogVisible4 = true
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
    },
    addRole() {
      console.log('添加');
    },
    commandClick() { },
    print() { },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.getDatafirst()
          break;
        case 'second':
          this.getDatasecond()
          break;
        case 'third':
          this.getDatathird()
          break;
        case 'fourth':
          this.getDatafourth()
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      switch (this.activeName) {
        case 'first':
          this.page.pageSize = val
          this.getDatafirst()
          break;
        case 'second':
          this.page2.pageSize = val
          this.getDatasecond()
          break;
        case 'third':
          this.page3.pageSize = val
          this.getDatathird()
          break;
        case 'fourth':
          this.page4.pageSize = val
          this.getDatafourth()
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
    },
    changePage(val) { //选择第几页
      switch (this.activeName) {
        case 'first':
          this.page.currentPage = val;
          this.getDatafirst()
          break;
        case 'second':
          this.page2.currentPage = val;
          this.getDatasecond()
          break;
        case 'third':
          this.page3.currentPage = val;
          this.getDatathird()
          break;
        case 'fourth':
          this.page4.currentPage = val;
          this.getDatafourth()
          break;
        case 'send':

          break;
        case 'tonnage':

          break;

        default:
          break;
      }
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getapprovalStatesList() {
      let data = {
        type: 6
      }
      this.$Api.myAppRovalPublic_ShipPlanDict(data).then(res => {
        if (res.code == 200) {
          this.dict.approvalStatesList = res.data.approvalStatesList
        }
      })
    },
    getDcit() {
      return new Promise(async (resolve, reject) => {
        let res = await this.$Api.shipPlan_Dict()
        if (res.code == 200) {
          this.dict.carrierList = res.data.carrierList
          this.dict.companyList = res.data.companyList
          this.dict.monthlyList = res.data.monthlyList
          this.dict.oilList = res.data.oilList
          this.dict.shipList = res.data.shipList
          this.dict.shipPortBegin = res.data.shipPortBegin
          this.dict.shipPortEnd = res.data.shipPortEnd
          this.dict.shipPortList = res.data.shipPortList
        }
        resolve('success')
      })
    },
    getDatathird() {
      let data = {
        page: this.page3.currentPage,
        limit: this.page3.pageSize,
        type: 8
      }
      this.tableLoading = true
      this.$Api.myAppRovalPublic_ShipPlanChangeList(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page3.total = Number(res.data.total);
          if (res.data.dataList.length > 0) {
            res.data.dataList.map(item => {
              res.data.myapproval.forEach(approval => {
                if (approval.table_id == item.id) {
                  item.myapproval = approval
                }
              })
              if (this.dict.shipList.length > 0) {
                this.dict.shipList.map(key => {
                  if (item.shipId == key.id) {
                    item.shipIdName = key.title
                  }
                })
              }
              if (this.dict.carrierList.length > 0) {
                this.dict.carrierList.map(key => {
                  if (item.companyId == key.id) {
                    item.companyIdName = key.subtitle
                  }
                })
              }
              if (this.dict.shipPortList.length > 0) {
                this.dict.shipPortList.map(key => {
                  if (item.startPort == key.id) {
                    item.startPortName = key.title
                  }
                  if (item.destPort == key.id) {
                    item.destPortName = key.title
                  }
                })
                let str = ''
                if (item.destPort1 != 0 && item.destPort1 != null) {
                  this.dict.shipPortList.map(key => {
                    if (item.destPort1 == key.id) {
                      str = str + key.title + '-'
                    }
                    if (item.destPort2 != 0 && item.destPort2 != null) {
                      if (item.destPort2 == key.id) {
                        str = str + key.title
                      }
                    }
                  })
                  item.destPort1OrdestPort2 = str
                }
              }
              if (this.dict.oilList.length > 0) {
                this.dict.oilList.map(key => {
                  if (item.oilTypeId == key.id) {
                    item.oilTypeIdName = key.title
                  }
                })
              }
              if (this.dict.companyList.length > 0) {
                this.dict.companyList.map(key => {
                  if (item.belongId == key.id) {
                    item.belongIdName = key.title
                  }
                })
              }

            })
            this.tableData = res.data.dataList
          }
        }
      })
    },
    getDatafirst() {
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        type: 6
      }
      this.tableLoading = true
      this.$Api.myAppRovalPublic_ShipPlanList(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = Number(res.data.total);
          if (res.data.dataList.length > 0) {
            res.data.dataList.map(item => {
              res.data.myapproval.forEach(approval => {
                if (approval.table_id == item.id) {
                  item.myapproval = approval
                }
              })
              if (this.dict.shipList.length > 0) {
                this.dict.shipList.map(key => {
                  if (item.shipId == key.id) {
                    item.shipIdName = key.title
                  }
                })
              }
              if (this.dict.carrierList.length > 0) {
                this.dict.carrierList.map(key => {
                  if (item.companyId == key.id) {
                    item.companyIdName = key.subtitle
                  }
                })
              }
              if (this.dict.shipPortList.length > 0) {
                this.dict.shipPortList.map(key => {
                  if (item.startPort == key.id) {
                    item.startPortName = key.title
                  }
                  if (item.destPort == key.id) {
                    item.destPortName = key.title
                  }
                })
              }
              if (this.dict.oilList.length > 0) {
                this.dict.oilList.map(key => {
                  if (item.oilTypeId == key.id) {
                    item.oilTypeIdName = key.title
                  }
                })
              }

            })
            this.tableData = res.data.dataList
          }
        }
      })
    },
    getDatasecond() { // 获取数据
      let data = {
        page: this.page2.currentPage,
        limit: this.page2.pageSize,
        type: '7',
      }
      this.tableLoading = true
      this.$Api.plan_approvePublic_getList(data).then(res => {
        this.tableLoading = false
        if (res.data.dataList.total) this.page2.total = res.data.dataList.total;
        this.tableData = res.data.dataList.data.map(item => {
          res.data.myapproval.forEach(approval => {
            if (approval.table_id == item.id) {
              item.myapproval = approval
            }
          })
          return item
        })
      })
    },
    getDatafourth() { // 获取数据
      let data = {
        page: this.page4.currentPage,
        limit: this.page4.pageSize,
        type: 9
      }
      this.tableLoading = true
      this.$Api.myAppRovalPublic_List9(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.dataList.total) this.page4.total = res.data.dataList.total;
          if (res.data.dataList.data.length > 0) {
            res.data.dataList.data.map(item => {
              item.shipStatesName = this.staticData.shipStates[item.shipStates]
              item.shipOutStatesName = this.staticData.shipOutStates[item.outStates]
              item.approveStatesName = this.staticData.approveStates[item.approveStates]
              res.data.myapproval.forEach(approval => {
                if (approval.table_id == item.id) {
                  item.myapproval = approval
                }
              })
              if (this.dict.shipList.length > 0) {
                this.dict.shipList.map(key => {
                  if (item.shipId == key.id) {
                    item.shipIdName = key.title
                  }
                })
              }
              if (this.dict.carrierList.length > 0) {
                this.dict.carrierList.map(key => {
                  if (item.carrierId == key.id) {
                    item.companyIdName = key.subtitle
                  }
                })
              }
              if (this.dict.oilList.length > 0) {
                this.dict.oilList.map(key => {
                  if (item.oilTypeId == key.id) {
                    item.oilTypeIdName = key.title
                  }
                })
              }
            })
            this.tableData = res.data.dataList.data
          }
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.planExamine {
  height: initial;

  .searchBox {
    /deep/.el-table .el-table__cell {
      padding: 4px 0;
    }
  }

  .container {
    width: 100%;
    // height: 800px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 15px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .item {
          margin-left: 10px;

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
          }
        }
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

  }

  .addBox {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 20px;

    .el-select {
      width: 100%;
    }
  }

  .tabsBox {
    width: 98%;
    margin: 10px auto;
  }
}
</style>