<template>
  <div class="page ClaimantNotice">
    <!-- <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="时间范围：">
          <el-date-picker size="small" v-model="searchForm.dateResult" type="daterange" align="right" unlink-panels
            value-format="yyyy-MM-dd" format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <div class="container">
      <div class="operateBox">
        <div class="left">
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" width="60" label="序号" prop="ROWNUMBER">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createdTime">
          <template slot-scope="scope">
            {{ toTime(scope.row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="承运商" prop="carrierstitle">
        </el-table-column>
        <el-table-column align="center" label="航次数" prop="sum">
        </el-table-column>
        <el-table-column align="center" label="赔付数量(吨)" prop="claimSum">
        </el-table-column>
        <el-table-column align="center" label="赔付金额" prop="totalPrice">
        </el-table-column>
        <el-table-column align="center" label="是否已发送" prop="issentInformCarrier">
          <template slot-scope="scope">
            <span>{{ scope.row.issentInformCarrier == 20 ? '已发送' : '未发送' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="scope.row.issentInformCarrier == 10"
              @click="editerow(scope.row, 1)">确认</el-button>
            <el-button type="primary" size="mini" @click="editerow(scope.row, 2)">撤回</el-button>
            <el-button type="primary" size="mini" @click="deleterow(scope.row)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="华中分公司水路运输超耗赔付通知书" :visible.sync="dialogVisible" width="82%" :before-close="handleClose">
      <div class="detailInfo" id="detailInfo">
        <div>
          XSHZ-{{ detailList[0]?.createdTimeData.replace("年", "").replace("月", "").replace("日", "") + detailList[0]?.id }}
        </div>
        <div>
          <h1 style="margin-top:10px;margin-bottom:50px; text-align:center;">华中分公司水路运输超耗赔付通知书</h1>
          <p>{{ detailInfo.carrierstitle }}</p>
          <p style="margin:5px 5px 30px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据GPS水运物流调度系统统计结果{{
            detailInfo.yearMonth }}贵公司配送至{{ belongdetail[0]?.belongIdName }}油品超耗{{
    belongdetail[0]?.sum }}航次，经收货方、承运商双方确认，华中分公司审核，超耗总量为{{ belongdetail[0]?.claimSum }}吨，应赔付金额为{{
    belongdetail[0]?.totalPrice }}
            元，请贵公司收到水路运输超耗赔付通知书后，将超耗赔付款汇至相应收货单位，并将汇款凭证电子版上传至水运系统中。</p>
        </div>
        <table id="sumsbTableId" class="tg" style="margin:auto;width:80%;">
          <thead>
            <tr>
              <th colspan="5">华中分公司水路配送油品运输超耗汇总表</th>
            </tr>
            <tr>
              <th class="tg-0pky">省市公司</th>
              <th class="tg-0pky">航次数</th>
              <th class="tg-0pky">赔付数量(吨)</th>
              <th class="tg-0pky">赔付金额(元)</th>
              <th class="tg-0pky">备注</th>
            </tr>
            <tr>
              <th class="tg-0pky">合计</th>
              <th class="tg-0pky">{{ toTotol('sum') }}</th>
              <th class="tg-0pky">{{ toTotol('claimSum') }}</th>
              <th class="tg-0pky">{{ toTotol('totalPrice') }}</th>
              <th class="tg-0pky"></th>
            </tr>
            <tr v-for="item in belongdetail">
              <th class="tg-0pky">{{ item.belongIdName }}</th>
              <th class="tg-0pky">{{ item.sum }}</th>
              <th class="tg-0pky">{{ item.claimSum }}</th>
              <th class="tg-0pky">{{ item.totalPrice }}</th>
              <th class="tg-0pky"></th>
            </tr>
          </thead>
        </table>
        <p name="default" style="margin-left:10%;font-weight:600;">备注：超耗明细见附表</p>
        <div style="text-align:center;margin-left:65%">
          <p name="default">华中分公司</p>
          <p id="manager_dept" name="default">经营管理部 安全环保部</p>
          <p name="default" id="nyr">{{ detailInfo?.createdTime?.split(' ')[0] }}</p>
        </div>
        <div style="page-break-after: always;"></div>
        <p name="default" style="margin:50px 5px 5px 5px;font-weight:600;">超耗明细</p>
        <table id="sumTableId" class="tg">
          <thead>
            <tr>
              <th class="tg-0lax">序号</th>
              <th class="tg-0lax">月份</th>
              <th class="tg-0lax">船名</th>
              <th class="tg-0lax">始发港</th>
              <th class="tg-0lax">到港</th>
              <th class="tg-0lax">收卸库</th>
              <th class="tg-0lax">流向</th>
              <th class="tg-0lax">油品</th>
              <th class="tg-0lax">原发量（吨）</th>
              <th class="tg-0lax">验收量（吨）</th>
              <th class="tg-0lax">损溢量（吨）</th>
              <th class="tg-0lax">定损量（吨）</th>
              <th class="tg-0lax">超耗量（吨）</th>
              <th class="tg-0lax">索赔量（吨）</th>
              <th class="tg-0lax">承运商</th>
              <th class="tg-0lax">收卸时间</th>
              <th class="tg-0lax">索赔单价(元)</th>
              <th class="tg-0lax">索赔金额(元)</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in detailList">
            <th class="tg-0lax">{{ index + 1 }}</th>
            <th class="tg-0lax">{{ item.monthlydate }}</th>
            <th class="tg-0lax">{{ item.shiptitle }}</th>
            <th class="tg-0lax">{{ item.startPortName }}</th>
            <th class="tg-0lax">{{ item.destPortName }}</th>
            <th class="tg-0lax">{{ item.closeddischargelibrary }}</th>
            <th class="tg-0lax">{{ item.belongIdName }}</th>
            <th class="tg-0lax">{{ item.oilTypeName }}</th>
            <th class="tg-0lax">{{ round(item.shipErpAmount, 3) }}</th>
            <th class="tg-0lax">{{ round(item.realTon, 3) }}</th>
            <th class="tg-0lax">{{ round(item.shipErpAmount - item.realTon, 3) }}</th>
            <th class="tg-0lax">{{ round(item.shipErpAmount * item.realTon, 3) }}</th>
            <th class="tg-0lax">{{ round(item.shipErpAmount, 3) }}</th>
            <th class="tg-0lax">{{ ((item.shipErpAmount - item.realTon) - (item.feerate * item.shipErpAmount)) <= 0 ? 0 :
              round(((item.shipErpAmount - item.realTon) - (item.feerate * item.shipErpAmount)), 3) }}</th>
            <th class="tg-0lax">{{ item.carrierstitle }}</th>
            <th class="tg-0lax">{{ item.arrivalTime == null ? '无' : item.arrivalTime.replace(" 0:00:00", "") }}</th>
            <th class="tg-0lax">{{ (item.practicalprice / ((item.shipErpAmount - item.realTon) - (item.feerate *
              item.shipErpAmount))).toString() == "Infinity" ? '0' : round_0(item.practicalprice, 2) }}</th>
            <th class="tg-0lax">{{ round_0((item.practicalprice * item.claimSum), 2) }}</th>
          </tr>
        </table>

        <div class="sign" style="text-align:center;">

          <p name="people1">部门负责人</p>

          <p style="margin-left:30%" name="people2">审核人</p>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="print">打 印</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  name: "ClaimantNotice",
  data() {
    return {
      multipleSelection: [],
      dialogVisible: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      searchForm: {
        name: '',
        is_hid: [],
        dateResult: ''
      },
      tableLoading: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      detailInfo: {},
      detailList: [],
      belongdetail: [],
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    this.getData()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    round_0(v, e) {
      var t = 1;
      for (; e > 0; t *= 10, e--);
      for (; e < 0; t /= 10, e++);
      return this.formatnumber(Math.round(v * t) / t, 2);


    },
    round(v, e) {
      var t = 1;
      for (; e > 0; t *= 10, e--);
      for (; e < 0; t /= 10, e++);
      return this.formatnumber(Math.round(v * t) / t, 3);
    },
    formatnumber(value, num) {
      let _value = value.toString();
      let _dot = _value.indexOf(".");
      let _valueLen = _value.length;
      if (num == 0) {
        if (_dot != -1) {
          _value = _value.substring(0, _dot);
        }
      } else {//如果没有小数点
        if (_dot == -1) {
          _value = _value + ".";
          for (let i = 1; i <= num; i++) {
            _value = _value + "0";
          }
        } else {//有小数点，超出位数自动截取，否则补0
          _value = _value.substring(0, _dot + num + 1);
          for (let i = _valueLen; i <= _dot + num; i++) {
            _value = _value + "0";
          }
        }
      }
      return _value;
    },
    toTotol(e) {
      let a = 0
      this.belongdetail.map(i => {
        a = a + i[e]
      })
      return a
    },
    print() {
      // let a = document.getElementById('detailInfo')
      // window.print(a.innerHTML)
      let printableContent = document.getElementById('detailInfo').innerHTML
      let printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

.detailInfo .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.detailInfo td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print()
      }, 200)

    },
    handleClose() {
      this.dialogVisible = false
    },
    toTime(e) {
      return e.split(' ')[0]
    },
    addRole() {
      console.log('添加');
    },
    handleSelectionChange() {
      this.multipleSelection = val;
    },
    editerow(row, a) {
      console.log('editerow');
      if (a === 1) {
        this.$confirm('确定发送赔付通知单吗?', '信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$Api.severalQuality_sentInformCarrier({ compensateinvoicesId: row.id }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.message
              });
            }
          })
        }).catch(() => {

        });
      } else {
        this.$confirm('确定要撤销索赔通知单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$Api.severalQuality_updatecompensateinvoices({ compensateinvoicesId: row.id }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.message
              });
            }
          })
        }).catch(() => {

        });
      }
    },
    deleterow(row) {
      console.log(JSON.parse(row.detail), JSON.parse(row.belongdetail));
      this.detailInfo = row
      this.detailList = JSON.parse(row.detail)
      this.belongdetail = JSON.parse(row.belongdetail)
      this.dialogVisible = true;
    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      this.tableLoading = true
      this.$Api.severalQuality_getcompensateinvoices(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = Number(res.data.total);
          this.tableData = res.data.payments
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.ClaimantNotice {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    /deep/.el-dialog__body {
      overflow-x: scroll;
    }

    .detailInfo {
      overflow-x: scroll;

      .tg {
        border-collapse: collapse;
        border-spacing: 0;

        td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 10px 5px;
          word-break: normal;
        }

        th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 10px 5px;
          word-break: normal;
        }

        .tg-0lax {
          border-color: inherit;
          text-align: center;
          vertical-align: middle;
        }
      }
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .tip {
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
<style>
.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

.detailInfo .tg {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}

.detailInfo td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.detailInfo .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
</style>