<template>
  <div class="page examine">
    <div class="container">
      <div class="tip">
        <el-button icon="el-icon-refresh-right" size="small" @click="getData">刷新</el-button>
        <el-button size="small" type="primary" @click="batchpass">批量通过</el-button>
        <el-button size="small" type="danger" @click="batchReject">批量拒绝</el-button>
      </div>
      <!-- 列表 -->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" style="width: 98%;margin:0 auto;"
        tooltip-effect="dark" @selection-change="handleSelectionChange" header-row-class-name="header-table">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="类型" prop="approveName" width="180">
        </el-table-column>
        <el-table-column align="center" label="标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="approveStatesName" width="180">
        </el-table-column>
        <el-table-column align="center" label="我的审核" prop="user_outcomeName" width="180">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="180">
        </el-table-column>
        <el-table-column align="center" label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="look(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="log(scope.row)">日志</el-button>
            <el-button type="success" size="mini" @click="approve(scope.row)">通过</el-button>
            <el-button type="danger" size="mini" @click="refuse(scope.row)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 查看 -->
      <el-dialog :close-on-click-modal="false" title="查看" :visible.sync="dialogVisible" destroy-on-close top="15vh"
        width="700px">
        <div>
          <el-form class="search-form" label-position="right" label-width="120px">
            <el-form-item label="承运商名称：">
              <div class="color_999">{{ lookData.groupTitle || ' ' }}</div>
            </el-form-item>
            <el-form-item label="船名：">
              <div class="color_999">{{ lookData.shipTitle || ' ' }}</div>
            </el-form-item>
            <el-form-item label="最后检查时间：">
              <div class="color_999">{{ lookData.lastCheckTime || ' ' }}</div>
            </el-form-item>
            <el-form-item label="最后检查结果：">
              <div class="color_999">{{ lookData.lastCheckStates || ' ' }}</div>
            </el-form-item>
            <el-form-item label="原因：">
              <div class="reason color_999">{{ lookData.intro || ' ' }}</div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="审核日志" :visible.sync="dialogVisible2" destroy-on-close top="15vh">
        <div class="dialogCont">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="(item.username?item.username:'未知') + '  ' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="approveStatesName">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="create_time">
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 拒绝原因 -->
      <el-dialog :close-on-click-modal="false" title="请输入拒绝原因" :visible.sync="dialogVisible3" destroy-on-close top="30vh"
        width="500px">
        <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="reasonCont">
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="subRefuse">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Examine",
  data() {
    return {
      reasonCont: '',
      reasonRow: '',
      multipleSelection: [],
      searchForm: {
        name: '',
        is_hid: [],
      },
      isbatch: false,
      batchIds:[],
      batchType: '',
      tableLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      lookData: {},
      logData: {},
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getDictState()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    batchpass() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选待审批项')
        return
      }
      if (this.multipleSelection.length > 0) {
        let types = []
        let ids = []
        this.multipleSelection.forEach(item => {
          types.push(item.type)
          ids.push(item.data_id)
        })
        let resulst = Array.from(new Set(types))
        if (resulst.length > 1) {
          this.$message.warning('请勾选相同类型的待审批项')
          return
        }
        let data = {
          type: resulst[0],
          msg: '',
          ids: ids.join(',')
        }
        this.$Api.myAppRovalPublicOkMyAppRovalList(data).then(res => {
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }
    },
    batchReject() {
      this.isbatch = true
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先勾选待审批项')
        return
      }
      if (this.multipleSelection.length > 0) {
        let types = []
        let ids = []
        this.multipleSelection.forEach(item => {
          types.push(item.type)
          ids.push(item.data_id)
        })
        let resulst = Array.from(new Set(types))
        if (resulst.length > 1) {
          this.$message.warning('请勾选相同类型的待审批项')
          return
        }
        this.dialogVisible3 = true
        this.reasonCont = ''
        this.batchIds = ids
        this.batchType = resulst
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    subRefuse() {
      if (this.reasonCont == '') {
        this.$message.warning('请填写拒绝原因')
        return
      }
      if (!this.isbatch) {
        let data = {
          msg: this.reasonCont,
          table_id: this.reasonRow.data_id,
          type: this.reasonRow.data_type,
          user_outcome: 3
        }
        this.$Api.listByState_refuse(data).then(res => {
          this.dialogVisible3 = false
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      } else {
        let  data = {
          msg: this.reasonCont,
          ids:this.batchIds.join(','),
          type: this.batchType[0]
        }
        this.$Api.myAppRovalPublicFailMyAppRovalList(data).then(res => {
          this.dialogVisible3 = false
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
          }
        })
      }

    },
    refuse(row) {//拒绝
      this.isbatch = false
      this.dialogVisible3 = true
      this.reasonCont = ''
      this.reasonRow = row
    },
    approve(row) { //通过
      this.$confirm('确定审核通过吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          msg: '',
          table_id: row.data_id,
          type: row.type,
          user_outcome: 2
        }
        this.$Api.myAppRovalPublicOkMyAppRoval(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    look(row) {//查看
      let data = {
        id: row.data_id
      }
      this.$Api.listByState_look(data).then(res => {
        this.lookData = res.data
        this.dialogVisible = true;
      })
    },
    log(row) {//日志
      let data = {
        id: row.data_id,
        type: '4'
      }
      this.$Api.myAppRovalPublicLogList(data).then(res => {
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        this.logData = res.data
        this.dialogVisible2 = true;
      })
    },
    editerow() {
      console.log('editerow');
    },
    deleterow() {

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getDictState() {
      this.$Api.myAppRovalPublicDictState().then(() => {

      });
    },
    getData() { // 获取数据
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        state: 1
      }
      this.tableLoading = true
      this.$Api.getListByState(data).then(res => {
        this.tableLoading = false
        if (res.data.count) this.page.total = res.data.count;
        this.tableData = res.data.list
      })
    },

  }
}
</script>
  
<style lang="scss" scoped>
.examine {
  height: inherit;

  .container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-radius: 10px;

    .tip {
      width: 100%;
      padding: 15px 15px 15px;
      border-bottom: 1px solid #f6f6f6;
      color: #333;
      border-radius: 2px 2px 0 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      i {
        color: #1e9fff;
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .reason {
      height: 100px;
      overflow-y: scroll;
    }

    .color_999 {
      color: #999;
    }

    .dialogCont {
      height: 50vh;
      overflow-y: scroll;
    }

    /deep/.el-steps--vertical {
      height: initial;
    }
  }
}
</style>