<template>
  <div class="page AssessmentResultsRelease">
    <div class="searchBox">
      <el-form :model="searchForm" class="search-form" inline>
        <el-form-item label="考核标题：">
          <el-input v-model="searchForm.title" placeholder="请输入考核标题" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
            @click="searchTable('search')">搜索
          </el-button>
          <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
            @click="searchTable('reset')">重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <div class="operateBox">
        <div class="left">
        </div>
        <div class="right"></div>
      </div>
      <!--列表-->
      <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark" height="520px"
        max-height="(100%-104px)" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
        <el-table-column align="center" label="考核标题" prop="title">
        </el-table-column>
        <el-table-column align="center" label="考核时间" prop="startTime">
        </el-table-column>
        <el-table-column align="center" label="结束时间" prop="endTime">
        </el-table-column>
        <el-table-column align="center" label="统计方式" prop="assessType">
          <template slot-scope="scope">
            <span v-if="scope.row.assessType == 1">月度考核</span>
            <span v-else-if="scope.row.assessType == 2">季度考核</span>
            <span v-else-if="scope.row.assessType == 3">年度考核</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="范围" prop="sumDate">
        </el-table-column>
        <el-table-column align="center" label="发布状态" prop="isPublish">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.isPublish == 0">未发布</el-tag>
            <el-tag type="success" v-else>已发布</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="lookDetails(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="looklog(scope.row)"
              v-if="scope.row.isPublish != 0">审核日志</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
        <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage" :page-size="page.pageSize"
          :total="page.total" @current-change="changePage" @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!-- 日志 -->
      <el-dialog :close-on-click-modal="false" title="日志" :visible.sync="dialogVisible2" destroy-on-close top="15vh">
        <div class="dialogCont">
          <el-steps direction="vertical" :active="1" :space="80">
            <el-step v-for="(item, index) in logData.logList" :title="item.create_time"
              :description="item.username ? item.username + '  ' + item.log_info : '' + item.log_info"></el-step>
          </el-steps>
          <!-- 列表 -->
          <el-table ref="multipleTable" border :data="logData.outcomeList" style="width: 98%;margin:0 auto;"
            tooltip-effect="dark" header-row-class-name="header-table">
            <el-table-column align="center" label="审核级别" prop="aprv_level_text">
            </el-table-column>
            <el-table-column align="center" label="审核人员" prop="username">
            </el-table-column>
            <el-table-column align="center" label="审核结果" prop="user_outcome_text">
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="update_time">
              <template slot-scope="scope">
                <div>{{ scope.row.user_outcome > 1 ? scope.row.update_time : '' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AssessmentResultsRelease",
  data() {
    return {
      multipleSelection: [],
      searchForm: {
        title: ''
      },
      tableLoading: false,
      dialogVisible2: false,
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
        lastPage: 1,
      },
      tableData: [],
      myapprovalStatesList: [],
      logData: {},
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      var key = e.keyCode;
      if (key == 13) {
        that.searchTable('search')
      }
    }
    that.getData()
    that.getType()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    looklog(row) {
      let data = {
        id: row.id,
        type: 5
      }
      this.$Api.MyAppRovalPublic_LogList(data).then(res => {
        let level = ['未知', '一级', '二级', '三级'];
        if (res.data.logList.length != 0) {
          res.data.logList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
          })
        }
        if (res.data.outcomeList.length != 0) {
          res.data.outcomeList.map(item => {
            res.data.userList.map(key => {
              if (item.user_id == key.id) {
                item.username = key.title
              }
            })
            if (this.myapprovalStatesList.length > 0) {
              this.myapprovalStatesList.map((key, kindex) => {
                if (item.user_outcome == kindex) {
                  item.user_outcome_text = key
                }
              })
            }
            item.aprv_level_text = level[item.aprv_level]
          })
        }
        res.data.logList = res.data.logList
        this.logData = res.data
        this.dialogVisible2 = true;
      })
    },
    lookDetails(row) {
      this.$router.push({ name: 'AssessmentResultsReleaseDetails', params: row })
    },
    editerow(row) {

      console.log('editerow');
    },
    deleterow() {

    },
    // ----------------------------------------------------------------
    handleSizeChange(val) { //切换每页条数
      this.page.pageSize = val;
      this.getData()
    },
    changePage(val) { //选择第几页
      this.page.currentPage = val;
      this.getData()
    },
    searchTable(t) { // 搜索/重置
      if (t === 'search') {
        this.page.currentPage = 1
        this.getData()
      } else {
        for (const key in this.searchForm) {
          if (this.searchForm[key] instanceof Array) {
            this.searchForm[key] = []
          } else {
            this.searchForm[key] = ''
          }
        }
        this.getData()
      }
    },
    getType() {
      this.$Api.myAppRovalPublicDictState().then(res => {
        if (res.code == 200) {
          this.myapprovalStatesList = res.data.myapprovalStatesList
        }
      })
    },
    getData() { // 获取数据
      let data = {
        title: this.searchForm.title,
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      this.tableLoading = true
      this.$Api.carriersResultList_getMemberAssessSocerResultList(data).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          if (res.data.total) this.page.total = res.data.total
          this.tableData = res.data.data
        }
      })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.AssessmentResultsRelease {
  .container {
    width: 100%;
    height: 1px;
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(214, 216, 219);
      border-radius: 4px;
    }

    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    .operateBox {
      margin: 0px auto 10px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .dialogCont {
      height: 50vh;
      overflow-y: scroll;

      /deep/.el-steps--vertical {
        height: initial;
      }
    }
  }
}
</style>