<template>
	<div :style="{ width: collapse ? '64px' : '240px' }" class="aside">
		<transition name="sidebarLogoFade">
			<div class="header u-flex u-col-center u-row-center">
				<!-- <img v-if="!collapse" :style="{ marginRight: collapse ? '0' : '12px' }" alt="logo" src="../assets/img/top_bg.jpg">
				<img v-else class="simg" alt="logo" src="../assets/img/logo222.png"> -->
			</div>
		</transition>
		<el-scrollbar class="u-row-nowrap" style="height: calc(100% - 50px);box-shadow: 4px 0 20px 1px rgba(0, 0, 0, 0.2);">
			<el-menu :collapse="collapse" :collapse-transition="false" :default-active="active" :unique-opened="true"
				active-text-color="#fff" background-color="#293449" text-color="#fff" class="menu-box" router>
				<!-- <menu-tree :list="list"></menu-tree> -->
				<template v-for="item in list">
					<template >
						<el-submenu v-if="item._child" :index="item.href">
							<template slot="title">
								<!-- <i :class="item.icon ? item.icon : 'el-icon-menu'"
									:style="{ visibility: item.icon ? 'visible' : 'hidden' }"></i> -->
								<span slot="title">{{ item.title }}</span>
							</template>
							<menu-tree :list="item._child"></menu-tree>
						</el-submenu>
						<el-menu-item v-else :index="item.href">
							<!-- <i :class="item.icon ? item.icon : 'el-icon-menu'"
								:style="{ visibility: item.icon ? 'visible' : 'hidden' }"></i> -->
							<span slot="title">{{ item.title }}</span>
						</el-menu-item>
					</template>
				</template>
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
import menuTree from "@/components/menuTree"
export default {
	name: "asideNav",
	components: {
		menuTree,
	},
	data() {
		return {
			menu:[],
			active: '',
			title: '',
			logo: '',
			list: [
			]
		}
	},
	watch: {
		'$route.path'(val) {
			this.setNavBar()
			this.$nextTick(() => {
				this.active = val
			})
		}
	},
	computed: {
		collapse() {
			return this.$store.state.collapse
		}
	},
	created() {
		// JSON.parse(localStorage.getItem('vuex')).menuList
		this.list = JSON.parse(localStorage.getItem('vuex')).menuList
		this.setMenu(this.list)
		let ishome = this.list.filter(item => {
			return item.href == '/home'
		})

		if (ishome.length == 0) {
			let ischild = this.list.filter(item => {
				return item?._child
			})
			if (ischild.length != 0) {
				this.active = this.list[0]._child[0].href
				this.$router.push({ path: this.active })
			} else {
				this.active = this.list[0].href
				this.$router.push({ path: this.active })
			}
		} else {
			this.active = this.$route.path
		}
		this.setNavBar()

		let screenWidth = document.body.clientWidth
		if (screenWidth <= 1200) {
			this.$store.commit('setCollapse', true)
		} else {
			this.$store.commit('setCollapse', false)
		}
		// const web_config = sessionStorage.getItem('web_config') ? sessionStorage.getItem('web_config') : '';
		// if (web_config) {
		// 	this.title = JSON.parse(web_config).title
		// 	this.logo = JSON.parse(web_config).site_logo
		// } else {
		// 	this.$http.get('backend/web_config').then(res => {
		// 		if (res.code === 1) {
		// 			this.title = res.data.title
		// 			this.logo = res.data.site_logo
		// 		}
		// 	})
		// }
		
	},
	mounted() {
		let that = this
		window.addEventListener('resize', function () {
			return (() => {
				let screenWidth = document.body.clientWidth

				if (screenWidth <= 1200) {
					that.$store.commit('setCollapse', true)
				} else {
					that.$store.commit('setCollapse', false)
				}
			})()
		})
		this.$bus.$on('changeMnue',()=>{
			this.list = this._deepClone(this.$store.state.userInfo.tree_role_menu)
			this.setMenu(this.list)
			let ishome = this.list.filter(item => {
				return item.href == '/home'
			})

			if (ishome.length == 0) {
				let ischild = this.list.filter(item => {
					return item?._child
				})
				if (ischild.length != 0) {
					this.active = this.list[0]._child[0].href
					this.$router.push({ path: this.active })
				} else {
					this.active = this.list[0].href
					this.$router.push({ path: this.active })
				}
			} else {
				this.active = this.$route.path
			}
			this.setNavBar()

			let screenWidth = document.body.clientWidth
			if (screenWidth <= 1200) {
				this.$store.commit('setCollapse', true)
			} else {
				this.$store.commit('setCollapse', false)
			}
			const web_config = sessionStorage.getItem('web_config') ? sessionStorage.getItem('web_config') : '';
			if (web_config) {
				this.title = JSON.parse(web_config).title
				this.logo = JSON.parse(web_config).site_logo
			} else {
				this.$http.get('backend/web_config').then(res => {
					if (res.code === 1) {
						this.title = res.data.title
						this.logo = res.data.site_logo
					}
				})
			}
		})
	},
	methods: {
		setMenu(arr) {
			arr.map(item => {
				if (item._child) {
					if (item.type === 'C') {
						delete item._child
					} else {
						this.setMenu(item._child)
					}
				}
			})
		},
		setNavBar() {
			if (this.$route.path !== '/' && this.$route.path !== '/home') {
				let list = this.$store.state.$navbar || []
				let flag = list.some(item => item.path === this.$route.path)
				list = flag ? list : [...list, {
					title:this.$route.meta.name.split(',').length!=1?this.$route.meta.name.split(',')[this.$route.meta.name.split(',').length-1]:this.$route.meta.name,
					path: this.$route.path
				}]
				this.$store.commit('setNavBar', list)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
	transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
	opacity: 0;
}

::v-deep.aside {
	height: 100vh;
	transition: width linear .28s;
	
	position: relative;
	z-index: 2;
	background-color: #293449;

	.header {
		height: 50px;
		font-weight: bold;
		background-color: #FFF;
		// box-shadow: 0 4px 20px 1px rgb(0 0 0 / 8%);
		// border-right: 1px solid #e6e6e6;

		img {
			width: 166px;
			height: 50px;
		}

		span {
			white-space: nowrap;
			font-size: 16px;
			font-weight: bold;
		}
		.simg{
			width: 45px;
    		height: 45px;
		}
	}

	.el-menu {
		border-right: none;
	}

	.el-menu-item.is-active {
		background-color: #409EFF !important;
	}

	::v-deep.menu-box.el-menu--collapse {
		width: 100%;
		min-height: calc(100vh - 50px);
		border-right: none;


		.el-menu-item:focus,
		.el-menu-item:hover {}

		.el-sub-menu {
			.el-menu-item {
				padding-left: 40px;
				background-color: #F9FAFB !important;

				i {
					width: 24px;
					height: 24px;
					margin-right: 5px;
				}
			}
		}
	}
}
</style>
