<template>
    <div class="page TariffDefend">
        <div class="searchBox">
            <el-form :model="searchForm" class="search-form" inline>
                <el-form-item label="月份：">
                    <el-date-picker v-model="searchForm.startTimeStart" type="date" size="small" placeholder="选择日期"  value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="发港：">
                    <el-select v-model="searchForm.startPort" placeholder="请选择发港" clearable size="small">
                        <el-option v-for="(item,index) in shipPortBegin" :label="item.title" :value="item.code" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="到港：">
                    <el-select v-model="searchForm.destPort" placeholder="请选择到港" clearable size="small">
                        <el-option v-for="(item,index) in shipPortEnd" :label="item.title" :value="item.code" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="u-custom-btn-primary" icon="el-icon-search" size="small" type="primary"
                        @click="searchTable('search')">搜索
                    </el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh-right" plain size="small" type="primary"
                        @click="searchTable('reset')">重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="container">
            <div class="operateBox">
                <div class="left">
                    <el-button class="u-custom-btn-primary" icon="el-icon-refresh" size="small" type="primary"
                        @click="addRole">同步运价</el-button>
                    <el-button class="u-custom-btn-primary" icon="el-icon-download" size="small" type="primary"
                        @click="addRole">导出运价</el-button>
                </div>
                <div class="right">
                    <el-tooltip class="item" effect="dark" content="导出" placement="top">
                        <el-dropdown trigger="click" size="small" @command="commandClick">
                            <el-button icon="el-icon-document-copy" size="mini"></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="Csv">导出到 Csv 文件</el-dropdown-item>
                                <el-dropdown-item command="Excel">导出到 Excel 文件</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打印" placement="top">
                        <el-button icon="el-icon-printer" size="mini" @click="print"></el-button>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table ref="multipleTable" border v-loading="tableLoading" :data="tableData" tooltip-effect="dark"
                height="800px" style="width: 98%;margin:0 auto;" header-row-class-name="header-table">
                <el-table-column align="center" type="index" width="55">
                </el-table-column>
                <el-table-column align="center" label="生效时间" prop="DATAB">
                </el-table-column>
                <el-table-column align="center" label="始发港口" prop="LIFNR_ZH_NAME" >
                </el-table-column>
                <el-table-column align="center" label="目的港口" prop="RSTAT_NAME">
                </el-table-column>
                <el-table-column align="center" label="运距(KM)" prop="YJ1" >
                </el-table-column>
                <el-table-column align="center" label="物料编码组" prop="MATKL" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.MATKL=='0603'">汽油</div>
                        <div v-else-if="scope.row.MATKL=='0607'">柴油</div>
                        <div v-else>{{ scope.row.MATKL }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="运输方式" prop="MOT" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.MOT=='Z003'">水运</div>
                        <div v-else>{{ scope.row.MOT }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="运价(元/吨)" prop="YUNJIA">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="u-flex u-p-t-15 u-p-b-24" style="width: 98%;margin:0 auto;">
                <el-pagination :page-sizes="[10, 15, 20, 25, 50, 100]" :current-page="page.currentPage"
                    :page-size="page.pageSize" :total="page.total" @current-change="changePage"
                    @size-change="handleSizeChange" layout="total,sizes ,prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div id="detailInfo" v-show="false">
                <table id="sumTableId" class="tg">
                    <thead>
                        <tr>
                            <th class="tg-0lax">生效时间</th>
                            <th class="tg-0lax">始发港口</th>
                            <th class="tg-0lax">目的港口</th>
                            <th class="tg-0lax">运距(KM)</th>
                            <th class="tg-0lax">物料编码组</th>
                            <th class="tg-0lax">运输方式</th>
                            <th class="tg-0lax">运价(元/吨)</th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in tableData">
                        <th class="tg-0lax">{{ item.DATAB }}</th>
                        <th class="tg-0lax">{{ item.LIFNR_ZH_NAME }}</th>
                        <th class="tg-0lax">{{ item.RSTAT_NAME }}</th>
                        <th class="tg-0lax">{{ item.YJ1 }}</th>
                        <th class="tg-0lax">{{ item.MATKL == '0603'?'汽油':item.MATKL == '0607'?'柴油':item.MATKL }}</th>
                        <th class="tg-0lax">{{ item.MOT == 'Z003'?'水运':item.MOT }}</th>
                        <th class="tg-0lax">{{ item.YUNJIA }}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TariffDefend",
    data() {
        return {
            multipleSelection: [],
            searchForm: {
                destPort:"",
                startPort:"",
                startTimeStart:''
            },
            tableLoading: false,
            page: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                lastPage: 1,
            },
            tableData: [],
            shipPortBegin:[],
            shipPortEnd:[]
        }
    },
    created() {
        let that = this
        document.onkeydown = function (e) {
            var key = e.keyCode;
            if (key == 13) {
                that.searchTable('search')
            }
        }
        this.getData()
        this.getDict()
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
    },
    methods: {
        commandClick(e) {
            if (e === 'Csv') {
                console.log('Csv');
            }
            if (e === 'Excel') {
                if (this.tableData.length > 0) {
                    let tableData = []
                    this.tableData.map(item => {
                        item.wlbmzlg = ''
                        item.ysfslg = ''
                        if(item.MATKL == '0603'){
                            item.wlbmzlg = '汽油'
                        }else if(item.MATKL == '0607'){
                            item.wlbmzlg = '柴油'
                        }else{
                            item.wlbmzlg = item.MATKL
                        }
                        if(item.MOT == 'Z003'){
                            item.ysfslg = '水运'
                        }else{
                            item.ysfslg = item.MOT
                        }
                        tableData.push(item)
                    })
                    let config = [
                        { field: 'DATAB', width: 80, align: 'center', title: '生效时间' },
                        { field: 'LIFNR_ZH_NAME', width: 80, align: 'center', title: '始发港口' },
                        { field: 'RSTAT_NAME', width: 80, align: 'center', title: '目的港口' },
                        { field: 'YJ1', width: 150, align: 'center', title: '运距(KM)' },
                        { field: 'wlbmzlg', width: 150, align: 'center', title: '物料编码组' },
                        { field: 'ysfslg', width: 150, align: 'center', title: '运输方式' },
                        { field: 'YUNJIA', width: 80, align: 'center', title: '运价(元/吨)' },
                    ]
                    let obj = {
                        name: '运价维护',
                        excel_config: JSON.stringify(config),
                        data: JSON.stringify(tableData),
                    }
                    this.$Api.execl_out(obj).then(res => {
                        if (res.code === 200) {
                            var a = document.createElement('a') // 创建一个<a></a>标签
                            a.href = res.data         //重点（如测试发现下载文件不存在/找不到，检查路径）
                            a.download = res.data.split('/').slice(-1)[0]     // 设置下载文件文件名
                            a.style.display = 'none'           // 隐藏a标签
                            document.body.appendChild(a)       // 将a标签追加到文档对象中
                            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                            a.remove()                        // 一次性的，用完就删除a标签
                        }
                    })
                } else {
                    this.$message({
                        type: 'info',
                        message: '暂无数据'
                    });
                }
            }
        },
        print() {
            // let a = document.getElementById('detailInfo')
            // window.print(a.innerHTML)
            let printableContent = document.getElementById('detailInfo').innerHTML
            let printWindow = window.open('', '_blank');
            printWindow.document.write(`<html><head><title>打印内容</title><style>.sign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

 .tg {
  border-collapse: collapse;
  border-spacing: 0;
  word-break: normal;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-0lax {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}</style></head><body><div class="detailInfo">${printableContent}</div></body></html>`);
            printWindow.document.close();
            setTimeout(() => {
                printWindow.print()
            }, 200)

        },
        addRole() {
            console.log('添加');
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        editerow() {
            console.log('editerow');
        },
        deleterow() {

        },
        // ----------------------------------------------------------------
        handleSizeChange(val) { //切换每页条数
            this.page.pageSize = val;
            this.getData()
        },
        changePage(val) { //选择第几页
            this.page.currentPage = val;
            this.getData()
        },
        searchTable(t) { // 搜索/重置
            if (t === 'search') {
                this.page.currentPage = 1
                this.getData()
            } else {
                for (const key in this.searchForm) {
                    if (this.searchForm[key] instanceof Array) {
                        this.searchForm[key] = []
                    } else {
                        this.searchForm[key] = ''
                    }
                }
                this.getData()
            }
        },
        getDict(){
            this.$Api.shipPlan_Dict().then(res=>{
                if(res.code == 200){
                    this.shipPortBegin = res.data.shipPortBegin
                    this.shipPortEnd = res.data.shipPortEnd
                }
            })
        },
        getData() { // 获取数据
            let data = {
                page: this.page.currentPage,
                limit: this.page.pageSize,
                startTimeStart:this.searchForm.startTimeStart,
                startPort:this.searchForm.startPort,
                destPort:this.searchForm.destPort,
            }
            this.tableLoading = true
            this.$Api.settle_getTariffDefend(data).then(res => {
                this.tableLoading = false
                if (res.code == 200) {
                    if (res.data.total) this.page.total = res.data.total;
                    this.tableData = res.data.data
                }
            })
        },
    }
}
</script>
    
<style lang="scss" scoped>
.TariffDefend {
    height: inherit;

    .searchBox {
        padding-top: 15px;

        /deep/.el-form--inline .el-form-item {
            margin-bottom: 5px;
        }

        .outbox {
            /deep/.el-form-item__label {
                width: 120px;
            }
        }

        /deep/.el-table .el-table__cell {
            padding: 4px 0;
        }

        .showData {
            display: flex;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .item {
                    margin: 0 10px 5px 0;
                }
            }
        }
    }

    .container {
        width: 100%;
        // height: 800px;
        flex: 1;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(214, 216, 219);
            border-radius: 4px;
        }

        // 滚动条的宽度
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px; // 横向滚动条
            height: 8px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 3px;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar:hover {
            width: 15px; // 横向滚动条
            height: 15px; // 纵向滚动条 必写
        }

        .operateBox {
            margin: 0px auto 10px;
            width: 98%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: center;

                .item {
                    margin-left: 10px;

                    /deep/.el-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                    }
                }
            }
        }

        .tip {
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
            border-bottom: 1px solid #f6f6f6;
            color: #333;
            border-radius: 2px 2px 0 0;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
                color: #1e9fff;
                font-size: 18px;
                margin-right: 6px;
            }
        }

        // .el-table{
        //     height: 800px;
        // }

    }
}
</style>